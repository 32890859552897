
import { Component, Vue } from 'vue-property-decorator';
import { GET_MAPPINGS, GET_MAPPING_TYPES, GET_SHOP } from '@/graphlql/queries';
import { DRY_RUN, TEST_DOWNLOAD } from '@/graphlql/mutations';

@Component({
  props: ['value'],
  components: {},
  apollo: {
    getShop: {
      query: GET_SHOP,
      variables() {
        return { getShopId: parseInt(this.$route.params.shopId, 10) };
      },
    },
    mappingTypes: {
      query: GET_MAPPING_TYPES,
    },
    mappings: {
      query: GET_MAPPINGS,
      manual: true,
      variables() {
        return {
          mappingsShopId: parseInt(this.$route.params.shopId, 10),
          mappingsLimit: 10,
          mappingsOffset: 0,
          mappingsType: 0,
        };
      },
      result(data: any) {
        if (data != null && data.data != null) {
          if (data.data.mappings != null) {
            this.analyse.categories = data.data.mappings.count;
            if (this.loading == true && data.data.mappings.count > 0) {
              this.done = true;
              this.loading = false;
              this.progress = 100;
            }
          }
        }
      },
    },
  },
})
export default class AssistentOverview extends Vue {
  loading: boolean = false;
  progress: number = 0;

  download: any = {
    loading: false,
    success: false,
    error: false,
  };

  analyse: any = {
    loading: false,
    success: false,
    error: false,
    total: 0,
    categories: 0,
  };

  mappingTypes: any[] = [];
  getShop: any = { config: {} };

  done: boolean = false;

  startAssistent() {
    // Call to the graphql mutation
    this.loading = true;

    this.download = {
      loading: true,
      success: false,
      error: false,
    };

    this.analyse = {
      loading: false,
      success: false,
      error: false,
      total: 0,
      categories: 0,
    };

    this.progress = 0;
    this.$apollo
      .mutate({
        mutation: TEST_DOWNLOAD,
        // Parameters
        variables: {
          testDownloadForUrlProcessUrl: this.getShop.config.processUrl,
        },
      })
      .then((data) => {
        this.download.loading = false;
        if (
          data != null &&
          data.data != null &&
          data.data.testDownloadForUrl != null &&
          data.data.testDownloadForUrl.success
        ) {
          this.download.error = false;
          this.download.success = true;
        } else {
          this.download.error = true;
          this.download.success = false;
        }

        this.progress = 25;

        setTimeout(() => this.startAnalyse(), 100);
      })
      .catch((error) => {
        this.download.loading = false;
        this.download.error = true;
        this.download.success = false;
        this.loading = false;
      });
  }

  startAnalyse() {
    this.analyse.loading = true;
    this.progress = 25;
    this.$apollo
      .mutate({
        mutation: DRY_RUN,
        // Parameters
        variables: {
          dryRunShopId: this.getShop.id,
        },
      })
      .then((data) => {
        this.analyse.loading = false;
        if (data != null && data.data != null && data.data.dryRun != null && data.data.dryRun.success) {
          this.progress = 80;
          this.analyse.error = false;
          this.analyse.success = true;
          this.analyse.total = data.data.dryRun.total;
        } else {
          this.progress = 100;
          this.analyse.error = true;
          this.analyse.success = false;
        }

        this.$emit('file-downloaded', data.data.dryRun.path);

        setTimeout(() => this.getShopCategories(), 100);
      })
      .catch((error) => {
        this.analyse.loading = false;
        this.analyse.error = true;
        this.analyse.success = false;
        this.loading = false;
      });
  }

  async getShopCategories() {
    let categoryMapping: number = -1;

    if (this.mappingTypes == null || this.mappingTypes.length == 0) {
      await this.$apollo.queries.mappingTypes.refetch();

      console.log(this);
    }

    for (const mappingType of this.mappingTypes) {
      if (mappingType.name == 'category') {
        categoryMapping = mappingType.id;
        break;
      }
    }

    if (categoryMapping > -1) {
      this.$apollo.queries.mappings.refetch({
        mappingsShopId: parseInt(this.$route.params.shopId, 10),
        mappingsLimit: 10,
        mappingsOffset: 0,
        mappingsType: categoryMapping,
      });
    } else {
      this.done = false;
      this.loading = false;
    }
  }

  nextStep() {
    this.$emit('step-finished');
  }
}
