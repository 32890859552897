
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseView from '@/views/common/BaseView.vue';
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import SmallDashboardCard from '@/components/dashboard/SmallDashboardCard.vue';
import ViewStatisticsChart from '@/components/statistics/ViewStatisticsChart.vue';
import getConfig from '@/helper/config-loader';

@Component({
  components: { BaseView, DashboardCard, SmallDashboardCard, ViewStatisticsChart },
})
export default class DashboardView extends Vue {
  config: any = getConfig('dashboard');
  items: any[] = [];

  getComponent(component: any) {
    if (component?.type && component?.type != 'Component') {
      return () => import(`@/components/dashboard/components/${component?.type}.vue`);
    }
    if (component?.component) {
      return () => import(`@/components/${component?.component}`);
    }
  }

  resolveComponents(items: any[]): any[] {
    const result: any[] = [];

    for (const item of items) {
      const obj: any = item;
      obj._computedComponent = this.getComponent(item);

      if (item.children) {
        obj.children = this.resolveComponents(item.children);
      }

      result.push(obj);
    }

    return result;
  }

  mounted() {
    this.items = this.resolveComponents(this.config.items);
  }
}
