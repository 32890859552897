
import SmallProductCard from '@/components/product/SmallProductCard.vue';
import {
  GET_BRANDMODELS,
  GET_CLIENTS,
  GET_PRODUCT_BRANDS,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_COLORS_FULL,
  GET_SIZES,
  SEARCH_INFORMATION,
  SEARCH_PRODUCTS,
} from '@/graphlql/queries';
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';

@Component({
  components: {
    BaseCmsComponent,
    SmallProductCard,
  },
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  apollo: {
    searchProducts: {
      query: SEARCH_PRODUCTS,
      manual: true,
      skip() {
        return this.skipProducts;
      },
      variables: {
        searchProductsPage: 0,
        searchProductsSize: 21,
        searchProductsQuery: '',
        searchProductsArgs: [],
        searchProductClient: null,
        searchProductsOrder: 'pop',
      },
      result(data) {
        if (data != null && data.loading == false) {
          if (data.data.searchProducts != null && data.data.searchProducts.pagination != null) {
            this.total = data.data.searchProducts.pagination.total;
            this.page = data.data.searchProducts.pagination.currentPage + 1;
            this.searchProducts = data.data.searchProducts;

            this.$forceUpdate();
          }
        }
      },
    },
    searchInformation: {
      query: SEARCH_INFORMATION,
      variables: {
        searchQuery: '',
        searchInformationClient: 0,
      },
      skip() {
        return this.skipSearchInformation;
      },
      manual: true,
      result(data) {
        if (data.data.searchInformation != null) {
          const searchInformation = data.data.searchInformation;
          if (searchInformation.filter != null) {
            for (const filter of searchInformation.filter) {
              if (filter.key == 'model') {
                this.models = filter.values;
              }
            }
          }
        }

        this.skipProducts = false;
        this.filterProducts();
      },
    },
    clients: {
      query: GET_CLIENTS,
      result(data) {
        if (data.data != null) {
          if (!data.loading) {
            this.clients = data.data.clients;
            this.skipSearchInformation = false;
            this.choosenClient = this.getClient();
            this.updateSearchInformation();
          }
        }
      },
    },
    brands: {
      query: GET_PRODUCT_BRANDS,
      manual: true,
      result(data) {
        if (data.data != null) {
          if (!data.loading) {
            this.brands = [];
            for (const entry of data.data.brands) {
              this.brands = this.brands.concat(entry);
            }
          }
        }
      },
    },
    brandModels: {
      query: GET_BRANDMODELS,
      manual: true,
      result(data) {
        if (data.data != null) {
          if (!data.loading) {
            this.brandModels = [];
            for (const entry of data.data.getBrandModels) {
              this.brandModels = this.brandModels.concat(entry);
            }
            this.$forceUpdate();
          }
        }
      },
    },
    categories: {
      query: GET_PRODUCT_CATEGORIES,
    },
    sizes: {
      manual: true,
      query: GET_SIZES,
      variables: {
        getShoeSizesKey: 'nike',
      },
      result(data) {
        if (data.data != null) {
          if (!data.loading) {
            this.sizes = [];
            for (const entry of data.data.getShoeSizes.women) {
              this.sizes = this.sizes.concat(entry.eu);
            }
          }
        }
      },
    },
    colors: {
      manual: true,
      query: GET_PRODUCT_COLORS_FULL,
      result(data) {
        if (data.data != null) {
          if (!data.loading) {
            this.colors = [];
            for (const entry of data.data.colors) {
              this.colors = this.colors.concat(entry.name);
            }
          }
        }
      },
    },
  },
  watch: {
    choosenBrand() {
      const self = this as SearchEngineList;
      self.updateContext();
    },
    choosenCategory() {
      const self = this as SearchEngineList;
      self.updateContext();
    },
    choosenModel() {
      const self = this as SearchEngineList;
      self.updateContext();
    },
    value() {
      const self = this as SearchEngineList;
      self.updateInternalValues();
    },
    choosenColor() {
      const self = this as SearchEngineList;
      self.updateContext();
    },
    choosenSize() {
      const self = this as SearchEngineList;
      self.updateContext();
    },
  },
})
export default class SearchEngineList extends BaseCmsComponentClass {
  loading: boolean = false;
  models: string[] = [];
  colors: string[] = [];
  sizes: string[] = [];
  categories: string[] = [];
  choosenBrand: string = '';
  choosenCategory: string = '';
  choosenModel: string = '';
  choosenColor: string = '';
  choosenSize: string = '';
  choosenClient: number = 0;
  brandModels: any = [];
  brands = [];
  clients = [];
  skipSearchInformation: boolean = true;
  skipProducts: boolean = true;
  searchProducts: any = {};

  updateContext() {
    this.content.context = [];
    if (this.choosenBrand != '' && this.choosenBrand != null) {
      this.content.context.push({
        key: 'brand.name.keyword',
        value: [this.choosenBrand],
      });
    }

    if (this.choosenCategory != '' && this.choosenCategory != null) {
      this.content.context.push({
        key: 'category.name.keyword',
        value: [this.choosenCategory],
      });
    }

    if (this.choosenModel != '' && this.choosenModel != null) {
      this.content.context.push({
        key: 'model.keyword',
        value: [this.choosenModel],
      });
    }

    if (this.choosenColor != '' && this.choosenColor != null) {
      this.content.context.push({
        key: 'color.name.keyword',
        value: [this.choosenColor],
      });
    }

    if (this.choosenSize != '' && this.choosenSize != null) {
      this.content.context.push({
        key: 'availabilities.sizesEu.keyword',
        value: [this.choosenSize],
      });
    }

    this.filterProducts();
  }

  getConfigurationText() {
    let text = '';

    text += this.getContentString(this.content.query, 'Suchbegriff');
    text += this.getContentString(this.choosenCategory, 'Kategorie');
    text += this.getContentString(this.choosenBrand, 'Marke');
    text += this.getContentString(this.choosenModel, 'Modell');
    text += this.getContentString(this.choosenColor, 'Farbe');
    text += this.getContentString(this.choosenSize, 'Größe');

    return text.substring(0, text.length - 2);
  }

  getContentString(object: any, text: string) {
    if (object != null && object != '') {
      return text + ' ' + object + ', ';
    }

    return '';
  }

  updateInternalValues() {
    this.choosenCategory = '';
    this.choosenBrand = '';
    this.choosenModel = '';
    this.choosenColor = '';
    this.choosenSize = '';

    for (const context of this.content.context) {
      if (context.key == 'brand.name.keyword') {
        this.choosenBrand = context.value[0];
      } else if (context.key == 'category.name.keyword') {
        this.choosenCategory = context.value[0];
      } else if (context.key == 'model.name.keyword') {
        this.choosenModel = context.value[0];
      } else if (context.key == 'model.keyword') {
        this.choosenModel = context.value[0];
      } else if (context.key == 'color.name.keyword') {
        this.choosenColor = context.value[0];
      } else if (context.key == 'availabilities.sizesEu.keyword') {
        this.choosenSize = context.value[0];
      }
    }
  }

  async updateSearchInformation() {
    this.$apollo.queries.searchInformation.refetch({
      searchQuery: '',
      searchInformationClient: this.choosenClient,
    });
  }

  async filterProducts() {
    await this.$apollo.queries.searchProducts.refetch({
      searchProductsPage: 1,
      searchProductsSize: 5,
      searchProductsQuery: this.content.query,
      searchProductsArgs: this.content.context != null ? this.content.context : [],
      searchProductClient: this.getClient(),
    });
  }
  getCurrentClientString() {
    let client = null;
    if (this.$props.clientId != null) {
      client = this.$props.clientId;
    }

    client = this.$store.getters['cmsItemsModule/getClient'];
    if (!client) {
      client = this.$route.params.client;
    }

    if (client != null && client.indexOf('-') > 0) {
      client = client.substring(0, client.indexOf('-'));
    }

    return client;
  }

  getClient() {
    if (this.clients != null) {
      const clientString = this.getCurrentClientString();
      for (const client of this.clients) {
        const anyClient = client as any;
        if (anyClient.name != null && anyClient.name.toLowerCase() == clientString) {
          return anyClient.id;
        }
      }
    }

    return 0;
  }

  mounted() {
    if (this.content != null && this.content.context != null && this.content.context.length > 0) {
      this.updateInternalValues();
    }
  }

  get filteredBrandModels() {
    if (this.choosenBrand == '') {
      return this.brandModels;
    }
    let res: any = [];
    for (const entry of this.brandModels) {
      if (entry.brand.name == this.choosenBrand) {
        res = res.concat(entry);
      }
    }
    return res;
  }
}
