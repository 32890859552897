export const login = {
  email: 'E-Mail',
  password: 'Passwort',
  errors: {
    invalidEmail: 'Hm, das scheint keine E-Mail Addresse zu sein.',
    userNameInvalid: 'Das ist kein gültiger Nutzername.',
    passwordTooShort:
      'Dein Passwort hat keine Mindestlänge | Dein Passwort ist mindestens ein Zeichen lang. | Dein Passwort ist mindestens {n} Zeichen lang.',
    invalidUserPassword: 'Dein Benutzername und Passwort stimmen nicht überein. ',
    invalidUserPasswordHint: 'Bitte versuche einen anderen Benutzernamen oder ein anderes Passwort. ',
  },
};
