import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import AdminView from '@/views/admin-view/AdminView.vue';
import MappingView from '@/views/mapping/MappingView.vue';
import AliasesView from '@/views/aliases/AliasesView.vue';
import BrandsView from '@/views/brands/BrandsView.vue';
import FilterBubblesView from '@/views/filterbubbles/filterbubbles.vue';
import BrandModelsView from '@/views/brandmodels/BrandModelsView.vue';
import ClientsView from '@/views/clients/ClientsView.vue';
import ColorsView from '@/views/colors/ColorsView.vue';
import ProductsView from '@/views/products/ProductsView.vue';
import ScoreView from '@/views/score/ScoreView.vue';
import StoresView from '@/views/stores/StoresView.vue';
import StoreView from '@/views/stores/StoreView.vue';
import SeoView from '@/views/seo/SeoView.vue';
import ReportingView from '@/views/reporting/ReportingView.vue';
import StoreAssistentView from '@/views/stores/StoreAssistentView.vue';
import ProductDetailView from '@/views/products/ProductDetail.vue';
import StatisticsView from '@/views/statistics/StatisticsView.vue';
import ViewsStatisticsView from '@/views/statistics/ViewsStatisticsView.vue';
import ProductsStatisticsView from '@/views/statistics/ProductsStatisticsView.vue';
import SalesStatisticsView from '@/views/statistics/SalesStatisticsView.vue';
import QueryStatisticsView from '@/views/statistics/QueryStatisticsView.vue';
import ProductSearchView from '@/views/search/ProductSearchView.vue';
import SearchView from '@/views/search/SearchView.vue';
import UserManagementView from '@/views/usermanagement/UserManagement.vue';
import ToDoView from '@/views/todo/ToDoView.vue';
import LoginView from '@/views/login/LoginView.vue';
import DashboardView from '@/views/admin-view/dashboard/DashboardView.vue';
import SearchDashboardView from '@/views/admin-view/searchdashboard/SearchDashboardView.vue';
import IncomingNikeView from '@/views/incoming/IncomingNikeView.vue';
import SitesView from '@/views/cms/SitesView.vue';
import ReleasesView from '@/views/cms/ReleasesView.vue';
import ReleaseView from '@/views/cms/ReleaseView.vue';
import DealsView from '@/views/cms/DealsView.vue';
import DealView from '@/views/cms/DealView.vue';
import TemplatesView from '@/views/cms/TemplatesView.vue';
import StringTemplatesView from '@/views/cms/StringTemplatesView.vue';
import BlogsView from '@/views/cms/BlogsView.vue';
import PushDetailsView from '@/views/cms/PushDetailsView.vue';
import PushNotificationsView from '@/views/cms/PushNotificationsView.vue';
import InAppFlyerView from '@/views/cms/InAppFlyerView.vue';
import StoriesView from '@/views/cms/StoriesView.vue';
import BlogView from '@/views/cms/BlogView.vue';
import PushDetailView from '@/views/cms/PushDetailView.vue';
import PushNotificationView from '@/views/cms/PushNotificationView.vue';
import StoryView from '@/views/cms/StoryView.vue';
import InAppFlyerDetailView from '@/views/cms/InAppFlyerDetailView.vue';
import TemplateView from '@/views/cms/TemplateView.vue';
import ResellShopsView from '@/views/cms/ResellShopsView.vue';
import ResellShopView from '@/views/cms/ResellShopView.vue';
import InstaFeedsView from '@/views/cms/InstaFeedsView.vue';
import InstaFeedView from '@/views/cms/InstaFeedView.vue';
import CategoryDetailsView from '@/views/cms/CategoryDetailsView.vue';
import CategoryDetailView from '@/views/cms/CategoryDetailView.vue';
import VoucherDetailsView from '@/views/cms/VoucherDetailsView.vue';
import VoucherDetailView from '@/views/cms/VoucherDetailView.vue';
import TagsView from '@/views/tags/TagsView.vue';
import ImagesView from '@/views/images/ImagesView.vue';

import store from '../store/index';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: AdminView,
    children: [
      {
        path: '',
        name: 'Home',

        component: DashboardView,
      },
      {
        path: '/mapping',
        name: 'Mapping',

        component: MappingView,
      },
      {
        path: '/search',
        name: 'Search',

        component: SearchView,
      },
      {
        path: '/brands',
        name: 'Brands',

        component: BrandsView,
      },
      {
        path: '/clients',
        name: 'Mandanten',

        component: ClientsView,
      },
      {
        path: '/colors',
        name: 'Colors',

        component: ColorsView,
      },
      {
        path: '/products/home',
        name: 'Products Dashboard',
        component: SearchDashboardView,
      },
      {
        path: '/products',
        name: 'Shoes',

        component: ProductsView,
      },
      {
        path: '/images',
        name: 'Images',

        component: ImagesView,
      },
      {
        path: '/products/:articleId',
        name: 'ProductDetail',

        component: ProductDetailView,
      },
      {
        path: '/stores',
        name: 'StoresView',

        component: StoresView,
      },
      {
        path: '/stores/:id',
        name: 'StoreViews',

        component: StoreView,
      },
      {
        path: '/seo',
        name: 'Seo',

        component: SeoView,
      },
      {
        path: '/statistics',
        name: 'Statistiken',

        component: StatisticsView,
      },
      {
        path: '/statistics/views',
        name: 'ViewsStatistiken',

        component: ViewsStatisticsView,
      },
      {
        path: '/statistics/sales',
        name: 'SalesStatistiken',

        component: SalesStatisticsView,
      },
      {
        path: '/statistics/products',
        name: 'ProduktStatistiken',

        component: ProductsStatisticsView,
      },
      {
        path: '/statistics/query',
        name: 'SuchStatistiken',

        component: QueryStatisticsView,
      },
      {
        path: '/reporting',
        name: 'Import Protokoll',

        component: ReportingView,
      },
      {
        path: '/stores/assistent/:shopId',
        name: 'Store Assistent',

        component: StoreAssistentView,
      },
      {
        path: '/aliases',
        name: 'Aliases',
        component: AliasesView,
      },
      {
        path: '/scores',
        name: 'Scores',
        component: ScoreView,
      },
      {
        path: '/brandmodels',
        name: 'BrandModels',
        component: BrandModelsView,
      },
      {
        path: '/filterbubbles',
        name: 'Filterbubbles',
        component: FilterBubblesView,
      },
      {
        path: 'productsearch',
        name: 'Suche',

        component: ProductSearchView,
      },
      {
        path: 'usermanagement',
        name: 'UserManagement',

        component: UserManagementView,
      },
      {
        path: 'incoming/nike',
        name: 'Nike Restock',

        component: IncomingNikeView,
      },
      {
        path: 'todo',
        name: 'ToDo',

        component: ToDoView,
      },
      {
        path: 'cms/sites/:clientId',
        name: 'Sites',

        component: SitesView,
      },
      {
        path: 'cms/sites/:clientId/:siteId',
        name: 'SitesDetail',

        component: SitesView,
      },
      {
        path: 'cms/deals/:dealType/:clientA/:clientB',
        name: 'DealsView',

        component: DealsView,
      },
      {
        path: 'cms/deals/:dealType/:clientA/:clientB/:dealId',
        name: 'DealView',

        component: DealView,
      },
      {
        path: 'cms/templates/:client',
        name: 'Templates',

        component: TemplatesView,
      },
      {
        path: 'cms/stringtemplates/:client/:type',
        name: 'StringTemplates',

        component: StringTemplatesView,
      },
      {
        path: 'cms/templates/:client/:templateId',
        name: 'Template',
        component: TemplateView,
      },

      {
        path: 'cms/releases/:clientA/:clientB',
        name: 'ReleasesView',

        component: ReleasesView,
      },
      {
        path: 'cms/releases/:clientA/:clientB/:dealId',
        name: 'DealView',

        component: ReleaseView,
      },

      {
        path: 'cms/blogs/:client',
        name: 'BlogsView',

        component: BlogsView,
      },
      {
        path: 'cms/blogs/:client/:id',
        name: 'BlogsDetail',

        component: BlogView,
      },

      {
        path: 'cms/pushdetails/:client',
        name: 'PushDetailsView',

        component: PushDetailsView,
      },
      {
        path: 'cms/pushdetails/:client/:id',
        name: 'PushDetailsDetail',

        component: PushDetailView,
      },
      {
        path: 'cms/categories/:client',
        name: 'CategoryDetailsView',

        component: CategoryDetailsView,
      },
      {
        path: 'cms/categories/:client/:id',
        name: 'CategoryDetailView',

        component: CategoryDetailView,
      },

      {
        path: 'cms/pushnotifications/:client',
        name: 'PushNotificationsView',

        component: PushNotificationsView,
      },
      {
        path: 'cms/pushnotifications/:client/:id',
        name: 'PushNotificationDetail',

        component: PushNotificationView,
      },
      {
        path: 'cms/inappflyer/:client',
        name: 'In-App Flyer',

        component: InAppFlyerView,
      },
      {
        path: 'cms/inappflyer/:client/:id',
        name: 'In-App Flyer Detail',

        component: InAppFlyerDetailView,
      },
      {
        path: 'cms/stories/:client',
        name: 'Stories',

        component: StoriesView,
      },
      {
        path: 'cms/stories/:client/:id',
        name: 'StoryDetail',

        component: StoryView,
      },

      {
        path: 'cms/instafeeds/:client',
        name: 'InstaFeedsView',

        component: InstaFeedsView,
      },
      {
        path: 'cms/instafeeds/:client/:id',
        name: 'InstaFeedView',

        component: InstaFeedView,
      },

      {
        path: 'cms/shops/:client',
        name: 'ResellShopsView',

        component: ResellShopsView,
      },
      {
        path: 'cms/shops/:client/:id',
        name: 'ResellShopView',

        component: ResellShopView,
      },
      {
        path: 'cms/tags/:client',
        name: 'TagsView',

        component: TagsView,
      },
      {
        path: 'cms/vouchers/:client',
        name: 'VoucherDetailsView',

        component: VoucherDetailsView,
      },
      {
        path: 'cms/vouchers/:client/:id',
        name: 'VoucherDetailView',

        component: VoucherDetailView,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const result = await store.dispatch('authModule/checkAuth');
  if (result === false && to.name !== 'Login') {
    console.log('returning to login');
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
