
import Vue from 'vue';
import BaseCmsComponent from './BaseCmsComponent.vue';

export default class BaseCmsComponentClass extends Vue {
  public content: any;
  public settings: any;

  openHandler() {
    if (this.$refs['baseComponent'] != null) {
      const baseComponent = this.$refs['baseComponent'] as BaseCmsComponent;
      baseComponent.openHandler();
    }
  }
}
