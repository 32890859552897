
import { Component, Vue } from 'vue-property-decorator';
import ClientReferrerView from '@/components/clients/ClientReferrerView.vue';
import ClientAdvertsView from '@/components/clients/ClientAdvertsView.vue';
import ClientImagesView from '@/components/clients/ClientImagesView.vue';

@Component({
  props: ['value'],
  components: { ClientReferrerView, ClientAdvertsView, ClientImagesView },
})
export default class ClientView extends Vue {
  value: any;

  activeTab: number = 0;
}
