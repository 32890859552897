
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import clients from '../../graphClient';
import { CMS_CREATE_TEMPLATE, CMS_UPDATE_TEMPLATE, UPDATE_USER_USAGE, CREATE_USER_USAGE } from '@/graphlql/mutations';
import CMSView from './CMSView.vue';
import { CMS_GET_DEAL, CMS_GET_TEMPLATE, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import * as helper from '@/helper/data-helper';
import VersionsTable from '@/components/versions/VersionsTable.vue';

@Component({
  components: {
    BaseView,
    CMSView,
    VersionsTable,
  },
  apollo: {
    getTemplate: {
      query: CMS_GET_TEMPLATE,
      client: 'clientB',
      variables: {
        id: '0',
      },
      skip() {
        return this.skipQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getTemplate != null) {
          this.template = data.data.getTemplate;
          this.$store.dispatch('cmsItemsModule/setItems', this.template.content.dynamicContent);
          this.siteTitle = 'Template (' + this.template.title + ') speichern';
          this.loaded = true;
          this.changed = false;

          this.$store.dispatch('cmsItemsModule/setClient', data.data.getTemplate.client);
          //   this.setItems(this.page.content.dynamicContent);
        } else {
          this.$store.dispatch('cmsItemsModule/setItems', []);
        }
      },
      error() {
        this.$store.dispatch('cmsItemsModule/setItems', []);
      },
    },
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.templateId,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.templateId;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.dealId !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.templateId !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.templateId !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.templateId === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
  },
  watch: {
    $route(to, from) {
      const self = this as TemplateView;
      self.refetch();
    },
    template: {
      handler() {
        const self = this as TemplateView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    const self = this as TemplateView;
    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class TemplateView extends Vue {
  siteTitle: string = '';
  skipQuery: boolean = true;
  templateTypes: string[] = ['deal', 'blog', 'releases', 'pushdetail'];

  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';
  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'template',
    lastTimeEdited: new Date(),
  };

  changed: boolean = false;
  loaded: boolean = false;
  template: any = {
    content: {
      dynamicContent: [],
    },
    title: '',
  };

  getEmpty() {
    return {
      content: {
        dynamicContent: [],
      },
      title: '',
    };
  }

  refetch() {
    if (this.$route.params.templateId == 'create') {
      this.siteTitle = 'Template erstellen';
      this.template = this.getEmpty();

      this.$store.dispatch('cmsItemsModule/setItems', this.template.content.dynamicContent);
      this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.client);
    } else if (this.$route.params.templateId != null) {
      this.skipQuery = false;
      this.$apollo.queries.getTemplate.refetch({
        id: this.$route.params.templateId,
      });
    }
  }

  mounted() {
    this.refetch();
  }

  async saveNew() {
    this.template.client = this.$route.params.client;
    clients.clientB
      .mutate({
        mutation: CMS_CREATE_TEMPLATE,
        variables: {
          template: helper.cleanTypeData(this.template, ['__typename', 'updatedAt', 'createdAt']),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.createTemplate != null && data.data.createTemplate._id) {
          this.$router.push({
            path: `/cms/templates/${this.$route.params.client}/${data.data.createTemplate._id}`,
          });
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async saveExisting() {
    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_TEMPLATE,
        variables: {
          template: helper.cleanTypeData(this.template, ['__typename', 'updatedAt', 'createdAt']),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.updateTemplate != null && data.data.updateTemplate._id) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async userLeave() {
    this.currentUsage.inUse = false;

    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  async saveHandler() {
    if (this.$route.params.templateId == 'create') {
      this.saveNew();
    } else if (this.$route.params.templateId != null) {
      this.saveExisting();
    }
  }
}
