
import { Component, Vue } from 'vue-property-decorator';
import LineChart from '../common/LineChart.vue';

import { GET_PRODUCT_STATISTICS } from '@/graphlql/queries';
import { TRACK_FAKES, TRACK_MUTATION } from '@/graphlql/mutations';

@Component({
  components: { LineChart },
  props: ['value'],
  watch: {
    value(newProduct, oldProduct) {
      const self = this as ProductStatistics;
      self.updateData();
    },
  },
  apollo: {
    statistics: {
      query: GET_PRODUCT_STATISTICS,
      variables: {
        statisticsStartDate: new Date().setDate(new Date().getDate() - 7),
        statisticsProduct: 1,
      },
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.statistics != null) {
          const wishlist = [];
          const views = [];
          const clicks = [];
          const fakes = [];
          const labels = [];

          for (const entry of data.data.statistics) {
            wishlist.push(entry.wishlist);
            views.push(entry.views);
            clicks.push(entry.clicks);
            fakes.push(entry.fakes);
            labels.push(new Date(entry.date).toLocaleDateString());
          }

          this.datacollection = {
            labels: labels,
            datasets: [
              {
                label: 'Views',
                data: views,
                borderColor: '#48c774',
                backgroundColor: '#48c77466',
              },
              {
                label: 'Clicks',
                data: clicks,
                borderColor: '#ffdd57',
                backgroundColor: '#ffdd5766',
              },
              {
                label: 'Wishlist',
                data: wishlist,
                borderColor: '#3298dc',
                backgroundColor: '#3298dc66',
              },
              {
                label: 'Fakes',
                data: fakes,
                borderColor: '#000000',
                backgroundColor: '#00000066',
              },
            ],
          };
        }
      },
    },
  },
})
export default class ProductStatistics extends Vue {
  value: any;
  fakeValue: number = 10;
  datacollection: any = {};

  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;

  addFakes() {
    console.log(this.value);
    this.$apollo
      .mutate({
        // Query
        mutation: TRACK_FAKES,
        // Parameters
        variables: {
          trackFakesInput: {
            value: this.fakeValue,
            client: this.value.client,
            product: '' + this.value.id,
          },
        },
      })
      .then((data) => {
        this.loading = false;
        this.result = true;
        this.error = false;
        this.success = true;
        setTimeout(() => this.clearStates(), 1000);
      })
      .catch((error) => {
        this.loading = false;
        this.result = true;
        this.error = true;
        this.success = false;
        setTimeout(() => this.clearStates(), 1000);
      });
  }

  clearStates() {
    this.loading = false;
    this.result = false;
    this.error = false;
    this.success = false;

    this.$apollo.queries.statistics.refetch({
      statisticsProduct: this.value.id,
    });
  }

  updateData() {
    this.$apollo.queries.statistics.refetch({
      statisticsProduct: this.value.id,
    });
  }

  async mounted() {
    this.updateData();
  }
}
