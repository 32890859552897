
import EditSingleColor from '@/components/common/EditSingleColor.vue';
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import clients from '../../graphClient';
import {
  CMS_CREATE_VOUCHER_LIST,
  CMS_DELETE_VOUCHER_LIST,
  CMS_UPDATE_VOUCHER_LIST,
  UPDATE_USER_USAGE,
  CREATE_USER_USAGE,
} from '@/graphlql/mutations';
import CMSView from './CMSView.vue';
import { CMS_GET_VOUCHER_LIST, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import * as helper from '@/helper/data-helper';
import UploadFile from '@/components/cms/UploadFile.vue';
import VersionsTable from '@/components/versions/VersionsTable.vue';

@Component({
  components: {
    BaseView,
    CMSView,
    UploadFile,
    EditSingleColor,
    VersionsTable,
  },
  apollo: {
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.id,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = await this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.id;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.dealId !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.id !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.id !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.id === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
    getVoucherList: {
      query: CMS_GET_VOUCHER_LIST,
      client: 'clientB',
      variables: {
        getVoucherListId: '0',
      },
      skip() {
        return this.skipQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getVoucherList != null) {
          if (data.data.getVoucherList['expirationDate'] != null) {
            data.data.getVoucherList['expirationDate'] = new Date(data.data.getVoucherList['expirationDate']);
          }

          this.voucher = data.data.getVoucherList;
          if (this.voucher['isActive'] == null) {
            this.voucher['isActive'] = false;
          }
          this.siteTitle = 'Gutscheine (' + this.voucher.name + ') speichern';

          this.loaded = true;
          this.changed = false;
        }
      },
    },
  },
  watch: {
    $route(to, from) {
      const self = this as VoucherDetailView;
      self.refetch();
    },
    voucher: {
      handler() {
        const self = this as VoucherDetailView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    const self = this as VoucherDetailView;
    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class VoucherDetailView extends Vue {
  siteTitle: string = '';
  skipQuery: boolean = true;
  skipPushQuery: boolean = true;

  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';

  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'voucher',
    lastTimeEdited: new Date(),
  };

  changed: boolean = false;
  loaded: boolean = false;

  updatePath(path: any) {
    this.voucher.filePath = path;
  }

  voucher: any = {
    name: '',
    isActive: true,
    expirationDate: new Date(),
    filePath: '',
  };

  getEmpty() {
    return {
      name: '',
      isActive: true,
      expirationDate: new Date(),
      filePath: '',
    };
  }

  refetch() {
    if (this.$route.params.id == 'create') {
      this.siteTitle = 'Gutscheine erstellen';
      this.voucher = this.getEmpty();
    } else if (this.$route.params.id != null) {
      this.skipQuery = false;
      this.$apollo.queries.getVoucherList.refetch({
        getVoucherListId: this.$route.params.id,
      });
    }
  }

  mounted() {
    this.refetch();
  }

  async saveNew() {
    clients.clientB
      .mutate({
        mutation: CMS_CREATE_VOUCHER_LIST,
        variables: {
          voucherList: this.getData(),
        },
      })
      .then((data) => {
        if (
          data !== null &&
          data.data !== null &&
          data.data.createVoucherList !== null &&
          data.data.createVoucherList.id
        ) {
          this.$router.push({
            path: `/cms/vouchers/${this.$route.params.client}/${data.data.createVoucherList.id}`,
          });
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async saveExisting() {
    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_VOUCHER_LIST,
        variables: {
          voucherList: {
            id: this.voucher.id,
            isActive: this.voucher.isActive,
            name: this.voucher.name,
            expirationDate: new Date(this.voucher.expirationDate),
          },
        },
      })
      .then((data) => {
        if (
          data != null &&
          data.data != null &&
          data.data.updateVoucherList != null &&
          data.data.updateVoucherList.id
        ) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
          this.siteTitle = 'Gutschein (' + this.voucher.name + ') speichern';
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  getData() {
    const returnData = JSON.parse(
      JSON.stringify(helper.cleanTypeData(this.voucher, ['__typename', 'updatedAt', 'createdAt']))
    );

    if (this.voucher['expirationDate']) {
      returnData['expirationDate'] = new Date(this.voucher['expirationDate']);
    }

    return returnData;
  }

  async userLeave() {
    this.currentUsage.inUse = false;

    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  async saveHandler() {
    if (this.$route.params.id == 'create') {
      this.saveNew();
    } else if (this.$route.params.id != null) {
      this.saveExisting();
    }
  }

  async deleteHandler() {
    const content = prompt('Bitte "löschen" zum bestätigen eingeben');
    if (content === 'löschen') {
      if (this.voucher?.id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_VOUCHER_LIST,
          variables: {
            deleteVoucherListId: this.voucher.id,
          },
        });
      }

      // Surpress unsaved change prompt, a bit hacky but works
      this.changed = false;
      this.$router.push({ name: 'VoucherDetailsView' });
    }
  }
}
