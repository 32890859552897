
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import NavbarTop from '@/components/navigation/NavbarTop.vue';
import SidebarNavigation from '@/components/navigation/SidebarNavigation.vue';
import MenuClickCreatedMixin from '@/events/navigation/NavigationEventBus';
import { NavigationEventBus } from '@/events/navigation/NavigationEventBus';

@Component({
  components: { SidebarNavigation, NavbarTop },
  props: {
    appName: {
      type: String,
      required: true,
    },
  },
})
export default class AdminView extends mixins<Vue>(MenuClickCreatedMixin) {
  public menuIsOpen = false;
  public contentIsFullscreen = false;
  homePath = { name: 'Home' };

  mounted() {
    NavigationEventBus.$on('show-fullscreen', (data: boolean) => {
      this.contentIsFullscreen = data;
    });
    this.updateContext();
  }

  // Sets the context-colors for the overview and detail pages
  updateContext() {
    const navigationPath = this.$store.getters['navigationModule/getNavigationElements'];

    for (const parent in navigationPath) {
      let found = false;
      for (const c in navigationPath[parent].children) {
        const child = navigationPath[parent].children[c];

        // If you reload the detail pages (e.g. the page for a single deal)
        // context-color-details is not properly set. This is why we have to set it again.
        // In this case we have to remove the id from the path, thats what the split is used for
        let path: any = this.$route.path.split('/');
        path.splice(path.length - 1);
        path = path.join('/');

        if (child.path == path) {
          //Setting colors when reloading Detail page e.g. http://localhost:8080/cms/deals/single/snkr-app/snkr-web/638757ec7b2be5aaa5424ec1
          //The id was removed by the split above
          const r = document.documentElement;
          r.style.setProperty('--context-color', navigationPath[parent].contextColor);
          r.style.setProperty('--context-color-details', child.contextColor);
          found = true;
        }

        // Setting colors when loading the table / overview pages.
        if (child.path == this.$route.path) {
          const r = document.documentElement;
          r.style.setProperty('--context-color', navigationPath[parent].contextColor);
          r.style.setProperty('--context-color-details', child.contextColor);
          found = true;
        }
        if (found) {
          break;
        }
      }

      if (found) {
        break;
      }
    }
  }

  updated() {
    this.updateContext();
  }
}
