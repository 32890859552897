
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { GET_ALL_COLORS } from '@/graphlql/queries';
import { UPDATE_COLOR } from '@/graphlql/mutations';

@Component({
  props: ['value'],
  apollo: {
    colors: {
      query: GET_ALL_COLORS,
      manual: true,

      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            const colors: any = [];
            for (const color of data.data.getAllColors) {
              const simpleColor = {
                id: color.id,
                name: color.name,
              };
              colors.push(simpleColor);
            }
            this.colors = colors;
          }
        }
      },
    },
  },
  watch: {
    value() {
      const self = this as EditReplaceSingleColor;
      if (self.value != null) {
        self.selected = self.value;
        self.hex = self.value.hex 
      } else if (self.value == null) {

        self.value.hex = '#ffffff';
      }
    },
  },
})
export default class EditReplaceSingleColor extends Vue {
  value: any;

  $refs!: {
    colorInput: HTMLInputElement;
  };

  private hex: string = '#ffffff';
  private colors: any[] = [];
  private selected: any = {};
  private selectedColor: any = {
    id: -1,
    name: '',
    color: '',
    hex: '',
    image: '',
  };
  private saveLoading = false;
  private saveResult = false;
  private saveSuccess = false;
  private saveError = false;
  private isActive = false;

  set chosenColor(value: any) {
    this.selectedColor = value;
  }
  get chosenColor() {
    return this.selectedColor;
  }

  resetActive() {
    setTimeout(() => {
      return !this.isActive;
    }, 1000);
  }

  async overwriteColors() {
    this.saveLoading = true;
    if (this.selectedColor != null && this.selected != null) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_COLOR,
          variables: {
            colorId: this.selected.id,
            name: this.selected.name,
            correctedColorId: this.selectedColor.id,
          },
        })
        .then((data) => {
          this.saveResult = true;
          this.saveSuccess = true;
          this.selected.id = -1;
          this.$emit('reload-color-table', { message: 'Reloading Colors' });
          setTimeout(() => this.resetSaveButton(), 1000);
        })
        .catch((error) => {
          console.log(error);
          this.saveError = true;
          setTimeout(() => this.resetSaveButton(), 1000);
        });
    }
  }

  resetSaveButton() {
    this.saveSuccess = false;
    this.saveError = false;
    this.saveResult = false;
    this.saveLoading = false;
  }

  mounted() {
    if (this.value != null) {
      this.selected = this.value;
      this.hex = this.value.hex;
    }
    this.loadAsyncData();
  }
  async loadAsyncData() {
    await this.$apollo.queries.colors.refetch();
  }

  updateEditorColor() {
    this.$emit('value-changed', this.hex);
  }

  isDisable() {
    if (this.selectedColor.id !== -1 && this.selected.id !== -1) return true;
    else return false;
  }

  selectColor() {
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    this.$refs.colorInput.dispatchEvent(clickEvent);
  }

  findColorInvert(hex: string) {
    let brightness = 255;
    if (hex != null && hex.length >= 6) {
      const r = parseInt(hex[1] + hex[2], 16);
      const g = parseInt(hex[3] + hex[4], 16);
      const b = parseInt(hex[5] + hex[6], 16);
      brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
    }
    return brightness > 125 ? '#000000' : '#ffffff';
  }

  buttonStyle() {
    return {
      backgroundColor: this.hex,
      color: this.findColorInvert(this.hex),
    };
  }
}
