export const login = {
  email: 'E-Mail',
  password: 'Password',
  errors: {
    invalidEmail: 'Hm, this seems to be no valid email.',
    userNameInvalid: 'Das ist kein gültiger Nutzername.',
    passwordTooShort:
      'Your Passwort does not have the minimum number of characters. | Your password contains minimum one character. | You password contains minimum {n} characters.',
    invalidUserPassword: 'Your username and password dont match.',
    invalidUserPasswordHint: 'Please try another username or password.'
  }
};
