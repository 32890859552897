
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import clients from '../../graphClient';
import {
  CMS_CREATE_IN_APP_FLYER,
  CMS_UPDATE_IN_APP_FLYER,
  CMS_DELETE_IN_APP_FLYER,
  UPDATE_USER_USAGE,
  CREATE_USER_USAGE,
} from '@/graphlql/mutations';
import CMSView from './CMSView.vue';
import { CMS_GET_IN_APP_FLYER, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import * as helper from '@/helper/data-helper';
import UploadImage from '@/components/cms/UploadImage.vue';
import ReferenceInput from '@/components/cms/ReferenceInput.vue';
import CustomTagInput from '@/components/tags/CustomTagInput.vue';
import VersionsTable from '@/components/versions/VersionsTable.vue';
import DealPrice from '@/components/cms/DealPrice.vue';
import DealVoucher from '@/components/cms/DealVoucher.vue';

@Component({
  components: {
    BaseView,
    CMSView,
    UploadImage,
    ReferenceInput,
    CustomTagInput,
    VersionsTable,
    DealPrice,
    DealVoucher,
  },
  apollo: {
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.id,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.id;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.id !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`,
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.id !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.id !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.id === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
    getInAppFlyerById: {
      query: CMS_GET_IN_APP_FLYER,
      client: 'clientB',
      variables: {
        getInAppFlyerByIdId: '0',
      },
      skip() {
        return this.skipQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getInAppFlyerById != null) {
          this.objectData = data.data.getInAppFlyerById;
          if (this.objectData.image == null) {
            this.objectData.image = {};
          }
          if (this.objectData.content == null) {
            this.objectData.content = undefined;
          }

          if (data.data.getInAppFlyerById.startDate) {
            this.objectData.startDate = new Date(data.data.getInAppFlyerById.startDate);
          }

          if (data.data.getInAppFlyerById.endDate) {
            this.objectData.endDate = new Date(data.data.getInAppFlyerById.endDate);
          }

          this.siteTitle = 'In-App Fyler speichern';

          this.changed = false;
          this.loaded = true;
        }
      },
    },
  },
  computed: {
    getClient() {
      const self = this as InAppFlyerDetailView;
      if (self.objectData.referenceType === 'pushdetail') {
        return self.$route.params.client;
      }
      return self.$route.params.client + '-app';
    },
  },
  watch: {
    $route(to, from) {
      const self = this as InAppFlyerDetailView;
      self.refetch();
    },
    objectData: {
      handler() {
        const self = this as InAppFlyerDetailView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    const self = this as InAppFlyerDetailView;
    if (self.changed) {
      const answer = window.confirm('Möchtest du die Seite wirklich verlassen? Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class InAppFlyerDetailView extends Vue {
  siteTitle: string = 'In App Flyer erstellen';
  skipQuery: boolean = true;
  changed: boolean = false;
  loaded: boolean = false;
  objectData: any = {
    image: {},
    isActive: false,
    startDate: null,
    endDate: null,
    reference: '',
    referenceType: 'link',
    headline: '',
    dealHeadline: '',
    subline: '',
    priceText: {},
    price: null,
    reducedPrice: null,
    strikePrice: '',
    voucher: '',
    voucherFile: '',
    voucherText: '',
    bullet1Key: '',
    bullet1Val: '',
    bullet2Key: '',
    bullet2Val: '',
    bullet3Key: '',
    bullet3Val: '',
    bullet4Key: '',
    bullet4Val: '',
    buttonLabel: '',
  };
  inUsage: boolean = false;
  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'in-app-flyer',
    lastTimeEdited: new Date(),
  };

  getEmpty() {
    return {
      image: {},
      isActive: false,
      startDate: null,
      endDate: null,
      reference: '',
      referenceType: '',
      headline: '',
      dealHeadline: '',
      subline: '',
      price: null,
      priceText: {},
      strikePrice: '',
      reducedPrice: null,
      voucher: '',
      voucherFile: '',
      voucherText: '',
      bullet1Key: '',
      bullet1Val: '',
      bullet2Key: '',
      bullet2Val: '',
      bullet3Key: '',
      bullet3Val: '',
      bullet4Key: '',
      bullet4Val: '',
      buttonLabel: '',
    };
  }

  refetch() {
    if (this.$route.params.id == 'create') {
      this.siteTitle = 'In-App Fyler erstellen';
      this.objectData = this.getEmpty();
    } else if (this.$route.params.id != null) {
      this.skipQuery = false;
      this.$apollo.queries.getInAppFlyerById.refetch({
        getInAppFlyerByIdId: this.$route.params.id,
      });
    }
  }

  mounted() {
    this.refetch();
  }

  referenceChanged(reference: string) {
    this.objectData.reference = reference;
  }

  referenceTypeChanged(referenceType: string) {
    this.objectData.referenceType = referenceType;
  }

  getData() {
    this.objectData.client = this.$route.params.client;

    const returnData = JSON.parse(
      JSON.stringify(helper.cleanTypeData(this.objectData, ['__typename', 'updatedAt', 'createdAt'])),
    );

    returnData['price'] = parseFloat(returnData['price']);
    returnData['strikePrice'] = parseFloat(returnData['strikePrice']);

    delete returnData['content'];

    // if (returnData['content'] && returnData['content']['_id']) {
    //   returnData['content'] = returnData['content']['_id'];
    // } else {
    //   delete returnData['content'];
    // }

    if (returnData['image'] && returnData['image']['_id']) {
      returnData['image'] = returnData['image']['_id'];
    } else {
      delete returnData['image'];
    }

    if (returnData['startAt']) {
      returnData['startAt'] = returnData['startAt'].toISOString();
    }

    if (returnData['endsAt']) {
      returnData['endsAt'] = returnData['endsAt'].toISOString();
    }

    return returnData;
  }

  async saveNew() {
    clients.clientB
      .mutate({
        mutation: CMS_CREATE_IN_APP_FLYER,
        variables: {
          inappflyer: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.createInAppFlyer != null && data.data.createInAppFlyer._id) {
          this.$router.push({
            path: `/cms/inappflyer/${this.$route.params.client}/${data.data.createInAppFlyer._id}`,
          });
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async saveExisting() {
    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_IN_APP_FLYER,
        variables: {
          inappflyer: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.updateInAppFlyer != null && data.data.updateInAppFlyer._id) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async userLeave() {
    this.currentUsage.inUse = false;
    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  async saveHandler() {
    if (this.$route.params.id == 'create') {
      this.saveNew();
    } else if (this.$route.params.id != null) {
      this.saveExisting();
    }
  }

  async deleteHandler() {
    const content = prompt('Bitte "löschen" zum Bestätigen eingeben');
    if (content === 'löschen') {
      if (this.objectData?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_IN_APP_FLYER,
          variables: {
            deleteInAppFlyerId: this.objectData._id,
          },
        });
      }

      // Surpress unsaved change prompt, a bit hacky but works
      this.changed = false;
      this.$router.push({ name: 'In-App Flyer' });
    }
  }
}
