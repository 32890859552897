
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import CMSView from '@/views/cms/CMSView.vue';
import DealChooser from './DealChooser.vue';
import ReleaseChooser from './ReleaseChooser.vue';
import PushDetailChooser from './PushDetailChooser.vue';
import { cmsLink } from '@/main';

@Component({
  components: {
    CMSView,
    DealChooser,
    ReleaseChooser,
    PushDetailChooser,
  },
  props: {
    referenceType: {
      type: String,
    },
    reference: {
      type: String,
    },
    clientId: {
      type: String,
    },
  },
  watch: {
    referenceType(newValue, oldValue) {
      const self = this as ReferenceInput;
      self.choosenType = newValue;
    },
    referenceValue() {
      const self = this as ReferenceInput;
      console.log('referenceValue watcher', self.referenceValue);
      this.$emit('value-changed', self.referenceValue);
      this.$emit('path-changed', self.referenceValue);
    },
    reference(newValue, oldValue) {
      const self = this as ReferenceInput;
      if (self.referenceValue != newValue) {
        self.referenceValue = newValue;
      }
    },
    $props: {
      handler(newValue, oldValue) {
        if (newValue.clientId != null) {
          const self = this as ReferenceInput;
          self.client = newValue.clientId;
        }
      },
      deep: true,
    },
  },
})
export default class ReferenceInput extends Vue {
  reference: any;
  referenceValue: string = '';
  referenceType: any;
  choosenType: string = 'link';
  choosenItem: any = null;
  path: string = '';
  client: string | null = null;

  cdn: string | undefined = cmsLink;
  referenceTypes = ['link', 'deal', 'release', 'pushdetail'];

  removeItem() {
    this.resetData();
  }

  itemSelected(item: any) {
    this.choosenItem = item;
    console.log('itemSelected', item);
    this.$emit('value-changed', item._id);
    this.$emit('path-changed', item.path);
  }

  mounted() {
    if (this.reference != null && this.reference != '') {
      this.referenceValue = this.reference;
    }

    if (this.referenceType != null && this.referenceType != '') {
      this.choosenType = this.referenceType;
    }

    if (this.$props.clientId) {
      this.client = this.$props.clientId;
    }

    if (this.reference == '' && this.referenceType == '') {
      console.log('mounted, query', this.$route.query);
      if (this.$route.query.reference != null && this.$route.query.referenceType != null) {
        this.referenceValue = this.$route.query.reference.toString();
        this.choosenType = this.$route.query.referenceType.toString();
      }
    }
  }

  resetData() {
    this.choosenItem = null;
    this.$emit('value-changed', '');
    this.$emit('path-changed', '');
    this.$emit('type-changed', this.choosenType);
  }
}
