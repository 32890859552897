<template>
  <div class="panel has-background-white">
    <p class="panel-heading">Tag Kategorien</p>
    <template v-for="category in categories">
      <div class="panel-block is-flex-grow-1 is-justify-content-space-between" :key="category._id">
        <b-field grouped :message="errorMessageCategory(category._id)" :type="errorMessageType(category._id)">
          <p class="px-3 py-2 is-flex-grow-1" v-if="categoryToUpdate !== category._id">{{ category.name }}</p>
          <b-input
            expanded
            v-if="categoryToUpdate === category._id"
            v-model="newCategoryName"
            @keyup.native.enter="updateCategory(category)"
          />
          <div class="buttons">
            <b-button
              size="is-small"
              type="is-info"
              icon-right="pen"
              @click="onUpdateCategory(category)"
              v-if="categoryToUpdate !== category._id"
            />
            <b-button
              size="is-small"
              type="is-success"
              icon-right="floppy"
              v-if="categoryToUpdate === category._id"
              @click="updateCategory(category)"
            />
            <b-button
              size="is-small"
              type="is-info"
              icon-right="cancel"
              v-if="categoryToUpdate === category._id"
              @click="cancelUpdateCategory"
            />
            <b-button size="is-small" type="is-danger" icon-right="delete" @click="deleteCategory(category)" />
          </div>
        </b-field>
      </div>

      <div
        class="panel-block is-flex is-align-items-center is-justify-content-start is-flex-wrap-wrap is-gap"
        :key="category._id + '_tags'"
      >
        <template v-for="catTag in category.tags">
          <b-tag
            type="is-info"
            class="cursor-pointer"
            :key="catTag._id"
            @click="
              tagEditorToShow = category._id;
              tagToEdit = catTag._id;
            "
            v-if="tagEditorToShow !== category._id"
            >{{ catTag.name }}</b-tag
          >
          <tag-editor
            class="round"
            :tag="catTag"
            :tagCategories="categories"
            :key="catTag._id + '_editor'"
            :closable="true"
            @closeTagEditor="
              tagEditorToShow = '';
              tagToEdit = '';
            "
            v-if="tagEditorToShow === category._id && tagToEdit === catTag._id"
          />
        </template>
      </div>
    </template>
    <div class="panel-block">
      <b-field
        grouped
        class="is-align-items-center"
        :message="errorMessageCategory('add')"
        :type="errorMessageType('add')"
      >
        <b-input
          class="mr-3"
          placeholder="Neue Kategorie"
          v-model="newCategory"
          @keyup.native.enter="addCategory('add')"
          expanded
        />
        <b-button type="is-success" icon-right="plus" @click="addCategory('add')" />
      </b-field>
    </div>
  </div>
</template>
<script>
import { CMS_GET_TAG_CATEGORIES } from '@/graphlql/queries';
import TagEditor from './TagEditor.vue';
import {
  CMS_CREATE_TAG_CATEGORY,
  CMS_UPDATE_TAG_CATEGORY,
  CMS_DELETE_TAG_CATEGORY,
  CMS_DELETE_CATEGORY_ID_FROM_TAGS,
} from '@/graphlql/mutations';
import { EventBus } from '@/components/common/event/eventbus';

export default {
  name: 'TagCategoryPanel',
  components: { TagEditor },
  data() {
    return {
      tagEditorToShow: '',
      tagToEdit: '',
      categoryToUpdate: '',
      newCategoryName: '',
      categories: [],
      newCategory: '',
      errorCategory: false,
      errorMsgCategory: '',
      errorMsgId: '',
      loading: false,
      success: false,
    };
  },
  apollo: {
    getTagCategories: {
      query: CMS_GET_TAG_CATEGORIES,
      fetchPolicy: 'no-cache',
      manual: false,
      variables() {
        return {
          client: this.$route.params.client,
        };
      },
      result(data) {
        if (data.data != null) {
          this.categories = [...data.data.getTagCategories];
        }
      },
    },
  },
  methods: {
    errorMessageCategory(id) {
      if (this.errorMsgId === id && this.errorCategory && this.errorMsgCategory !== '') {
        return this.errorMsgCategory;
      }
      return '';
    },
    errorMessageType(id) {
      if (this.errorMsgId === id && this.errorCategory && this.errorMsgCategory !== '') {
        return 'is-danger';
      }
      return '';
    },
    addCategory(id) {
      this.errorMsgId = id;
      if (!this.newCategory) {
        this.errorCategory = true;
        this.errorMsgCategory = 'Kann nicht gespeichert werden! Kategorie ist leer!';
        return;
      }
      this.$apollo
        .mutate({
          // Query
          mutation: CMS_CREATE_TAG_CATEGORY,
          // Parameters
          variables: {
            tagCategoryInput: {
              client: this.$route.params.client,
              name: this.newCategory,
            },
          },
        })
        .then((data) => this.onCategoryFullfilled(data))
        .catch((error) => this.onCategoryError(error));
    },
    onUpdateCategory(category) {
      this.categoryToUpdate = category._id;
      this.newCategoryName = category.name;
    },
    updateCategory(category) {
      this.errorMsgId = category._id;
      if (!this.newCategoryName) {
        this.errorCategory = true;
        this.errorMsgCategory = 'Kann nicht gespeichert werden! Kategorie ist leer!';
        return;
      }
      this.$apollo
        .mutate({
          mutation: CMS_UPDATE_TAG_CATEGORY,
          variables: {
            id: this.categoryToUpdate,
            tagCategoryInput: {
              client: this.$route.params.client,
              name: this.newCategoryName,
            },
          },
        })
        .then((data) => this.onCategoryFullfilled(data))
        .catch((error) => this.onCategoryError(error));
    },
    cancelUpdateCategory() {
      this.categoryToUpdate = '';
      this.newCategoryName = '';
    },
    deleteCategory(category) {
      const ids = category.tags.map((tag) => tag._id);
      this.errorMsgId = category._id;
      this.$apollo
        .mutate({
          mutation: CMS_DELETE_CATEGORY_ID_FROM_TAGS,
          variables: {
            ids,
          },
        })
        .then(() => this.$emit('updateTagPanel'))
        .then(() => {
          this.$apollo.mutate({
            // Query
            mutation: CMS_DELETE_TAG_CATEGORY,
            // Parameters
            variables: {
              id: category._id,
            },
          });
        })
        .then((data) => this.onCategoryFullfilled(data))
        .catch((error) => this.onCategoryError(error));
    },
    onCategoryFullfilled(data) {
      Promise.resolve(this.$apollo.queries.getTagCategories.refetch());
      EventBus.$emit('updateTagPanel');
      this.onUpdateCategoryPanel();
    },
    onCategoryError(error) {
      this.loading = false;
      this.result = true;
      this.errorCategory = true;
      this.errorMsgCategory = error.message;
      this.success = false;
    },
    onUpdateCategoryPanel() {
      Promise.resolve(this.$apollo.queries.getTagCategories.refetch());
      this.tagEditorToShow = '';
      this.tagToEdit = '';
      this.errorMsgId = '';
      this.categoryToUpdate = '';
      this.newCategoryName = '';
      this.loading = false;
      this.errorCategory = false;
      this.success = true;
      this.newCategory = '';
    },
  },
  mounted() {
    EventBus.$on('updateCategoryPanel', () => this.onUpdateCategoryPanel());
  },
};
</script>
<style lang="scss">
.is-gap {
  gap: 0.5rem;
}
.cursor-pointer {
  cursor: pointer;
}
.round {
  border: 2px solid $info;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
</style>
