
import clients from '@/graphClient';
import { CMS_UPDATE_MEDIA, CMS_UPLOAD_FILE } from '@/graphlql/mutations';
import { Vue } from 'vue-property-decorator';
import { cmsLink } from '@/main';
import Component from 'vue-class-component';
import * as helper from '@/helper/data-helper';
import cacheImage from '@/helper/image-caching';
import { $axios } from '@/store/axios-instance';

@Component({
  props: {
    image: {
      type: Object,
    },
  },
})
export default class UploadImage extends Vue {
  public image: any;
  file: any = null;
  cdn: string | undefined = cmsLink;

  updateMedia() {
    if (this.image != null && this.image._id != null) {
      clients.clientB
        .mutate({
          // Query
          mutation: CMS_UPDATE_MEDIA,
          // Parameters
          variables: {
            media: helper.cleanTypeData(this.image, ['__typename', 'updatedAt', 'createdAt']),
          },
        })
        .then((data) => {
          if (data != null && data.data != null && data.data.uploadFile != null) {
            this.image.filename = data.data.uploadFile.filename;
            this.image.originalName = data.data.uploadFile.originalName;
            this.image._id = data.data.uploadFile._id;
            this.image.altName = data.data.uploadFile.altName;
            this.image.altText = data.data.uploadFile.altText;
            this.image.mimeType = data.data.uploadFile.mimeType;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  onFileSelected(file: any) {
    console.log("UploadImage CMS_UPLOAD_FILE DONE");

    const formData = new FormData();
    formData.append('file', file);

    $axios.post(process.env.VUE_APP_CMS_URL + "media/upload",
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then((data: any) => {
      if (data?.data) {
        this.image.filename = data.data.filename;
        this.image.originalName = data.data.originalName;
        this.image._id = data.data._id;
        this.image.mimeType = data.data.mimeType;
        this.image.altName = data.data.altName;
        this.image.altText = data.data.altText;


        cacheImage(this.image._id, this.image.originalName);

        this.$forceUpdate();
      }
    })
      .catch((error) => {
        console.error(error);
      });


    /*  
    clients.clientB
      .mutate({
        // Query
        mutation: CMS_UPLOAD_FILE,
        // Parameters
        variables: {
          file: file,
        },
        context: {
          hasUpload: true,
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.uploadFile != null) {
          this.image.filename = data.data.uploadFile.filename;
          this.image.originalName = data.data.uploadFile.originalName;
          this.image._id = data.data.uploadFile._id;
          this.image.altName = data.data.uploadFile.altName;
          this.image.altText = data.data.uploadFile.altText;
          this.image.mimeType = data.data.uploadFile.mimeType;

          cacheImage(data.data.uploadFile._id, data.data.uploadFile.originalName);

          this.$forceUpdate();
        }
      })
      .catch((error) => {
        console.error(error);
      });
      */
  }
}
