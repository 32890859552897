import getConfig from '@/helper/config-loader';

const account = getConfig('i18n/en/account.en', 'account');
const common = getConfig('i18n/en/common.en', 'common');
const login = getConfig('i18n/en/login.en', 'login');
const navigation = getConfig('i18n/en/navigation.en', 'navigation');
const notifications = getConfig('i18n/en/notifications.en', 'notifications');

export const en = {
  account,
  common,
  login,
  navigation,
  notifications,
};
