
import CKEditor from 'ckeditor4-vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    content: {
      type: String,
    },
    eventName: {
      type: String,
    },
  },
  watch: {
    content(newValue, oldValue) {
      const self = this as Richtext;
      self.contentData = newValue;
    },
    contentData() {
      const self = this as Richtext;
      this.$emit('input', self.contentData);
    },
  },
})
export default class Richtext extends Vue {
  contentData: string = this.$props.content;
  editorConfig = {
    disableNativeSpellChecker : false,
  }
}
