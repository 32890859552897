
import clients from '@/graphClient';
import { CMS_UPLOAD_FILE } from '@/graphlql/mutations';
import cacheImage from '@/helper/image-caching';
import { cmsLink } from '@/main';
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';
import { $axios } from '@/store/axios-instance';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
  },
  watch: {
    files(newFiles, oldFiles) {
      const self = this as BannerList;
      for (const file of newFiles) {
        if (!file.uploaded) {
          file.uploaded = true;
          self.uploadImage(file, self.addNew());
        }
      }
    },
  },
})
export default class BannerList extends BaseCmsComponentClass {
  draggingRow: any = null;
  draggingRowIndex: any = null;

  files: any[] = [];

  addNew() {
    this.content.banner.push({
      itemId: this.getNextId(),
      filename: '',
      _id: '',
      originalName: '',
      link: '',
      linkAlt: '',
      target: '',
    });

    return this.content.banner[this.content.banner.length - 1];
  }

  deleteBanner(event: any, id: number) {
    let index = '';
    for (const i in this.content.banner) {
      const banner = this.content.banner[i];
      if (banner.itemId == id) {
        index = i;
      }
    }

    if (parseInt(index, 10) > -1) {
      this.content.banner.splice(index, 1); // 2nd parameter means remove one item only
    }
    event.preventDefault();
    return false;
  }

  uploadImage(file: any, image: any) {
    console.log("BannerList CMS_UPLOAD_FILE DONE");

    const formData = new FormData();
    formData.append('file', file);

    $axios.post(process.env.VUE_APP_CMS_URL + "media/upload",
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then((data: any) => {
      if (data?.data) {
        image.filename = data.data.filename;
        image.originalName = data.data.originalName;
        image._id = data.data._id;
        image.mimeType = data.data.mimeType;
        image.altName = data.data.altName;
        image.altText = data.data.altText;

        cacheImage(image._id, image.originalName);
      }
    })
      .catch((error) => {
        console.error(error);
      });

    /*
        clients.clientB
          .mutate({
            // Query
            mutation: CMS_UPLOAD_FILE,
            // Parameters
            variables: {
              file: file,
            },
            context: {
              hasUpload: true,
            },
          })
          .then((data) => {
            if (data != null && data.data != null && data.data.uploadFile != null) {
              image.filename = data.data.uploadFile.filename;
              image.originalName = data.data.uploadFile.originalName;
              image._id = data.data.uploadFile._id;
              image.mimeType = data.data.uploadFile.mimeType;
    
              cacheImage(data.data.uploadFile._id, data.data.uploadFile.originalName);
            }
          })
          .catch((error) => {
            console.error(error);
          });
    
          */
  }

  onFileSelected(file: any, props: any) {
    this.uploadImage(file, props.row);
  }

  getNextId() {
    let max = this.resolveMaxId(this.content.banner);
    return ++max;
  }

  resolveMaxId(items: any[]) {
    if (items == null || items.length == 0) {
      return 0;
    }

    let max = 0;

    for (const element of items) {
      if (element.itemId > max) {
        max = element.itemId;
      }
    }

    return max;
  }
  dragstart(payload: any) {
    this.draggingRow = payload.row;
    this.draggingRowIndex = payload.index;
    payload.event.dataTransfer.effectAllowed = 'copy';
  }
  dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = 'copy';
    payload.event.target.closest('tr').classList.add('is-selected');
    payload.event.preventDefault();
  }
  dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    payload.event.preventDefault();
  }
  drop(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    const droppedOnRowIndex = payload.index;
    this.content.banner = this.arraymove(this.content.banner, this.draggingRowIndex, droppedOnRowIndex);
  }

  arraymove(arr: any[], oldindex: number, newindex: number) {
    if (newindex >= arr.length) {
      let k = newindex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newindex, 0, arr.splice(oldindex, 1)[0]);
    return arr; // for testing
  }

  file: any = null;
  target: string[] = ['self', 'blank'];
  cdn: string | undefined = cmsLink;
}
