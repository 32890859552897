
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: ['value'],
  components: {}
})
export default class ClientReferrerView extends Vue {
  value: any;

  deleteItem(index: number) {
    this.value.splice(index, 1);
  }

  addItem() {
    this.value.push({ name: '', referrer: '' });
  }

}
