
import { SEARCH_REPORTS } from '@/graphlql/queries';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  apollo: {
    searchReports: {
      query: SEARCH_REPORTS,
      manual: true,
      variables() {
        const now = new Date();
        const start = new Date();

        start.setDate(start.getDate() - this.searchTime);

        return {
          shopId: parseInt(this.$route.params.id, 10),
          dateStart: start,
          dateEnd: now,
        };
      },

      fetchPolicy: 'no-cache',
      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            this.loading = data.loading;
            const tableData: any = [];
            for (const shop of data.data.shopsPagination.shops) {
              tableData.push(shop);
            }

            this.tableData = tableData;
            this.total = data.data.shopsPagination.count;
          }
        }
      },
    },
  },
})
export default class StoreStatistics extends Vue {
  searchTime: number = 14;
}
