
import { GET_INCOMING } from '@/graphlql/queries';
import { Component, Vue } from 'vue-property-decorator';
import BaseView from '../common/BaseView.vue';
import { cdnLink } from '@/main';

@Component({
  components: { BaseView },
  apollo: {
    incomingPagination: {
      query: GET_INCOMING,
      variables: {
        offset: 0,
        limit: 50,
      },
      result(data) {
        if (data != null && data.loading == false) {
          if (data.data.incomingPagination != null && data.data.incomingPagination.incoming != null) {
            this.loading = data.loading;
            const tableData: unknown[] = [];
            for (const incoming of data.data.incomingPagination.incoming) {
              const obj = incoming;
              if (incoming.data != null && incoming.data.length > 0) {
                try {
                  incoming.json = JSON.parse(incoming.data);
                } catch (exp) {
                  console.log(exp);
                }
              } else {
                incoming.json = {};
              }
              tableData.push(obj);
            }

            this.tableData = tableData;
            this.total = data.data.incomingPagination.count;
          }
        }
      },
    },
  },
})
export default class IncomingNikeView extends Vue {
  tableData: unknown = [];
  defaultOpenedDetails: unknown = [];
  page: number = 1;
  total: number = 0;
  perPage: number = 50;
  loading: boolean = true;

  cdn: string | undefined = cdnLink;

  loadAsyncData() {
    this.$apollo.queries.incomingPagination.refetch({
      offset: this.perPage * (this.page - 1),
      limit: this.perPage,
    });
  }

  onPageChange(page: number) {
    this.page = page;
    this.loadAsyncData();
  }
}
