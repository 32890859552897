
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import ReleasesTable from '@/components/cms/ReleasesTable.vue';

@Component({
  components: {
    ReleasesTable,
    BaseView,
  },
})
export default class ReleasesView extends Vue {
  createDealHandler() {
    this.$router.push({
      path: `/cms/releases/${this.$route.params.clientA}/${this.$route.params.clientB}/create`,
    });

    //this.$router.push({ name: 'CreateDeal' });
  }
}
