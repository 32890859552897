
import { Component, Vue } from 'vue-property-decorator';
import { GET_MAPPING_INFORMATION, GET_SHOP } from '@/graphlql/queries';
import { DRY_RUN_WITH_PATH, PROCESS_SHOP } from '@/graphlql/mutations';

@Component({
  props: ['path'],
  components: {},
  apollo: {
    getShop: {
      query: GET_SHOP,
      variables() {
        return { getShopId: parseInt(this.$route.params.shopId, 10) };
      },
    },
    getMappingInformation: {
      query: GET_MAPPING_INFORMATION,
      variables() {
        return { getMappingInformationShopId: parseInt(this.$route.params.shopId, 10) };
      },
      result(data: any) {
        if (data != null && data.data != null && data.data.getMappingInformation != null) {
          for (const mappingInformation of data.data.getMappingInformation) {
            if (mappingInformation.type != null && mappingInformation.type.name != null)
              this.mappingInformation[mappingInformation.type.name] = mappingInformation;
          }
        }
      },
    },
  },
})
export default class AssistentFinish extends Vue {
  mappingInformation: any = {};

  dryRunLoading: boolean = false;
  dryRunSuccess: boolean = false;
  dryRunError: boolean = false;

  importLoading: boolean = false;
  importSuccess: boolean = false;
  importError: boolean = false;
  importResult: any = null;

  checked: boolean = false;

  getShop: any = { config: {} };

  exampleProduct: any = null;

  nextStep() {
    this.$emit('step-finished');
  }

  loadExampleProduct() {
    this.resetDryRunState();
    this.dryRunLoading = true;

    this.$apollo
      .mutate({
        mutation: DRY_RUN_WITH_PATH,
        // Parameters
        variables: {
          dryRunShopId: parseInt(this.$route.params.shopId, 10),
          dryRunPath: this.$props.path,
        },
      })
      .then((data) => {
        this.dryRunLoading = false;
        if (data != null && data.data != null && data.data.dryRun != null && data.data.dryRun.success) {
          this.dryRunSuccess = true;
          this.dryRunError = false;
          if (data.data.dryRun.products != null && data.data.dryRun.products.length > 0) {
            this.exampleProduct = data.data.dryRun.products[0];
          }
        } else {
          this.dryRunError = true;
          this.dryRunSuccess = false;
        }

        setTimeout(() => this.resetDryRunState(), 5000);
      })
      .catch((error) => {
        this.dryRunLoading = false;
        this.dryRunSuccess = false;
        this.dryRunError = true;

        setTimeout(() => this.resetDryRunState(), 5000);
      });
  }

  startImport() {
    this.importLoading = true;
    this.$apollo
      .mutate({
        mutation: PROCESS_SHOP,
        // Parameters
        variables: {
          processShopShopId: parseInt(this.$route.params.shopId, 10),
          processShopPath: this.$props.path,
        },
      })
      .then((data) => {
        this.importLoading = false;
        if (data != null && data.data != null && data.data.processShop != null && data.data.processShop.success) {
          this.importSuccess = true;
          this.importError = false;
          this.importResult = data.data.processShop;
          this.exampleProduct = null;
        } else {
          this.importSuccess = true;
          this.importError = false;
        }

        setTimeout(() => this.resetImportState(), 5000);
      })
      .catch((error) => {
        this.importLoading = false;
        this.importSuccess = false;
        this.importError = true;

        setTimeout(() => this.resetImportState(), 5000);
      });
  }

  resetImportState(): void {
    this.importLoading = false;
    this.importSuccess = false;
    this.importError = false;
  }

  resetDryRunState(): void {
    this.dryRunLoading = false;
    this.dryRunSuccess = false;
    this.dryRunError = false;
  }

  getString(array: string[]) {
    if (array != null) {
      return array.join(', ');
    }
    return '';
  }
  getImgUrl(item: any) {
    if (
      this.exampleProduct != null &&
      item != null &&
      item.i != null &&
      this.exampleProduct.images != null &&
      this.exampleProduct.images[item.i] != null
    ) {
      return this.exampleProduct.images[item.i];
    }

    return null;
  }
}
