
import { Component, Vue } from 'vue-property-decorator';
import { UPLOAD_IMAGE } from '@/graphlql/mutations';
import { cdnLink } from '@/main';
import cacheImage from '@/helper/image-caching';
import { $axios } from '@/store/axios-instance';

@Component({
  props: ['value', 'context'],
  watch: {
    file(newFile: any, oldFile: any) {
      // Call to the graphql mutation
      const self = this as UploadImage;


      console.log("UploadFile UPLOAD_IMAGE");
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('context', self.$props.context);

      $axios.post(process.env.VUE_APP_CMS_URL + "products/images/upload",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((data: any) => {
        if (data?.data) {
          console.log(data.data);
          if (data != null && data.data != null && data.data.path != null) {
            self.$emit('update-path', data.data.path);
          }
        }
      })
        .catch((error) => {
          console.error(error);
        });

    },
  },
})
export default class UploadImage extends Vue {
  value: any;
  context: any;
  file: any = null;

  cdn: string | undefined = cdnLink;
}
