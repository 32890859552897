
import { Component, Vue } from 'vue-property-decorator';
import LineChart from '../common/LineChart.vue';

import { GET_CLIENTS, GET_VIEW_STATISTICS } from '@/graphlql/queries';

@Component({
  components: { LineChart },
  props: ['type'],
  watch: {
    dates(oldDate, newDate) {
      const self = this as ViewStatisticsChart;
      self.refetchStatistics();
    },
  },
  apollo: {
    clients: {
      manual: true,
      fetchPolicy: 'no-cache',
      query: GET_CLIENTS,
      result(data: any) {
        if (data.data != null) {
          for (const client of data.data.clients) {
            this.clients = this.clients.concat(client);
          }
          this.clients = this.clients.concat({ name: 'Alle', id: 0 });
        }
      },
    },
    statistics: {
      query: GET_VIEW_STATISTICS,
      variables: {
        client: null,
        startDate: 1,
        endDate: null,
      },

      manual: true,
      // Disable the query
      skip() {
        return this.skipQuery;
      },
      result(data: any) {
        if (data.data != null && data.data.getViewStatistics != null) {
          const views = [];
          const clicks = [];
          const labels = [];
          for (const entry of data.data.getViewStatistics) {
            if (this.type == 'view') {
              views.push(entry.views);
            } else if (this.type == 'clicks') {
              clicks.push(entry.clicks);
            } else {
              views.push(entry.views);
              clicks.push(entry.clicks);
            }
            views.push(entry.views);
            clicks.push(entry.clicks);
            labels.push(new Date(entry.date).toLocaleDateString());
          }

          this.datacollection = {
            labels: labels,
            datasets: [],
          };

          if (this.type == 'view') {
            this.datacollection.datasets.push({
              label: 'Views',
              data: views,
              borderColor: '#2893c9',
              backgroundColor: '#2893c933',
            });
          } else if (this.type == 'clicks') {
            this.datacollection.datasets.push({
              label: 'Klicks',
              data: clicks,
              borderColor: '#e50404',
              backgroundColor: '#e5040433',
            });
          } else {
            this.datacollection.datasets.push({
              label: 'Views',
              data: views,
              borderColor: '#2893c9',
              backgroundColor: '#2893c933',
            });
            this.datacollection.datasets.push({
              label: 'Klicks',
              data: clicks,
              borderColor: '#e50404',
              backgroundColor: '#e5040433',
            });
          }
        }
      },
    },
  },
})
export default class ViewStatisticsChart extends Vue {
  value: any;
  type?: string;
  datacollection: any = {};
  clients = [];
  selectedClient = 0;
  loading: boolean = false;
  dates: Date[] = [];
  skipQuery: boolean = true;
  constructor() {
    super();
    this.dates = [];

    const date = new Date();
    date.setDate(date.getDate() - 7);

    this.dates.push(date);
    this.dates.push(new Date());
  }

  refetchStatistics() {
    this.skipQuery = false;
    this.$apollo.queries.statistics.refetch({
      client: this.selectedClient,
      startDate: this.dates[0],
      endDate: this.dates[1],
    });
  }

  async mounted() {
    setTimeout(() => {
      this.refetchStatistics();
    }, 100);
  }
}
