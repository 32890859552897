
import { Component, Vue } from 'vue-property-decorator';
import { NavigationEventBus } from '@/events/navigation/NavigationEventBus';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faBars } from '@fortawesome/free-solid-svg-icons';
//library.add(faBars);

@Component
export default class SidebarNavigation extends Vue {
  private checked = false;

  menuIsClicked() {
    NavigationEventBus.$emit('menu-got-clicked', this.checked);
    this.$store.dispatch('navigationModule/setNavigationPathOpen', []);
  }

  created() {
    NavigationEventBus.$on('menu-got-clicked', (data: boolean) => {
      this.checked = data;
    });
  }
}
