
import { Component, Vue } from 'vue-property-decorator';
import { CREATE_SEO } from '@/graphlql/mutations';

import SeoDetailView from '@/components/seo/SeoDetailView.vue';

@Component({
  props: ['value'],
  components: {
    SeoDetailView,
  },
})
export default class StoreEdit extends Vue {
  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;
  value: any;

  updatePath(path: any) {
    this.value.logo = path;
  }

  mounted() {
    if (this.value.context == null) {
      this.value.context = [];
    }
    if (this.value.text == null) {
      this.value.text = '';
    }
  }

  save() {
    // Call to the graphql mutation
    this.loading = true;
    this.$apollo
      .mutate({
        // Query
        mutation: CREATE_SEO,
        // Parameters
        variables: {
          createSeoInput: {
            context: this.value.context,
            client: this.value.client,
            path: this.value.path,
            text: this.value.text,
          },
        },
      })
      .then((data) => {
        this.loading = false;
        this.result = true;
        this.error = false;
        this.success = true;

        this.$emit('seo:created', data.data.createSeo);        

        setTimeout(() => this.clearStates(), 1000);
      })
      .catch((error) => {
        this.loading = false;
        this.result = true;
        this.error = true;
        this.success = false;
        console.error(error);
        setTimeout(() => this.clearStates(), 1000);
      });
  }
  clearStates() {
    this.loading = false;
    this.result = false;
    this.error = false;
    this.success = false;
  }
}
