
import Vue from 'vue';
import Component from 'vue-class-component';
import { LoginDataInterface } from '@/interfaces/auth.interface';

import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('authModule');

@Component({
  methods: {
    ...mapActions(['verifyLoginAction', 'checkAuth']),
  },
})
export default class LoginForm extends Vue {
  private isLoading = false;
  private authenticationErrors = Array<Error>();
  private minPasswordLength = 0;
  private minUserNameLength = 4;

  verifyLoginAction!: (loginData: LoginDataInterface) => Promise<never>;
  checkAuth!: () => Promise<boolean>;

  private user: LoginDataInterface = {
    userName: '',
    password: '',
    email: '',
  };

  private message = {
    header: '',
    body: '',
  };

  get validFields() {
    const validEmailsRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isEmailValid = validEmailsRegex.test(this.user.email || '');

    /**
     let showEmailHint = false;
     if (this.user.email) {
      showEmailHint = this.user.email.length > 0;
    }
     */

    const isPasswordValid = this.user.password.length >= this.minPasswordLength;
    const showPasswordHint = this.user.password.length > 0;

    const isUserNameValid = this.user.userName.length >= this.minUserNameLength;
    const showUserNameHint = this.user.userName.length > 0;

    return {
      userName: {
        valid: isUserNameValid,
        state: showUserNameHint && !isUserNameValid ? 'error' : 'success',
        hint: 'login.errors.userNameInvalid',
      },
      password: {
        valid: isUserNameValid,
        state: showPasswordHint && !isPasswordValid ? 'error' : 'success',
        hint: 'login.errors.passwordTooShort',
      },
    };
  }

  get allFieldsAreValid() {
    let valid = true;
    Object.values(this.validFields).forEach((field) => {
      if (!field.valid) {
        valid = false;
      }
    });
    return valid;
  }

  resetFormState() {
    this.isLoading = false;
    this.user = {
      email: '',
      userName: '',
      password: '',
    };
  }

  async verifyLogin() {
    this.isLoading = true;
    if (
      Object.keys(this.user).includes('email') &&
      Object.keys(this.user).includes('userName') &&
      Object.keys(this.user).includes('password') &&
      ((this.user.userName != null && this.user.userName.length > 0) ||
        (this.user.email != null && this.user.email.length > 0)) &&
      this.user.password != null &&
      this.user.password.length > 0
    ) {
      try {
        await this.verifyLoginAction(this.user);
        const loggedIn = await this.checkAuth();
        if (loggedIn === true) {
          return this.$router.push({ name: 'Home' });
        } else {
          this.authenticationErrors.push(new Error('Login failed'));
          this.resetFormState();
          this.message = {
            header: 'login.errors.invalidUserPassword',
            body: 'login.errors.invalidUserPasswordHint',
          };
        }
      } catch (e) {
        this.authenticationErrors.push(new Error('Login failed'));
        this.resetFormState();
        this.message = {
          header: 'login.errors.invalidUserPassword',
          body: 'login.errors.invalidUserPasswordHint',
        };
      }
    }
  }
}
