import gql from 'graphql-tag';

export const UPDATE_PRODUCT_BRANDS = gql`
  mutation DescriptionMutation($updateBrandInput: BrandInputDto!) {
    updateBrand(input: $updateBrandInput) {
      description
      logo
      name
      id
      popularity
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_FILTERBUBBLE = gql`
  mutation UpdateFilterbubble($filterbubbleId: Float!, $content: String!, $clientId: Float!) {
    updateFilterbubble(filterbubbleId: $filterbubbleId, content: $content, clientId: $clientId) {
      id
      content
      clientId
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CREATE_FILTERBUBBLE = gql`
  mutation CreateFilterbubble($clientId: Float!, $content: String!) {
    createFilterbubble(clientId: $clientId, content: $content) {
      content
      clientId
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const DELETE_FILTERBUBBLE = gql`
  mutation DeleteFilterbubble($deleteFilterbubbleId: Float!) {
    deleteFilterbubble(id: $deleteFilterbubbleId)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation UploadImage($file: Upload!, $uploadImageContext: String!) {
    uploadImage(file: $file, context: $uploadImageContext) {
      success
      path
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_COLOR = gql`
  mutation OverwriteColor($correctedColorId: Float!, $colorId: Float!, $name: String!) {
    overwriteColor(correctedColorId: $correctedColorId, colorId: $colorId, name: $name) {
      id
      name
      correctColorId
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CREATE_USER_USAGE = gql`
  mutation CreateUserUsage($userUsageDto: UserUsageInputDto!) {
    createUsage(userUsageDto: $userUsageDto) {
      inUse
      userId
      username
      targetId
      type
      lastTimeEdited
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_USER_USAGE = gql`
  mutation UpdateUserUsage($userUsageDto: UserUsageInputDto!, $targetId: String!) {
    updateUsageById(userUsageDto: $userUsageDto, targetId: $targetId) {
      inUse
      userId
      username
      targetId
      type
      lastTimeEdited
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_SHOP = gql`
  mutation UpdateShopMutation($updateShopInput: ShopInputDto!, $updateShopId: Float!) {
    updateShop(input: $updateShopInput, id: $updateShopId) {
      id
      title
      secondaryTitle
      logo
      logoDarkmode
      description
      active
      createdAt
      updatedAt
      client
      voucher
      voucherTitle
      voucherDescription
      voucherValue
      voucherApplyToSale
      voucherApplyToNoSale
      voucherDateLimit
      tags
      isReseller
      config {
        processor
        updateInterval
        lastImportDate
        processUrl
        id
        imageOrder
        popularity
      }
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CREATE_SHOP = gql`
  mutation CreateShopMutation($createShopInput: ShopInputDto!) {
    createShop(input: $createShopInput) {
      id
      title
      secondaryTitle
      logo
      logoDarkmode
      description
      active
      createdAt
      updatedAt
      client
      voucher
      voucherTitle
      voucherDescription
      voucherApplyToSale
      voucherDateLimit
      tags
      isReseller
      config {
        processor
        updateInterval
        lastImportDate
        processUrl
        id
        imageOrder
        popularity
      }
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const TEST_DOWNLOAD = gql`
  mutation TestDownload($testDownloadForUrlProcessUrl: String!) {
    testDownloadForUrl(processUrl: $testDownloadForUrlProcessUrl) {
      imported
      total
      end
      start
      success
      shopId
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const DRY_RUN = gql`
  mutation DryRunMutation($dryRunShopId: Float!) {
    dryRun(shopId: $dryRunShopId) {
      imported
      total
      end
      start
      success
      shopId
      path
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const DRY_RUN_WITH_PATH = gql`
  mutation DryRun($dryRunShopId: Float!, $dryRunPath: String) {
    dryRun(shopId: $dryRunShopId, path: $dryRunPath) {
      products {
        id
        category
        model
        displayModel
        brand
        link
        sizes_US
        sizes_EU
        sizes_UK
        shipping
        stock
        gender
        description
        price
        uvp
        colors
        images
        name
        gtin
      }
      imported
      total
      end
      start
      success
      shopId
      path
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_MAPPING = gql`
  mutation UpdateMappingMutation(
    $updateMappingValue: String!
    $updateMappingId: Float!
    $updateMappingActive: Boolean!
  ) {
    updateMapping(value: $updateMappingValue, id: $updateMappingId, active: $updateMappingActive) {
      type {
        name
        id
      }
      active
      key
      value
      id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const DELETE_MAPPING = gql`
  mutation DeleteMapping($deleteMappingId: Float!) {
    deleteMapping(id: $deleteMappingId)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const PROCESS_SHOP = gql`
  mutation ProductsMutation($processShopShopId: Float!, $processShopPath: String) {
    processShop(shopId: $processShopShopId, path: $processShopPath) {
      path
      imported
      total
      end
      start
      success
      shopId
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_PRODUCT_COLORS = gql`
  mutation ProductsMutation($updateColorInput: ProductColorInputDto!) {
    updateColor(input: $updateColorInput) {
      id
      name
      color
      hex
      image
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_ALIAS = gql`
  mutation UpdateSearchAlias($input: AliasInputDto!, $id: Float!) {
    updateSearchAlias(input: $input, id: $id) {
      id
      origin
      alias
      isActive
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_ALIASES = gql`
  mutation UpdateSearchAliases($input: [AliasInputDto!]!) {
    updateSearchAliases(input: $input) {
      id
      origin
      alias
      isActive
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_BRANDMODEL = gql`
  mutation UpdateBrandModel($input: BrandModelUpdateDto!) {
    updateBrandModel(input: $input)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CREATE_BRANDMODEL = gql`
  mutation CreateBrandModel($input: BrandModelInputDto!) {
    createBrandModel(input: $input)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const DELETE_BRANDMODEL = gql`
  mutation DeleteBrandModel($id: Float!) {
    deleteBrandModel(id: $id)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CREATE_ALIAS = gql`
  mutation CreateSearchAlias($input: AliasInputDto!) {
    createSearchAlias(input: $input) {
      id
      origin
      alias
      isActive
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CREATE_CLIENT = gql`
  mutation CreateClient($createClientInput: ClientInputDto!) {
    createClient(input: $createClientInput) {
      name
      active
      createdAt
      updatedAt
      id
      referrer {
        referrer
        name
        id
      }
      images {
        link
        image
        name
        id
      }
      adverts {
        image
        name
        link
        id
      }
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_CLIENT = gql`
  mutation UpdateClientMutation($updateClientInput: ClientInputDto!, $updateClientId: Float!) {
    updateClient(input: $updateClientInput, id: $updateClientId) {
      name
      referrer {
        referrer
        name
        id
      }
      adverts {
        image
        name
        link
        id
      }
      images {
        link
        image
        name
        id
      }
      active
      createdAt
      updatedAt
      id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_SEO = gql`
  mutation UpdateSeoMutation($updateSeoInput: SeoInputDto!) {
    updateSeo(input: $updateSeoInput)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const TOGGLE_MONOTOTE = gql`
  mutation toggleMonotote {
    toggleMonotote
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CREATE_SEO = gql`
  mutation CreateSeoMutation($createSeoInput: SeoInputDto!) {
    createSeo(input: $createSeoInput) {
      text
      context {
        value
        key
      }
      path
      id
      client
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const LOGIN = gql`
  mutation BackendUserLogin($user: BackendLoginInputDto!) {
    backendUserLogin(user: $user) {
      accessToken
      refreshToken
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const ACTIVATE_PRODUCTS = gql`
  mutation ActivateProducts($activateProductsInput: [Float!]!) {
    activateProducts(input: $activateProductsInput)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;
export const DEACTIVATE_PRODUCTS = gql`
  mutation DeactivateProducts($activateProductsInput: [Float!]!) {
    deactivateProducts(input: $activateProductsInput)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const ACTIVATE_PRODUCTS_BY_ARTICLE_ID = gql`
  mutation ActivateProductsByArticleId($activateProductsInput: [String!]!) {
    activateProductsByArticleId(input: $activateProductsInput)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const DEACTIVATE_PRODUCTS_BY_ARTICLE_ID = gql`
  mutation DeactivateProductsByArticleId($activateProductsInput: [String!]!) {
    deactivateProductsByArticleId(input: $activateProductsInput)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const DEACTIVATE_SHOP = gql`
  mutation DeactivateShop($shopId: Float!) {
    deactivateShop(shopId: $shopId)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_MAPPINGS = gql`
  mutation UpdateMappings($updateMappingsInput: [MappingInputDto!]!) {
    updateMappings(input: $updateMappingsInput) {
      id
      value
      key
      active
      type {
        id
        name
      }
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_PRODUCT_BASE_DATA = gql`
  mutation UpdateProductBaseData($updateProductBaseDataInput: ProductInputDto!) {
    updateProductBaseData(input: $updateProductBaseDataInput)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($updateProductInput: ProductInputDto!) {
    updateProduct(input: $updateProductInput) {
      id
      client
      active
      touched
      brand {
        description
        logo
        name
        id
      }
      color {
        image
        hex
        color
        name
        id
      }
      category {
        image
        alt
        name
        id
      }
      name
      title
      model
      displayModel
      gender
      material
      articleId
      articleNo
      description
      images {
        origin
        order
        link
        alt
        name
        id
      }
      uvp
      disabled
      minPrice
      discount
      createdAt
      updatedAt
      lastUpdateDate
      releaseDate
      availabilities {
        sizesUk
        sizesUs
        sizesEu
        stock
        price
        discount
        shippingCosts
        directLink
        shop
        id
      }
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const UPDATE_INDEX = gql`
  mutation UpdateIndexMutation {
    updateIndex
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const START_IMPORT = gql`
  mutation StartImportMutation {
    startImport
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const TRACK_MUTATION = gql`
  mutation ($trackInput: InteractionInputDto!) {
    track(input: $trackInput)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const TRACK_FAKES = gql`
  mutation TrackFakes($trackFakesInput: FakeInteractionInputDto!) {
    trackFakes(input: $trackFakesInput)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const BACKEND_USER_CHANGE_PASSWORD = gql`
  mutation BackendUserChangePassword($changeModel: BackendChangePasswordInputDto!) {
    backendUserChangePassword(changeModel: $changeModel)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const BACKEND_USER_LOGOUT = gql`
  mutation BackendUserLogout {
    backendUserLogout
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const BACKEND_USER_REGISTER = gql`
  mutation BackendUserRegister($user: BackendRegisterInputDto!) {
    backendUserRegister(user: $user) {
      id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const BACKEND_USER_UPDATE = gql`
  mutation BackendUserUpdate($user: BackendUserInputDto!) {
    backendUserUpdate(user: $user) {
      id
      username
      email
      avatar
      permissions
      firstname
      lastname
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_PAGE = gql`
  mutation UpdatePage($page: UpdatePageInput!) {
    updatePage(page: $page) {
      _id
      content
      client
      title
      pageTitle
      description
      img {
        mimeType
        filename
        _id
      }
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_NODE = gql`
  mutation UpdateNode($updateNode: UpdateUrlNodeInput!, $client: String) {
    updateNode(updateNode: $updateNode, client: $client) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_NODE_CHILD_ORDER = gql`
  mutation UpdateNodeChildSort($updateUrlNodeChildSort: UpdateUrlNodeChildSortInput!) {
    updateNodeChildSort(updateUrlNodeChildSort: $updateUrlNodeChildSort) {
      _id
      path
      previewPath
      title
      isActive
      tags
      redirect
      rank
      children {
        _id
      }
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_PAGE = gql`
  mutation CreatePage($page: CreatePageInput!) {
    createPage(page: $page) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_DEAL = gql`
  mutation CreateDeal($deal: CreateDealInput!) {
    createDeal(deal: $deal) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_MODIFY_DOUBLE_DEAL = gql`
  mutation ModifyDoubleDeal($deals: ModifyDoubleDealInput!, $updateWhatsnewDate: Boolean!) {
    modifyDoubleDeal(deals: $deals, updateWhatsnewDate: $updateWhatsnewDate) {
      dealApp {
        _id
        content
        client
        createdAt
        updatedAt
        headline
        subline
        images {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        price
        reducedPrice
        strikePrice
        priceText
        isActive
        startAt
        endsAt
        validTill
        voucherValidUntil
        metaDescription
        metaTitle
        dealType
        path
        bullet1Key
        bullet1Val
        bullet2Key
        bullet2Val
        bullet3Key
        bullet3Val
        bullet4Key
        bullet4Val
        voucher
        voucherText
        voucherFile
        exclusive
        tags
        buttonLabel
        links {
          label
          url
          type
          target
        }
      }
      dealWeb {
        _id
        content
        client
        createdAt
        updatedAt
        headline
        subline
        images {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        price
        reducedPrice
        strikePrice
        priceText
        isActive
        startAt
        endsAt
        validTill
        voucherValidUntil
        metaDescription
        metaTitle
        dealType
        path
        bullet1Key
        bullet1Val
        bullet2Key
        bullet2Val
        bullet3Key
        bullet3Val
        bullet4Key
        bullet4Val
        voucher
        voucherText
        voucherFile
        exclusive
        tags
        buttonLabel
        links {
          label
          url
          type
          target
        }
      }
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;
export const CMS_DELETE_DEAL = gql`
  mutation DeleteDeal($deleteDealId: String!) {
    deleteDeal(id: $deleteDealId) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_DEAL = gql`
  mutation UpdateDeal($deal: UpdateDealInput!) {
    updateDeal(deal: $deal) {
      _id
      path
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_TEMPLATE = gql`
  mutation CreateTemplate($template: CreateTemplateInput!) {
    createTemplate(template: $template) {
      content
      title
      createdAt
      updatedAt
      client
      type
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($template: UpdateTemplateInput!) {
    updateTemplate(template: $template) {
      _id
      content
      createdAt
      updatedAt
      title
      client
      type
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($deleteTemplateId: String!) {
    deleteTemplate(id: $deleteTemplateId)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_DEAL_SIBLING = gql`
  mutation UpdateDealSiblings($updateDealSibling: UpdateDealSiblingInput!) {
    updateDealSiblings(updateDealSibling: $updateDealSibling)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_DEAL_RANK = gql`
  mutation UpdateDealRank($updateDealRank: UpdateDealRankInput!) {
    updateDealRank(updateDealRank: $updateDealRank)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_STORY_RANK = gql`
  mutation UpdateStoryRank($updateStoryRank: UpdateStoryRankInput!) {
    updateStoryRank(updateStoryRank: $updateStoryRank)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_BLOG_RANK = gql`
  mutation UpdateBlogRank($updateBlogRank: UpdateBlogRankInput!) {
    updateBlogRank(updateBlogRank: $updateBlogRank)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const RESET_SHOP_IMAGEORDER = gql`
  mutation resetShopImageOrder($id: Float!) {
    resetShopImageOrder(id: $id)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const RESET_PRODUCT_IMAGEORDER = gql`
  mutation resetProductImageOrder($id: Float!) {
    resetProductImageOrder(id: $id)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_RELEASE_SIBLING = gql`
  mutation UpdateReleaseSiblings($updateReleaseSibling: UpdateReleaseSiblingInput!) {
    updateReleaseSiblings(updateReleaseSibling: $updateReleaseSibling)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_RELEASE = gql`
  mutation CreateRelease($release: CreateReleaseInput!) {
    createRelease(release: $release) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_RELEASE = gql`
  mutation DeleteRelease($deleteReleaseId: String!) {
    deleteRelease(id: $deleteReleaseId) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_MODIFY_DOUBLE_RELEASE = gql`
  mutation ModifyDoubleRelease($releases: ModifyDoubleReleaseInput!) {
    modifyDoubleRelease(releases: $releases) {
      releaseApp {
        _id
        content
        client
        createdAt
        updatedAt
        images {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        headline
        price
        reducedPrice
        releaseDate
        hasReleaseDate
        isActive
        tags
        shops {
          _id
          type
          url
          shop {
            _id
            content
            client
            createdAt
            updatedAt
            isActive
            previewImage {
              _id
              filename
              originalName
              mimeType
              altName
              altText
              createdAt
              updatedAt
            }
            headline
            tags
          }
          releaseDate
          price
        }
        raffles {
          _id
          type
          url
          shop {
            _id
            content
            client
            createdAt
            updatedAt
            isActive
            previewImage {
              _id
              filename
              originalName
              mimeType
              altName
              altText
              createdAt
              updatedAt
            }
            headline
            tags
          }
          releaseDate
          validUntil
          price
        }
        path
        articleId
        bullet1Key
        bullet1Val
        bullet2Key
        bullet2Val
        bullet3Key
        bullet3Val
        bullet4Key
        bullet4Val
        metaDescription
        metaTitle
      }
      releaseWeb {
        _id
        content
        client
        createdAt
        updatedAt
        images {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        headline
        price
        reducedPrice
        releaseDate
        hasReleaseDate
        isActive
        tags
        shops {
          _id
          type
          url
          shop {
            _id
            content
            client
            createdAt
            updatedAt
            isActive
            previewImage {
              _id
              filename
              originalName
              mimeType
              altName
              altText
              createdAt
              updatedAt
            }
            headline
            tags
          }
          releaseDate
          price
        }
        raffles {
          _id
          type
          url
          shop {
            _id
            content
            client
            createdAt
            updatedAt
            isActive
            previewImage {
              _id
              filename
              originalName
              mimeType
              altName
              altText
              createdAt
              updatedAt
            }
            headline
            tags
          }
          releaseDate
          validUntil
          price
        }
        path
        articleId
        bullet1Key
        bullet1Val
        bullet2Key
        bullet2Val
        bullet3Key
        bullet3Val
        bullet4Key
        bullet4Val
        metaDescription
        metaTitle
      }
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;
export const CMS_UPDATE_RELEASE = gql`
  mutation UpdateRelease($release: UpdateReleaseInput!) {
    updateRelease(release: $release) {
      _id
      sibling {
        _id
      }
      tags
      isActive
      releaseDate
      hasReleaseDate
      reducedPrice
      price
      images {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      headline
      updatedAt
      createdAt
      client
      content
      metaTitle
      metaDescription
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_URL_TREE = gql`
  mutation CreateUrlTree($client: String!) {
    createUrlTree(client: $client) {
      _id
      client
      root
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!, $overwrite: Boolean) {
    uploadFile(file: $file, overwrite: $overwrite) {
      _id
      filename
      mimeType
      createdAt
      altName
      altText
      originalName
      updatedAt
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_MEDIA = gql`
  mutation UpdateMedia($media: UpdateMediaInput!) {
    updateMedia(media: $media) {
      _id
      filename
      mimeType
      createdAt
      altName
      altText
      originalName
      updatedAt
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_NODE = gql`
  mutation AddNode($createNode: CreateUrlNodeInput!) {
    addNode(createNode: $createNode) {
      path
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_BLOG = gql`
  mutation CreateBlog($blog: CreateBlogInput!) {
    createBlog(Blog: $blog) {
      _id
      content
      client
      createdAt
      updatedAt
      title
      isActive
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      tags
      metaTitle
      metaDescription
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_BLOG = gql`
  mutation UpdateBlog($blog: UpdateBlogInput!) {
    updateBlog(Blog: $blog) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      title
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      tags
      metaTitle
      metaDescription
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_BLOG = gql`
  mutation DeleteBlog($deleteBlogId: String!) {
    deleteBlog(id: $deleteBlogId) {
      _id
    }
  }
`;

export const CMS_CREATE_PUSHDETAIL = gql`
  mutation CreatePushDetail($pushDetail: CreatePushDetailInput!) {
    createPushDetail(pushDetail: $pushDetail) {
      _id
      content
      client
      createdAt
      updatedAt
      headline
      images {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      subline
      displayDate
      tags
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_PUSHDETAIL = gql`
  mutation UpdatePushDetail($pushDetail: UpdatePushDetailInput!) {
    updatePushDetail(pushDetail: $pushDetail) {
      _id
      content
      client
      createdAt
      updatedAt
      headline
      subline
      displayDate
      tags
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_PUSHDETAIL = gql`
  mutation DeletePushDetail($deletePushDetailId: String!) {
    deletePushDetail(id: $deletePushDetailId) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_PUSHNOTIFICATION = gql`
  mutation CreatePushNotification($pushNotification: CreatePushNotificationInput!) {
    createPushNotification(pushNotification: $pushNotification) {
      _id
      content
      client
      createdAt
      updatedAt
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      topic
      reference
      referenceType
      shippingDate
      firebaseSentDate
      firebaseResponse
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_PUSHNOTIFICATION = gql`
  mutation UpdatePushNotification($pushNotification: UpdatePushNotificationInput!) {
    updatePushNotification(pushNotification: $pushNotification) {
      _id
      content
      client
      createdAt
      updatedAt
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      topic
      reference
      referenceType
      shippingDate
      firebaseSentDate
      firebaseResponse
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_PUSHNOTIFICATION = gql`
  mutation DeletePushNotification($deletePushNotificationId: String!) {
    deletePushNotification(id: $deletePushNotificationId) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_STORY = gql`
  mutation CreateStory($story: CreateStoryInput!) {
    createStory(story: $story) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      tags
      reference
      referenceType
      headline
      subline
      priceText
      price
      reducedPrice
      strikePrice
      voucher
      buttonLabel
      buttonType
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_STORY = gql`
  mutation UpdateStory($story: UpdateStoryInput!) {
    updateStory(story: $story) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      tags
      reference
      referenceType
      headline
      subline
      priceText
      price
      reducedPrice
      strikePrice
      voucher
      buttonLabel
      buttonType
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_STORY = gql`
  mutation DeleteStory($deleteStoryId: String!) {
    deleteStory(id: $deleteStoryId) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_IN_APP_FLYER = gql`
  mutation CreateInAppFlyer($inappflyer: CreateInAppFlyerInput!) {
    createInAppFlyer(inappflyer: $inappflyer) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      headline
      image {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        ignoreIndex
        createdAt
        updatedAt
      }
      dealHeadline
      subline
      priceText
      price
      reducedPrice
      strikePrice
      voucher
      voucherFile
      voucherText
      bullet1Key
      bullet1Val
      bullet2Key
      bullet2Val
      bullet3Key
      bullet3Val
      bullet4Key
      bullet4Val
      reference
      referenceType
      buttonLabel
      startDate
      endDate
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_IN_APP_FLYER = gql`
  mutation UpdateInAppFlyer($inappflyer: UpdateInAppFlyerInput!) {
    updateInAppFlyer(inappflyer: $inappflyer) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      headline
      image {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        ignoreIndex
        createdAt
        updatedAt
      }
      dealHeadline
      subline
      priceText
      price
      reducedPrice
      strikePrice
      voucher
      voucherFile
      voucherText
      bullet1Key
      bullet1Val
      bullet2Key
      bullet2Val
      bullet3Key
      bullet3Val
      bullet4Key
      bullet4Val
      reference
      referenceType
      buttonLabel
      startDate
      endDate
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_IN_APP_FLYER = gql`
  mutation DeleteInAppFlyer($deleteInAppFlyerId: String!) {
    deleteInAppFlyer(id: $deleteInAppFlyerId) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      headline
      image {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        ignoreIndex
        createdAt
        updatedAt
      }
      dealHeadline
      subline
      priceText
      price
      reducedPrice
      strikePrice
      voucher
      voucherFile
      voucherText
      bullet1Key
      bullet1Val
      bullet2Key
      bullet2Val
      bullet3Key
      bullet3Val
      bullet4Key
      bullet4Val
      reference
      referenceType
      buttonLabel
      startDate
      endDate
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_INSTAFEED = gql`
  mutation CreateInstafeed($instafeed: CreateInstafeedInput!) {
    createInstafeed(instafeed: $instafeed) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      headline
      subline
      tags
      reference
      referenceType
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_INSTAFEED = gql`
  mutation UpdateInstafeed($instafeed: UpdateInstafeedInput!) {
    updateInstafeed(instafeed: $instafeed) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      headline
      subline
      tags
      reference
      referenceType
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_INSTAFEED = gql`
  mutation DeleteInstafeed($deleteInstafeedId: String!) {
    deleteInstafeed(id: $deleteInstafeedId) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_RESELLSHOP = gql`
  mutation deleteResellShop($id: String!) {
    deleteResellShop(id: $id)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_RESELLSHOP = gql`
  mutation CreateResellShop($shop: CreateShopInput!) {
    createResellShop(shop: $shop) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      headline
      tags
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_RESELLSHOP = gql`
  mutation UpdateResellShop($shop: UpdateShopInput!) {
    updateResellShop(shop: $shop) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        createdAt
        updatedAt
      }
      headline
      tags
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_CATEGORY = gql`
  mutation CreateCategory($createCategoryInput: CreateCategoryInput!) {
    createCategory(createCategoryInput: $createCategoryInput) {
      _id
      isActive
      title
      client
      query
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        createdAt
        updatedAt
      }
      colorCode
      tag
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_CATEGORY = gql`
  mutation UpdateCategory($category: UpdateCategoryInput!) {
    updateCategory(category: $category) {
      _id
      isActive
      title
      client
      query
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        createdAt
        updatedAt
      }
      colorCode
      tag
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_CATEGORY = gql`
  mutation DeleteCategory($deleteCategoryId: String!) {
    deleteCategory(id: $deleteCategoryId) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_CATEGORY_RANK = gql`
  mutation UpdateCategoryRank($updateDealRank: UpdateCategoryRankInput!) {
    updateCategoryRank(updateDealRank: $updateDealRank)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_VOUCHER_LIST = gql`
  mutation CreateVoucherList($voucherList: CreateVoucherListInput!) {
    createVoucherList(voucherList: $voucherList) {
      id
      name
      expirationDate
      isActive
      createdAt
      updatedAt
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_VOUCHER_LIST = gql`
  mutation UpdateVoucherList($voucherList: UpdateVoucherListInput!) {
    updateVoucherList(voucherList: $voucherList) {
      id
      name
      expirationDate
      isActive
      createdAt
      updatedAt
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPLOAD_VOUCHER_LIST = gql`
  mutation UploadVoucherList($file: Upload!) {
    uploadVoucherList(file: $file)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_VOUCHER_LIST = gql`
  mutation DeleteVoucherList($deleteVoucherListId: String!) {
    deleteVoucherList(id: $deleteVoucherListId)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_TAG = gql`
  mutation CreateTag($tagInput: TagInput!) {
    createTag(tagInput: $tagInput) {
      _id
      client
      name
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_CREATE_TAG_CATEGORY = gql`
  mutation CreateTagCategory($tagCategoryInput: TagCategoryInput!) {
    createTagCategory(tagCategoryInput: $tagCategoryInput) {
      _id
      client
      name
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_TAG = gql`
  mutation UpdateTag($id: String!, $tagInput: TagInput!) {
    updateTag(_id: $id, tagInput: $tagInput) {
      _id
      client
      name
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_UPDATE_TAG_CATEGORY = gql`
  mutation UpdateTagCategory($id: String!, $tagCategoryInput: TagCategoryInput!) {
    updateTagCategory(_id: $id, tagCategoryInput: $tagCategoryInput) {
      _id
      client
      name
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_TAG_CATEGORY = gql`
  mutation DeleteTagCategory($id: String!) {
    deleteTagCategory(_id: $id) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_TAG = gql`
  mutation DeleteTag($id: String!) {
    deleteTag(_id: $id) {
      _id
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_ADD_TAG_TO_CATEGORY = gql`
  mutation AddTagToCategory($id: String!, $categoryId: String!) {
    addTagToCategory(_id: $id, categoryId: $categoryId) {
      _id
      categoryId {
        _id
        client
        name
        tags {
          _id
          client
          name
        }
      }
      client
      name
    }
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;

export const CMS_DELETE_CATEGORY_ID_FROM_TAGS = gql`
  mutation DeleteCategoryIdFromTags($ids: [String!]!) {
    deleteCategoryIdFromTags(ids: $ids)
    ${process.env.VUE_APP_PURGE_ENABLED === 'true' ? '_purgeAll' : ''}
  }
`;
