
import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

import * as helper from '@/helper/data-helper';
import { EventBus } from '../common/event/eventbus';

@Component({
  props: {
    links: {
      type: Array,
    },
    version: {
      type: String,
    },
    inUsage: {
      type: Boolean,
    },
  },
})
export default class LinkList extends Vue {
  draggingRow: any = null;
  draggingRowIndex: any = null;
  draggingColumn: any = null;
  draggingColumnIndex: any = null;
  label: any = null;
  inUsage: any;
  public links: any;
  version: any;
  types: string[] = ['primary', 'secondary', 'alternative', 'ghost'];
  draggable: boolean = false;

  tableMouseEnter() {
    this.draggable = true;
  }

  tableMouseLeave() {
    this.draggable = false;
  }

  addNew() {
    if (this.links == null) {
      this.links = [];
    }
    let buttonDealLabel = '';
    this.$route.params.clientA == 'snkr-app' ||
    this.$route.params.clientB == 'snkr-web' ||
    this.$route.params.clientA == 'ps-app' ||
    this.$route.params.clientB == 'ps-web'
      ? (buttonDealLabel = 'ZUM SHOP')
      : (buttonDealLabel = 'SHOP NOW');

    this.links.push({
      id: helper.getNextId(this.links),
      url: '',
      target: '',
      type: 'primary',
      label: this.links.length == 0 ? buttonDealLabel : '',
    });

    return this.links[this.links.length - 1];
  }

  deleteLink(event: any, id: number) {
    let index = '';
    for (const i in this.links) {
      const image = this.links[i];

      if (image.id == id) {
        index = i;
      }
    }

    if (parseInt(index, 10) > -1) {
      this.links.splice(parseInt(index, 10), 1); // 2nd parameter means remove one item only
    }
    event.preventDefault();
    return false;
  }

  dragstart(payload: any) {
    this.draggingRow = payload.row;
    this.draggingRowIndex = payload.index;
    payload.event.dataTransfer.effectAllowed = 'copy';
  }
  dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = 'copy';
    payload.event.target.closest('tr').classList.add('is-selected');
    payload.event.preventDefault();
  }
  dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    payload.event.preventDefault();
  }
  drop(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    const droppedOnRowIndex = payload.index;
    this.links = this.arraymove(this.links, this.draggingRowIndex, droppedOnRowIndex);
  }

  arraymove(arr: any[], oldindex: number, newindex: number) {
    if (newindex >= arr.length) {
      let k = newindex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newindex, 0, arr.splice(oldindex, 1)[0]);
    return arr; // for testing
  }

  checkValidityAndSafe(whatsnew: boolean) {
    let allUrlsValid = true;
    for (let i = 1; i != this.links.length + 1; i++) {
      allUrlsValid =
        allUrlsValid &&
        (this.$refs['url' + String(i)] as Vue & { checkHtml5Validity: () => boolean }).checkHtml5Validity();
    }

    if (!allUrlsValid) {
      this.$buefy.notification.open({
        message: `Ein oder mehrere Links sind ungültig`,
        duration: 5000,
        type: 'is-danger',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });
    }

    if (allUrlsValid) {
      if (whatsnew) EventBus.$emit('save-deals-' + this.version, whatsnew);
      else EventBus.$emit('save-deals-' + this.version);
    }
  }

  mounted() {
    EventBus.$on('validate-and-save-deals', this.checkValidityAndSafe);
  }

  beforeDestroy() {
    EventBus.$off('validate-and-save-deals', this.checkValidityAndSafe);
  }
}
