
import { Component, Vue } from 'vue-property-decorator';
import { BACKEND_USER_GET_CURRENT } from '@/graphlql/queries';
import { cdnLink } from '@/main';
import { BACKEND_USER_LOGOUT } from '@/graphlql/mutations';

@Component({
  apollo: {
    backendUserGetCurrentUser: {
      query: BACKEND_USER_GET_CURRENT,
      result(data) {
        if (data?.data?.backendUserGetCurrentUser) {
          this.$store.dispatch('authModule/setCurrentUser', data?.data?.backendUserGetCurrentUser);
        }
      },
    },
  },
})
export default class CurrentUser extends Vue {
  cdn: string | undefined = cdnLink;
  backendUserGetCurrentUser: any = null;

  getLogoUrl() {
    return require('@/assets/logo-top.png');
  }

  async logout() {
    await this.$apollo
      .mutate({
        // Query
        mutation: BACKEND_USER_LOGOUT,
        // Parameters
      })
      .then(() => this.$store.dispatch('authModule/logout'))
      .then(() => {
        this.$router.go(0);
      })
      .catch((error) => {
        this.$router.go(0);
      });
  }
}
