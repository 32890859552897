
import { Component, Vue, Watch } from 'vue-property-decorator';

import { UPDATE_ALIAS } from '@/graphlql/mutations';

@Component({
  props: ['value']
})
export default class AliasesEdit extends Vue {
  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;
  value!: any;
  origin = '';
  alias = '';
  debug = '...';

  created() {
    this.origin = this.value.origin;
    this.alias = this.value.alias;
    //this.$apollo.queries.aliasessss.skip = true
  }

  updated() {
    // this.origin = this.value.origin;
    // this.alias = this.value.alias;
  }

  @Watch('value')
  onPropertyChanged(value: any, oldvalue: any) {
    this.origin = this.value.origin;
    this.alias = this.value.alias;
  }

  save() {
    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_ALIAS,
        // Parameters
        variables: {
          input: {
            alias: this.alias,
            origin: this.origin
          },
          id: this.value.id
        }
      })
      .then(data => {
        this.loading = false;
        this.result = true;
        this.error = false;
        this.success = true;
        this.debug = 'unloading';
        setTimeout(() => this.clearStates(), 1000);

        //setTimeout(() => this.clearStates(), 1000);
      })
      .catch(error => {
        this.loading = false;
        this.result = true;
        this.error = true;
        this.success = false;

        setTimeout(() => this.clearStates(), 1000);
      });
  }
  clearStates() {
    this.loading = false;
    this.result = false;
    this.error = false;
    this.success = false;
  }
  //console.log(this.$apollo.queries.aliases.start());
}
