
import NavigationNode from '@/components/navigation/NavigationNode.vue';
import BurgerMenu from '@/components/navigation/BurgerMenu.vue';
import { Component, Vue } from 'vue-property-decorator';
import { NavigationHeadInterface, NavigationNodeInterface } from '@/interfaces/navigation/navigation.interface';
import { createNamespacedHelpers } from 'vuex';
import MenuClickCreatedMixin, { NavigationEventBus } from '@/events/navigation/NavigationEventBus';
import { mixins } from 'vue-class-component';
import getConfig from '@/helper/config-loader';

const deviceModule = createNamespacedHelpers('deviceModule');
const navigationModule = createNamespacedHelpers('navigationModule');
const authModule = createNamespacedHelpers('authModule');

@Component({
  components: { BurgerMenu, NavigationNode },
  computed: {
    ...deviceModule.mapGetters(['getDeviceSize']),
    ...navigationModule.mapGetters(['getNavigationLevel2']),
    ...authModule.mapGetters(['getCurrentUser']),
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch('navigationModule/setNavigationLevel2', null);
      if (window.innerWidth < 640) {
        const self = this as SidebarNavigation;
        if (self.menuIsOpen) {
          NavigationEventBus.$emit('menu-got-clicked', false);
        }
      }
    },
  },
})
export default class SidebarNavigation extends mixins<Vue>(MenuClickCreatedMixin) {
  /*private newData: NavigationHeadInterface = {};



  @Prop()*/
  public navigation: NavigationHeadInterface = {};
  public menuIsOpen = false;
  public openNavigationLevel: NavigationNodeInterface | null = null;
  getCurrentUser: any;

  config: any = getConfig('meta');

  getLogoUrl() {
    return this.config.logo;
  }

  closeMenuMenu() {
    if (window.innerWidth < 640) {
      if (this.menuIsOpen) {
        NavigationEventBus.$emit('menu-got-clicked', false);
      }
    }
  }
  emptyNavigationPathOpen() {
    this.$store.dispatch('navigationModule/setNavigationPathActive', []);
  }

  checkPermissions(nodes: NavigationNodeInterface[]): NavigationNodeInterface[] {
    const navigation: NavigationNodeInterface[] = [];
    const currentUser = this.getCurrentUser;

    for (const index in nodes) {
      const layer = nodes[index];
      if (layer.permission == null || (currentUser != null && currentUser.permissions.indexOf(layer.permission) >= 0)) {
        navigation.push(layer);
      }
    }

    return navigation;
  }
  closeLevel2() {
    this.$store.dispatch('navigationModule/setNavigationLevel2', null);
  }
  getLevel2(navigation: NavigationHeadInterface) {
    const subnavigation: NavigationNodeInterface[] = [];
    const currentUser = this.getCurrentUser;

    for (const index in navigation) {
      const layer = navigation[index];
      if (
        (layer.permission == null || (currentUser != null && currentUser.permissions.indexOf(layer.permission) >= 0)) &&
        layer.children != null &&
        layer.children.length > 0
      ) {
        for (const layer2 of layer.children) {
          if (layer2.children != null && layer2.children.length > 0) {
            subnavigation.push(layer2);
          }
        }
      }
    }

    return subnavigation;
  }

  mounted() {
    this.$store.dispatch('navigationModule/fetchNavigation');
    this.$store.dispatch('navigationModule/setNavigationIndexes');
    this.navigation = this.$store.getters['navigationModule/getNavigationElements'];
  }
}
