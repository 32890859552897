
import { Component, Vue } from 'vue-property-decorator';

import { GET_MAPPING_TYPES, GET_MAPPINGS, GET_SHOPS } from '@/graphlql/queries';
import { UPDATE_MAPPINGS, DELETE_MAPPING } from '@/graphlql/mutations';
import TableBase from '../common/TableBase.vue';

@Component({
  watch: {
    selected(newSelected: any, oldSelected: any) {
      const self = this as MappingTable;
      if (newSelected != null && newSelected.id != self.currentType) {
        self.currentType = newSelected.id;
        self.page = 1;
        self.loading = true;
        self.loadAsyncData();
        self.putState();
      }
    },
    selectedShop(newSelectedShop: any, oldSelectedShop: any) {
      const self = this as MappingTable;
      if (newSelectedShop != null && newSelectedShop != self.currentShopId) {
        self.currentShopId = newSelectedShop;
        self.page = 1;
        self.loading = true;

        self.loadAsyncData();
        self.putState();
      }
    },
  },
  apollo: {
    shops: {
      query: GET_SHOPS,
      fetchPolicy: 'no-cache',
      result(data: any) {
        if (data.data != null) {
          for (const shop of data.data.shops) {
            let found = false;

            if (this.currentShopId == shop.id) {
              this.selectedShop = shop.id;
            }

            for (const current of this.shopData) {
              if (current.id == shop.id) {
                found = true;
                break;
              }
            }

            if (!found) {
              this.shopData.push(shop);
            }
          }
        }
      },
    },
    mappingTypes: {
      query: GET_MAPPING_TYPES,
      fetchPolicy: 'no-cache',
      result(data: any) {
        if (!data.loading) {
          this.typesData = data.data.mappingTypes;
          if (this.typesData != null && this.typesData.length > 0) {
            if (this.currentType > 0) {
              for (const typeData of this.typesData) {
                if (typeData.id == this.currentType) {
                  this.selected = typeData;
                }
              }
            } else {
              this.selected = this.typesData[0];
            }
          }
        }
      },
    },
    deleteMapping: {
      query: DELETE_MAPPING,
      fetchPolicy: 'no-cache',
      variables: {
        deleteMappingId: -1,
      },
      manual: true,
      result(data) {
        this.loadAsyncData();
      },
    },
    mappings: {
      query: GET_MAPPINGS,
      fetchPolicy: 'no-cache',
      variables: {
        mappingsOffset: 10,
        mappingslimit: 0,
        mappingsType: 1,
        mappingsShopId: -1,
        onlyActive: true,
      },
      manual: true,
      result(data: any) {
        if (!data.loading) {
          this.readData(data);
        }
      },
    },
  },
})
export default class MappingTable extends TableBase {
  typesColumns = [
    {
      field: 'name',
      label: 'Mapping Typ',
    },
  ];

  selectedOrder: string = 'key_ASC';
  checkedRows: any[] = [];
  orderData = [
    { key: 'key_ASC', name: 'Name aufsteigend' },
    { key: 'key_DESC', name: 'Name absteigend' },
    { key: 'createdAt_ASC', name: 'Erstelldatum aufsteigend' },
    { key: 'createdAt_DESC', name: 'Erstelldatum absteigend' },
  ];
  tableData: any[] = [];
  typesData = [];
  shopData = [{ id: -1, title: 'Alle' }];

  currentType: number = -1;
  currentShopId: number = -1;
  onlyActive: boolean = false;

  selected: any = null;
  selectedShop: any = null;
  selectedMapping: any = null;

  saveSuccess: boolean = false;
  saveError: boolean = false;
  saveResult: boolean = false;
  saveLoading: boolean = false;

  changedOrder() {
    this.page = 1;
    this.loadAsyncData();

    this.putState();
  }
  async deleteMapping(id: any) {
    await this.$apollo.queries.deleteMapping.refetch({
      deleteMappingId: id,
    });
  }
  readData(data: any) {
    this.loading = data.loading != null ? data.loading : false;
    const tableData: any = [];
    this.checkedRows = [];
    if (data.data != null) {
      if (data.data.mappings != null) {
        for (const mapping of data.data.mappings.mappings) {
          const entry = {
            id: mapping.id,
            key: mapping.key,
            value: mapping.value,
            type: mapping.type.name,
            active: mapping.active,
          };
          tableData.push(entry);

          if (entry.active) {
            this.checkedRows.push(entry);
          }
        }

        this.total = data.data.mappings.count;
      } else if (data.data.updateMappings != null) {
        for (const mapping of data.data.updateMappings) {
          const entry = {
            id: mapping.id,
            key: mapping.key,
            value: mapping.value,
            type: mapping.type.name,
            active: mapping.active,
          };
          tableData.push(entry);

          if (entry.active) {
            this.checkedRows.push(entry);
          }
        }
      }
    }
    this.tableData = tableData;
  }

  loadAsyncData() {
    if (this.currentType >= 0) {
      this.$apollo.queries.mappings.refetch({
        mappingsOffset: this.perPage * (this.page - 1),
        mappingslimit: this.perPage,
        mappingsType: this.currentType,
        mappingsShopId: this.currentShopId,
        mappingOrder: this.selectedOrder,
        onlyActive: this.onlyActive,
      });
    }
  }

  saveActive() {
    this.saveLoading = true;
    const updateMappings = [];

    for (const mapping of this.tableData) {
      const mappingData: any = { id: mapping.id, value: mapping.value };
      let active = false;
      for (const activeMapping of this.checkedRows) {
        if (activeMapping != null && activeMapping.id == mapping.id) {
          active = true;
          break;
        }
      }
      mappingData['active'] = active;
      updateMappings.push(mappingData);
    }

    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_MAPPINGS,
        // Parameters
        variables: {
          updateMappingsInput: updateMappings,
        },
      })
      .then((data) => {
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = false;
        this.saveSuccess = true;
        //reload data
        this.readData(data);
        setTimeout(() => this.resetSaveButton(), 1000);
      })
      .catch((error) => {
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = true;
        this.saveSuccess = false;
        this.loading = false;
        setTimeout(() => this.resetSaveButton(), 1000);
      });
  }
  resetSaveButton() {
    this.saveSuccess = false;
    this.saveError = false;
    this.saveResult = false;
    this.saveLoading = false;
  }

  keys = ['page', 'currentType', 'currentShopId', 'selectedOrder'];

  onPageChange(page: number) {
    super.onPageChange(page);
  }

  popstateEventAction() {
    super.popstateEventAction();
    this.loadAsyncData();
  }

  mounted() {
    super.mounted();
    this.loadAsyncData();
  }
}
