
import Vue from 'vue';
import Component from 'vue-class-component';

import LoginForm from '@/components/login/LoginForm.vue';
import getConfig from '@/helper/config-loader';
import { CMS_GENERATE_TEXT, GET_RANDOM_WALLPAPER_IMAGE } from '@/graphlql/queries';

const LoginProps = Vue.extend({
  props: {
    appName: {
      type: String,
      required: true,
    },
  },
});
@Component({
  components: {
    LoginForm,
  },
  apollo: {
    generateText: {
      query: CMS_GENERATE_TEXT,
      fetchPolicy: 'no-cache',
      manual: true,
      skip() {
        return !this.openAIEnabled;
      },
      variables() {
        return {
          prompt: getConfig('meta').greetingsPrompt,
        };
      },
      result(data) {
        if (data.data.generateText) {
          this.greetings = data.data.generateText.replace('\n\n', '');
          localStorage.setItem('login-greetings', this.greetings);
        }
      },
    },
    getRandomWallpaperImage: {
      query: GET_RANDOM_WALLPAPER_IMAGE,
      fetchPolicy: 'no-cache',
      manual: true,
      skip() {
        return this.skipQuery;
      },
      result(data) {
        if (data != null && data.data != null && data.data.getRandomWallpaperImage != null) {
          localStorage.setItem(
            'background-image',
            JSON.stringify({ date: new Date(), image: data.data.getRandomWallpaperImage })
          );

          this.backgroundImage = data.data.getRandomWallpaperImage.src.original;
        }
      },
    },
  },
})
export default class LoginView extends LoginProps {
  config: any = getConfig('meta');
  backgroundImage: string | null = null;
  greetings: string | null = 'Moin! Willkommen im Admin-Backend';
  skipQuery: boolean = true;
  openAIEnabled: boolean = process.env.VUE_APP_OPENAI_ENABLED == 'true';

  reloadBackgroundImage() {
    this.skipQuery = false;
    this.$apollo.queries.getRandomWallpaperImage.refetch();
  }

  mounted() {
    const localBackgroundImage = localStorage.getItem('background-image');
    const localBackgroundImageObj = JSON.parse('' + localBackgroundImage);

    if (localBackgroundImageObj?.image?.src?.original) {
      this.backgroundImage = localBackgroundImageObj?.image?.src?.original;
    } else {
      this.backgroundImage = this.config.background;

      this.reloadBackgroundImage();
    }

    if (localBackgroundImageObj?.date) {
      const date = new Date(localBackgroundImageObj.date).getTime() + 1 * 3 * 60 * 60 * 1000;
      const timestamp = new Date().getTime(); //day hour  min  sec  msec -> all 3 hours

      if (timestamp > date) {
        this.reloadBackgroundImage();
      }
    }

    const localStorageGreetings = localStorage.getItem('login-greetings');
    if (localStorageGreetings) {
      this.greetings = localStorageGreetings;
    }
  }
}
