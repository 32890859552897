import { Module } from 'vuex';
import { NavigationModuleInterface } from '@/interfaces/modules/navigation-module.interface';
import { RootStateInterface } from '@/interfaces/rootState.interface';
import { NavigationHeadInterface, NavigationNodeInterface } from '@/interfaces/navigation/navigation.interface';
import getConfig from '@/helper/config-loader';

const navigationModule: Module<NavigationModuleInterface, RootStateInterface> = {
  namespaced: true,
  state: {
    navigationElements: {},
    level2: null,
    navigationPathActive: [],
    navigationIndexed: [],
    navigationPathOpen: [],
    activeNode: '',
  },
  getters: {
    getNavigationElements(state: NavigationModuleInterface) {
      return state.navigationElements;
    },
    getNavigationPathActive(state: NavigationModuleInterface) {
      return state.navigationPathActive;
    },
    getNavigationPathOpen(state: NavigationModuleInterface) {
      return state.navigationPathOpen;
    },
    getNavigationIndexed(state: NavigationModuleInterface) {
      return state.navigationIndexed;
    },
    getNavigationLevel2(state: NavigationModuleInterface) {
      return state.level2;
    },
    getActiveNode(state: NavigationModuleInterface) {
      return state.activeNode;
    },
  },
  mutations: {
    SET_NAVIGATION_ELEMENTS(state: NavigationModuleInterface, navigationElements: NavigationHeadInterface) {
      state.navigationElements = navigationElements;
    },
    SET_NAVIGATION_PATH_ACTIVE(state: NavigationModuleInterface, navigationPath: []) {
      state.navigationPathActive = navigationPath;
    },
    SET_NAVIGATION_INDEXED(state: NavigationModuleInterface, navigationIndexed) {
      state.navigationIndexed = navigationIndexed;
    },
    SET_NAVIGATION_PATH_OPEN(state: NavigationModuleInterface, navigationPath: []) {
      state.navigationPathOpen = navigationPath;
    },
    SET_NAVIGATION_LEVEL2(state: NavigationModuleInterface, level2: NavigationNodeInterface) {
      state.level2 = level2;
    },
    SET_ACTIVE_NODE(state: NavigationModuleInterface, activeNode: string) {
      state.activeNode = activeNode;
    },
  },
  actions: {
    fetchNavigation({ commit }) {
      commit('SET_NAVIGATION_ELEMENTS', getConfig('navigation-tree'));
    },
    setNavigationPathActive({ commit }, navigationPath: []) {
      commit('SET_NAVIGATION_PATH_ACTIVE', navigationPath);
    },
    setNavigationPathOpen({ commit }, navigationPath: []) {
      commit('SET_NAVIGATION_PATH_OPEN', navigationPath);
    },
    setNavigationIndexes({ commit }) {
      commit('SET_NAVIGATION_INDEXED', []);
    },
    setNavigationLevel2({ commit }, level2: NavigationNodeInterface) {
      commit('SET_NAVIGATION_LEVEL2', level2);
    },
    setActiveNode({ commit }, activeNode: NavigationNodeInterface) {
      commit('SET_ACTIVE_NODE', activeNode);
    },
  },
};
export default navigationModule;
