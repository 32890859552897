
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import clients from '../../graphClient';
import {
  CMS_CREATE_BLOG,
  CMS_DELETE_BLOG,
  CMS_UPDATE_BLOG,
  CMS_UPDATE_BLOG_RANK,
  UPDATE_USER_USAGE,
  CREATE_USER_USAGE,
} from '@/graphlql/mutations';
import CMSView from './CMSView.vue';
import { CMS_GET_BLOG, CMS_GET_TEMPLATES, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import * as helper from '@/helper/data-helper';

import UploadImage from '@/components/cms/UploadImage.vue';
import { DragInterface } from '@/interfaces/cms/drag.interface';
import { previewLink, previewLinkPS, previewLinkSNKR } from '@/main';
import CustomTagInput from '@/components/tags/CustomTagInput.vue';
import VersionsTable from '@/components/versions/VersionsTable.vue';

@Component({
  components: {
    BaseView,
    CMSView,
    UploadImage,
    CustomTagInput,
    VersionsTable,
  },
  apollo: {
    getTemplates: {
      query: CMS_GET_TEMPLATES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          client: this.$route.params.client,
          type: 'blog',
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getTemplates != null) {
          this.templates = data.data.getTemplates;
        }
      },
    },
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.id,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.id;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.dealId !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.id !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.id !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.id === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
    getBlogById: {
      query: CMS_GET_BLOG,
      client: 'clientB',
      variables: {
        getBlogByIdId: '0',
      },
      skip() {
        return this.skipQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getBlogById != null) {
          if (data.data.getBlogById.previewImage == null) {
            data.data.getBlogById.previewImage = {};
          }

          this.blog = data.data.getBlogById;
          this.$store.dispatch('cmsItemsModule/setItems', this.blog.content.dynamicContent);

          this.siteTitle = 'Blogeintrag (' + this.blog.title + ') speichern';

          this.loaded = true;
          this.changed = false;
          //   this.setItems(this.page.content.dynamicContent);
        } else {
          this.$store.dispatch('cmsItemsModule/setItems', []);
        }

        this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.client + '-web');
      },
      error() {
        this.$store.dispatch('cmsItemsModule/setItems', []);
        this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.client + '-web');
      },
    },
  },
  watch: {
    $route(to, from) {
      const self = this as BlogView;
      self.refetch();
    },
    blog: {
      handler() {
        const self = this as BlogView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }

        self.lastChange = new Date();
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    const self = this as BlogView;
    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class BlogView extends Vue {
  siteTitle: string = '';
  skipQuery: boolean = true;

  changed: boolean = false;
  loaded: boolean = false;

  previewUrl: string | undefined = previewLink;
  previewUrlPS: string | undefined = previewLinkPS;
  previewUrlSNKR: string | undefined = previewLinkSNKR;

  lastChange = new Date();
  lastUpdateChange: any = null;

  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';
  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'blog',
    lastTimeEdited: new Date(),
  };
  templateTabIndex: number = 0;
  templates: any[] = [];

  blog: any = {
    content: {
      dynamicContent: [],
    },
    title: '',
    isActive: false,
    previewImage: {},
  };

  getEmpty() {
    const prefix = '';

    // if (this.$route.params.client == 'ps') {
    //   prefix = '/magazin/';
    // } else if (this.$route.params.client == 'snkr') {
    //   prefix = '/sneaker-news/';
    // }

    return {
      content: {
        dynamicContent: [],
      },
      title: '',
      isActive: true,
      previewImage: {},
      path: prefix,
    };
  }

  refetch() {
    if (this.$route.params.id == 'create') {
      this.siteTitle = 'Blogeintrag erstellen';
      this.blog = this.getEmpty();

      this.$store.dispatch('cmsItemsModule/setItems', this.blog.content.dynamicContent);
      this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.client + '-web');
    } else if (this.$route.params.id != null) {
      this.skipQuery = false;
      this.$apollo.queries.getBlogById.refetch({
        getBlogByIdId: this.$route.params.id,
      });
    }
  }

  async save() {
    try {
      await this.$apollo.mutate({
        mutation: CMS_UPDATE_BLOG_RANK,
        variables: {
          updateBlogRank: {
            orderBy: 'rank',
            sortOrder: -1,
            updateId: this.blog._id,
            newPredecessorId: null,
            newSuccessorId: null,
            client: null,
          },
        },
      });
    } catch (exception) {
      this.$buefy.notification.open({
        message: `Fehler beim Anlegen`,
        duration: 5000,
        type: 'is-danger',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });
    }
    this.$buefy.notification.open({
      message: `Erfolgreich gespeichert`,
      duration: 5000,
      type: 'is-success',
      pauseOnHover: true,
      position: 'is-bottom-right',
    });
  }

  openPreview() {
    const client = this.$route.params.client;

    if (client.indexOf('ps') > -1) {
      window.open(this.previewUrlPS + 'preview', '_blank');
    } else if (client.indexOf('snkr') > -1) {
      window.open(this.previewUrlSNKR + 'preview', '_blank');
    } else {
      window.open(this.previewUrl + 'preview', '_blank');
    }
  }

  mounted() {
    this.refetch();

    window.addEventListener('message', this.updatePreviewData, false);
  }

  unmounted() {
    window.removeEventListener('message', this.updatePreviewData);
  }

  updatePreviewData(event: any) {
    if (event.data != null && event.data.message == 'startsync') {
      this.lastUpdateChange = new Date();
      event.source.postMessage({ message: 'sentdata', getBlogByPath: this.getPreviewData() }, event.origin);
    }

    if (event.data != null && event.data.message == 'syncdata') {
      if (this.lastUpdateChange == null || this.lastChange.getTime() > this.lastUpdateChange.getTime()) {
        this.lastUpdateChange = new Date();
        event.source.postMessage({ message: 'sentdata', getBlogByPath: this.getPreviewData() }, event.origin);
      }
    }
  }

  addTemplateHandler(template: any, type: string) {
    switch (type) {
      case 'replace':
        this.blog.content = JSON.parse(JSON.stringify(template.content));

        break;
      case 'add':
        for (const item of template.content.dynamicContent) {
          const copy = JSON.parse(JSON.stringify(item));
          copy.id = this.getNextId(this.blog.content.dynamicContent);
          this.blog.content.dynamicContent.push(copy);
        }

        break;
    }

    this.$store.dispatch('cmsItemsModule/setItems', this.blog.content.dynamicContent);

    this.templateTabIndex = 0;
  }

  getNextId(items: DragInterface[]) {
    let max = this.resolveMaxId(items);
    return ++max;
  }
  resolveMaxId(items: DragInterface[]) {
    if (items == null || items.length == 0) {
      return 0;
    }

    let max = 0;

    for (const element of items) {
      if (element.id > max) {
        max = element.id;
      }

      if (element.items != null && element.items.length > 0) {
        const temp = this.resolveMaxId(element.items);
        if (temp > max) {
          max = temp;
        }
      }
    }

    return max;
  }

  async saveNew() {
    clients.clientB
      .mutate({
        mutation: CMS_CREATE_BLOG,
        variables: {
          blog: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.createBlog != null && data.data.createBlog._id) {
          this.$router.push({
            path: `/cms/blogs/${this.$route.params.client}/${data.data.createBlog._id}`,
          });
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async userLeave() {
    this.currentUsage.inUse = false;

    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  async saveExisting() {
    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_BLOG,
        variables: {
          blog: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.updateBlog != null && data.data.updateBlog._id) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  getData() {
    this.blog.client = this.$route.params.client;

    const returnData = JSON.parse(
      JSON.stringify(helper.cleanTypeData(this.blog, ['__typename', 'updatedAt', 'createdAt']))
    );

    if (returnData['previewImage'] && returnData['previewImage']['_id']) {
      returnData['previewImage'] = returnData['previewImage']['_id'];
    } else {
      delete returnData['previewImage'];
    }

    if (returnData['path']) {
      const lastChar = returnData['path'].charAt(returnData['path'].length - 1);
      if (lastChar !== '/') {
        returnData['path'] = returnData['path'] + '/';
      }
    }

    return returnData;
  }

  async deleteHandler() {
    const content = prompt('Bitte "löschen" zum bestätigen eingeben');
    if (content === 'löschen') {
      if (this.blog?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_BLOG,
          variables: {
            deleteBlogId: this.blog._id,
          },
        });
      }

      // Surpress unsaved change prompt, a bit hacky but works
      this.changed = false;
      this.$router.push({ name: 'BlogsView' });
    }
  }

  getPreviewData() {
    this.blog.client = this.$route.params.client;

    const returnData = JSON.parse(
      JSON.stringify(helper.cleanTypeData(this.blog, ['__typename', 'updatedAt', 'createdAt']))
    );

    return returnData;
  }

  async saveHandler() {
    if (this.$route.params.id == 'create') {
      this.saveNew();
    } else if (this.$route.params.id != null) {
      this.saveExisting();
    }
  }
}
