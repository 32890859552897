
import { SEARCH_EVENT_REPORTS } from '@/graphlql/queries';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    method: {
      type: Array,
    },
    productPath: {
      type: Array,
    },
    id: {
      type: Array,
    },
    omitCard: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    searchEventReports: {
      query: SEARCH_EVENT_REPORTS,
      skip() {
        return this.skip;
      },
      variables: {
        size: 0,
        page: 0,
        args: [],
        query: '',
      },
      async result(data: any) {
        const self = this as unknown as VersionsTable;

        if (data?.data?.searchEventReports?.hits?.length) {
          for (const it of data?.data?.searchEventReports?.hits) {
            const item = it as any;

            if (self.tableData.filter((i) => i.id == item.id).length == 0) {
              self.tableData.push({
                createdAt: this.format(item.createdAt),
                id: item.id,
                user: item?.payload?.user,
                payload: item?.payload,
                method: item?.payload?.operationName ?? item?.payload?.body?.operationName ?? item?.type,
              });
            }
          }
        }

        self.loading = false;
      },
      manual: true,
    },
  },
  computed: {
    table() {
      const self = this as VersionsTable;
      return self.tableData.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    },
  },
  watch: {
    '$props.id': {
      async handler() {
        await (this as VersionsTable).loadAsyncData();
      },
      deep: true,
    },
  },
})
export default class VersionsTable extends Vue {
  tableData: any[] = [];

  page: number = 1;
  perPage: number = 300;
  total: number = 0;
  loading: boolean = true;
  selected: any = null;
  skip: boolean = true;
  updateTimeout: number | null = null;

  async loadAsyncData() {
    this.skip = false;

    this.tableData = [];

    if (this.updateTimeout != null) window.clearTimeout(this.updateTimeout);

    this.updateTimeout = window.setTimeout(() => {
      if (
        this.$props.method.length == this.$props.id.length &&
        this.$props.id.length == this.$props.productPath.length
      ) {
        for (let i = 0; i < this.$props.method.length; i++) {
          window.setTimeout(async () => {
            const args: any[] = [];

            args.push({
              key: this.$props.productPath[i],
              value: '' + this.$props.id[i],
            });

            await this.$apollo.queries.searchEventReports.refetch({
              page: this.perPage * (this.page - 1),
              size: this.perPage,
              query: this.$props.method[i],
              args: args,
            });
          }, 1000 * i + 500);
        }
      }
    }, 0);
  }

  format(value: string) {
    if (!value) return '';
    const d = new Date(value);
    return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
  }

  async mounted() {
    await this.loadAsyncData();
  }
}
