
import { Component, Vue } from 'vue-property-decorator';
import {
  GET_CLIENTS,
  GET_PRODUCT_BRANDS,
  GET_PRODUCT_COLORS_FULL,
  GET_SIZES,
  GET_PRODUCT_CATEGORIES,
  SEARCH_INFORMATION,
  GET_BRANDMODELS,
} from '@/graphlql/queries';

import RichText from '../../components/common/RichText.vue';
import UploadImage from '@/components/common/UploadImage.vue';

@Component({
  props: ['value'],
  components: {
    UploadImage,
    RichText,
  },
  apollo: {
    clients: {
      query: GET_CLIENTS,
    },
    brands: {
      query: GET_PRODUCT_BRANDS,
      manual: true,
      result(data) {
        if (data.data != null) {
          if (!data.loading) {
            this.brands = [];
            for (const entry of data.data.brands) {
              this.brands = this.brands.concat(entry);
            }
          }
        }
      },
    },
    brandModels: {
      query: GET_BRANDMODELS,
      manual: true,
      result(data) {
        if (data.data != null) {
          if (!data.loading) {
            this.brandModels = [];
            for (const entry of data.data.getBrandModels) {
              this.brandModels = this.brandModels.concat(entry);
            }
            this.$forceUpdate();
          }
        }
      },
    },
    categories: {
      query: GET_PRODUCT_CATEGORIES,
    },
    sizes: {
      manual: true,
      query: GET_SIZES,
      variables: {
        getShoeSizesKey: 'nike',
      },
      result(data) {
        if (data.data != null) {
          if (!data.loading) {
            this.sizes = [];
            for (const entry of data.data.getShoeSizes.women) {
              this.sizes = this.sizes.concat(entry.eu);
            }
          }
        }
      },
    },
    searchInformation: {
      query: SEARCH_INFORMATION,
      result(data) {
        if (data.data.searchInformation != null) {
          const searchInformation = data.data.searchInformation;
          if (searchInformation.filter != null) {
            for (const filter of searchInformation.filter) {
              if (filter.key == 'model') {
                this.models = filter.values;
              }
            }
          }
        }
      },
    },
    colors: {
      manual: true,
      query: GET_PRODUCT_COLORS_FULL,
      result(data) {
        if (data.data != null) {
          if (!data.loading) {
            this.colors = [];
            for (const entry of data.data.colors) {
              this.colors = this.colors.concat(entry.name);
            }
          }
        }
      },
    },
  },
  watch: {
    choosenBrand() {
      const self = this as SeoDetailView;
      self.updateContext();
    },
    choosenCategory() {
      const self = this as SeoDetailView;
      self.updateContext();
    },
    choosenModel() {
      const self = this as SeoDetailView;
      self.updateContext();
    },
    value() {
      const self = this as SeoDetailView;
      self.updateInternalValues();
    },
    choosenColor() {
      const self = this as SeoDetailView;
      self.updateContext();
    },
    choosenSize() {
      const self = this as SeoDetailView;
      self.updateContext();
    },
  },
})
export default class SeoDetailView extends Vue {
  loading: boolean = false;
  value: any;
  models: string[] = [];
  colors: string[] = [];
  sizes: string[] = [];
  choosenBrand: string = '';
  choosenCategory: string = '';
  choosenModel: string = '';
  choosenColor: string = '';
  choosenSize: string = '';
  brandModels: any = [];
  brands = [];

  updateContext() {
    this.value.context = [];
    if (this.choosenBrand != '' && this.choosenBrand != null) {
      this.value.context.push({
        key: 'brand.name.keyword',
        value: [this.choosenBrand],
      });
    }

    if (this.choosenCategory != '' && this.choosenCategory != null) {
      this.value.context.push({
        key: 'category.name.keyword',
        value: [this.choosenCategory],
      });
    }

    if (this.choosenModel != '' && this.choosenModel != null) {
      this.value.context.push({
        key: 'model.keyword',
        value: [this.choosenModel],
      });
    }

    if (this.choosenColor != '' && this.choosenColor != null) {
      this.value.context.push({
        key: 'color.keyword',
        value: [this.choosenColor],
      });
    }

    if (this.choosenSize != '' && this.choosenSize != null) {
      this.value.context.push({
        key: 'size.keyword',
        value: [this.choosenSize],
      });
    }
  }

  updateInternalValues() {
    this.choosenCategory = '';
    this.choosenBrand = '';
    this.choosenModel = '';
    this.choosenColor = '';
    this.choosenSize = '';

    for (const context of this.value.context) {
      if (context.key == 'brand.name.keyword') {
        this.choosenBrand = context.value[0];
      } else if (context.key == 'category.name.keyword') {
        this.choosenCategory = context.value[0];
      } else if (context.key == 'model.keyword') {
        this.choosenModel = context.value[0];
      } else if (context.key == 'color.keyword') {
        this.choosenColor = context.value[0];
      } else if (context.key == 'size.keyword') {
        this.choosenSize = context.value[0];
      }
    }
  }

  mounted() {
    if (this.value != null && this.value.context != null && this.value.context.length > 0) {
      this.updateInternalValues();
    }
  }

  get filteredBrandModels() {
    if (this.choosenBrand == '') {
      return this.brandModels;
    }
    let res: any = [];
    for (const entry of this.brandModels) {
      if (entry.brand.name == this.choosenBrand) {
        res = res.concat(entry);
      }
    }
    return res;
  }
}
