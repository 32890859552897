
import { BACKEND_USER_GET_ALL } from '@/graphlql/queries';
import { Component, Vue } from 'vue-property-decorator';
import BaseView from '../common/BaseView.vue';
import UploadAvatar from '@/components/common/UploadAvatar.vue';
import { cdnLink } from '@/main';
import { BACKEND_USER_CHANGE_PASSWORD, BACKEND_USER_UPDATE } from '@/graphlql/mutations';
import RegisterModal from '@/components/usermanagement/RegisterModal.vue';
import { createNamespacedHelpers } from 'vuex';
import getConfig from '@/helper/config-loader';
const authModule = createNamespacedHelpers('authModule');

@Component({
  components: { BaseView, UploadAvatar },
  computed: {
    ...authModule.mapGetters(['getCurrentUser']),
  },
  apollo: {
    getBackendUsers: {
      query: BACKEND_USER_GET_ALL,
      variables: {
        offset: 0,
        limit: 20,
      },
      result(data) {
        if (data != null && data.loading == false) {
          if (data.data.getBackendUsers != null && data.data.getBackendUsers.backendUsers != null) {
            this.loading = data.loading;
            const tableData: any = [];
            for (const users of data.data.getBackendUsers.backendUsers) {
              tableData.push(users);
            }

            this.tableData = tableData;
            this.total = data.data.getBackendUsers.count;
          }
        }
      },
    },
  },
})
export default class UserManagementView extends Vue {
  tableData: any = [];
  defaultOpenedDetails: any = [];
  page: number = 1;
  total: number = 0;
  perPage: number = 50;
  loading: boolean = true;
  allPermissions: string[] = ['SEARCH_ENGINE_CMS', 'SYSTEM_SETTINGS', 'USER_MANAGEMENT', 'CMS'];
  cdn: string | undefined = cdnLink;
  password: string | null = null;
  oldPassword: string | null = null;
  passwordRepeat: string | null = null;
  passwordError: string | null = null;
  passwordOldError: string | null = null;
  config: any = getConfig('meta');

  closeAllOtherDetails(row: any) {
    this.defaultOpenedDetails = [row.id];
  }

  toggleDetails(props: any) {
    this.password = null;
    this.passwordRepeat = null;
    this.passwordError = null;
    this.passwordOldError = null;
    this.oldPassword = null;
    this.closeAllOtherDetails(props.row);
    props.toggleDetails(props.row);
  }

  async saveUser(user: any) {
    await this.$apollo
      .mutate({
        // Query
        mutation: BACKEND_USER_UPDATE,
        // Parameters
        variables: {
          user: {
            username: user.username,
            id: user.id,
            permissions: user.permissions,
            avatar: user.avatar,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
          },
        },
      })
      .then((data) => {
        if (data.data.backendUserUpdate != null) {
          this.$buefy.notification.open({
            message: `Nutzer gespeichert geändert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.notification.open({
            message: `Nutzer wurde nicht geändert. Die Daten des Nutzers sind nicht korrekt.`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      })
      .catch((error) => {
        this.$buefy.notification.open({
          message: `Beim Speichern des Nutzers ist ein Fehler passiert.`,
          duration: 5000,
          type: 'is-danger',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      });
  }

  async savePassword(user: any) {
    if (this.password == null || this.password.trim().length == 0) {
      this.passwordError = 'Das Passwort darf nicht leer sein.';
    } else if (this.password != this.passwordRepeat) {
      this.passwordError = 'Die Passwörter müssen identisch sein.';
    } else {
      this.passwordError = null;
    }

    if (this.oldPassword == null || this.oldPassword.trim().length == 0) {
      this.passwordOldError = 'Das vorherige Passwort muss eingegeben werden';
    } else {
      this.passwordOldError = null;
    }

    if (this.passwordError == null && this.passwordOldError == null) {
      await this.$apollo
        .mutate({
          // Query
          mutation: BACKEND_USER_CHANGE_PASSWORD,
          // Parameters
          variables: {
            changeModel: {
              oldPassword: this.oldPassword,
              newPassword: this.password,
              uuid: user.id,
            },
          },
        })
        .then((data) => {
          if (data.data.backendUserChangePassword == true) {
            this.$buefy.notification.open({
              message: `Passwort geändert`,
              duration: 5000,
              type: 'is-success',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          } else {
            this.$buefy.notification.open({
              message: `Passwort wurde nicht geändert. Das alte Passwort ist nicht korrekt oder ein Serverfehler liegt vor. `,
              duration: 5000,
              type: 'is-danger',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          }
        })
        .catch((error) => {
          this.$buefy.notification.open({
            message: `Beim Ändern des Passworts ist ein Fehler passiert.`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        });
    }
  }

  updatePath(data: any, image: string) {
    data.avatar = image;
  }

  isChecked(permission: string, permissions: string[]) {
    if (permissions != null) {
      return permissions.includes(permission);
    }
    return false;
  }

  modifyPermission(permission: string, permissions: string[]) {
    if (permissions != null) {
      const index = permissions.indexOf(permission);
      if (index > -1) {
        permissions.splice(index, 1);
      } else {
        permissions.push(permission);
      }
      return permissions;
    }
    return null;
  }

  getLogoUrl() {
    return this.config.logoTop;
  }

  openRegisterModal() {
    this.$buefy.modal.open({
      parent: this,
      component: RegisterModal,
      hasModalCard: true,
      trapFocus: true,
      events: {
        close: () => {
          this.refresh();
        },
      },
    });
  }

  refresh() {
    this.$apollo.queries.getBackendUsers.refetch();
  }

  mounted() {
    this.refresh();
  }
}
