import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueApollo from 'vue-apollo';
import clients from './graphClient';
import CKEditor from 'ckeditor4-vue';
import i18n from '@/i18n';

require('dotenv').config();

import { faBars } from '@fortawesome/free-solid-svg-icons';

import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fal);
library.add(far);
library.add(fas);
library.add(fab);

library.add(faBars);

/*
  Here we inject the global components and load them with a helper function
 */
//import GlobalComponentLoader from '@/components/global/main';

//new GlobalComponentLoader().bootstrap();

Vue.config.productionTip = false;

Vue.use(Buefy);
Vue.use(VueApollo);
Vue.use(CKEditor);

//messages
Vue.component('font-awesome-icon', FontAwesomeIcon);
export const cdnLink = process.env.VUE_APP_CDN_URL;
export const cmsLink = process.env.VUE_APP_CMS_URL;
export const previewLink = process.env.VUE_APP_PREVIEW_URL;
export const previewLinkPS = process.env.VUE_APP_PREVIEW_URL_PS;
export const previewLinkSNKR = process.env.VUE_APP_PREVIEW_URL_SNKR;

const apolloProvider = new VueApollo({
  clients,
  defaultClient: clients.clientA,
});

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
