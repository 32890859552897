
import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { CMS_GET_TEMPLATES } from '@/graphlql/queries';
import { EventBus } from '../common/event/eventbus';

@Component({
  components: {},
  apollo: {
    getTemplates: {
      query: CMS_GET_TEMPLATES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          client: this.getTextClient(),
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getTemplates != null) {
          if (this.selectedPriceTemplate == null || this.selectedPriceTemplate == '') {
            if (this.deal.priceText != null && this.deal.priceText._id != null) {
              this.selectedPriceTemplate = this.deal.priceText._id;
              this.default = false;
            } else if (data.data.getTemplates.length > 0) {
              this.selectedPriceTemplate = data.data.getTemplates[0]._id;
              this.deal.priceText = data.data.getTemplates[0];
              this.default = true;
            }
          }
          this.templates = data.data.getTemplates;
        }
      },
    },
  },
  watch: {
    deal: {
      handler() {
        const self = this as DealPrice;
        if (
          (self.default && (self.selectedPriceTemplate == null || self.selectedPriceTemplate == '')) ||
          (self.templates.length > 0 && self.selectedPriceTemplate == self.templates[0]._id)
        ) {
          if (self.deal.priceText != null && self.deal.priceText._id != null) {
            self.selectedPriceTemplate = self.deal.priceText._id;
            self.default = false;
          }
        }
      },
      deep: true,
    },
  },
})
export default class DealPrice extends Vue {
  @Prop({ required: true })
  deal: any;

  @Prop({ required: true })
  inUsage: any;

  default: boolean = true;
  selectedPriceTemplate: string = '';
  templates: any[] = [];

  selectPriceTemplate() {
    this.default = false;
    for (const t of this.templates) {
      if (this.selectedPriceTemplate == t._id) {
        this.deal.priceText = t;
      }
    }
  }

  mounted() {
    if (this.deal.priceText != null && this.deal.priceText._id != null) {
      this.selectedPriceTemplate = this.deal.priceText._id;
      this.default = false;
    }

    EventBus.$on('price-template-changed', this.priceTemplateChanged);
  }

  EventBus() {
    EventBus.$off('price-template-changed', this.priceTemplateChanged);
  }

  priceTemplateChanged() {
    this.default = true;
    this.selectedPriceTemplate = '';
  }

  getTextClient(): string {
    let client = this.$route.params.clientA;
    if (client == null || client.length == 0) {
      client = this.$route.params.client;
    }

    if (client == null || client.length == 0) {
      client = this.$route.params.clientId;
    }

    if (client.indexOf('-') >= 0) {
      client = client.substring(0, client.indexOf('-'));
    }

    return client + '-text';
  }
}
