
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { CMS_GET_BLOGS, CMS_GET_INSTAFEEDS } from '@/graphlql/queries';
import { cmsLink } from '@/main';
import clients from '@/graphClient';

@Component({
  components: {},
  apollo: {
    getInstafeeds: {
      query: CMS_GET_INSTAFEEDS,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,

      skip() {
        return this.skipQuery;
      },
      variables() {
        return {
          offset: this.perPage * (this.page - 1),
          limit: this.perPage,
          orderBy: 'createdAt',
          sortOrder: -1,
          client: '',
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getInstafeeds != null) {
          this.tableData = data.data.getInstafeeds.items;
          this.page = data.data.getInstafeeds.currentPage;
          this.total = data.data.getInstafeeds.totalItems;
        }
      },
    },
  },

  watch: {
    $route(to, from) {
      const self = this as InstaFeedTable;
      self.resetData();
    },
  },
  filters: {
    format: function (value: string) {
      if (!value) return '';
      const d = new Date(value);
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    },
  },
})
export default class InstaFeedTable extends Vue {
  page: number = 1;
  total: number = 1;
  perPage: number = 10;
  tableData: any[] = [];
  skipQuery: boolean = true;
  cdn: string | undefined = cmsLink;
  pageSizes = [2, 10, 20, 50, 100, 200];

  mounted() {
    this.refetchData();
  }

  resetData() {
    this.page = 1;
    this.refetchData();
  }

  onPageChange(page: number) {
    this.page = page;
    this.refetchData();
  }

  changePageSize() {
    this.page = 1;
    this.refetchData();
  }

  refetchData() {
    this.skipQuery = false;
    this.$apollo.queries.getInstafeeds.refetch({
      offset: this.perPage * (this.page - 1),
      limit: this.perPage,
      orderBy: 'createdAt',
      sortOrder: -1,
      client: this.$route.params.client,
    });
  }

  clickTable(event: any) {
    if (event._id != null) {
      this.$router.push({
        path: `/cms/instafeeds/${this.$route.params.client}/${event._id}`,
      });
    }
  }

  editHandler(id: string) {
    this.$router.push({
      path: `/cms/instafeeds/${this.$route.params.client}/${id}`,
    });
  }
}
