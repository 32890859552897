
import clients from '@/graphClient';
import { Prop, Vue } from 'vue-property-decorator';
import { cmsLink } from '@/main';
import Component from 'vue-class-component';

import * as helper from '@/helper/data-helper';
import { CMS_GET_RESELL_SHOPS } from '@/graphlql/queries';
import { EventBus } from '../common/event/eventbus';
import { isReactive } from 'vue';

@Component({
  props: {
    isRaffle: {
      type: Boolean,
      default: false,
    },
    shops: {
      type: Array,
    },
    hideDate: {
      type: String,
    },
    hidePrice: {
      type: String,
    },
    version: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  computed: {
    buttonLabel: {
      get() {
        const self = this as ShopList;
        return self.label;
      },
    },
  },
  apollo: {
    getResellShops: {
      query: CMS_GET_RESELL_SHOPS,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        let client = this.$route.params.client;
        if (client == null) {
          const clientA = this.$route.params.clientA;
          if (clientA != null) {
            client = clientA.substring(0, clientA.indexOf('-'));
          }
        }

        return {
          offset: 0,
          limit: 1000,
          orderBy: 'headline',
          sortOrder: 1,
          client: client,
          isActive: true,
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getResellShops != null) {
          this.allShops = data.data.getResellShops.items;
        }
      },
    },
  },
})
export default class ShopList extends Vue {
  draggingRow: any = null;
  draggingRowIndex: any = null;
  draggingColumn: any = null;
  draggingColumnIndex: any = null;

  @Prop({ required: true })
  label: string | undefined;
  @Prop({ required: true })
  inUsage: boolean | undefined;
  @Prop()
  mainReleaseDate: Date | undefined;
  @Prop()
  mainReleasePrice: number | string | undefined;

  cdn: string | undefined = cmsLink;
  public shops: any;
  allShops: any[] = [];
  files: any[] = [];
  selectedShop: any;

  emptyShopAlert = false;
  type: any;
  version: any;
  draggable: boolean = false;

  tableMouseEnter() {
    this.draggable = true;
  }

  tableMouseLeave() {
    this.draggable = false;
  }

  addNew() {
    if (this.shops == null) {
      this.shops = [];
    }
    this.shops.push({
      id: helper.getNextId(this.shops),
      url: '',
      shop: '',
      type: '',
      releaseDate: this.mainReleaseDate,
      validUntil: this.mainReleaseDate,
      price: this.mainReleasePrice,
    });

    return this.shops[this.shops.length - 1];
  }

  deleteShop(event: any, id: number, _id: string) {
    let index = '';
    for (const i in this.shops) {
      const image = this.shops[i];

      if (id != null) {
        if (image.id == id) {
          index = i;
        }
      } else if (_id != null) {
        if (image._id == _id) {
          index = i;
        }
      }
    }

    if (parseInt(index, 10) > -1) {
      this.shops.splice(parseInt(index, 10), 1); // 2nd parameter means remove one item only
    }
    event.preventDefault();
    return false;
  }

  dragstart(payload: any) {
    this.draggingRow = payload.row;
    this.draggingRowIndex = payload.index;
    payload.event.dataTransfer.effectAllowed = 'copy';
  }
  dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = 'copy';
    payload.event.target.closest('tr').classList.add('is-selected');
    payload.event.preventDefault();
  }
  dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    payload.event.preventDefault();
  }
  drop(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    const droppedOnRowIndex = payload.index;
    this.shops = this.arraymove(this.shops, this.draggingRowIndex, droppedOnRowIndex);
  }

  arraymove(arr: any[], oldindex: number, newindex: number) {
    if (newindex >= arr.length) {
      let k = newindex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newindex, 0, arr.splice(oldindex, 1)[0]);
    return arr; // for testing
  }

  checkValidityAndSafe() {
    let allShopsCorrect = true;
    for (const shop of this.shops) {
      if (shop.shop == '') {
        allShopsCorrect = false;
      }
    }
    let allUrlsValid = true;
    for (let i = 1; i != this.shops.length + 1; i++) {
      allUrlsValid =
        allUrlsValid &&
        (this.$refs['url' + String(i)] as Vue & { checkHtml5Validity: () => boolean }).checkHtml5Validity();
    }

    if (!allShopsCorrect) {
      this.$buefy.notification.open({
        message: `Mindestens ein Shop oder Raffle hat keinen Shop`,
        duration: 5000,
        type: 'is-danger',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });
      this.emptyShopAlert = true;

      setTimeout(() => this.resetEmptyShopAlert(), 4000);
      return;
    }
    if (!allUrlsValid) {
      this.$buefy.notification.open({
        message: `Ein oder mehrere Links sind ungültig`,
        duration: 5000,
        type: 'is-danger',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });
    }

    if (allShopsCorrect && allUrlsValid) {
      EventBus.$emit('save-releases-' + this.type + '-' + this.version);
    }
  }

  resetEmptyShopAlert() {
    this.emptyShopAlert = false;
  }

  mounted() {
    EventBus.$on('validate-and-save-releases', this.checkValidityAndSafe);
  }

  beforeDestroy() {
    EventBus.$off('validate-and-save-releases', this.checkValidityAndSafe);
  }
}
