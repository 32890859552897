
import Vue from 'vue';
import Component from 'vue-class-component';
import ProductsTable from '@/components/product/ProductsTable.vue';
import BaseView from '../common/BaseView.vue';
import { EventBus } from '@/components/common/event/eventbus';
@Component({ components: { ProductsTable, BaseView } })
export default class ProductsView extends Vue {
  saveMarked() {
    EventBus.$emit('save-marked-products');
  }
  saveMarkedForAllClients() {
    EventBus.$emit('save-marked-products-all');
  }
  saveMarkedDeactivate() {
    EventBus.$emit('save-marked-products-deactivate');
  }
  saveMarkedDeactivateForAllClients() {
    EventBus.$emit('save-marked-products-deactivate-all');
  }
}
