
import Vue from 'vue';
import Component from 'vue-class-component';
import { defaultLocale } from '@/i18n';
import AdminView from '@/views/admin-view/AdminView.vue';
import { mapGetters } from 'vuex';

@Component({
  components: { AdminView },
  computed: {
    ...mapGetters(['deviceModule/getDeviceSize', 'authModule/isLoggedIn']),
  },
})
export default class App extends Vue {
  appName: string | undefined = process.env.VUE_APP_NAME;
  isLoggedIn!: any;

  get transition(): string {
    return this.isLoggedIn ? '' : 'slide-page-up';
  }

  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.setDeviceWidth);
      this.setDeviceWidth();
    });

    //init i18n with defautLanguage
    this.$store.dispatch('i18nModule/setLanguage', defaultLocale);
    this.$store.dispatch('i18nModule/fetchLanguageJson', this.$store.getters['i18nModule/getLanguage']);
    this.$i18n.setLocaleMessage(
      this.$store.getters['i18nModule/getLanguage'],
      this.$store.getters['i18nModule/getLanguageObject'],
    );
  }

  setDeviceWidth() {
    this.$store.dispatch('deviceModule/setDeviceSize', document.documentElement.clientWidth);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.setDeviceWidth);
  }
}
