
import { Component, Vue } from 'vue-property-decorator';
import ClientEdit from '@/components/clients/ClientEdit.vue';
import ClientCreate from '@/components/clients/ClientCreate.vue';
import { GET_CLIENTS } from '@/graphlql/queries';

@Component({
  components: {
    ClientEdit,
    ClientCreate,
  },
  apollo: {
    clients: {
      query: GET_CLIENTS,

      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            this.loading = data.loading;
            const tableData: any = [];
            for (const client of data.data.clients) {
              tableData.push(client);
            }

            this.tableData = tableData;
          }
        }
      },
    },
  },
})
export default class ClientsTable extends Vue {
  columns = [
    {
      field: 'id',
      label: 'ID',
      width: '80',
      numeric: true,
    },
    {
      field: 'name',
      label: 'Name',
    },
  ];

  tableData: any[] = [];

  selected: any = null;
  createNewObject: any = null;

  createdClient(data: any) {
    this.loadAsyncData();

    this.createNewObject = null;
    if (this.selected == null) {
      this.selected = data.createClient;
    }
  }

  notCreatedClient(data: any) {
    this.loadAsyncData();
    this.createNewObject = null;
  }

  loadAsyncData() {
    this.$apollo.queries.clients.refetch();
  }

  createNew() {
    this.createNewObject = {
      referrer: [],
      adverts: [],
      active: false,
      name: '',
    };

    this.selected = null;
  }
}
