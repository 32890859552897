import { Module } from 'vuex';
import I18nModuleInterface from '@/interfaces/modules/i18n-module.interface';
import { RootStateInterface } from '@/interfaces/rootState.interface';

import { Locales } from '@/i18n/locales';
import i18n, { defaultLocale } from '@/i18n';
import { de } from '@/i18n/translations/de';
import { en } from '@/i18n/translations/en';

const i18nModule: Module<I18nModuleInterface, RootStateInterface> = {
  namespaced: true,
  state: {
    languageObject: {},
    language: defaultLocale
  },
  getters: {
    getLanguageObject(state: I18nModuleInterface) {
      return state.languageObject;
    },
    getLanguage(state: I18nModuleInterface) {
      return state.language;
    }
  },
  mutations: {
    SET_LANGUAGE_OBJECT(state: I18nModuleInterface, languageObject: {}) {
      state.languageObject = languageObject;
    },
    SET_LANGUAGE(state: I18nModuleInterface, language: Locales) {
      state.language = language;
      i18n.locale = language;
    }
  },
  actions: {
    fetchLanguageJson({ commit }, language: Locales) {
      /*
          axios calls for languageJSON. handout the giving language to receive correct JSON from SERVER.
          if there are no separated language-files, then dont send the language as param

          Example below gives a static object when a specific language is called (dummy data)
       */
      if (language === 'de') {
        commit('SET_LANGUAGE_OBJECT', de);
      } else if (language === 'en') {
        commit('SET_LANGUAGE_OBJECT', en);
      }
    },
    setLanguage({ commit }, language: Locales) {
      commit('SET_LANGUAGE', language);
    }
  }
};
export default i18nModule;
