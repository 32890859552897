
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import clients from '../../graphClient';
import {
  CMS_CREATE_RELEASE,
  CMS_DELETE_RELEASE,
  CMS_MODIFY_DOUBLE_RELEASE,
  CMS_UPDATE_RELEASE,
  CMS_UPDATE_RELEASE_SIBLING,
  UPDATE_USER_USAGE,
  CREATE_USER_USAGE,
} from '@/graphlql/mutations';
import ReleaseDetail from '@/components/cms/ReleaseDetail.vue';
import CMSView from './CMSView.vue';
import { CMS_GET_RELEASE, CMS_GET_TEMPLATES, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import { DragInterface } from '@/interfaces/cms/drag.interface';
import * as helper from '@/helper/data-helper';
import { previewLink, previewLinkPS, previewLinkSNKR } from '@/main';
import { EventBus } from '@/components/common/event/eventbus';
import VersionsTable from '@/components/versions/VersionsTable.vue';

@Component({
  components: {
    ReleaseDetail,
    BaseView,
    CMSView,
    VersionsTable,
  },
  apollo: {
    getTemplates: {
      query: CMS_GET_TEMPLATES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        const clientA = this.$route.params.clientA;
        const client = clientA.substring(0, clientA.indexOf('-'));
        return {
          client: client,
          type: 'releases',
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getTemplates != null) {
          this.templates = data.data.getTemplates;
        }
      },
    },
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.dealId,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.dealId;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.dealId !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.dealId !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.dealId !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.dealId === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
    getReleaseById: {
      query: CMS_GET_RELEASE,
      client: 'clientB',
      variables: {
        getReleaseByIdId: '0',
      },
      skip() {
        return this.skipQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getReleaseById != null) {
          if (data.data.getReleaseById.client != null) {
            this.syncDeals = false;
            if (data.data.getReleaseById.raffles == null) {
              data.data.getReleaseById.raffles = [];
            }
            if (data.data.getReleaseById.shops == null) {
              data.data.getReleaseById.shops = [];
            }

            if (data.data.getReleaseById.client.indexOf('app') > -1) {
              this.dealApp = this.fixDates(data.data.getReleaseById);

              this.dealApp.loaded = true;
              this.applyImageIds(this.dealApp);
              this.cmsClient = 'app';
              this.$store.dispatch('cmsItemsModule/setItems', this.dealApp.content.dynamicContent);
              this.$store.dispatch('cmsItemsModule/setClient', data.data.getReleaseById.client);
              if (this.dealApp.sibling != null && this.dealApp.sibling._id != null) {
                this.dealClient = 'both';
                this.$apollo.queries.getReleaseById.refetch({
                  getReleaseByIdId: this.dealApp.sibling._id,
                });
              } else if (!this.dealWeb.loaded) {
                this.dealClient = 'app';
              }

              this.changed = false;
              this.loaded = true;
            } else {
              this.dealWeb = this.fixDates(data.data.getReleaseById);

              this.dealWeb.loaded = true;

              this.applyImageIds(this.dealWeb);

              if (this.dealWeb.sibling == null) {
                this.dealClient = 'web';
                this.cmsClient = 'web';
                this.$store.dispatch('cmsItemsModule/setItems', this.dealWeb.content.dynamicContent);
                this.$store.dispatch('cmsItemsModule/setClient', data.data.getReleaseById.client);
              } else if (this.dealWeb.sibling != null && this.dealWeb.sibling._id != null && !this.dealApp.loaded) {
                this.dealClient = 'both';
                this.$apollo.queries.getReleaseById.refetch({
                  getReleaseByIdId: this.dealWeb.sibling._id,
                });
              }

              this.changed = false;
              this.loaded = true;
            }
          }
          this.siteTitle = this.dealLabel + ' (' + data.data.getReleaseById.headline + ') speichern';
          //   this.setItems(this.page.content.dynamicContent);
        } else {
          this.$store.dispatch('cmsItemsModule/setItems', []);

          this.$store.dispatch('cmsItemsModule/setClient', '');
        }
      },
      error() {
        this.$store.dispatch('cmsItemsModule/setItems', []);
        this.$store.dispatch('cmsItemsModule/setClient', '');
      },
    },
  },
  watch: {
    $route(to, from) {
      const self = this as ReleaseView;
      self.refetch();
    },
    dealApp: {
      handler(newDeal, oldDeal) {
        const self = this as ReleaseView;

        if (self.syncDeals) {
          self.syncDealData(newDeal);
        }
      },
      deep: true,
    },
    dealWeb: {
      handler(newDeal, oldDeal) {
        const self = this as ReleaseView;
        self.lastChange = new Date();
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
    dealClient(newClient, oldClient) {
      const self = this as ReleaseView;

      if (newClient == 'both' && oldClient != 'both') {
        if (helper.isEmpty(self.dealWeb)) {
          self.syncDealData(self.dealApp);
        }
      }

      if (newClient != 'both') {
        self.cmsClient = newClient;
      }
    },
    cmsClient(newClient, oldClient) {
      if (newClient != null && newClient != oldClient) {
        const self = this as ReleaseView;
        if (newClient.indexOf('app') > -1) {
          this.$store.dispatch('cmsItemsModule/setItems', self.dealApp.content.dynamicContent);
        } else if (newClient.indexOf('web') > -1) {
          this.$store.dispatch('cmsItemsModule/setItems', self.dealWeb.content.dynamicContent);
        }

        this.$store.dispatch('cmsItemsModule/setClient', newClient);
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    const self = this as ReleaseView;
    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class ReleaseView extends Vue {
  siteTitle: string = '';
  dealType: string = 'single';
  dealLabel: string = 'Release';

  lastChange = new Date();
  lastUpdateChange: any = null;

  changed: boolean = false;
  loaded: boolean = false;
  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'release',
    lastTimeEdited: new Date(),
  };

  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';

  previewUrl: string | undefined = previewLink;
  previewUrlPS: string | undefined = previewLinkPS;
  previewUrlSNKR: string | undefined = previewLinkSNKR;

  skipQuery: boolean = true;
  templates: any[] = [];
  dealClients: any[] = [
    {
      type: 'app',
      label: 'App',
      icon: 'mobile-screen',
    },
    {
      type: 'web',
      label: 'Web',
      icon: 'display',
    },
    {
      type: 'both',
      label: 'Beide',
      icon: 'laptop-mobile',
    },
  ];

  cmsVersion: any[] = [
    {
      type: 'app',
      label: 'App',
      icon: 'mobile-screen',
    },
    {
      type: 'web',
      label: 'Web',
      icon: 'display',
    },
  ];

  dealClient: string = 'both';
  cmsClient: string = 'app';
  syncDeals: boolean = false;
  templateTabIndex: number = 0;
  isUpdating: boolean = false;

  dealApp: any = {
    images: [],
    headline: '',
    path: '',
    price: 0,
    isActive: false,
    releaseDate: new Date(new Date().setHours(9, 0, 0, 0)),
    hasReleaseDate: true,
    tags: [],
    bullet1Key: '',
    bullet1Val: '',
    bullet2Val: '',
    bullet3Key: '',
    bullet3Val: '',
    bullet4Key: '',
    bullet4Val: '',
    content: {
      dynamicContent: [],
    },
    shops: [],
    raffles: [],
  };

  dealWeb: any = {
    images: [],
    headline: '',
    path: '',
    price: 0,
    isActive: false,
    releaseDate: new Date(new Date().setHours(9, 0, 0, 0)),
    hasReleaseDate: true,
    tags: [],
    bullet1Key: '',
    bullet1Val: '',
    bullet2Val: '',
    bullet3Key: '',
    bullet3Val: '',
    bullet4Key: '',
    bullet4Val: '',
    content: {
      dynamicContent: [],
    },
    shops: [],
    raffles: [],
  };

  appRaffleValid = false;
  webRaffleValid = false;
  appShopValid = false;
  webShopValid = false;

  refetch() {
    if (this.$route.params.dealId == 'create') {
      this.existing = false;
      this.currentUsage.inUse = false;
      this.usingUser = '';
      this.siteTitle = 'Release erstellen';
      this.syncDeals = true;

      this.$store.dispatch('cmsItemsModule/setItems', this.dealApp.content.dynamicContent);
      this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.clientA);
    } else if (this.$route.params.dealId != null) {
      this.skipQuery = false;
      this.$apollo.queries.getReleaseById.refetch({
        getReleaseByIdId: this.$route.params.dealId,
      });
    }
  }

  // TODO MS: This is bad. DRY, just copied code and even not changed the namings.
  // you can do better. fast is not always better
  syncDealData(newDeal: any) {
    for (const key in newDeal) {
      if (key != 'image') {
        if (Array.isArray(newDeal[key])) {
          if (key == 'shops' || key == 'raffles') {
            // See DealView.vue syncDealData function for Info.
            this.dealWeb[key] = helper.cleanTypeArray(newDeal[key], ['url'], this.dealWeb[key], ['url']);
          } else {
            this.dealWeb[key] = helper.cleanTypeArray(newDeal[key], []);
          }
        } else {
          if (key != '_id' && key != 'client') {
            if (key == 'content') {
              //do not sync
              //self.dealWeb[key] = helper.cleanTypeData(newDeal[key], []);
            } else {
              this.dealWeb[key] = newDeal[key];
            }
          }
        }
      }
    }
  }
  clientToCopy() {
    let client = this.$route.params.clientA;
    client = client.split('-')[0];
    if (client === 'snkr') {
      return `Import zu SnkrUK`;
    } else if (client === 'snkruk') {
      return `Import zu Snkr`;
    } else {
      return 'Import';
    }
  }

  importToClient() {
    const dealApp = this.getDealData(this.dealApp);
    if (dealApp.client == null || dealApp.client.length == 0) {
      dealApp.client = this.$route.params.clientA;
    }

    const dealWeb = this.getDealData(this.dealWeb);
    if (dealWeb.client == null || dealWeb.client.length == 0) {
      dealWeb.client = this.$route.params.clientB;
    }
    const appResult = helper.cleanTypeData(dealApp, [
      '__typename',
      'updatedAt',
      'createdAt',
      'client',
      '_id',
      'sibling',
      'shops',
      'raffles',
      'metaTitle',
      'metaDescription',
      'bullet1Key',
      'bullet1Val',
      'bullet2Key',
      'bullet2Val',
      'bullet3Key',
      'bullet3Val',
      'bullet4Key',
      'bullet4Val',
    ]);
    appResult.price = 0;
    appResult.content.dynamicContent = [];
    if (dealApp.client === 'snkruk-app') {
      appResult.client = 'snkr-app';
    } else if (dealApp.client === 'snkr-app') {
      appResult.client = 'snkruk-app';
    }

    const webResult = helper.cleanTypeData(dealWeb, [
      '__typename',
      'updatedAt',
      'createdAt',
      'client',
      '_id',
      'sibling',
      'shops',
      'raffles',
      'metaTitle',
      'metaDescription',
      'bullet1Key',
      'bullet1Val',
      'bullet2Key',
      'bullet2Val',
      'bullet3Key',
      'bullet3Val',
      'bullet4Key',
      'bullet4Val',
    ]);
    webResult.content.dynamicContent = [];
    webResult.price = 0;

    if (dealWeb.client === 'snkruk-web') {
      webResult.client = 'snkr-web';
    } else if (dealWeb.client === 'snkr-web') {
      webResult.client = 'snkruk-web';
    }

    if (this.dealClient == 'both') {
      clients.clientB
        .mutate({
          mutation: CMS_CREATE_RELEASE,
          variables: {
            release: appResult,
          },
        })
        .then((data) => {
          if (data != null && data.data != null && data.data.createRelease != null && data.data.createRelease._id) {
            clients.clientB
              .mutate({
                mutation: CMS_CREATE_RELEASE,
                variables: {
                  release: webResult,
                },
              })
              .then((data2) => {
                if (
                  data2 != null &&
                  data2.data != null &&
                  data2.data.createRelease != null &&
                  data2.data.createRelease._id
                ) {
                  clients.clientB
                    .mutate({
                      mutation: CMS_UPDATE_RELEASE_SIBLING,
                      variables: {
                        updateReleaseSibling: {
                          releaseAId: data.data.createRelease._id,
                          releaseBId: data2.data.createRelease._id,
                          disconnect: false,
                        },
                      },
                    })
                    .then((data3) => {
                      if (data3 != null && data3.data != null && data3.data.updateReleaseSiblings) {
                        this.$router.push({
                          path: `/cms/releases/${appResult.client}/${webResult.client}/${data.data.createRelease._id}`,
                        });

                        this.$buefy.notification.open({
                          message: `Erfolgreich kopiert`,
                          duration: 5000,
                          type: 'is-success',
                          pauseOnHover: true,
                          position: 'is-bottom-right',
                        });
                      } else {
                        this.$buefy.notification.open({
                          message: `Fehler beim Kopieren`,
                          duration: 5000,
                          type: 'is-danger',
                          pauseOnHover: true,
                          position: 'is-bottom-right',
                        });
                      }

                      this.changed = false;
                    });
                } else {
                  this.$buefy.notification.open({
                    message: `Fehler beim Kopieren`,
                    duration: 5000,
                    type: 'is-danger',
                    pauseOnHover: true,
                    position: 'is-bottom-right',
                  });
                }
              });
          } else {
            this.$buefy.notification.open({
              message: `Fehler beim Kopieren`,
              duration: 5000,
              type: 'is-danger',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          }
        })
        .catch((error) => {
          this.$buefy.notification.open({
            message: error.message.includes(':') ? error.message.split(':')[1] : 'Fehler beim speichern',
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        });
    } else {
      if (this.dealClient == 'app') {
        clients.clientB
          .mutate({
            mutation: CMS_CREATE_RELEASE,
            variables: {
              release: appResult,
            },
          })
          .then((data) => {
            if (data != null && data.data != null && data.data.createRelease != null && data.data.createRelease._id) {
              this.$router.push({
                path: `/cms/releases/${appResult.client}/${webResult.client}/${data.data.createRelease._id}`,
              });
              this.$buefy.notification.open({
                message: `Erfolgreich kopiert`,
                duration: 5000,
                type: 'is-success',
                pauseOnHover: true,
                position: 'is-bottom-right',
              });
            } else {
              this.$buefy.notification.open({
                message: `Fehler beim kopieren`,
                duration: 5000,
                type: 'is-danger',
                pauseOnHover: true,
                position: 'is-bottom-right',
              });
            }

            this.changed = false;
          })
          .catch((error) => {
            this.$buefy.notification.open({
              message: error.message.includes(':') ? error.message.split(':')[1] : 'Fehler beim speichern',
              duration: 5000,
              type: 'is-danger',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          });
      } else {
        clients.clientB
          .mutate({
            mutation: CMS_CREATE_RELEASE,
            variables: {
              release: webResult,
            },
          })
          .then((data) => {
            if (data != null && data.data != null && data.data.createRelease != null && data.data.createRelease._id) {
              this.$router.push({
                path: `/cms/releases/${appResult.client}/${webResult.client}/${data.data.createRelease._id}`,
              });
              this.$buefy.notification.open({
                message: `Erfolgreich kopiert`,
                duration: 5000,
                type: 'is-success',
                pauseOnHover: true,
                position: 'is-bottom-right',
              });
            } else {
              this.$buefy.notification.open({
                message: `Fehler beim kopieren`,
                duration: 5000,
                type: 'is-danger',
                pauseOnHover: true,
                position: 'is-bottom-right',
              });
            }

            this.changed = false;
          })
          .catch((error) => {
            this.$buefy.notification.open({
              message: error.message.includes(':') ? error.message.split(':')[1] : 'Fehler beim speichern',
              duration: 5000,
              type: 'is-danger',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          });
      }
    }
  }

  openPreview() {
    const client = this.$route.params.clientA;

    if (client.indexOf('ps') > -1) {
      window.open(this.previewUrlPS + 'preview', '_blank');
    } else if (client.indexOf('snkr') > -1) {
      window.open(this.previewUrlSNKR + 'preview', '_blank');
    } else {
      window.open(this.previewUrl + 'preview', '_blank');
    }
  }

  mounted() {
    this.dealType = this.$route.params.dealType;
    this.refetch();

    window.addEventListener('message', this.updatePreviewData, false);
    EventBus.$on('save-releases-Raffle-Web', this.webRaffleIsValid);
    EventBus.$on('save-releases-Raffle-App', this.appRaffleIsValid);
    EventBus.$on('save-releases-Shop-Web', this.webShopIsValid);
    EventBus.$on('save-releases-Shop-App', this.appShopIsValid);
  }

  unmounted() {
    window.removeEventListener('message', this.updatePreviewData);

    EventBus.$off('save-releases-Raffle-Web', this.webRaffleIsValid);
    EventBus.$off('save-releases-Raffle-App', this.appRaffleIsValid);
    EventBus.$off('save-releases-Shop-Web', this.webShopIsValid);
    EventBus.$off('save-releases-Shop-App', this.appShopIsValid);
  }

  beforeDestroy() {
    EventBus.$off('save-releases-Raffle-Web', this.webRaffleIsValid);
    EventBus.$off('save-releases-Raffle-App', this.appRaffleIsValid);
    EventBus.$off('save-releases-Shop-Web', this.webShopIsValid);
    EventBus.$off('save-releases-Shop-App', this.appShopIsValid);
  }

  allValid() {
    if (this.webRaffleValid && this.appRaffleValid && this.webShopValid && this.appShopValid) {
      this.webRaffleValid = false;
      this.appRaffleValid = false;
      this.webShopValid = false;
      this.appShopValid = false;
      this.saveDealHandler();
    } else if (this.webRaffleValid && this.webShopValid && this.dealClient == 'web') {
      this.webRaffleValid = false;
      this.appRaffleValid = false;
      this.webShopValid = false;
      this.appShopValid = false;
      this.saveDealHandler();
    } else if (this.appRaffleValid && this.appShopValid && this.dealClient == 'app') {
      this.webRaffleValid = false;
      this.appRaffleValid = false;
      this.webShopValid = false;
      this.appShopValid = false;
      this.saveDealHandler();
    }
  }

  appRaffleIsValid() {
    this.appRaffleValid = true;
    this.allValid();
  }

  webRaffleIsValid() {
    this.webRaffleValid = true;
    this.allValid();
  }

  appShopIsValid() {
    this.appShopValid = true;
    this.allValid();
  }

  webShopIsValid() {
    this.webShopValid = true;
    this.allValid();
  }

  updatePreviewData(event: any) {
    if (event.data != null && event.data.message == 'startsync') {
      this.lastUpdateChange = new Date();
      event.source.postMessage(
        { message: 'sentdata', getReleaseByPath: this.getPreviewDealData(this.dealWeb) },
        event.origin
      );
    }

    if (event.data != null && event.data.message == 'syncdata') {
      if (this.lastUpdateChange == null || this.lastChange.getTime() > this.lastUpdateChange.getTime()) {
        this.lastUpdateChange = new Date();
        event.source.postMessage(
          { message: 'sentdata', getReleaseByPath: this.getPreviewDealData(this.dealWeb) },
          event.origin
        );
      }
    }
  }

  async deleteHandler() {
    const content = prompt('Bitte "löschen" zum bestätigen eingeben');
    if (content === 'löschen') {
      if (this.dealApp?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_RELEASE,
          variables: {
            deleteReleaseId: this.dealApp._id,
          },
        });
      }

      if (this.dealWeb?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_RELEASE,
          variables: {
            deleteReleaseId: this.dealWeb._id,
          },
        });
      }
      // Surpress unsaved change prompt, a bit hacky but works
      this.changed = false;
      this.$router.push({ name: 'ReleasesView' });
    }
  }

  async userLeave() {
    this.currentUsage.inUse = false;
    this.currentUsage.type = 'release';
    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  validateAndSave() {
    let linksEmpty = false;
    const valid =
      (this.dealClient == 'app' && (this.dealApp.shops.length > 0 || this.dealApp.raffles.length > 0)) ||
      (this.dealClient == 'web' && (this.dealWeb.shops.length > 0 || this.dealWeb.raffles.length > 0)) ||
      (this.dealClient == 'both' &&
        (this.dealApp.shops.length > 0 || this.dealApp.raffles.length > 0) &&
        (this.dealWeb.shops.length > 0 || this.dealWeb.raffles.length > 0));

    if (!valid) {
      this.$buefy.notification.open({
        message: `Bei Web & App muss jeweils ein Shop/Raffle existieren`,
        duration: 5000,
        type: 'is-danger',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });

      return;
    }
    for (const shop of this.dealApp.shops) {
      if (linksEmpty || this.dealClient == 'web') {
        break;
      }
      linksEmpty = shop['url'] == '';
    }
    for (const shop of this.dealWeb.shops) {
      if (linksEmpty || this.dealClient == 'app') {
        break;
      }
      linksEmpty = shop['url'] == '';
    }
    for (const raffle of this.dealApp.raffles) {
      if (linksEmpty || this.dealClient == 'web') {
        break;
      }
      linksEmpty = raffle['url'] == '';
    }
    for (const raffle of this.dealWeb.raffles) {
      if (linksEmpty || this.dealClient == 'app') {
        break;
      }
      linksEmpty = raffle['url'] == '';
    }
    if (linksEmpty) {
      this.$buefy.notification.open({
        message: `Links dürfen nicht leer sein`,
        duration: 5000,
        type: 'is-danger',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });
      return;
    }
    EventBus.$emit('validate-and-save-releases');
  }

  async saveDealHandler() {
    if (this.isUpdating) {
      this.$buefy.notification.open({
        message: `Bereits im Speichervorgang`,
        duration: 5000,
        type: 'is-warning ',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });

      setTimeout(() => {
        this.isUpdating = false;
      }, 10000);

      return;
    }
    this.isUpdating = true;
    const dealApp = this.getDealData(this.dealApp);
    if (dealApp.client == null || dealApp.client.length == 0) {
      dealApp.client = this.$route.params.clientA;
    }
    const dealWeb = this.getDealData(this.dealWeb);
    if (dealWeb.client == null || dealWeb.client.length == 0) {
      dealWeb.client = this.$route.params.clientB;
    }
    clients.clientB
      .mutate({
        mutation: CMS_MODIFY_DOUBLE_RELEASE,
        variables: {
          releases: {
            releaseApp:
              this.dealClient == 'both' || this.dealClient == 'app'
                ? helper.cleanTypeData(dealApp, ['__typename', 'updatedAt', 'createdAt', 'sibling'])
                : null,
            releaseWeb:
              this.dealClient == 'both' || this.dealClient == 'web'
                ? helper.cleanTypeData(dealWeb, ['__typename', 'updatedAt', 'createdAt', 'sibling'])
                : null,
          },
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.modifyDoubleRelease) {
          if (this.$route.params.dealId == 'create') {
            this.$router.push({
              path: `/cms/releases/${this.$route.params.clientA}/${this.$route.params.clientB}/${
                data.data.modifyDoubleRelease.releaseApp
                  ? data.data.modifyDoubleRelease.releaseApp._id
                  : data.data.modifyDoubleRelease.releaseWeb._id
              }`,
            });
          }

          if (data.data.modifyDoubleRelease.releaseApp) {
            this.dealApp = this.fixDates(data.data.modifyDoubleRelease.releaseApp);
            this.dealApp.loaded = true;
            helper.applyImageIds(this.dealApp);

            this.$store.dispatch('cmsItemsModule/setItems', this.dealApp.content.dynamicContent);
            this.$store.dispatch('cmsItemsModule/setClient', this.dealApp.client);
          }

          if (data.data.modifyDoubleRelease.releaseWeb) {
            this.dealWeb = this.fixDates(data.data.modifyDoubleRelease.releaseWeb);

            this.dealWeb.loaded = true;
            helper.applyImageIds(this.dealWeb);

            if (!data.data.modifyDoubleRelease.releaseApp || this.cmsClient == 'web') {
              this.$store.dispatch('cmsItemsModule/setItems', this.dealWeb.content.dynamicContent);
              this.$store.dispatch('cmsItemsModule/setClient', this.dealWeb.client);
            }
          }

          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }

        this.isUpdating = false;
        this.changed = false;
      })
      .catch((error) => {
        this.isUpdating = false;
        this.$buefy.notification.open({
          message: error.message.includes(':') ? error.message.split(':')[1] : 'Fehler beim speichern',
          duration: 5000,
          type: 'is-danger',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      });
  }

  getDealData(dealData: any) {
    const deal = JSON.parse(
      JSON.stringify(helper.cleanTypeData(dealData, ['__typename', 'updatedAt', 'createdAt', 'images']))
    );

    deal['images'] = [];
    deal['price'] = parseFloat(deal['price']);
    if (dealData['releaseDate']) {
      deal['releaseDate'] = dealData['releaseDate'].toISOString();
    }

    if (dealData['images'] != null && dealData['images'].length > 0) {
      for (const imageObj of dealData.images) {
        deal.images.push(imageObj._id);
      }
    }

    if (deal['shops'] != null && deal['shops'].length > 0) {
      for (const shopObject of deal.shops) {
        if (shopObject['releaseDate']) {
          for (const realObject of dealData.shops) {
            if (
              (realObject['_id'] != null && realObject['_id'] == shopObject['_id']) ||
              (realObject['id'] != null && realObject['id'] == shopObject['id'])
            ) {
              shopObject['releaseDate'] =
                realObject['releaseDate'] === null ? null : realObject['releaseDate'].toISOString();
            }
          }
        }

        delete shopObject['id'];
        shopObject['shop'] = shopObject['shop']['_id'];
      }
    }

    if (deal['raffles'] != null && deal['raffles'].length > 0) {
      for (const shopObject of deal.raffles) {
        if (shopObject['releaseDate']) {
          for (const realObject of dealData.raffles) {
            if (
              (realObject['_id'] != null && realObject['_id'] == shopObject['_id']) ||
              (realObject['id'] != null && realObject['id'] == shopObject['id'])
            ) {
              shopObject['releaseDate'] =
                realObject['releaseDate'] === null ? null : realObject['releaseDate'].toISOString();
              shopObject['validUntil'] =
                realObject['validUntil'] === null ? null : realObject['validUntil'].toISOString();
            }
          }
        }

        delete shopObject['id'];
        shopObject['shop'] = shopObject['shop']['_id'];
      }
    }

    if (deal['path']) {
      const lastChar = deal['path'].charAt(deal['path'].length - 1);
      if (lastChar !== '/') {
        deal['path'] = deal['path'] + '/';
        dealData['path'] = dealData['path'] + '/';
      }
    }

    delete deal['loaded'];

    return deal;
  }

  getPreviewDealData(dealData: any) {
    const deal = JSON.parse(
      JSON.stringify(helper.cleanTypeData(dealData, ['__typename', 'updatedAt', 'createdAt', 'images']))
    );

    deal['images'] = [];
    deal['price'] = parseFloat(deal['price']);
    if (dealData['releaseDate']) {
      deal['releaseDate'] = dealData['releaseDate'].toISOString();
    }

    if (dealData['images'] != null && dealData['images'].length > 0) {
      for (const imageObj of dealData.images) {
        deal.images.push(imageObj);
      }
    }

    if (deal['shops'] != null && deal['shops'].length > 0) {
      for (const shopObject of deal.shops) {
        if (shopObject['releaseDate']) {
          for (const realObject of dealData.shops) {
            if (realObject['_id'] == shopObject['_id']) {
              shopObject['releaseDate'] =
                realObject['releaseDate'] === null ? null : realObject['releaseDate'].toISOString();
            }
          }
        }

        delete shopObject['id'];
        shopObject['shop'] = shopObject['shop']['_id'];
      }
    }

    if (deal['raffles'] != null && deal['raffles'].length > 0) {
      for (const shopObject of deal.raffles) {
        if (shopObject['releaseDate']) {
          for (const realObject of dealData.raffles) {
            if (realObject['_id'] == shopObject['_id']) {
              shopObject['releaseDate'] =
                realObject['releaseDate'] === null ? null : realObject['releaseDate'].toISOString();
              shopObject['validUntil'] =
                realObject['validUntil'] === null ? null : realObject['validUntil'].toISOString();
            }
          }
        }

        delete shopObject['id'];
        shopObject['shop'] = shopObject['shop']['_id'];
      }
    }

    delete deal['loaded'];

    return deal;
  }

  addTemplateHandler(template: any, type: string) {
    switch (type) {
      case 'replace':
        if (this.cmsClient == 'app') {
          this.dealApp.content = JSON.parse(JSON.stringify(template.content));
        } else if (this.cmsClient == 'web') {
          this.dealWeb.content = JSON.parse(JSON.stringify(template.content));
        }
        break;
      case 'add':
        if (this.cmsClient == 'app') {
          for (const item of template.content.dynamicContent) {
            const copy = JSON.parse(JSON.stringify(item));
            copy.id = this.getNextId(this.dealApp.content.dynamicContent);
            this.dealApp.content.dynamicContent.push(copy);
          }
        } else if (this.cmsClient == 'web') {
          for (const item of template.content.dynamicContent) {
            const copy = JSON.parse(JSON.stringify(item));
            copy.id = this.getNextId(this.dealApp.content.dynamicContent);
            this.dealWeb.content.dynamicContent.push(copy);
          }
        }
        break;
    }

    if (this.cmsClient == 'app') {
      this.$store.dispatch('cmsItemsModule/setItems', this.dealApp.content.dynamicContent);
      this.templateTabIndex = 0;
    } else if (this.cmsClient == 'web') {
      this.$store.dispatch('cmsItemsModule/setItems', this.dealWeb.content.dynamicContent);
      this.templateTabIndex = 0;
    }

    this.$store.dispatch('cmsItemsModule/setClient', this.cmsClient);
  }

  getNextId(items: DragInterface[]) {
    let max = this.resolveMaxId(items);
    return ++max;
  }

  resolveMaxId(items: DragInterface[]) {
    if (items == null || items.length == 0) {
      return 0;
    }

    let max = 0;

    for (const element of items) {
      if (element.id > max) {
        max = element.id;
      }

      if (element.items != null && element.items.length > 0) {
        const temp = this.resolveMaxId(element.items);
        if (temp > max) {
          max = temp;
        }
      }
    }

    return max;
  }

  applyImageIds(deal: any) {
    if (deal['images'] != null) {
      let i = 1;
      for (const item of deal.images) {
        item.itemId = i++;
      }
    }
  }

  fixDates(deal: any) {
    if (deal['releaseDate'] != null) {
      deal['releaseDate'] = new Date(deal['releaseDate']);
    }

    if (deal['raffles'] != null && deal['raffles'].length > 0) {
      for (const shopObject of deal.raffles) {
        if (shopObject['releaseDate']) {
          shopObject['releaseDate'] = new Date(shopObject['releaseDate']);
          shopObject['validUntil'] = shopObject['validUntil'] === null ? null : new Date(shopObject['validUntil']);
        }
      }
    }

    if (deal['shops'] != null && deal['shops'].length > 0) {
      for (const shopObject of deal.shops) {
        if (shopObject['releaseDate']) {
          shopObject['releaseDate'] = new Date(shopObject['releaseDate']);
        }
      }
    }

    return deal;
  }
}
