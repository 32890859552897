
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import clients from '../../graphClient';
import EmojiSelect from '@/components/cms/EmojiSelect.vue';
import {
  CMS_CREATE_PUSHDETAIL,
  CMS_UPDATE_PUSHDETAIL,
  CMS_DELETE_PUSHDETAIL,
  UPDATE_USER_USAGE,
  CREATE_USER_USAGE,
} from '@/graphlql/mutations';
import CMSView from './CMSView.vue';
import { CMS_GET_PUSHDETAIL, CMS_GET_TEMPLATES, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import * as helper from '@/helper/data-helper';
import ImageList from '@/components/cms/ImageList.vue';
import LinkList from '@/components/cms/LinkList.vue';
import VersionsTable from '@/components/versions/VersionsTable.vue';

@Component({
  components: {
    BaseView,
    CMSView,
    ImageList,
    LinkList,
    EmojiSelect,
    VersionsTable,
  },
  apollo: {
    getTemplates: {
      query: CMS_GET_TEMPLATES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          client: this.$route.params.client,
          type: 'pushdetail',
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getTemplates != null) {
          this.templates = data.data.getTemplates;
        }
      },
    },
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.id,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.id;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.dealId !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.id !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.id !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.id === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
    getPushDetail: {
      query: CMS_GET_PUSHDETAIL,
      client: 'clientB',
      variables: {
        getPushDetailId: '0',
      },
      skip() {
        return this.skipQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getPushDetail != null) {
          this.pushdetail = data.data.getPushDetail;

          helper.applyImageIds(this.pushdetail);

          if (this.pushdetail['displayDate'] != null) {
            this.pushdetail['displayDate'] = new Date(this.pushdetail['displayDate']);
          }

          this.$store.dispatch('cmsItemsModule/setItems', this.pushdetail.content.dynamicContent);

          this.siteTitle = 'Pushdetail (' + this.pushdetail.headline + ') speichern';

          this.loaded = true;
          this.changed = false;
        } else {
          this.$store.dispatch('cmsItemsModule/setItems', []);
        }

        this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.client + '-app');
      },
      error() {
        this.$store.dispatch('cmsItemsModule/setItems', []);

        this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.client + '-app');
      },
    },
  },
  watch: {
    $route(to, from) {
      const self = this as PushDetailView;
      self.refetch();
    },
    pushdetail: {
      handler() {
        const self = this as PushDetailView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    const self = this as PushDetailView;
    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class PushDetailView extends Vue {
  siteTitle: string = '';
  skipQuery: boolean = true;

  changed: boolean = false;
  loaded: boolean = false;
  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'pushDetail',
    lastTimeEdited: new Date(),
  };
  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';

  pushdetail: any = {
    content: {
      dynamicContent: [],
    },
    links: [],
    headline: '',
    subline: '',
    images: [],
    displayDate: new Date(),
  };
  templateTabIndex: number = 0;
  templates: any[] = [];

  routeToNotifications() {
    this.$router.push({
      path:
        '/cms/pushnotifications/' +
        this.$route.params.client +
        '/create?reference=' +
        this.pushdetail._id +
        '&referenceType=pushdetail',
    });
  }

  getEmpty() {
    return {
      content: {
        dynamicContent: [],
      },
      headline: '',
      subline: '',
      images: [],
      displayDate: new Date(),
    };
  }

  refetch() {
    if (this.$route.params.id == 'create') {
      this.siteTitle = 'Pushdetail erstellen';
      this.pushdetail = this.getEmpty();

      this.$store.dispatch('cmsItemsModule/setItems', this.pushdetail.content.dynamicContent);

      this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.client + '-app');
    } else if (this.$route.params.id != null) {
      this.skipQuery = false;
      this.$apollo.queries.getPushDetail.refetch({
        getPushDetailId: this.$route.params.id,
      });
    }
  }

  mounted() {
    this.refetch();
  }

  addTemplateHandler(template: any, type: string) {
    switch (type) {
      case 'replace':
        this.pushdetail.content = JSON.parse(JSON.stringify(template.content));

        break;
      case 'add':
        for (const item of template.content.dynamicContent) {
          const copy = JSON.parse(JSON.stringify(item));
          copy.id = helper.getNextId(this.pushdetail.content.dynamicContent);
          this.pushdetail.content.dynamicContent.push(copy);
        }

        break;
    }

    this.$store.dispatch('cmsItemsModule/setItems', this.pushdetail.content.dynamicContent);
    this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.client + '-app');
    this.templateTabIndex = 0;
  }

  async saveNew() {
    this.pushdetail.client = this.$route.params.client;
    clients.clientB
      .mutate({
        mutation: CMS_CREATE_PUSHDETAIL,
        variables: {
          pushDetail: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.createPushDetail != null && data.data.createPushDetail._id) {
          this.$router.push({
            path: `/cms/pushdetails/${this.$route.params.client}/${data.data.createPushDetail._id}`,
          });
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async saveExisting() {
    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_PUSHDETAIL,
        variables: {
          pushDetail: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.updatePushDetail != null && data.data.updatePushDetail._id) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
          this.siteTitle = 'Pushdetail (' + this.pushdetail.headline + ') speichern';
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async userLeave() {
    this.currentUsage.inUse = false;

    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  getData() {
    this.pushdetail.client = this.$route.params.client;

    const returnData = JSON.parse(
      JSON.stringify(helper.cleanTypeData(this.pushdetail, ['__typename', 'updatedAt', 'createdAt']))
    );

    returnData['images'] = [];

    if (this.pushdetail['images'] != null && this.pushdetail['images'].length > 0) {
      for (const imageObj of this.pushdetail.images) {
        returnData['images'].push(imageObj._id);
      }
    }

    if (this.pushdetail['displayDate']) {
      returnData['displayDate'] = this.pushdetail['displayDate'].toISOString();
    }

    if (returnData['links'] != null && returnData['links'].length > 0) {
      for (const linkObj of returnData.links) {
        delete linkObj['id'];
      }
    }

    return returnData;
  }

  async saveHandler() {
    if (this.$route.params.id == 'create') {
      this.saveNew();
    } else if (this.$route.params.id != null) {
      this.saveExisting();
    }
  }

  async deleteHandler() {
    const content = prompt('Bitte "löschen" zum bestätigen eingeben');
    if (content === 'löschen') {
      if (this.pushdetail?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_PUSHDETAIL,
          variables: {
            deletePushDetailId: this.pushdetail._id,
          },
        });
      }

      // Surpress unsaved change prompt, a bit hacky but works
      this.changed = false;
      this.$router.push({ name: 'PushDetailsView' });
    }
  }
}
