import { DragContainer } from '@/interfaces/cms/drag.interface';

const data: DragContainer[] = [
  {
    id: 0,
    label: 'Gruppierung',
    context: 'web',
    items: [
      {
        id: 0,
        component: 'ContentSection',
        label: 'Sektion',
        icon: 'square-dashed',
        items: [],
        content: { colorSelected: '#ffffff' },
      },
      {
        id: 1,
        component: 'Columns',
        label: 'Spalten',
        icon: 'columns-3',
        items: [],
        content: {},
        componentOptions: {
          hasConfiguration: false,
        },
      },
    ],
  },
  {
    id: 1,
    label: 'Inhalt',
    items: [
      {
        id: 1,
        component: 'Headline',
        label: 'Überschrift',
        icon: 'heading',
        context: 'web',
        content: { text: '', type: 'h1', alignment: 'center', color: '#222222' },
      },
      {
        id: 666,
        component: 'Ticker',
        icon: 'text',
        content: { rawHtml: '' },
      },
      {
        id: 3,
        component: 'RichText',
        icon: 'text',
        content: { rawHtml: '' },
      },
      {
        id: 4,
        component: 'ImageComponent',
        label: 'Bild',
        icon: 'image',
        content: { filename: '', _id: '', originalName: '' },
      },
      {
        id: 5,
        component: 'Banner',
        label: 'Banner',
        icon: 'rug',
        content: { filename: '', _id: '', originalName: '', link: '', linkAlt: '', target: '' },
      },

      {
        id: 6,
        component: 'BannerList',
        label: 'BannerList',
        icon: 'images',
        content: { banner: [] },
      },
      {
        id: 7,
        component: 'ImageText',
        label: 'BildText',
        context: 'web',
        icon: 'table-columns',
        content: {
          image: { filename: '', _id: '', originalName: '' },
          rawHtml: '',
          orientation: '',
          textAlignment: 'center',
        },
      },
      {
        id: 8,
        component: 'Button',
        label: 'Button',
        icon: 'link-simple',
        content: { icon: '', text: '', link: '', type: 'primary' },
      },
      {
        id: 8,
        component: 'DoubleButton',
        label: 'Button²',
        icon: 'link-simple',
        content: { icon: '', text: '', link: '', type: 'primary', icon2: '', text2: '', link2: '', type2: 'primary' },
      },
      {
        id: 9,
        component: 'Separator',
        label: 'Trenner',
        icon: 'horizontal-rule',
        context: 'web',
        content: {},
        componentOptions: {
          hasConfiguration: false,
        },
      },
      {
        id: 10,
        component: 'SmallCard',
        label: 'SmallCard',
        icon: 'card-spade',
        content: {
          image: { filename: '', _id: '', originalName: '' },
          headline: '',
          subline: '',
          reference: '',
          referenceType: 'link',
          referenceTarget: '_blank',
          voucher: '',
          voucherText: '',
          bullet1Key: '',
          bullet1Value: '',
          bullet2Key: '',
          bullet2Value: '',
          priceText: '',
          price: '',
          strikePrice: '',
          path: '',
          linkTarget: false,
        },
      },
      {
        id: 101,
        component: 'DoubleSmallCard',
        label: 'Double SmallCard',
        icon: 'fa-cards',
        context: 'app',
        content: {
          cardOne: {
            image: { filename: '', _id: '', originalName: '' },
            headline: '',
            subline: '',
            reference: '',
            referenceType: 'link',
            referenceTarget: '_blank',
            voucher: '',
            voucherText: '',
            bullet1Key: '',
            bullet1Value: '',
            bullet2Key: '',
            bullet2Value: '',
            priceText: '',
            price: '',
            strikePrice: '',
          },
          cardTwo: {
            image: { filename: '', _id: '', originalName: '' },
            headline: '',
            subline: '',
            reference: '',
            referenceType: 'link',
            referenceTarget: '_blank',
            voucher: '',
            voucherText: '',
            bullet1Key: '',
            bullet1Value: '',
            bullet2Key: '',
            bullet2Value: '',
            priceText: '',
            price: '',
            strikePrice: '',
          },
        },
      },
      {
        id: 102,
        component: 'Battle',
        label: 'Battle',
        icon: 'fa-cards',
        context: 'app',
        content: {
          cardOne: {
            image: { filename: '', _id: '', originalName: '' },
            headline: '',
            clicktext: '',
            reference: '',
            referenceType: 'link',
            referenceTarget: '_blank',
            voucher: '',
            voucherText: '',
            bullet1Key: '',
            bullet1Value: '',
            bullet2Key: '',
            bullet2Value: '',
            priceText: '',
            price: '',
            strikePrice: '',
          },
          cardTwo: {
            image: { filename: '', _id: '', originalName: '' },
            headline: '',
            clicktext: '',
            reference: '',
            referenceType: 'link',
            referenceTarget: '_blank',
            voucher: '',
            voucherText: '',
            bullet1Key: '',
            bullet1Value: '',
            bullet2Key: '',
            bullet2Value: '',
            priceText: '',
            price: '',
            strikePrice: '',
          },
        },
      },
      {
        id: 11,
        component: 'LargeCard',
        label: 'LargeCard',
        icon: 'credit-card-blank',
        content: {
          image: { filename: '', _id: '', originalName: '' },
          headline: '',
          subline: '',
          reference: '',
          referenceType: 'link',
          referenceTarget: '_blank',
          voucher: '',
          voucherText: '',
          bullet1Key: '',
          bullet1Value: '',
          bullet2Key: '',
          bullet2Value: '',
          priceText: '',
          price: '',
          strikePrice: '',
          path: '',
          linkTarget: false,
        },
      },
    ],
  },
  {
    id: 2,
    label: 'Suchmaschine',
    items: [
      {
        id: 1,
        component: 'SearchEngineContainer',
        label: 'Container',
        icon: 'folder-magnifying-glass',
        context: 'web',
        content: { query: '', order: '', category: '', brand: '', model: '', color: '', size: '' },
      },
      {
        id: 2,
        component: 'SearchEngineList',
        label: 'Liste',
        icon: 'magnifying-glass',
        content: { query: '', order: '', category: '', brand: '', model: '', color: '', size: '' },
      },
      {
        id: 2,
        component: 'SearchEngineInput',
        label: 'Input',
        icon: 'magnifying-glass',
        content: { placeholder: '' },
      },
    ],
  },
  {
    id: 3,
    label: 'Deals',
    items: [
      {
        id: 2,
        component: 'DealContainer',
        label: 'Deal Container',
        icon: 'badge-percent',
        content: { skip: 0, take: 8, limit: '', tags: [] },
      },
      {
        id: 3,
        component: 'DealList',
        label: 'Deal Slider',
        icon: 'badge-percent',
        content: { skip: 0, take: 8, limit: '', tags: [] },
      },
      {
        id: 4,
        component: 'DealSlider',
        label: '3D Deal Slider',
        icon: 'badge-percent',
        content: { skip: 0, take: 8, limit: '', tags: [] },
      },
      {
        id: 6,
        component: 'DoubleDealContainer',
        label: 'Deal²Container',
        icon: 'bagel',
        content: { skip: 0, take: 8, limit: '', tags: [] },
      },
      {
        id: 7,
        component: 'VoucherField',
        label: 'Gutscheinfeld',
        icon: 'badge-dollar',
        content: { voucher: '' },
      },
    ],
  },
  {
    id: 4,
    label: 'Container',
    items: [
      {
        id: 36,
        component: 'WhatsNew',
        icon: 'credit-card',
        content: {
          image: { filename: '', _id: '', originalName: '' },
          imageDark: { filename: '', _id: '', originalName: '' },
          tag: '',
        },
      },
      {
        id: 2,
        component: 'BlogContainer',
        icon: 'credit-card',
        content: { skip: 0, take: 8, tags: [] },
      },
      {
        id: 21,
        component: 'BlogSlider',
        icon: 'credit-card',
        content: { skip: 0, take: 8, tags: [] },
      },
      {
        id: 3,
        component: 'ReleasesContainer',
        label: 'Releases',
        icon: 'bullhorn',
        content: { skip: 0, take: 8, limit: '', filter: '', tags: [] },
      },
      {
        id: 31,
        component: 'ReleasesList',
        label: 'Releases List',
        icon: 'bullhorn',
        content: { skip: 0, take: 8, limit: '', filter: '', tags: [] },
      },
      {
        id: 32,
        component: 'ReleasesSlider',
        label: 'Releases Slider',
        icon: 'bullhorn',
        content: { skip: 0, take: 8, limit: '', filter: '', tags: [] },
      },
      {
        id: 33,
        component: 'ReleaseListSlider',
        label: 'Release List Slider',
        icon: 'bullhorn',
        content: { skip: 0, take: 6, limit: '', filter: '', tags: [] },
      },
      {
        id: 34,
        component: 'ReleaseShops',
        label: 'Shops',
        icon: 'shop',
        content: { shops: [] },
      },
      {
        id: 35,
        component: 'ReleaseContainer',
        label: 'Release Container',
        icon: 'credit-card',
        content: { skip: 1, take: 8, limit: '', filter: '', tag: '' },
      },
      {
        id: 4,
        component: 'InstaFeedContainer',
        label: 'InstaFeed',
        icon: 'fa-brands fa-instagram',
        content: { skip: 0, take: 8, limit: '', tags: [] },
      },
    ],
  },
];

export default data;
