import { render, staticRenderFns } from "./DealContainer.vue?vue&type=template&id=1ae8232c&scoped=true"
import script from "./DealContainer.vue?vue&type=script&lang=ts"
export * from "./DealContainer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ae8232c",
  null
  
)

export default component.exports