
import ImageList from './ImageList.vue';
import ShopList from './ShopList.vue';
import CustomTagInput from '../tags/CustomTagInput.vue';

export default {
  components: {
    ImageList,
    ShopList,
    CustomTagInput,
  },
  props: {
    release: Object,
    version: String,
    inUsage: Boolean,
  },
};
