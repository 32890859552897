<template>
  <div class="panel has-background-white">
    <p class="panel-heading">Tags ohne Kategorie</p>
    <template v-for="tag in tagsWithoutCategory">
      <tag-editor :key="tag._id" :tag="tag" :tagCategories="tagCategories" />
    </template>
    <div class="panel-block">
      <b-field
        grouped
        class="is-align-items-center"
        :message="errorMessageTag('addTag')"
        :type="errorMessageType('addTag')"
      >
        <b-input
          class="mr-3"
          placeholder="Neuer Tag"
          v-model="newTag"
          @keyup.native.enter="addTag('addTag')"
          expanded
        />
        <b-button type="is-success" icon-right="plus" @click="addTag('addTag')" />
      </b-field>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import TagEditor from './TagEditor.vue';
import { CMS_GET_TAGS, CMS_GET_TAG_CATEGORIES } from '@/graphlql/queries';
import { CMS_CREATE_TAG } from '@/graphlql/mutations';
import { EventBus } from '../../components/common/event/eventbus';

export default {
  name: 'TagPanel',
  components: { TagEditor },
  data() {
    return {
      tagToUpdate: '',
      newTagName: '',
      tagCategories: [],
      tagsWithoutCategory: [],
      newTag: '',
      newTagCategory: null,
      errorTag: false,
      errorMsgTag: '',
      errorMsgId: '',
      loading: false,
      success: false,
    };
  },
  apollo: {
    getTagCategories: {
      query: CMS_GET_TAG_CATEGORIES,
      fetchPolicy: 'no-cache',
      manual: false,
      variables() {
        return {
          client: this.$route.params.client,
        };
      },
      result(data) {
        if (data.data != null) {
          this.tagCategories = [...data.data.getTagCategories];
        }
      },
    },
    getTags: {
      query: CMS_GET_TAGS,
      fetchPolicy: 'no-cache',
      manual: false,
      variables() {
        return {
          client: this.$route.params.client,
          category: null,
        };
      },
      result(data) {
        if (data.data != null) {
          this.tagsWithoutCategory = [...data.data.getTags];
        }
      },
    },
  },
  methods: {
    addTag(id) {
      this.errorMsgId = id;
      if (!this.newTag) {
        this.errorTag = true;
        this.errorMsgTag = 'Kann nicht gespeichert werden! Tag ist leer!';
        return;
      }
      this.$apollo
        .mutate({
          mutation: CMS_CREATE_TAG,
          variables: {
            tagInput: {
              client: this.$route.params.client,
              name: this.newTag,
            },
          },
        })
        .then((data) => this.onTagFullfilled(data))
        .catch((error) => this.onTagError(error));
    },
    onTagFullfilled(data) {
      // EVENT TO TAG_CAT
      EventBus.$emit('updateTagPanel');
      EventBus.$emit('updateCategoryPanel');
      this.errorMsgId = '';
      this.tagToUpdate = '';
      this.newTagName = '';
      this.loading = false;
      this.errorTag = false;
      this.success = true;
      this.newTag = '';
    },
    onTagError(error) {
      this.loading = false;
      this.result = true;
      this.errorTag = true;
      this.errorMsgTag = error.message;
      this.success = false;
    },
    errorMessageTag(id) {
      if (this.errorMsgId === id && this.errorTag && this.errorMsgTag !== '') {
        return this.errorMsgTag;
      }
      return '';
    },
    errorMessageType(id) {
      if (this.errorMsgId === id && this.errorTag && this.errorMsgTag !== '') {
        return 'is-danger';
      }
      return '';
    },
  },
  mounted() {
    EventBus.$on('updateTagPanel', () => {
      Promise.resolve(this.$apollo.queries.getTags.refetch());
      Promise.resolve(this.$apollo.queries.getTagCategories.refetch());
    });
  },
};
</script>
<style lang="scss"></style>
