import { EventBus } from '@/components/common/event/eventbus';

const availableSizes = ['400', '500', '600', '800', '1000', '1200'];
const gateways = ['https://gateway.prinz-sportlich.de/', 'https://gateway.snkraddicted.com/'];
const title = 'Image caching';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function getHitCount(images: any[]): number {
  return images.filter((arr) => {
    return arr.hit || arr.hit == undefined;
  }).length;
}

async function checkImage(images: any[], index: number, trip: number) {
  if (images != null && images[index]) {
    try {
      EventBus.$emit('show-message', { body: `Check image ${index + 1}`, title: title });

      const response = await fetch(images[index].url);

      //check cache status
      if (response?.headers?.has('X-Cache')) {
        const xcache = response?.headers?.get('X-Cache');
        if (xcache == 'HIT') {
          images[index].hit = true;
        } else {
          images[index].hit = false;
        }

        //check if all images are cached
        if (getHitCount(images) == images.length) {
          EventBus.$emit('show-message', { body: `Caching done`, title: title });
        } else {
          await checkImage(images, ++index, trip);
        }
      } else {
        //Cache not active
        images[index].hit = true;

        //check if all images are cached
        if (getHitCount(images) == images.length) {
          EventBus.$emit('show-message', { body: `Caching done`, title: title });
        } else {
          await checkImage(images, ++index, trip);
        }
      }
    } catch (e) {
      console.log('Error on image', images[index], e);

      //let's pretend that's a hit
      images[index].hit = true;
      await checkImage(images, ++index, trip);
    }
  } else {
    await sleep(500);
    if (trip > 2) {
      //fallback: do not loop endless
      EventBus.$emit('show-message', { body: `Caching done`, title: title });
    } else {
      // start again
      await checkImage(images, 0, ++trip);
    }
  }
}

async function internalCache(images: any[]) {
  //wait
  await sleep(1000);

  //fetch image in order to start image cache mechanism
  if (images != null) {
    for (const image of images) {
      try {
        fetch(image.url);
        await sleep(30);
      } catch (e) {
        console.log('some error', image, e);
      }
    }
  }

  EventBus.$emit('show-message', { body: `Preheat initialized`, title: title });

  //wait to cache warmup
  await sleep(3000);

  //start cache checking
  await checkImage(images, 0, 0);
}

export default function cacheImage(id: string, originalName: string) {
  const images: any[] = [];

  //collect image urls
  for (const size of availableSizes) {
    for (const url of gateways) {
      /* images.push({ url: url + `media/images/${id}/${size}/${originalName.replace(
        /\s/gm,
        '_'
      )}` }); */
      images.push({ url: url + `media/images/${id}/${size}/webp/${originalName.replace(/\s/gm, '_')}.webp` });
    }
  }

  EventBus.$emit('show-message', { body: `Start caching: ${images.length} images found`, title: title });

  //start cache mechanism
  internalCache(images);
}
