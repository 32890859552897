
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import CMSView from '@/views/cms/CMSView.vue';
import ImageList from './ImageList.vue';
import LinkList from './LinkList.vue';
import DealPrice from './DealPrice.vue';
import DealVoucher from './DealVoucher.vue';
import CustomTagInput from '../tags/CustomTagInput.vue';
import { CMS_UPDATE_DEAL_RANK } from '@/graphlql/mutations';

@Component({
  components: {
    CMSView,
    ImageList,
    LinkList,
    DealPrice,
    DealVoucher,
    CustomTagInput,
  },
  props: {
    deal: Object,
    version: String,
    inUsage: Boolean,
  },
})
export default class DealDetail extends Vue {
  deal: any;
  version: any;

  async save() {
    try {
      await this.$apollo.mutate({
        mutation: CMS_UPDATE_DEAL_RANK,
        variables: {
          updateDealRank: {
            updateId: this.deal._id,
            newPredecessorId: null,
            newSuccessorId: null,
            client: null,
          },
        },
      });
    } catch (exception) {
      this.$buefy.notification.open({
        message: `Fehler beim Anlegen`,
        duration: 5000,
        type: 'is-danger',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });
    }
    this.$buefy.notification.open({
      message: `Erfolgreich gespeichert`,
      duration: 5000,
      type: 'is-success',
      pauseOnHover: true,
      position: 'is-bottom-right',
    });
  }
  inUsage: any;
}
