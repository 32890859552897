
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { CMS_GET_SIBLING_DEALS } from '@/graphlql/queries';
import { cmsLink } from '@/main';
import clients from '@/graphClient';
import { CMS_UPDATE_DEAL_RANK } from '@/graphlql/mutations';

@Component({
  components: {},
  props: {
    dealType: {
      type: String,
    },
  },
  apollo: {
    getSiblingDeals: {
      query: CMS_GET_SIBLING_DEALS,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables: {
        clientB: '',
        clientA: '',
        offset: '',
        limit: '',
        orderBy: 'createdAt',
        sortOrder: -1,
        dealType: '',
        onlyClientA: false,
      },
      skip() {
        return this.skipQuery;
      },
      result(data) {
        if (data != null && data.data != null && data.data.getSiblingDeals != null) {
          this.tableData = data.data.getSiblingDeals.items;
          this.page = data.data.getSiblingDeals.currentPage;
          this.total = data.data.getSiblingDeals.totalItems;
        }
      },
    },
  },
  watch: {
    $route(to, from) {
      const self = this as DealsTable;

      self.clientA = self.$route.params.clientA;
      self.clientB = self.$route.params.clientB;

      self.refetchData();
    },
    dealClient(newClient, oldClient) {
      const self = this as DealsTable;

      self.resetData();
    },
  },
  filters: {
    format: function (value: string) {
      if (!value) return '';
      const d = new Date(value);
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    },
  },
})
export default class DealsTable extends Vue {
  page: number = 1;
  total: number = 1;
  perPage: number = 10;
  tableData: any[] = [];
  skipQuery: boolean = true;
  clientA: string = '';
  clientB: string = '';
  cdn: string | undefined = cmsLink;
  pageSizes = [2, 10, 20, 50, 100, 200];
  draggingRow: any = null;
  draggingRowIndex: any = null;
  dealClient: string = 'both';
  aktivitätsfilter = 'beides';
  dealClients: any[] = [
    {
      type: 'app',
      label: 'App',
      icon: 'mobile-screen',
    },
    {
      type: 'web',
      label: 'Web',
      icon: 'display',
    },
    {
      type: 'both',
      label: 'Alle',
      icon: 'laptop-mobile',
    },
  ];

  dragstart(payload: any) {
    this.draggingRow = payload.row;
    this.draggingRowIndex = payload.index;
    payload.event.dataTransfer.effectAllowed = 'copy';
  }
  dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = 'copy';
    payload.event.target.closest('tr').classList.add('is-selected');
    payload.event.preventDefault();
  }
  dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    payload.event.preventDefault();
  }
  drop(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    const droppedOnRowIndex = payload.index;

    const successor = payload.index > 0 ? this.tableData[payload.index - 1] : null;
    let predecessor = this.tableData[payload.index + 1] != null ? this.tableData[payload.index + 1] : null;
    const self = this.tableData[this.draggingRowIndex];

    if (this.draggingRowIndex < droppedOnRowIndex && predecessor == null) {
      predecessor = self;
    }

    this.tableData = this.arraymove(this.tableData, this.draggingRowIndex, droppedOnRowIndex);

    clients.clientB.mutate({
      // Query
      mutation: CMS_UPDATE_DEAL_RANK,
      // Parameters
      variables: {
        updateDealRank: {
          newSuccessorId: this.draggingRowIndex > droppedOnRowIndex && successor != null ? successor._id : null,
          newPredecessorId: this.draggingRowIndex < droppedOnRowIndex && predecessor != null ? predecessor._id : null,
          updateId: self._id,
          client: self.client,
        },
      },
    });
  }

  arraymove(arr: any[], oldindex: number, newindex: number) {
    if (newindex >= arr.length) {
      let k = newindex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newindex, 0, arr.splice(oldindex, 1)[0]);
    return arr; // for testing
  }

  contextColor = ""
  mounted() {
    this.clientA = this.$route.params.clientA;
    this.clientB = this.$route.params.clientB;

    this.refetchData();
    
  }

 
  onPageChange(page: number) {
    this.page = page;
    this.refetchData();
  }

  resetData() {
    this.page = 1;
    this.refetchData();
  }

  refetchData() {
    this.skipQuery = false;

    const clientA = this.dealClient == 'web' ? this.clientB : this.clientA;
    const clientB = this.dealClient == 'web' ? this.clientA : this.clientB;

    this.$apollo.queries.getSiblingDeals.refetch({
      clientB: clientB,
      clientA: clientA,
      offset: this.perPage * (this.page - 1),
      limit: this.perPage,
      orderBy: 'rank',
      sortOrder: -1,
      isActive: this.aktivitätsfilter == 'beides' ? null : this.aktivitätsfilter == 'aktiv' ? true : false,
      dealType: this.$props.dealType,
      onlyClientA: this.dealClient != 'both',
    });
  }

  clickTable(event: any) {
    if (event._id != null) {
      this.$router.push({
        path: `/cms/deals/${this.$props.dealType}/${this.$route.params.clientA}/${this.$route.params.clientB}/${event._id}`,
      });
    }
  }

  editHandler(id: string) {
    this.$router.push({
      path: `/cms/deals/${this.$props.dealType}/${this.$route.params.clientA}/${this.$route.params.clientB}/${id}`,
    });
  }
  deleteHandler(url: string) {
    console.log('delete ', url);
  }
}
