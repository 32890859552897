
import { Component, Vue } from 'vue-property-decorator';
import StoreView from '@/components/stores/StoreView.vue';

@Component({
  props: ['value'],
  components: {
    StoreView,
  },
})
export default class StoreEdit extends Vue {
  value: any;

  updatePath(path: any) {
    this.value.logo = path;
  }

  mounted() {
    if (this.value.config == null) {
      this.value.config = {};
    }
  }
}
