import { render, staticRenderFns } from "./DealList.vue?vue&type=template&id=61f8a4ef&scoped=true"
import script from "./DealList.vue?vue&type=script&lang=ts"
export * from "./DealList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f8a4ef",
  null
  
)

export default component.exports