
import { cmsLink } from '@/main';
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { $axios } from '@/store/axios-instance';

@Component({
  props: {
    uploadFile: {
      type: String,
    },
  },
})
export default class UploadFile extends Vue {
  public file: Record<string, any> = {};
  filePath: string = '';
  cdn: string | undefined = cmsLink;

  onFileSelected(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    $axios
      .post(process.env.VUE_APP_CMS_URL + 'voucher/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((data: any) => {
        const self = this as UploadFile;
        if (data?.data) {
          this.filePath = data.data;
          self.$emit('update-path', data.data);
          this.$forceUpdate();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
