<template>
  <base-view class="tags-view">
    <template v-slot:title>
      <div class="level">
        <div class="level-left">Tags</div>
      </div>
    </template>
    <template v-slot:content>
      <div class="columns">
        <div class="column">
          <tag-category-panel />
        </div>
        <div class="column">
          <tag-panel />
        </div>
      </div>
    </template>
  </base-view>
</template>

<script>
import Vue from 'vue';
import BaseView from '../common/BaseView.vue';
import TagPanel from './TagPanel.vue';
import TagCategoryPanel from './TagCategoryPanel.vue';

export default {
  name: 'TagsView',
  components: { BaseView, TagPanel, TagCategoryPanel },
};
</script>
<style lang="scss"></style>
