import { Module } from 'vuex';

import { AuthInterface, LoginDataInterface } from '@/interfaces/auth.interface';
import { RootStateInterface } from '@/interfaces/rootState.interface';
import clients from '../../graphClient';
import { LOGIN } from '@/graphlql/mutations';
import { BACKEND_USER_IS_LOGGED_IN } from '@/graphlql/queries';

const authModule: Module<AuthInterface, RootStateInterface> = {
  namespaced: true,
  state: {
    loggedIn: false,
    user: null,
    loginData: {
      email: '',
      userName: '',
      password: '',
    },
    accessToken: '',
    refreshToken: '',
  },
  mutations: {
    SET_ACCESS_TOKEN(state: AuthInterface, token: string) {
      state.accessToken = token;
    },
    SET_REFRESH_TOKEN(state: AuthInterface, token: string) {
      state.refreshToken = token;
    },
    SET_LOGIN_DATA(state: AuthInterface, loginData: LoginDataInterface) {
      state.loginData = loginData;
    },
    SET_LOGGED_IN(state: AuthInterface, loggedIn: boolean) {
      state.loggedIn = loggedIn;
    },
    SET_USER(state: AuthInterface, user: any) {
      state.user = user;
    },
  },
  getters: {
    isLoggedIn(state: AuthInterface) {
      console.log('isLoggedIn?', state.loggedIn);
      return state.loggedIn;
    },
    getCurrentUser(state: AuthInterface) {
      return state.user;
    },
    hasPermission(state: AuthInterface, permission: string) {
      return state.user?.permission.includes(permission);
    },
  },
  actions: {
    async setCurrentUser({ commit }, user: any) {
      commit('SET_USER', user);
    },
    async checkAuth({ commit }) {
      if (process.env.VUE_APP_DISABLE_AUTH == 'true') {
        await commit('SET_LOGGED_IN', true);
      } else {
        console.log('auth enabled, checking auth');
        try {
          const result = await clients.clientA.query({ query: BACKEND_USER_IS_LOGGED_IN });
          if (result.data.backendUserIsLoggedIn) {
            await commit('SET_LOGGED_IN', true);
            await localStorage.setItem('loggedIn', 'true');
            return true;
          } else {
            await commit('SET_LOGGED_IN', false);
            await localStorage.setItem('loggedIn', 'false');
            return false;
          }
        } catch (e) {
          console.log(e);
          return false;
        }
      }
    },
    async verifyLoginAction({ commit }, loginData: LoginDataInterface) {
      const result = await clients.clientA.mutate({
        mutation: LOGIN,
        variables: {
          user: {
            username: loginData.userName,
            password: loginData.password,
          },
        },
      });

      const backendUserLogin = result?.data?.backendUserLogin || false;

      if (backendUserLogin?.accessToken) {
        commit('SET_ACCESS_TOKEN', backendUserLogin.accessToken);
        sessionStorage.setItem('accessToken', backendUserLogin.accessToken);
      }

      if (backendUserLogin?.refreshToken) {
        commit('SET_REFRESH_TOKEN', backendUserLogin.refreshToken);
        sessionStorage.setItem('refreshToken', backendUserLogin.refreshToken);
      }

      if (backendUserLogin?.refreshToken && backendUserLogin?.accessToken) {
        await commit('SET_LOGGED_IN', true);
        localStorage.setItem('loggedIn', 'true');
      }

      return backendUserLogin;
    },
    async logout({ commit }) {
      commit('SET_LOGGED_IN', false);
      commit('SET_ACCESS_TOKEN', '');
      commit('SET_REFRESH_TOKEN', '');
      localStorage.setItem('loggedIn', 'false');
      sessionStorage.setItem('refreshToken', '');
      sessionStorage.setItem('accessToken', '');
    },
  },
};

export default authModule;
