
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { CMS_GET_BLOGS } from '@/graphlql/queries';
import { CMS_UPDATE_BLOG_RANK } from '@/graphlql/mutations';
import { cmsLink } from '@/main';
import clients from '@/graphClient';

@Component({
  components: {},
  apollo: {
    getBlogs: {
      query: CMS_GET_BLOGS,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,

      skip() {
        return this.skipQuery;
      },
      variables() {
        return {
          offset: this.perPage * (this.page - 1),
          limit: this.perPage,
          orderBy: 'rank',
          sortOrder: -1,
          client: '',
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getBlogs != null) {
          this.tableData = data.data.getBlogs.items;
          this.page = data.data.getBlogs.currentPage;
          this.total = data.data.getBlogs.totalItems;
        }
      },
    },
  },
  filters: {
    format: function (value: string) {
      if (!value) return '';
      const d = new Date(value);
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    },
  },
  watch: {
    $route(to, from) {
      const self = this as BlogsTable;

      self.resetData();
    },
  },
})
export default class BlogsTable extends Vue {
  page: number = 1;
  total: number = 1;
  perPage: number = 10;
  tableData: any[] = [];
  skipQuery: boolean = true;
  cdn: string | undefined = cmsLink;
  pageSizes = [2, 10, 20, 50, 100, 200];

  draggingRow: any = null;
  dragginRowIndex: any = null;
  draggingRowIndex: any = null;

  mounted() {
    this.refetchData();
  }

  resetData() {
    this.page = 1;
    this.refetchData();
  }

  onPageChange(page: number) {
    this.page = page;
    this.refetchData();
  }

  changePageSize() {
    this.page = 1;
    this.refetchData();
  }

  refetchData() {
    this.skipQuery = false;
    this.$apollo.queries.getBlogs.refetch({
      offset: this.perPage * (this.page - 1),
      limit: this.perPage,
      orderBy: 'rank',
      sortOrder: -1,
      client: this.$route.params.client,
    });
  }

  dragstart(payload: any) {
    this.draggingRow = payload.row;
    this.draggingRowIndex = payload.index;
    payload.event.dataTransfer.effectAllowed = 'copy';
  }
  dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = 'copy';
    payload.event.target.closest('tr').classList.add('is-selected');
    payload.event.preventDefault();
  }
  dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    payload.event.preventDefault();
  }
  drop(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    const droppedOnRowIndex = payload.index;

    const successor = payload.index > 0 ? this.tableData[payload.index - 1] : null;
    let predecessor = this.tableData[payload.index + 1] != null ? this.tableData[payload.index + 1] : null;
    const self = this.tableData[this.draggingRowIndex];

    if (this.draggingRowIndex < droppedOnRowIndex && predecessor == null) {
      predecessor = self;
    }

    this.tableData = this.arraymove(this.tableData, this.draggingRowIndex, droppedOnRowIndex);

    clients.clientB.mutate({
      // Query
      mutation: CMS_UPDATE_BLOG_RANK,
      // Parameters
      variables: {
        updateBlogRank: {
          orderBy: 'rank',
          sortOrder: -1,
          newSuccessorId: this.draggingRowIndex > droppedOnRowIndex && successor != null ? successor._id : null,
          newPredecessorId: this.draggingRowIndex < droppedOnRowIndex && predecessor != null ? predecessor._id : null,
          updateId: self._id,
          client: self.client,
        },
      },
    });
  }

  arraymove(arr: any[], oldindex: number, newindex: number) {
    if (newindex >= arr.length) {
      let k = newindex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newindex, 0, arr.splice(oldindex, 1)[0]);
    return arr; // for testing
  }

  clickTable(event: any) {
    if (event._id != null) {
      this.$router.push({
        path: `/cms/blogs/${this.$route.params.client}/${event._id}`,
      });
    }
  }

  editHandler(id: string) {
    this.$router.push({
      path: `/cms/blogs/${this.$route.params.client}/${id}`,
    });
  }
}
