import getConfig from '@/helper/config-loader';

const account = getConfig('i18n/de/account.de', 'account');
const common = getConfig('i18n/de/common.de', 'common');
const login = getConfig('i18n/de/login.de', 'login');
const navigation = getConfig('i18n/de/navigation.de', 'navigation');
const notifications = getConfig('i18n/de/notifications.de', 'notifications');

export const de = {
  account,
  common,
  login,
  navigation,
  notifications,
};
