
import { Component, Vue } from 'vue-property-decorator';
import { GET_MAPPINGS, GET_MAPPING_TYPES } from '@/graphlql/queries';
import { DRY_RUN_WITH_PATH, UPDATE_MAPPING } from '@/graphlql/mutations';

@Component({
  props: ['mappingType', 'dryRun', 'path'],
  components: {},
  apollo: {
    mappings: {
      query: GET_MAPPINGS,
      manual: true,
      variables() {
        return {
          mappingsShopId: parseInt(this.$route.params.shopId, 10),
          mappingsLimit: 1,
          mappingsOffset: 0,
          mappingsType: -1,
          mappingOrder: 'key',
        };
      },
      result(data: any) {
        if (data != null && data.data != null) {
          if (data.data.mappings != null) {
            this.apolloData = data.data.mappings.mappings;
            this.loading = {};

            for (const item of this.apolloData) {
              this.loading[item.id] = {
                loading: false,
                success: false,
                error: false,
              };
            }

            this.total = data.data.mappings.count;
          }
        }
      },
    },
    mappingTypes: {
      query: GET_MAPPING_TYPES,
      result(data: any) {
        for (const mappingType of this.mappingTypes) {
          if (mappingType.name == this.$props.mappingType) {
            this.currentType = mappingType.id;
            this.currentTypeName = mappingType.name;
            break;
          }
        }

        if (this.currentType > -1) {
          this.$apollo.queries.mappings.refetch({
            mappingsOffset: this.perPage * (this.page - 1),
            mappingsLimit: this.perPage,
            mappingsType: this.currentType,
          });
        }
      },
    },
  },
  watch: {
    page() {
      const self = this as AssistentMapping;
      this.$apollo.queries.mappingTypes.refetch();
    },
  },
})
export default class AssistentMapping extends Vue {
  page: number = 1;
  total: number = 10;
  perPage: number = 20;
  apolloData: any[] = [];
  currentType: number = -1;
  currentTypeName: string = '';
  loading: any = {};
  update: boolean = false;

  dryRunLoading: boolean = false;
  dryRunSuccess: boolean = false;
  dryRunError: boolean = false;

  prevStep() {
    this.$emit('step-back');
  }

  nextStep() {
    this.$emit('step-finished');
  }

  saveMapping(item: any) {
    this.loading[item.id].loading = true;
    this.loading[item.id].error = false;
    this.loading[item.id].success = false;

    this.$forceUpdate();

    this.$apollo
      .mutate({
        mutation: UPDATE_MAPPING,
        // Parameters
        variables: {
          updateMappingValue: item.value,
          updateMappingId: item.id,
          updateMappingActive: item.active,
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.updateMapping != null) {
          this.loading[item.id].loading = false;
          this.loading[item.id].success = true;
        } else {
          this.loading[item.id].loading = false;
          this.loading[item.id].error = true;
        }
        this.$forceUpdate();
        setTimeout(() => this.resetState(item.id), 5000);
      })
      .catch((error) => {
        this.loading[item.id].loading = false;
        this.loading[item.id].error = true;
        this.$forceUpdate();
        setTimeout(() => this.resetState(item.id), 5000);
      });
  }

  runDryRun() {
    this.dryRunLoading = true;

    this.$apollo
      .mutate({
        mutation: DRY_RUN_WITH_PATH,
        // Parameters
        variables: {
          dryRunShopId: parseInt(this.$route.params.shopId, 10),
          dryRunPath: this.$props.path,
        },
      })
      .then((data) => {
        this.dryRunLoading = false;
        if (data != null && data.data != null && data.data.dryRun != null && data.data.dryRun.success) {
          this.dryRunSuccess = true;
          this.dryRunError = false;
        } else {
          this.dryRunError = true;
          this.dryRunSuccess = false;
        }

        setTimeout(() => this.resetDryRunState(), 5000);
      })
      .catch((error) => {
        this.dryRunLoading = false;
        this.dryRunSuccess = false;
        this.dryRunError = true;

        setTimeout(() => this.resetDryRunState(), 5000);
      });
  }

  mounted() {
    this.$apollo.queries.mappingTypes.refetch();
  }
  resetDryRunState() {
    this.dryRunLoading = false;
    this.dryRunSuccess = false;
  }

  resetState(itemId: any) {
    if (this.loading[itemId]) {
      this.loading[itemId].loading = false;
      this.loading[itemId].error = false;
      this.loading[itemId].success = false;

      this.$forceUpdate();
    }
  }
}
