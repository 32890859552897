
import EditSingleColor from '@/components/common/EditSingleColor.vue';
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
    EditSingleColor,
  },
  computed: {
    getStyle() {
      const self = this as ContentSection;
      return 'background-color: ' + self.content.colorSelected;
    },
  },
})
export default class ContentSection extends BaseCmsComponentClass {
  colorPickerHandler(hex: string) {
    this.content.colorSelected = hex;
  }
}
