
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LOCALES, Locales } from '@/i18n/locales';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('i18nModule');
import BurgerMenu from '@/components/navigation/BurgerMenu.vue';
import MenuClickCreatedMixin, { NavigationEventBus } from '@/events/navigation/NavigationEventBus';
import { mixins } from 'vue-class-component';
import CurrentUser from './CurrentUser.vue';
import getConfig from '@/helper/config-loader';

@Component({
  components: { BurgerMenu, CurrentUser },
  computed: {
    ...mapGetters({
      getLanguage: 'getLanguage',
      getLanguageObject: 'getLanguageObject',
    }),
    ...mapActions(['setLanguage', 'fetchLanguageJson']),
  },
  watch: {
    $route(to, from) {
      const self = this as NavbarTop;
      self.query = to.query.query as string;
    },
  },
})
export default class NavbarTop extends mixins<Vue>(MenuClickCreatedMixin) {
  @Prop({ required: true })
  appName!: string;
  query: string = '';
  //TODO: types for the store->class to get rid of the long store-calls. using map-getters/actions is the better was
  getLanguage!: Locales;
  config: any = getConfig('meta');

  /*private menuIsOpen = false;*/
  getLOCALES() {
    return LOCALES;
  }

  getLogoUrl() {
    return this.config.logoTop;
  }

  submitSearch() {
    this.$router.push({ path: '/search', query: { query: this.query } });
    this.query = '';
  }

  updateLanguage(lang: Locales) {
    this.$store.dispatch('i18nModule/setLanguage', lang);
    this.$store.dispatch('i18nModule/fetchLanguageJson', this.getLanguage);
    this.$i18n.setLocaleMessage(
      this.$store.getters['i18nModule/getLanguage'],
      this.$store.getters['i18nModule/getLanguageObject']
    );
  }

  mounted() {
    this.query = this.$route.query.query as string;
  }

  /* created() {
    NavigationEventBus.$on('menu-got-clicked', (data: boolean) => {
      this.menuIsOpen = data;
    });
  }*/
}
