import Vue from 'vue';
import Component from 'vue-class-component';
export const NavigationEventBus = new Vue();

@Component
export default class MenuClickCreatedMixin extends Vue {
  private menuIsOpen = false;

  created() {
    NavigationEventBus.$on('menu-got-clicked', (data: boolean) => {
      this.menuIsOpen = data;
    });
  }

  beforeDestroy() {
    NavigationEventBus.$off('menu-got-clicked', (data: boolean) => {
      this.menuIsOpen = data;
    });
  }
}
