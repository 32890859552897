
import { Vue } from 'vue-property-decorator';

import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import UrlTree from '@/components/cms/UrlTree.vue';
import { CMS_GET_PAGE, CMS_GET_TEMPLATES, CMS_GET_URL_NODE, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import clients from '../../graphClient';
import {
  CMS_CREATE_NODE,
  CMS_CREATE_PAGE,
  CMS_UPDATE_NODE,
  CMS_UPDATE_PAGE,
  UPDATE_USER_USAGE,
  CREATE_USER_USAGE,
} from '@/graphlql/mutations';
import CMSDetail from '@/components/cms/CMSDetail.vue';

import * as helper from '@/helper/data-helper';
import { previewLink, previewLinkPS, previewLinkSNKR } from '@/main';
import CMSMeta from '@/components/cms/CMSMeta.vue';
import VersionsTable from '@/components/versions/VersionsTable.vue';

@Component({
  components: {
    UrlTree,
    BaseView,
    CMSDetail,
    CMSMeta,
    VersionsTable,
  },
  apollo: {
    getTemplates: {
      query: CMS_GET_TEMPLATES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          client: this.$route.params.clientId,
          type: 'sites',
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getTemplates != null) {
          this.templates = data.data.getTemplates;
        }
      },
    },
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.siteId,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.siteId;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.dealId !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.siteId !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.siteId !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.siteId === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
      error(error) {
        console.log('No Page selected');
      },
    },
    getPage: {
      query: CMS_GET_PAGE,
      client: 'clientB',
      variables: {
        id: '0',
      },
      skip() {
        return this.skipQueryPage;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getPage != null) {
          if (data.data.getPage.img == null) {
            data.data.getPage.img = {};
          }

          this.page = data.data.getPage;

          this.$store.dispatch('cmsItemsModule/setItems', this.page.content.dynamicContent);

          this.changed = false;
          this.loaded = true;
        } else {
          this.page = this.getEmpty();
          this.url = this.getEmptyUrl();

          this.$store.dispatch('cmsItemsModule/setItems', []);

          this.changed = false;
          this.loaded = true;
        }

        this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.clientId);
      },
      error() {
        this.page = this.getEmpty();
        this.url = this.getEmptyUrl();
        this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.clientId);
        this.$store.dispatch('cmsItemsModule/setItems', []);
      },
    },
    getNode: {
      query: CMS_GET_URL_NODE,
      client: 'clientB',
      variables: {
        getNodeId: '0',
      },
      skip() {
        return this.skipQueryNode;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getNode != null && data.data.getNode.target != null) {
          this.pageId = data.data.getNode.target._id;
          this.url = data.data.getNode;
          this.cmsTitle = `Bearbeiten: ${this.url.title} | ${this.url.path}`;
          this.fetchPage();
        }
      },
      error() {
        this.page = this.getEmpty();
        this.url = this.getEmptyUrl();
        this.$store.dispatch('cmsItemsModule/setItems', []);
        this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.clientId);
      },
    },
  },
  watch: {
    $route(to, from) {
      const self = this as SitesView;
      self.currentClient = this.$route.params.clientId;
      self.refetch();
    },
    page: {
      handler() {
        const self = this as SitesView;
        self.lastChange = new Date();

        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
    url: {
      handler() {
        const self = this as SitesView;
        self.lastChange = new Date();

        if (self.loadedUrl) {
          self.loadedUrl = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    const self = this as SitesView;

    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class SitesView extends Vue {
  page: any = { content: { dynamicContent: [] }, title: '', description: '', pageTitle: '', img: {} };
  url: any = { path: '' };
  cmsTitle: string = 'Seiten';
  currentClient: string = this.$route.params.clientId;
  skipQueryPage: boolean = true;
  skipQueryNode: boolean = true;
  pageId: string | null = null;
  templates: any[] = [];
  previewUrl: string | undefined = previewLink;
  previewUrlPS: string | undefined = previewLinkPS;
  previewUrlSNKR: string | undefined = previewLinkSNKR;

  lastChange = new Date();
  lastUpdateChange: any = null;

  changed: boolean = false;
  loaded: boolean = false;
  loadedUrl: boolean = false;
  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'page',
    lastTimeEdited: new Date(),
  };

  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';
  checkRouteLeave() {
    if (this.changed) {
      return window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
    } else {
      return true;
    }
  }

  openPreview() {
    const client = this.currentClient;

    if (client.indexOf('ps') > -1) {
      window.open(this.previewUrlPS + 'preview', '_blank');
    } else if (client.indexOf('snkr') > -1) {
      window.open(this.previewUrlSNKR + 'preview', '_blank');
    } else {
      window.open(this.previewUrl + 'preview', '_blank');
    }
  }

  createSiteHandler() {
    this.$router.push({ path: `/cms/sites/${this.currentClient}/create` });
  }

  getEmpty() {
    return {
      content: {
        dynamicContent: [
          {
            component: 'ContentSection',
            content: { colorSelected: '#ffffff' },
            icon: 'square-dashed',
            id: 1,
            items: [],
            label: 'Sektion',
          },
        ],
      },
      title: '',
      description: '',
      pageTitle: '',
      img: {},
    };
  }

  getEmptyUrl() {
    return {
      path: '',
      tags: [],
      title: '',
    };
  }

  refetch() {
    if (this.$route.params.siteId == 'create') {
      this.cmsTitle = 'Seite erstellen';
      this.page = this.getEmpty();
      this.url = this.getEmptyUrl();
      this.$store.dispatch('cmsItemsModule/setItems', this.page.content.dynamicContent);
      this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.clientId);
    } else if (this.$route.params.siteId == null) {
      this.cmsTitle = 'Seiten';
    } else {
      this.skipQueryNode = false;
      this.$apollo.queries.getNode.refetch({
        getNodeId: this.$route.params.siteId,
      });
    }
  }

  fetchPage() {
    this.skipQueryPage = false;
    this.$apollo.queries.getPage.refetch({
      id: this.pageId,
    });
  }

  treeClicked(element: any) {
    if (this.checkRouteLeave()) {
      this.$emit('tree:clicked', element);
      this.$router.push({ path: `/cms/sites/${this.currentClient}/${element._id}` });
    }
  }

  mounted() {
    this.currentClient = this.$route.params.clientId;
    this.refetch();

    window.addEventListener('message', this.updatePreviewData, false);
  }

  unmounted() {
    window.removeEventListener('message', this.updatePreviewData);
  }

  updatePreviewData(event: any) {
    if (event.data != null && event.data.message == 'startsync') {
      this.lastUpdateChange = new Date();
      event.source.postMessage({ message: 'sentdata', getNodeByPath: this.page }, event.origin);
    }

    if (event.data != null && event.data.message == 'syncdata') {
      if (this.lastUpdateChange == null || this.lastChange.getTime() > this.lastUpdateChange.getTime()) {
        this.lastUpdateChange = new Date();
        event.source.postMessage({ message: 'sentdata', getNodeByPath: this.page }, event.origin);
      }
    }
  }

  getPageData() {
    const data = helper.cleanTypeData(this.page, ['__typename', 'updatedAt', 'createdAt', 'img']);

    if (this.page.img != null && this.page.img._id != null) {
      data.img = this.page.img._id;
    }

    data.client = this.$route.params.clientId;
    return data;
  }

  getUrlData() {
    const data = helper.cleanTypeData(this.url, ['__typename', 'previewPath', 'target']);

    return data;
  }

  async userLeave() {
    this.currentUsage.inUse = false;

    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  createNew() {
    if (this.url != null && this.url.path != null) {
      this.page.content.dynamicContent = (this.$refs['cmsDetailCreate'] as any).getData();
      try {
        clients.clientB
          .mutate({
            mutation: CMS_CREATE_PAGE,
            variables: {
              page: this.getPageData(),
            },
          })
          .then((data) => {
            if (data.data != null && data.data.createPage != null) {
              clients.clientB
                .mutate({
                  mutation: CMS_CREATE_NODE,
                  variables: {
                    createNode: {
                      path: this.url.path,
                      client: this.currentClient,
                      target: data.data.createPage._id,
                      tags: this.url.tags,
                      title: this.url.title,
                    },
                  },
                })
                .then((data2) => {
                  if (data2.data != null && data2.data.addNode != null) {
                    this.$router.push({ path: `/cms/sites/${this.currentClient}/${data2.data.addNode._id}` });
                    this.$buefy.notification.open({
                      message: `Seite erfolgreich angelegt`,
                      duration: 5000,
                      type: 'is-success',
                      pauseOnHover: true,
                      position: 'is-bottom-right',
                    });

                    this.changed = false;
                  }
                })
                .catch((e) => {
                  this.$buefy.notification.open({
                    message: `Fehler beim Anlegen`,
                    duration: 5000,
                    type: 'is-danger',
                    pauseOnHover: true,
                    position: 'is-bottom-right',
                  });
                });
            }
          })
          .catch((e) => {
            this.$buefy.notification.open({
              message: `Fehler beim Anlegen`,
              duration: 5000,
              type: 'is-danger',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          });
      } catch (e) {
        console.log('CreateSite: ', e);
      }
    }
  }

  saveExisting() {
    const self = this as SitesView;
    this.page.content.dynamicContent = (this.$refs['cmsDetail'] as any).getData();
    try {
      clients.clientB
        .mutate({
          mutation: CMS_UPDATE_NODE,
          variables: {
            updateNode: {
              _id: this.$route.params.siteId,
              ...self.getUrlData(),
            },
            client: this.currentClient,
          },
        })
        .then((data) => {
          if (data != null && data.data != null && data.data.updateNode != null) {
            clients.clientB
              .mutate({
                mutation: CMS_UPDATE_PAGE,
                variables: {
                  page: {
                    _id: this.$route.params.siteId,
                    ...self.getPageData(),
                  },
                },
              })
              .then((data2) => {
                if (data2 != null && data2.data != null && data2.data.updatePage != null) {
                  self.$buefy.notification.open({
                    message: `Seite erfolgreich gespeichert`,
                    duration: 5000,
                    type: 'is-success',
                    pauseOnHover: true,
                    position: 'is-bottom-right',
                  });

                  this.changed = false;
                } else {
                  self.$buefy.notification.open({
                    message: `Fehler beim Speichern`,
                    duration: 5000,
                    type: 'is-danger',
                    pauseOnHover: true,
                    position: 'is-bottom-right',
                  });
                }
              });
          } else {
            self.$buefy.notification.open({
              message: `Fehler beim Speichern`,
              duration: 5000,
              type: 'is-danger',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          }
        });
    } catch (e) {
      console.log('CreateSite: ', e);
    }
  }

  getWebOrApp() {
    return this.currentClient.split('-')[1];
  }

  saveSiteHandler() {
    if (this.$refs['cmsDetail'] != null) {
      this.saveExisting();
    } else if (this.$refs['cmsDetailCreate'] != null) {
      this.createNew();
    }
  }
}
