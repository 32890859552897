
import { Component, Prop, Vue } from 'vue-property-decorator';
import { cdnLink } from '@/main';
import { mixins } from 'vue-class-component';
import IntlMixin from '@/mixins/intl';

@Component
export default class ProductCard extends mixins(IntlMixin) {
  cdn: string | undefined = cdnLink;
  @Prop({ required: true })
  productData: any;

  get hasDiscount() {
    return this.productData.discount && this.productData.discount < 0;
  }
}
