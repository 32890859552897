
import clients from '@/graphClient';
import { CMS_UPLOAD_FILE } from '@/graphlql/mutations';
import { cmsLink } from '@/main';
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';
import CKEditor from 'ckeditor4-vue';
import cacheImage from '@/helper/image-caching';
import { $axios } from '@/store/axios-instance';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
    ckeditor: CKEditor.component,
  },
  watch: {
    file(newFile: any, oldFile: any) {
      // Call to the graphql mutation
      const self = this as ImageText;
      console.log("ImageText CMS_UPLOAD_FILE DONE");


      const formData = new FormData();
      formData.append('file', newFile);

      $axios.post(process.env.VUE_APP_CMS_URL + "media/upload",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((data: any) => {
        if (data?.data) {
          self.content.image.filename = data.data.filename;
          self.content.image.originalName = data.data.originalName;
          self.content.image._id = data.data._id;
          self.content.image.mimeType = data.data.mimeType;
          self.content.image.altName = data.data.altName;
          self.content.image.altText = data.data.altText;

          cacheImage(self.content.image._id, self.content.image.originalName);
        }
      })
        .catch((error) => {
          console.error(error);
        });

      /*
      
            clients.clientB
              .mutate({
                // Query
                mutation: CMS_UPLOAD_FILE,
                // Parameters
                variables: {
                  file: newFile,
                },
                context: {
                  hasUpload: true,
                },
              })
              .then((data) => {
                if (data != null && data.data != null && data.data.uploadFile != null) {
                  if (self.content.image == null) {
                    self.content.image = {};
                  }
                  self.content.image.filename = data.data.uploadFile.filename;
                  self.content.image.originalName = data.data.uploadFile.originalName;
                  self.content.image._id = data.data.uploadFile._id;
                  self.content.image.mimeType = data.data.uploadFile.mimeType;
                  self.content.image.altName = data.data.uploadFile.altName;
      
                  cacheImage(data.data.uploadFile._id, data.data.uploadFile.originalName);
                }
              })
              .catch((error) => {
                console.error(error);
              });
              */
    },
  },
})
export default class ImageText extends BaseCmsComponentClass {
  editorConfig = {
    disableNativeSpellChecker: false,
  };
  file: any = null;
  orientation: string[] = ['left', 'right'];
  textAlignment: string[] = ['start', 'center', 'end'];
  cdn: string | undefined = cmsLink;
}
