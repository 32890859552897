
import { Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import NestedDraggable from '@/components/cms/NestedDraggable.vue';
import { DragContainer, DragInterface } from '@/interfaces/cms/drag.interface';
import { mapGetters } from 'vuex';
import Component from 'vue-class-component';

import { NavigationEventBus } from '@/events/navigation/NavigationEventBus';
import getConfig from '@/helper/config-loader';

@Component({
  components: { NestedDraggable, draggable },
  computed: {
    ...mapGetters('cmsItemsModule', ['getItems']),
  },
  props: {
    context: {
      type: String,
    },
  },
})
export default class CMSView extends Vue {
  dragContainer: DragContainer[] = getConfig('drag-items');
  fullscreen: boolean = false;
  context: undefined;
  log(evt: InstanceType<typeof Event>) {
    window.console.log(evt);
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    NavigationEventBus.$emit('show-fullscreen', this.fullscreen);
  }

  getNextId() {
    const items = this.$store.getters['cmsItemsModule/getItems'];
    let max = this.resolveMaxId(items);
    return ++max;
  }

  resolveMaxId(items: DragInterface[]) {
    if (items == null || items.length == 0) {
      return 0;
    }

    let max = 0;

    for (const element of items) {
      if (element.id > max) {
        max = element.id;
      }

      if (element.items != null && element.items.length > 0) {
        const temp = this.resolveMaxId(element.items);
        if (temp > max) {
          max = temp;
        }
      }
    }

    return max;
  }

  cloneItem(item: DragInterface): DragInterface {
    const cloneItem: DragInterface = {
      ...item,
      id: this.getNextId(),
      content: JSON.parse(JSON.stringify(item.content)),
    };

    if (item.items) {
      cloneItem.items = [...item.items];
    }

    return cloneItem;
  }

  getDragContainer() {
    let dragItemsFiltered: DragContainer[] = [];

    const deepcopy: DragContainer[] = [];
    for (const [index, container] of this.dragContainer.entries()) {
      deepcopy[index] = { ...container };
    }

    for (const container of deepcopy) {
      if (!this.context || !container.context || container.context == this.context) {
        const dragContainer = container;
        dragContainer.items = dragContainer.items.filter(
          (item) => !this.context || item.context == this.context || item.context == null
        );
        if (dragContainer.items && dragContainer.items.length != 0) {
          dragItemsFiltered = dragItemsFiltered.concat(dragContainer);
        }
      }
    }
    return dragItemsFiltered;
  }
}
