<template>
  <div>
    <div class="control" v-for="(category, index) in categories" :key="index">
      <div class="tags has-addons taglist-tags is-info m-0" @click="collapse(category._id)">
        <span class="tag m-0 pr-0">
          <span>{{ category.name }}</span>
        </span>
        <i class="tag mdi m-0" :class="isCategorySelected(category._id) > -1 ? 'mdi-minus' : 'mdi-plus'"></i>
      </div>
      <div
        class="is-flex is-flex-wrap-wrap is-flex-gap"
        :class="isCategorySelected(category._id) > -1 ? 'my-3' : 'mt-3'"
      >
        <div
          class="control"
          v-for="(tag, index) in category.tags"
          :key="index"
          v-show="isCategorySelected(category._id) > -1"
        >
          <div
            class="tags has-addons m-0 taglist-tags"
            :class="{ 'is-success': tagInSelectedTags(tag.name) }"
            @click="emitTagClick(tag.name)"
          >
            <span class="tag m-0 pr-0">
              <span class="">{{ tag.name }}</span>
            </span>
            <i class="tag mdi m-0" :class="tagInSelectedTags(tag.name) ? 'mdi-close' : 'mdi-plus'"></i>
          </div>
        </div>
      </div>

      <!-- <div
          class="tags has-addons m-0 taglist-tags"
          :class="{ 'is-success': tagInSelectedTags(tag) }"
          @click="emitTagClick(tag)"
        >
          <span class="tag m-0 pr-0">
            <span class="">{{ tag }}</span>
          </span>
          <i class="tag mdi m-0" :class="tagInSelectedTags(tag) ? 'mdi-close' : 'mdi-plus'"></i>
        </div> -->
    </div>

    <div class="control">
      <div
        class="tags has-addons taglist-tags is-info m-0"
        @click="tagsWithoutCategoryCollapsed = !tagsWithoutCategoryCollapsed"
      >
        <span class="tag m-0 pr-0">
          <span>Tags ohne Kategorie</span>
        </span>
        <i class="tag mdi m-0" :class="tagsWithoutCategoryCollapsed ? 'mdi-minus' : 'mdi-plus'"></i>
      </div>
    </div>
    <div class="is-flex is-flex-wrap-wrap is-flex-gap" :class="tagsWithoutCategoryCollapsed ? 'my-3' : 'mt-3'">
      <div class="control" v-for="tag in tagsWithoutCategory" :key="tag._id" v-show="tagsWithoutCategoryCollapsed">
        <div
          class="tags has-addons m-0 taglist-tags"
          :class="{ 'is-success': tagInSelectedTags(tag.name) }"
          @click="emitTagClick(tag.name)"
        >
          <span class="tag m-0 pr-0">
            <span class="">{{ tag.name }}</span>
          </span>
          <i class="tag mdi m-0" :class="tagInSelectedTags(tag.name) ? 'mdi-close' : 'mdi-plus'"></i>
        </div>
      </div>
    </div>

    <!-- <div class="control" v-if="isScrollHeightTooBig()">
      <div class="tags has-addons m-0 taglist-tags is-info" @click="collapse">
        <span class="tag m-0 pr-0">
          <span>{{ isCollapsed ? 'Zeige weniger' : 'Zeige mehr' }}</span>
        </span>
        <i class="tag mdi m-0" :class="isCollapsed ? 'mdi-minus' : 'mdi-plus'"></i>
      </div>
    </div> -->
  </div>
</template>

<script>
import { CMS_GET_TAGS, CMS_GET_TAG_CATEGORIES } from '@/graphlql/queries';
export default {
  name: 'TagList',
  data() {
    return {
      categories: [],
      selectedCategories: [],
      tagsWithoutCategory: [],
      tagsWithoutCategoryCollapsed: false,
    };
  },
  props: {
    client: String,
    selectedTags: Array,
  },
  methods: {
    isScrollHeightTooBig() {
      return this.$refs?.customCollapse?.$el?.scrollHeight > 72;
    },
    emitTagClick(tag) {
      this.$emit('tagClick', tag);
    },
    tagInSelectedTags(tag) {
      return this.selectedTags?.indexOf(tag) > -1;
    },
    isCategorySelected(categoryId) {
      return this.selectedCategories.indexOf(categoryId);
    },
    collapse(categoryId) {
      const index = this.isCategorySelected(categoryId);
      if (index > -1) {
        this.selectedCategories.splice(index, 1);
      } else {
        this.selectedCategories.push(categoryId);
      }
    },
  },
  apollo: {
    getTagCategories: {
      query: CMS_GET_TAG_CATEGORIES,
      fetchPolicy: 'no-cache',
      manual: false,
      variables() {
        return {
          client: this.client,
        };
      },
      result(data) {
        if (data.data != null) {
          this.categories = [...data.data.getTagCategories];
        }
      },
    },
    getTags: {
      query: CMS_GET_TAGS,
      fetchPolicy: 'no-cache',
      manual: false,
      variables() {
        return {
          client: this.client,
          category: null,
        };
      },
      result(data) {
        if (data.data != null) {
          this.tagsWithoutCategory = [...data.data.getTags];
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.is-flex-gap {
  gap: 0.5rem;
}
.taglist-tags {
  user-select: none;
  -webkit-user-select: none;
  --tag-color: #{$grey-lighter};
  --tag-hover: #{$grey-light};
  &.is-success {
    --tag-color: #{$success};
    --tag-hover: #{darken($success, 5%)};
    .tag,
    .tag > * {
      color: white;
    }
  }
  &.is-info {
    --tag-color: #{$info};
    --tag-hover: #{darken($info, 5%)};
    .tag,
    .tag > * {
      color: white;
    }
  }
  cursor: pointer;
  .tag,
  .tag > * {
    background: var(--tag-color);
  }
  &:hover {
    .tag,
    .tag > * {
      background: var(--tag-hover);
    }
  }
}
.custom-collapse {
  max-height: 4rem;
  overflow: hidden;

  &.is-collapsed {
    max-height: none;
    overflow: visible;
  }
}
</style>
