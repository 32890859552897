
import clients from '@/graphClient';
import { CMS_UPLOAD_FILE } from '@/graphlql/mutations';
import { Vue } from 'vue-property-decorator';
import { cmsLink } from '@/main';
import Component from 'vue-class-component';
import cacheImage from '@/helper/image-caching';
import { $axios } from '@/store/axios-instance';

@Component({
  props: {
    images: {
      type: Array,
    },
    inUsage: Boolean,
  },
  watch: {
    files(newFiles, oldFiles) {
      const self = this as ImageList;
      for (const file of newFiles) {
        if (!file.uploaded) {
          file.uploaded = true;
          self.uploadImage(file, self.addNew());
        }
      }
    },
  },
})
export default class ImageList extends Vue {
  draggingRow: any = null;
  draggingRowIndex: any = null;
  draggingColumn: any = null;
  draggingColumnIndex: any = null;
  inUsage: any;
  public images: any;
  files: any[] = [];
  addNew() {
    this.images.push({
      itemId: this.getNextId(),
      filename: '',
      _id: '',
      originalName: '',
      altName: '',
    });

    return this.images[this.images.length - 1];
  }

  deleteImage(event: any, id: number) {
    let index = '';
    for (const i in this.images) {
      const image = this.images[i];

      if (image.itemId == id) {
        index = i;
      }
    }

    if (parseInt(index, 10) > -1) {
      this.images.splice(parseInt(index, 10), 1); // 2nd parameter means remove one item only
    }
    event.preventDefault();
    return false;
  }

  uploadImage(file: any, image: any) {

    console.log("ImageList CMS_UPLOAD_FILE DONE", process.env, file);

    const formData = new FormData();
    formData.append('file', file);

    $axios.post(process.env.VUE_APP_CMS_URL + "media/upload",
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then((data: any) => {
      if (data?.data) {
        image.filename = data.data.filename;
        image.originalName = data.data.originalName;
        image._id = data.data._id;
        image.mimeType = data.data.mimeType;
        image.altName = data.data.altName;
        image.altText = data.data.altText;

        cacheImage(image._id, image.originalName);
      }
    })
      .catch((error) => {
        console.error(error);
      });
  }

  onFileSelected(file: any, props: any) {
    this.uploadImage(file, props.row);
  }

  getNextId() {
    let max = this.resolveMaxId(this.images);
    return ++max;
  }

  resolveMaxId(items: any[]) {
    if (items == null || items.length == 0) {
      return 0;
    }

    let max = 0;

    for (const element of items) {
      if (element.itemId > max) {
        max = element.itemId;
      }
    }

    return max;
  }
  dragstart(payload: any) {
    this.draggingRow = payload.row;
    this.draggingRowIndex = payload.index;
    payload.event.dataTransfer.effectAllowed = 'copy';
  }
  dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = 'copy';
    payload.event.target.closest('tr').classList.add('is-selected');
    payload.event.preventDefault();
  }
  dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    payload.event.preventDefault();
  }
  drop(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    const droppedOnRowIndex = payload.index;
    this.images = this.arraymove(this.images, this.draggingRowIndex, droppedOnRowIndex);
  }

  arraymove(arr: any[], oldindex: number, newindex: number) {
    if (newindex >= arr.length) {
      let k = newindex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newindex, 0, arr.splice(oldindex, 1)[0]);
    return arr; // for testing
  }

  file: any = null;
  cdn: string | undefined = cmsLink;
}
