
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

import { CMS_GET_DEAL, CMS_GET_DEALS } from '@/graphlql/queries';

import BaseChooser from './BaseChooser.vue';
@Component({
  components: {
    BaseChooser,
  },
  props: {
    reference: {
      type: String,
    },
    clientId: {
      type: String,
    },
  },
  apollo: {
    getDeal: {
      query: CMS_GET_DEAL,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      skip() {
        return this.skipyQuery;
      },
      variables() {
        return {
          getDealId: '',
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getDeal != null) {
          this.$emit('item-selected', data.data.getDeal);
        }
      },
    },
    getDeals: {
      query: CMS_GET_DEALS,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          client: this.getClient(),
          offset: 0,
          limit: 10,
          orderBy: 'rank',
          sortOrder: -1,
          headline: null,
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getDeals != null) {
          this.items = data.data.getDeals.items;
        }
      },
    },
  },
  watch: {
    query(old, newValue) {
      const self = this as DealChooser;
      self.refetchData();
    },
    reference(newValue, oldValue) {
      const self = this as DealChooser;
      self.loadItem(newValue);
    },
  },
})
export default class DealChooser extends Vue {
  reference: any;
  query: string = '';
  items: any[] = [];
  skipyQuery: boolean = true;

  getClient() {
    if (this.$props.clientId != null) {
      return this.$props.clientId;
    }

    const client = this.$store.getters['cmsItemsModule/getClient'];
    console.log('adsa1', client);
    if (client) {
      return client;
    }
    return this.$route.params.client + '-app';
  }

  queryHandler(query: string) {
    this.query = query;
    this.refetchData();
  }

  mounted() {
    if (this.reference != null && this.reference != '') {
      this.loadItem(this.reference);
    }
  }

  itemSelected(item: any) {
    this.$emit('item-selected', item);
  }

  loadItem(id: string) {
    this.skipyQuery = false;

    this.$apollo.queries.getDeal.refetch({
      getDealId: id,
    });
  }

  refetchData() {
    this.$apollo.queries.getDeals.refetch({
      sortOrder: -1,
      client: this.getClient(),
      offset: 0,
      limit: 10,
      orderBy: 'rank',
      headline: this.query != '' ? this.query : null,
    });
  }
}
