
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
  },
  computed: {
    rawHtml() {
      const self = this as Button;
      return `<${self.content.type}>${self.content.text}</${self.content.type}>`;
    },
  },
})
export default class Button extends BaseCmsComponentClass {
  types: string[] = ['primary', 'secondary', 'alternative', 'ghost'];
}
