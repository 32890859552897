
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';
import UploadImage from '../UploadImage.vue';
import { cmsLink } from '@/main';

interface ImageType {
  filename: string;
  _id: string;
  originalName: string;
}

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
    UploadImage,
  },
})
export default class WhatsNew extends BaseCmsComponentClass {
  cdn: string | undefined = cmsLink;
  entries: { image: ImageType; imageDark: ImageType; tag: string }[] = [];
  wantToAddNew = false;
  currentEditableEntry = -1;
  imgToEdit: ImageType = { filename: '', _id: '', originalName: '' };
  imgToEditDark: ImageType = { filename: '', _id: '', originalName: '' };
  tagToEdit = '';

  newEntry() {
    this.wantToAddNew = true;
  }
  addEntry() {
    this.entries.push({ image: this.imgToEdit, tag: this.tagToEdit, imageDark: this.imgToEditDark });
    this.wantToAddNew = false;
    this.$props.content.entries = this.entries;
    this.imgToEdit = { filename: '', _id: '', originalName: '' };
    this.imgToEditDark = { filename: '', _id: '', originalName: '' };
    this.tagToEdit = '';
  }
  startEditingEntry(img: ImageType, imgDark: ImageType, tag: string, index: number) {
    this.imgToEdit = img;
    this.imgToEditDark = imgDark;
    this.tagToEdit = tag;
    this.currentEditableEntry = index;
  }
  updateEntry(index: number) {
    this.entries[index].image = this.imgToEdit;
    this.entries[index].imageDark = this.imgToEditDark;
    this.entries[index].tag = this.tagToEdit;
    this.currentEditableEntry = -1;
    this.$props.content.entries = this.entries;
    this.imgToEdit = { filename: '', _id: '', originalName: '' };
    this.imgToEditDark = { filename: '', _id: '', originalName: '' };
    this.tagToEdit = '';
  }
  mounted() {
    this.entries = this.$props.content.entries || [];
  }
  deleteEntry(index: number) {
    this.entries.splice(index, 1);
    this.$props.content.entries = this.entries;
  }
}
