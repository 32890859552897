var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"statistics"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('b-field',{attrs:{"label":"Mandanten","custom-class":"is-small","horizontal":""}},[_c('b-select',{attrs:{"size":"is-small","placeholder":"Mandant"},on:{"input":_vm.refetchStatistics},model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}},_vm._l((_vm.clients),function(client){return _c('option',{key:client.id,domProps:{"value":client.id}},[_vm._v(" "+_vm._s(client.name)+" ")])}),0)],1)],1),_c('div',{staticClass:"level-right"},[_c('b-field',{attrs:{"label":"Zeitraum","custom-class":"is-small","horizontal":""}},[_c('b-datepicker',{attrs:{"position":"is-bottom-left","size":"is-small","placeholder":"Click to select...","range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1)])]),_c('div',{staticClass:"line-chart"},[_c('line-chart',{attrs:{"chart-data":_vm.datacollection,"options":{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            stacked: true,
          },
          yAxes: [{ gridLines: { display: false } }],
        },
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }