
import Vue from 'vue';
import Component from 'vue-class-component';

import AssistentOverview from '@/components/stores/assistent/AssistentOverview.vue';
import AssistentMapping from '@/components/stores/assistent/AssistentMapping.vue';
import AssistentFinish from '@/components/stores/assistent/AssistentFinish.vue';


@Component({ components: { AssistentOverview, AssistentMapping, AssistentFinish } })
export default class StoreAssistentView extends Vue {
  page: number = 0;
  path: string = '';

  nextStep() {
    this.page = this.page + 1;
  }
  prevStep() {
    this.page = this.page - 1;
  }
  savePath(path: string) {
    this.path = path;
  }
}
