import axios, { AxiosRequestConfig } from 'axios';

const config: AxiosRequestConfig = {};

if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
  config.baseURL = process.env.VUE_APP_BASE_URL;
  config.headers = {
    'Content-Type': 'application/json'
  };
}

export const $axios = axios.create(config);
