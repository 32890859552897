export const common = {
  from: 'ab',
  shops: 'Shops',
  discountCode: 'Rabattcode',
  instead: 'Statt',
  plus: 'zzgl.',
  shipping: 'Versand',
  update1: 'Update vor',
  update2: '',
  minutes: 'Minuten',
  hours: 'Stunden',
  days: 'Tagen'
};
