<template>
  <div>
    <b-field label="Tags">
      <b-taginput
        :value="value"
        @input="onInput"
        ellipsis
        icon="tag"
        placeholder="Tag hinzufügen"
        aria-close-label="Tag löschen"
      >
      </b-taginput>
    </b-field>
    <tag-list class="mt-2" :client="client" :type="type" :selected-tags="value" @tagClick="onTagClick" />
  </div>
</template>
<script>
import TagList from './TagList.vue';
export default {
  name: 'CustomTagInput',
  components: {
    TagList,
  },
  props: { value: Array, client: String, type: String },
  methods: {
    onTagClick(tag) {
      const tagsInputIndex = this.value.indexOf(tag);
      if (tagsInputIndex > -1) {
        this.value.splice(tagsInputIndex, 1);
      } else {
        this.value.push(tag);
      }
    },
    onInput(evt) {
      this.$emit('input', evt);
    },
  },
};
</script>
