
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import clients from '../../graphClient';
import {
  CMS_CREATE_RESELLSHOP,
  CMS_UPDATE_RESELLSHOP,
  CMS_DELETE_RESELLSHOP,
  UPDATE_USER_USAGE,
  CREATE_USER_USAGE,
} from '@/graphlql/mutations';
import CMSView from './CMSView.vue';
import { CMS_GET_RESELL_SHOP, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import * as helper from '@/helper/data-helper';

import UploadImage from '@/components/cms/UploadImage.vue';

@Component({
  components: {
    BaseView,
    CMSView,
    UploadImage,
  },
  apollo: {
    getResellShopById: {
      query: CMS_GET_RESELL_SHOP,
      client: 'clientB',
      variables: {
        getResellShopByIdId: '0',
      },
      skip() {
        return this.skipQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getResellShopById != null) {
          if (data.data.getResellShopById.previewImage == null) {
            data.data.getResellShopById.previewImage = {};
          }

          if (data.data.getResellShopById.previewImageDark == null) {
            data.data.getResellShopById.previewImageDark = {};
          }

          this.shop = data.data.getResellShopById;

          this.siteTitle = 'Shop (' + this.shop.headline + ') speichern';
          this.loaded = true;
          this.changed = false;
          //   this.setItems(this.page.content.dynamicContent);
        }
      },
    },
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.id,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.id;
          if (this.currentUsage.inUse && this.existing && this.usingUser !== currentUser.username 
          && this.$route.params.dealId !== 'create' && process.env.USER_USAGE_CHECK_ENABLED == 'true') {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.id !== "create") {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (this.currentUsage.inUse && this.existing && this.usingUser === currentUser.username && this.$route.params.id !== 'create') {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.id === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
  },
  watch: {
    $route(to, from) {
      const self = this as ResellShopView;
      self.refetch();
    },
    shop: {
      handler() {
        const self = this as ResellShopView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    const self = this as ResellShopView;
    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class ResellShopView extends Vue {
  siteTitle: string = '';
  skipQuery: boolean = true;

  changed: boolean = false;
  loaded: boolean = false;

  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'shop',
    lastTimeEdited: new Date(),
  };
  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';

  shop: any = {
    headline: '',
    isActive: false,
    previewImage: {},
    previewImageDark: {},
  };

  getEmpty() {
    return {
      headline: '',
      isActive: false,
      previewImage: {},
      previewImageDark: {},
    };
  }

  refetch() {
    if (this.$route.params.id == 'create') {
      this.siteTitle = 'Shopeintrag erstellen';
      this.shop = this.getEmpty();
    } else if (this.$route.params.id != null) {
      this.skipQuery = false;
      this.$apollo.queries.getResellShopById.refetch({
        getResellShopByIdId: this.$route.params.id,
      });
    }
  }

  mounted() {
    this.refetch();
  }

  async saveNew() {
    clients.clientB
      .mutate({
        mutation: CMS_CREATE_RESELLSHOP,
        variables: {
          shop: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.createResellShop != null && data.data.createResellShop._id) {
          this.$router.push({
            path: `/cms/shops/${this.$route.params.client}/${data.data.createResellShop._id}`,
          });
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async saveExisting() {
    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_RESELLSHOP,
        variables: {
          shop: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.updateResellShop != null && data.data.updateResellShop._id) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async userLeave() {
    this.currentUsage.inUse = false;

    if(this.currentUsage.targetId !== "create")
    await clients.clientB.mutate({
      mutation: UPDATE_USER_USAGE,
      variables: {
        targetId: this.currentUsage.targetId,
        userUsageDto: {
          ...this.currentUsage,
        },
      },
    });
  }

  getData() {
    this.shop.client = this.$route.params.client;

    const returnData = JSON.parse(
      JSON.stringify(helper.cleanTypeData(this.shop, ['__typename', 'updatedAt', 'createdAt']))
    );

    if (returnData['previewImage'] && returnData['previewImage']['_id']) {
      returnData['previewImage'] = returnData['previewImage']['_id'];
    } else {
      delete returnData['previewImage'];
    }

    if (returnData['previewImageDark'] && returnData['previewImageDark']['_id']) {
      returnData['previewImageDark'] = returnData['previewImageDark']['_id'];
    } else {
      delete returnData['previewImageDark'];
    }

    return returnData;
  }

  async saveHandler() {
    if (this.$route.params.id == 'create') {
      this.saveNew();
    } else if (this.$route.params.id != null) {
      this.saveExisting();
    }
  }

  async deleteHandler() {
    const content = prompt('Bitte "löschen" zum bestätigen eingeben');
    if (content === 'löschen') {
      if (this.shop?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_RESELLSHOP,
          variables: {
            id: this.shop._id,
          },
        });
      }

      // Surpress unsaved change prompt, a bit hacky but works
      this.changed = false;
      this.$router.push({ name: 'ResellShopsView' });
    }
  }
}
