<template>
  <draggable
    tag="div"
    class="dragArea"
    group="foo"
    :list="list"
    ghostClass="ghost"
    dragClass="drag"
    chosenClass="chosen"
  >
    <component
      class="drag-list-item is-relative has-radius is-clipped has-background-white shadow--m"
      :is="element.component"
      v-for="element in list"
      :key="element.id"
      :settings="element"
      :content="element.content"
      :ref="'comp' + element.id"
    >
      <template #component>
        <div class="drag-list-item__head has-background-grey has-text-white is-flex is-align-items-center">
          <p class="px-2 is-size-7" :title="element.component + ' ID:' + element.id">
            {{ element.label != null ? element.label : element.component }}
          </p>
          <b-button
            class="has-no-radius ml-auto"
            type="is-info"
            icon-right="pencil"
            :disabled="element.componentOptions && element.componentOptions.hasConfiguration === false"
            @click.prevent="editComponent(element)"
          />

          <b-button
            class="has-no-radius"
            type="is-danger"
            icon-right="delete"
            @click.prevent="removeComponent(element)"
          />
        </div>
      </template>

      <nested-draggable
        v-if="element.items"
        class="has-flex-gap"
        :class="componentClasses(element.component)"
        :list="element.items"
      />
    </component>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import ContentSection from '@/components/cms/components/ContentSection.vue';
import Columns from '@/components/cms/components/Columns.vue';
import Headline from '@/components/cms/components/Headline';
import Ticker from '@/components/cms/components/Ticker';
import WhatsNew from '@/components/cms/components/WhatsNew';
import RichText from '@/components/cms/components/RichText';
import ImageComponent from '@/components/cms/components/ImageComponent';
import Banner from '@/components/cms/components/Banner';
import BannerList from '@/components/cms/components/BannerList';
import Button from '@/components/cms/components/Button';
import DoubleButton from '@/components/cms/components/DoubleButton';
import ImageText from '@/components/cms/components/ImageText';
import Separator from '@/components/cms/components/Separator';
import BlogCard from '@/components/cms/components/BlogCard';
import BlogContainer from '@/components/cms/components/BlogContainer';
import BlogSlider from '@/components/cms/components/BlogSlider';
import DealCard from '@/components/cms/components/DealCard';
import DealContainer from '@/components/cms/components/DealContainer';
import DealList from '@/components/cms/components/DealList';
import DealSlider from '@/components/cms/components/DealSlider';
import SmallCard from '@/components/cms/components/SmallCard';
import LargeCard from '@/components/cms/components/LargeCard';
import DoubleDealContainer from '@/components/cms/components/DoubleDealContainer';
import SearchEngineContainer from '@/components/cms/components/SearchEngineContainer';
import SearchEngineInput from '@/components/cms/components/SearchEngineInput';
import SearchEngineList from '@/components/cms/components/SearchEngineList';
import ReleasesContainer from '@/components/cms/components/ReleasesContainer';
import ReleaseShops from '@/components/cms/components/ReleaseShops';
import ReleasesList from '@/components/cms/components/ReleasesList';
import ReleaseListSlider from '@/components/cms/components/ReleaseListSlider';
import ReleasesSlider from '@/components/cms/components/ReleasesSlider';
import InstaFeedContainer from '@/components/cms/components/InstaFeedContainer';
import ReleaseContainer from '@/components/cms/components/ReleaseContainer';
import DoubleSmallCard from '@/components/cms/components/DoubleSmallCard';
import Battle from '@/components/cms/components/Battle';
import VoucherField from '@/components/cms/components/VoucherField';

import { mapActions } from 'vuex';

export default {
  name: 'NestedDraggable',
  methods: {
    ...mapActions('cmsItemsModule', ['removeItem']),
    removeComponent(element) {
      this.removeItem(element);
    },
    editComponent(element) {
      this.$refs['comp' + element.id][0].openHandler();
    },
  },
  components: {
    draggable,
    ContentSection,
    Columns,
    Headline,
    RichText,
    Ticker,
    WhatsNew,
    ImageComponent,
    Banner,
    BannerList,
    ImageText,
    Button,
    DoubleButton,
    Separator,
    BlogCard,
    BlogContainer,
    BlogSlider,
    DealCard,
    DealContainer,
    DealList,
    DealSlider,
    DoubleDealContainer,
    SearchEngineContainer,
    SearchEngineInput,
    SearchEngineList,
    SmallCard,
    LargeCard,
    InstaFeedContainer,
    ReleasesContainer,
    ReleaseShops,
    ReleasesList,
    ReleaseListSlider,
    ReleasesSlider,
    ReleaseContainer,
    DoubleSmallCard,
    VoucherField,
    Battle,
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    componentClasses: () => (comp) => {
      let classes = '';
      switch (comp) {
        case 'Columns':
          classes = 'is-flex';
          break;
        case 'ContentSection':
          classes = 'simple-grid has-flex-gap';
          break;
        default:
          break;
      }
      return classes;
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : this.list;
    },
  },
  props: {
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.drag-list-item {
  flex: 1 1 0;
}
</style>
