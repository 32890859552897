
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { CMS_GET_TEMPLATES } from '@/graphlql/queries';
import { cmsLink } from '@/main';
import clients from '@/graphClient';
import { CMS_DELETE_TEMPLATE } from '@/graphlql/mutations';

@Component({
  components: {},
  apollo: {
    getTemplates: {
      query: CMS_GET_TEMPLATES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          client: this.$route.params.client,
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getTemplates != null) {
          this.tableData = data.data.getTemplates;
        }
      },
    },
  },
  watch: {
    $route(to, from) {

      const self = this as TemplatesTable;
      self.refetchData();
    },
  },
})
export default class TemplatesTable extends Vue {
  page: number = 1;
  total: number = 1;
  perPage: number = 10;
  tableData: any[] = [];

  cdn: string | undefined = cmsLink;

  mounted() {
    this.refetchData();
  }


  onPageChange(page: number) {
    this.page = page;
    this.refetchData();
  }

  changePageSize() {
    this.page = 1;
    this.refetchData();
  }

  refetchData() {
    this.$apollo.queries.getTemplates.refetch();
  }

  clickTable(event: any) {
    if (event._id != null) {
      this.$router.push({
        path: `/cms/templates/${this.$route.params.client}/${event._id}`,
      });
    }
  }

  editHandler(id: string) {
    this.$router.push({
      path: `/cms/templates/${this.$route.params.client}/${id}`,
    });
  }

  deleteHandler(id: string) {
    clients.clientB
      .mutate({
        mutation: CMS_DELETE_TEMPLATE,
        variables: {
          deleteTemplateId: id,
        },
      })
      .then((data) => {
        this.$apollo.queries.getTemplates.refetch();
      });
  }
}
