
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import BlogsTable from '@/components/cms/BlogsTable.vue';

@Component({
  components: {
    BlogsTable,
    BaseView,
  },
})
export default class BlogsView extends Vue {
  createHandler() {
    this.$router.push({ path: `/cms/blogs/${this.$route.params.client}/create` });
  }
}
