
import { Component, Vue } from 'vue-property-decorator';
import BarChart from '../../components/common/BarChart.vue';

import { GET_PRODUCT, GET_PRODUCTS, GET_TOP_PRODUCTS } from '@/graphlql/queries';
import BaseView from '../common/BaseView.vue';

@Component({
  components: { BarChart, BaseView },
  apollo: {
    statistics: {
      query: GET_TOP_PRODUCTS,
      variables: {
        topProductsOrder: 1,
        topProductsCount: 10,
        topProductsStartDate: new Date().setDate(new Date().getDate() - 6),
      },
      manual: true,
      result(data) {
        if (data.data != null && data.data.topProducts != null) {
          const wishlist: any[] = [];
          const views: any[] = [];
          const clicks: any[] = [];
          const fakes: any[] = [];
          const labels: any[] = [];

          for (const entry of data.data.topProducts) {
            wishlist.push(entry.wishlist);
            views.push(entry.views);
            clicks.push(entry.clicks);
            labels.push(entry.product);
            fakes.push(entry.fakes);
          }

          this.datacollection = {
            labels: labels,
            datasets: [
              {
                label: 'Views',
                data: views,
                borderColor: '#48c774',
                backgroundColor: '#48c77466',
              },
              {
                label: 'Clicks',
                data: clicks,
                borderColor: '#ffdd57',
                backgroundColor: '#ffdd5766',
              },
              {
                label: 'Wishlist',
                data: wishlist,
                borderColor: '#3298dc',
                backgroundColor: '#3298dc66',
              },
              {
                label: 'Fakes',
                data: fakes,
                borderColor: '#000000',
                backgroundColor: '#00000066',
              },
            ],
          };
          this.$apollo.queries.productsById.refetch({
            productsByIdIds: labels,
          });
        }
      },
    },
  },
  watch: {
    choosenCount() {
      const self = this as QueryStatisticsView;
      self.refetch();
    },
    choosenOrder() {
      const self = this as QueryStatisticsView;
      self.refetch();
    },
    choosenStartDate() {
      const self = this as QueryStatisticsView;
      self.refetch();
    },
  },
})
export default class QueryStatisticsView extends Vue {
  choosenOrder: number = 1;
  choosenCount: number = 10;
  choosenStartDate: string = 'week';
  products: any = {};
  orders: any[] = [
    { value: 1, label: 'Views' },
    { value: 2, label: 'Wishlist' },
    { value: 4, label: 'Click' },
    { value: 10, label: 'Fakes' },
  ];
  count: number[] = [10, 20, 50];
  dateMap: any = {
    day: new Date().setDate(new Date().getDate() - 1),
    week: new Date().setDate(new Date().getDate() - 7),
    month: new Date().setDate(new Date().getDate() - 31),
  };
  startDates: any[] = [
    { value: this.dateMap['day'], label: 'day' },
    { value: this.dateMap['week'], label: 'week' },
    { value: this.dateMap['month'], label: 'month' },
  ];

  datacollection: any = {};

  refetch() {
    this.$apollo.queries.statistics.refetch({
      topProductsOrder: this.choosenOrder,
      topProductsCount: this.choosenCount,
      topProductsStartDate: this.dateMap[this.choosenStartDate],
    });
  }

  updateLabels() {
    if (this.datacollection != null && this.datacollection.labels != null) {
      const labels = [];

      for (const label of this.datacollection.labels) {
        if (this.products[label] != null) {
          labels.push(this.products[label].title + ' - ' + this.products[label].articleId);
        } else {
          labels.push(label);
        }
      }

      this.datacollection = { labels: labels, datasets: this.datacollection.datasets };
    }
  }
  mounted() {
    // this.refetch();
  }
}
