
import { Component, Vue } from 'vue-property-decorator';
import SeoEdit from '@/components/seo/SeoEdit.vue';
import SeoCreate from '@/components/seo/SeoCreate.vue';
import { GET_CLIENTS, GET_SEO_PAGINATION } from '@/graphlql/queries';
import TableBase from '../common/TableBase.vue';

@Component({
  components: {
    SeoEdit,
    SeoCreate,
  },
  apollo: {
    clients: {
      query: GET_CLIENTS,
    },
    shops: {
      query: GET_SEO_PAGINATION,
      manual: true,
      variables: {
        seoPaginationOffset: 0,
        seoPaginationLimit: 10,
      },
      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            this.loading = data.loading;
            const tableData: any = [];
            const clientMap: any = {};
            if (this.clients != null) {
              for (const client of this.clients) {
                clientMap[client.id] = client;
              }
            }

            for (const shop of data.data.seoPagination.entries) {
              shop.clientName = clientMap[shop.client] != null ? clientMap[shop.client].name : shop.client;
              tableData.push(shop);
            }

            this.tableData = tableData;
            this.total = data.data.seoPagination.count;
          }
        }
      },
    },
  },
})
export default class SeoTable extends TableBase {
  columns = [
    {
      field: 'id',
      label: 'ID',
      width: '80',
      numeric: true,
    },
    {
      field: 'path',
      label: 'Pfad',
    },
    {
      field: 'clientName',
      label: 'Mandant',
    },
    {
      field: 'client',
      label: 'Mandant Id',
    },
  ];

  createNewObject: any = null;

  createdStore(data: any) {
    this.loadAsyncData();

    this.createNewObject = null;

    if (this.selected == null) {
      this.selected = data.createShop;
    }
  }

  loadAsyncData() {
    this.$apollo.queries.shops.refetch({
      seoPaginationOffset: this.perPage * (this.page - 1),
      seoPaginationLimit: this.perPage,
    });
  }

  createdSeo(data: any) {
    this.selected = data;
    this.loadAsyncData();
  }

  createNew() {
    this.createNewObject = {
      context: [],
      text: '',
      path: '',
      client: 0,
    };

    this.selected = null;
  }

  keys = ['page'];

  onPageChange(page: number) {
    super.onPageChange(page);
  }

  popstateEventAction() {
    super.popstateEventAction();
    this.loadAsyncData();
  }

  mounted() {
    super.mounted();
    this.loadAsyncData();
  }
}
