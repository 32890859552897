
import { Vue } from 'vue-property-decorator';

import Component from 'vue-class-component';
import { CMS_GET_URL_TREE } from '@/graphlql/queries';
import NestedTree from './NestedTree.vue';
import clients from '@/graphClient';
import { CMS_UPDATE_NODE_CHILD_ORDER } from '@/graphlql/mutations';

@Component({
  name: 'UrlTree',
  props: ['client'],
  data() {
    return {
      skipGetUrlTree: true,
    };
  },
  components: {
    NestedTree,
  },
  apollo: {
    getUrlTree: {
      query: CMS_GET_URL_TREE,
      client: 'clientB',
      manual: true,
      skip() {
        return this.skipGetUrlTree;
      },
      variables() {
        return {
          client: this.$route.params.clientId,
        };
      },
      result(data) {
        //  console.log('geturltree', data);
        if (data.data != null && data.data.getUrlTree != null) {
          const self = this as unknown as UrlTree;
          self.urlTree = data.data.getUrlTree;
        }
      },
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  watch: {
    $route(to, from) {
      const self = this as UrlTree;
      self.loadAsyncData();
    },
  },
})
export default class UrlTree extends Vue {
  urlTree: any = null;

  treeClicked(element: any) {
    this.$emit('tree:clicked', element);
  }

  treeUpdated(event: any) {
    const nodeIds = [];
    for (const child of event.children) {
      nodeIds.push(child._id);
    }

    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_NODE_CHILD_ORDER,
        variables: {
          updateUrlNodeChildSort: {
            _id: event._id,
            children: nodeIds,
          },
        },
      })
      .then((data) => {
        this.loadAsyncData();
      });
  }

  loadAsyncData() {
    this.$apollo.queries.getUrlTree.refetch({
      client: this.$props.client,
    });
  }

  mounted() {
    this.$data.skipGetUrlTree = false;
    this.loadAsyncData();
  }
}
