
import { Component, Vue } from 'vue-property-decorator';
import { UPDATE_CLIENT } from '@/graphlql/mutations';

import ClientView from '@/components/clients/ClientView.vue';

@Component({
  props: ['value'],
  components: {
    ClientView,
  },
})
export default class ClientEdit extends Vue {
  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;

  value: any;

  save() {
    //  console.log(this.value);
    // Call to the graphql mutation
    this.loading = true;

    const referrer: any[] = [];
    const adverts: any[] = [];
    const images: any[] = [];

    if (this.value.referrer != null) {
      for (const item of this.value.referrer) {
        referrer.push({
          id: item.id,
          referrer: item.referrer,
          name: item.name,
        });
      }
    }

    if (this.value.adverts != null) {
      for (const item of this.value.adverts) {
        adverts.push({
          id: item.id,
          image: item.image,
          link: item.link,
          name: item.name,
        });
      }
    }

    if (this.value.images != null) {
      for (const item of this.value.images) {
        images.push({
          id: item.id,
          image: item.image,
          link: item.link,
          name: item.name,
        });
      }
    }

    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_CLIENT,
        // Parameters
        variables: {
          updateClientInput: {
            referrer: referrer,
            adverts: adverts,
            images: images,
            active: this.value.active,
            name: this.value.name,
          },
          updateClientId: this.value.id,
        },
      })
      .then((data) => {
        this.loading = false;
        this.result = true;
        this.error = false;
        this.success = true;

        console.log(data);

        setTimeout(() => this.clearStates(), 1000);
      })
      .catch((error) => {
        this.loading = false;
        this.result = true;
        this.error = true;
        this.success = false;
        setTimeout(() => this.clearStates(), 1000);
      });
  }
  clearStates() {
    this.loading = false;
    this.result = false;
    this.error = false;
    this.success = false;
  }
}
