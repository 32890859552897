import Vue from 'vue';
import Vuex from 'vuex';
import notificationModule from './modules/notifications';
import deviceModule from '@/store/modules/device';
import authModule from '@/store/modules/auth';
import i18nModule from '@/store/i18n/i18n';
import navigationModule from '@/store/modules/navigation/navigation';
import cmsItemsModule from '@/store/modules/cms/cmsItems';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    notificationModule,
    deviceModule,
    authModule,
    i18nModule,
    navigationModule,
    cmsItemsModule,
  },
});
