
import { CMS_GENERATE_LONG_TEXT } from '@/graphlql/queries';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: ['title'],
  components: {},
  apollo: {
    generateLongText: {
      query: CMS_GENERATE_LONG_TEXT,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      skip() {
        return this.skipyQuery;
      },
      variables() {
        return {
          prompt: '',
          maxToken: 200,
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.generateLongText != null) {
          this.process(data.data.generateLongText);
        }
      },
    },
  },
})
export default class OpenAiModal extends Vue {
  givenTitle: string = '';
  metaTitle: string = '';
  metaDescription: string = '';
  richtext: string = '';
  skipyQuery: boolean = true;
  state: string = '';

  placeholder: any = {
    richtext: '',
    metaDescription: '',
    metaTitle: '',
  };

  loading: any = {
    richtext: false,
    metaDescription: false,
    metaTitle: false,
  };

  recreate: any = {
    richtext: false,
    metaDescription: false,
    metaTitle: false,
  };

  async generateText() {
    this.loading.metaTitle = true;
    this.loading.metaDescription = true;
    this.loading.richtext = true;

    await this.recreateMetaTitle();
  }

  async process(text: string) {
    switch (this.state) {
      case 'metatitle':
        this.loading.metaTitle = false;
        this.recreate.metaTitle = true;
        this.metaTitle = this.clean(text);

        if (this.loading.metaDescription) {
          await this.recreateMetaDescription();
        }
        break;
      case 'metadescription':
        this.loading.metaDescription = false;
        this.recreate.metaDescription = true;
        this.metaDescription = this.clean(text);
        if (this.loading.richtext) {
          await this.recreateRichtext();
        }
        break;
      case 'richtext':
        this.loading.richtext = false;
        this.recreate.richtext = true;
        this.richtext = this.clean(text);
        break;
    }
  }

  clean(text: string) {
    return text.replace('\n\n', '');
  }

  async recreateMetaTitle() {
    this.skipyQuery = false;
    this.state = 'metatitle';
    this.loading.metaTitle = true;

    await this.$apollo.queries.generateLongText.refetch({
      prompt: `Erstelle einen meta title für den folgenden Text "${this.givenTitle}".`,
      maxToken: 200,
    });
  }

  async recreateMetaDescription() {
    this.skipyQuery = false;
    this.state = 'metadescription';
    this.loading.metaDescription = true;

    await this.$apollo.queries.generateLongText.refetch({
      prompt: `Erstelle eine meta description für den folgenden Text "${this.givenTitle}"`,
      maxToken: 200,
    });
  }

  async recreateRichtext() {
    this.skipyQuery = false;
    this.state = 'richtext';
    this.loading.richtext = true;

    await this.$apollo.queries.generateLongText.refetch({
      prompt: `Erstelle einen Seo Text für den folgenden Text "${this.givenTitle}" mit mindestens 300 Wörtern`,
      maxToken: 1000,
    });
  }

  mounted() {
    this.givenTitle = this.$props.title;
  }
}
