
import { Component, Vue } from 'vue-property-decorator';

import BaseView from '../common/BaseView.vue';
import ViewStatisticsChart from '@/components/statistics/ViewStatisticsChart.vue';
import DashboardCard from '@/components/dashboard/DashboardCard.vue';

@Component({
  components: { BaseView, ViewStatisticsChart, DashboardCard },
})
export default class ViewsStatisticsView extends Vue {
  mounted() {
    // this.refetch();
  }
}
