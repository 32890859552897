
import { Component, Vue } from 'vue-property-decorator';
import { UPLOAD_IMAGE } from '@/graphlql/mutations';
import { cdnLink } from '@/main';

@Component({
  props: ['value', 'context'],
  watch: {
    file(newFile: any, oldFile: any) {
      // Call to the graphql mutation
      const self = this as UploadAvatar;

      this.$apollo
        .mutate({
          // Query
          mutation: UPLOAD_IMAGE,
          // Parameters
          variables: {
            file: newFile,
            uploadImageContext: self.$props.context,
          },
          context: {
            hasUpload: true,
          },
        })
        .then((data) => {
          if (data != null && data.data != null && data.data.uploadImage != null) {
            self.$emit('update-path', data.data.uploadImage.path);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
})
export default class UploadAvatar extends Vue {
  value: any;
  context: any;
  file: any = null;

  cdn: string | undefined = cdnLink;

  tuEs() {
    console.log('Blub',this.file)
  }
}
