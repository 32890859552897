
import { Component, Vue } from 'vue-property-decorator';
import { GET_CLIENTS, GET_PROCESSORS } from '@/graphlql/queries';
import { BACKEND_USER_REGISTER } from '@/graphlql/mutations';

@Component({
  props: [],
  components: {},
  apollo: {
    processors: {
      query: GET_PROCESSORS,
    },
    clients: {
      query: GET_CLIENTS,
    },
  },
})
export default class RegisterModal extends Vue {
  email: string = '';
  username: string = '';
  password: string = '';
  passwordRepeat: string = '';
  passwordError: string | null = null;
  permissions: string[] = [];
  allPermissions: string[] = ['SEARCH_ENGINE_CMS', 'SYSTEM_SETTINGS', 'USER_MANAGEMENT', 'CMS'];

  isChecked(permission: string, permissions: string[]) {
    if (permissions != null) {
      return permissions.includes(permission);
    }
    return false;
  }

  modifyPermission(permission: string, permissions: string[]) {
    if (permissions != null) {
      const index = permissions.indexOf(permission);
      if (index > -1) {
        permissions.splice(index, 1);
      } else {
        permissions.push(permission);
      }
      return permissions;
    }
    return null;
  }

  async saveUser() {
    if (this.password == null || this.password.trim().length == 0) {
      this.passwordError = 'Das Passwort darf nicht leer sein.';
    } else if (this.password != this.passwordRepeat) {
      this.passwordError = 'Die Passwörter müssen identisch sein.';
    } else {
      this.passwordError = null;
    }

    if (this.passwordError == null) {
      await this.$apollo
        .mutate({
          // Query
          mutation: BACKEND_USER_REGISTER,
          // Parameters
          variables: {
            user: {
              username: this.username,
              password: this.password,
              email: this.email,
              permissions: this.permissions,
            },
          },
        })
        .then((data) => {
          if (data.data.backendUserRegister?.id != null) {
            this.$buefy.notification.open({
              message: `Nutzer angelegt geändert`,
              duration: 5000,
              type: 'is-success',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
            this.$emit('close');
          } else {
            this.$buefy.notification.open({
              message: `Nutzer wurde nicht angelegt. Die eingegeben Daten sind nicht korrekt oder ein Nutzer mit dem Namen existiert bereits. `,
              duration: 5000,
              type: 'is-danger',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          }
        })
        .catch((error) => {
          this.$buefy.notification.open({
            message: `Nutzer wurde nicht angelegt. Die eingegeben Daten sind nicht korrekt oder ein Nutzer mit dem Namen existiert bereits. `,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        });
    }
  }
}
