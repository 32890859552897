export const navigation = {
  products: 'Produkte',
  browgames: 'Browgames',
  search: 'Suche',
  productsearch: 'Produktsuche',
  colors: 'Farben',
  brands: 'Marken',
  stores: 'Stores',
  clients: 'Mandanten',
  mapping: 'Mapping',
  seo: 'SEO-Texte',
  aliases: 'Synonyme',
  brandmodels: 'Markenspez. Modelle',
  filterbubbles: 'Filter Bubbles',
  scores: 'Produktbeliebtheit',
  reporting: 'Import Historie',
  statistics: 'Statistiken',
  Produkte: 'Produkte',
  Import: 'Import',
  Konfiguration: 'Konfiguration',
  Charts: 'Charts',
  voucher: 'Gutscheine',
  suchmaschine: 'Suchmaschine',
  'search-admin': 'Administration',
  editing: 'Pflege',
  'search-statistics': 'Statistiken',
  'search-statistics-views': 'Views',
  'search-statistics-sales': 'Sales',
  'search-statistics-products': 'Produkte',
  'search-statistics-queries': 'Suchbegriffe',
  cms: 'CMS',
  prinzsportlich: 'PrinzSportlich',
  snkraddicted: 'SnkrAddicted',
  snkraddicteduk: 'SnkrAddicted🇬🇧',
  dealfam: 'Dealfam',
  admin: 'Administration',
  usermanagement: 'Benutzerverwaltung',
  deals: 'Deals',
  'double-deals': 'DoubleDeals',
  releases: 'Releases',
  restock: 'Restock',
  sites: 'Sites',
  'sites-app': 'App-Seiten',
  cmsconfig: 'Einstellungen',
  templates: 'Templates',
  nike: 'Nike Restock',
  incoming: 'Incoming',
  pushnotifications: 'Push Notifications',
  pushdetail: 'Push Details',
  stories: 'Stories',
  blog: 'Blog',
  instafeeds: 'Instafeeds',
  resellshops: 'Shops',
  texttemplates: 'Texttemplates',
  categories: 'Kategorien',
  vouchers: 'Gutscheine',
  tags: 'Tags',
  content: 'Inhalt',
  versions: 'Versionshistorie',
  meta: 'Meta',
  inappflyer: 'In-App Flyer',
};
