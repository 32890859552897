
import { Component, Prop, Vue } from 'vue-property-decorator';
import { cmsLink } from '@/main';
import { CMS_SEARCH } from '@/graphlql/queries';

@Component({
  watch: {
    $route(to, from) {
      const self = this as SearchResult;
      self.reload();
    },
  },
  filters: {
    format: function (value: string) {
      if (!value) return '';
      const d = new Date(value);
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    },
  },
  apollo: {
    searchCMS: {
      query: CMS_SEARCH,
      client: 'clientB',
      manual: true,
      skip() {
        return this.skip;
      },
      variables: {
        query: '',
        type: '',
        type2: null,
        client: '',
        onlyActive: null,
      },
      result(data) {
        if (data != null && data.loading == false) {
          this.loading = false;

          if (data.data.searchCMS != null) {
            this.totalItems = data.data.searchCMS.totalItems;
            this.data = data.data.searchCMS.items;
          }
        }
      },
    },
  },
})
export default class SearchResult extends Vue {
  cdn: string | undefined = cmsLink;
  loading: boolean = true;
  totalItems: number = 0;
  data: any[] = [];
  skip: boolean = true;
  itemsToShow: number = 2;

  @Prop({ required: true })
  client: any;

  @Prop({ required: true })
  label: any;

  @Prop({ required: true })
  type: any;

  @Prop({ required: false })
  type2: any;

  @Prop({ required: false })
  link: any;

  getLink(item: any) {
    return this.link + item.id;
  }
  async reload() {
    this.skip = false;
    await this.$apollo.queries.searchCMS.refetch({
      query: this.$route.query.query as string,
      type: this.type,
      type2: this.type2,
      client: this.client,
      onlyActive: null,
      limit: this.listAll() ? 1000 : 10,
    });
  }

  updateItemsShown() {
    if (window.innerWidth < 640) {
      this.itemsToShow = 2;
    } else if (window.innerWidth < 1028) {
      this.itemsToShow = 4;
    } else if (window.innerWidth < 1280) {
      this.itemsToShow = 6;
    } else {
      this.itemsToShow = 8;
    }
  }

  showAll() {
    this.$router.push({
      path: '/search',
      query: {
        query: this.$route.query.query as string,
        type: this.type,
        type2: this.type2,
        client: this.client,
      },
    });
  }

  listAll() {
    return this.$route.query.type != null || this.$route.query.type2 != null || this.$route.query.client != null;
  }

  async mounted() {
    this.updateItemsShown();
    await this.reload();

    window.addEventListener('resize', this.updateItemsShown);
  }

  unmounted() {
    window.removeEventListener('resize', this.updateItemsShown);
  }
}
