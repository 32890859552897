
import Vue from 'vue';
import Component from 'vue-class-component';
import SearchResult from '@/components/search/SearchResult.vue';
import BaseView from '../common/BaseView.vue';
import router from '@/router';

@Component({
  components: {
    SearchResult,
    BaseView,
  },
})
export default class SearchView extends Vue {
  searchPairs = [
    {
      client: 'ps-app',
      type: 'deal',
      type2: 'single',
      label: 'PS - Deals - App',
      link: '/cms/deals/single/ps-app/ps-web/',
    },
    {
      client: 'ps-web',
      type: 'deal',
      type2: 'single',
      label: 'PS - Deals - Web',
      link: '/cms/deals/single/ps-app/ps-web/',
    },
    {
      client: 'snkr-app',
      type: 'deal',
      type2: 'single',
      label: 'SNKR - Deals - App',
      link: '/cms/deals/single/snkr-app/snkr-web/',
    },
    {
      client: 'snkr-web',
      type: 'deal',
      type2: 'single',
      label: 'SNKR - Deals - Web',
      link: '/cms/deals/single/snkr-app/snkr-web/',
    },
    {
      client: 'snkruk-app',
      type: 'deal',
      type2: 'single',
      label: 'SNKR-UK - Deals - App',
      link: '/cms/deals/single/snkruk-app/snkruk-web/',
    },
    {
      client: 'snkruk-web',
      type: 'deal',
      type2: 'single',
      label: 'SNKR-UK - Deals - Web',
      link: '/cms/deals/single/snkruk-app/snkruk-web/',
    },
    {
      client: 'dealfam-app',
      type: 'deal',
      type2: 'single',
      label: 'Dealfam - Deals - App',
      link: '/cms/deals/single/dealfam-app/dealfam-web/',
    },
    {
      client: 'dealfam-web',
      type: 'deal',
      type2: 'single',
      label: 'Dealfam - Deals - Web',
      link: '/cms/deals/single/dealfam-app/dealfam-web/',
    },

    {
      client: 'ps-app',
      type: 'deal',
      type2: 'double',
      label: 'PS - DoubleDeals - App',
      link: '/cms/deals/double/ps-app/ps-web/',
    },
    {
      client: 'ps-web',
      type: 'deal',
      type2: 'double',
      label: 'PS - DoubleDeals - Web',
      link: '/cms/deals/double/ps-app/ps-web/',
    },
    {
      client: 'snkr-app',
      type: 'deal',
      type2: 'double',
      label: 'SNKR - DoubleDeals - App',
      link: '/cms/deals/double/snkr-app/snkr-web/',
    },
    {
      client: 'snkr-web',
      type: 'deal',
      type2: 'double',
      label: 'SNKR - DoubleDeals - Web',
      link: '/cms/deals/double/snkr-app/snkr-web/',
    },
    {
      client: 'snkruk-app',
      type: 'deal',
      type2: 'double',
      label: 'SNKR-UK - DoubleDeals - App',
      link: '/cms/deals/double/snkruk-app/snkruk-web/',
    },
    {
      client: 'snkruk-web',
      type: 'deal',
      type2: 'double',
      label: 'SNKR-UK - DoubleDeals - Web',
      link: '/cms/deals/double/snkruk-app/snkruk-web/',
    },
    {
      client: 'dealfam-app',
      type: 'deal',
      type2: 'double',
      label: 'Dealfam-UK - DoubleDeals - App',
      link: '/cms/deals/double/dealfam-app/dealfam-web/',
    },
    {
      client: 'dealfam-web',
      type: 'deal',
      type2: 'double',
      label: 'Dealfam-UK - DoubleDeals - Web',
      link: '/cms/deals/double/dealfam-app/dealfam-web/',
    },
    { client: 'snkr-app', type: 'release', label: 'SNKR - Release - App', link: '/cms/releases/snkr-app/snkr-web/' },
    { client: 'snkr-web', type: 'release', label: 'SNKR - Release - Web', link: '/cms/releases/snkr-app/snkr-web/' },

    {
      client: 'snkruk-app',
      type: 'release',
      label: 'SNKR-UK - Release - App',
      link: '/cms/releases/snkruk-app/snkruk-web/',
    },
    {
      client: 'snkruk-web',
      type: 'release',
      label: 'SNKR-UK - Release - Web',
      link: '/cms/releases/snkruk-app/snkruk-web/',
    },

    { client: 'ps', type: 'blog', label: 'PS - Blog', link: '/cms/blogs/ps/' },
    { client: 'snkr', type: 'blog', label: 'SNKR - Blog', link: '/cms/blogs/snkr/' },
    { client: 'dealfam', type: 'blog', label: 'Dealfam - Blog', link: '/cms/blogs/dealfam/' },
    { client: 'ps', type: 'pushdetail', label: 'PS - Pushdetail', link: '/cms/pushdetails/snkr/' },
    { client: 'dealfam', type: 'pushdetail', label: 'Dealfam - Pushdetail', link: '/cms/pushdetails/dealfam/' },
    { client: 'snkr', type: 'pushdetail', label: 'SNKR - Pushdetail', link: '/cms/pushdetails/snkr/' },
    { client: 'snkruk', type: 'pushdetail', label: 'SNKR-UK - Pushdetail', link: '/cms/pushdetails/snkruk/' },

    { client: 'ps-web', type: 'page', label: 'PS - Seiten', link: '/cms/sites/ps-web/' },
    { client: 'ps-app', type: 'page', label: 'PS - App-Seiten', link: '/cms/sites/ps-app/' },
    { client: 'dealfam-web', type: 'page', label: 'Dealfam - Seiten', link: '/cms/sites/dealfam-web/' },
    { client: 'dealfam-app', type: 'page', label: 'Dealfam - App-Seiten', link: '/cms/sites/dealfam-app/' },
    { client: 'snkr-web', type: 'page', label: 'SNKR - Seiten', link: '/cms/sites/snkr-web/' },
    { client: 'snkr-app', type: 'page', label: 'SNKR - App-Seiten', link: '/cms/sites/snkr-app/' },
    { client: 'snkruk-web', type: 'page', label: 'SNKR-UK - Seiten', link: '/cms/sites/snkruk-web/' },
    { client: 'snkruk-app', type: 'page', label: 'SNKR-UK - App-Seiten', link: '/cms/sites/snkruk-app/' },
  ];

  getPairs() {
    if (this.$route.query.type != null || this.$route.query.type2 != null || this.$route.query.client != null) {
      const temp = [];
      for (const searchResult of this.searchPairs) {
        let match = true;
        if (this.$route.query.type != null) {
          match = searchResult.type == this.$route.query.type;
        }
        if (match && this.$route.query.type2 != null) {
          match = searchResult.type2 == this.$route.query.type2;
        }
        if (match && this.$route.query.client != null) {
          match = searchResult.client == this.$route.query.client;
        }
        if (match) {
          temp.push(searchResult);
        }
      }
      return temp;
    } else {
      return this.searchPairs;
    }
  }
  /*
    await this.indexShops();
    await this.indexTemplates();
    */
}
