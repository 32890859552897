
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: ['value'],
  watch: {
    value() {
      const self = this as EditSingleColor;
      if (self.value != null) {
        self.hex = self.value;
      } else if (self.value == null) {
        self.hex = '#ffffff';
      }
    },
  },
})
export default class EditSingleColor extends Vue {
  value: any;

  $refs!: {
    colorInput: HTMLInputElement;
  };

  hex: string = '#ffffff';

  mounted() {
    if (this.value != null) {
      this.hex = this.value;
    }
  }

  updateEditorColor() {
    this.$emit('value-changed', this.hex);
  }

  selectColor() {
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    this.$refs.colorInput.dispatchEvent(clickEvent);
  }

  findColorInvert(hex: string) {
    let brightness = 255;
    if (hex != null && hex.length >= 6) {
      const r = parseInt(hex[1] + hex[2], 16);
      const g = parseInt(hex[3] + hex[4], 16);
      const b = parseInt(hex[5] + hex[6], 16);
      brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
    }
    return brightness > 125 ? '#000000' : '#ffffff';
  }

  buttonStyle() {
    return {
      backgroundColor: this.hex,
      color: this.findColorInvert(this.hex),
    };
  }
}
