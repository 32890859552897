
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { CMS_GET_CATEGORIES } from '@/graphlql/queries';
import { cmsLink } from '@/main';
import clients from '@/graphClient';
import { CMS_UPDATE_CATEGORY_RANK } from '@/graphlql/mutations';

@Component({
  components: {},
  props: {
    tag: {
      type: String,
    },
  },
  apollo: {
    getCategories: {
      query: CMS_GET_CATEGORIES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      skip() {
        return this.skipQuery;
      },
      variables() {
        return {
          tag: this.categoryTag,
          offset: this.perPage * (this.page - 1),
          limit: this.perPage,
          orderBy: 'rank',
          sortOrder: -1,
          client: this.$route.params.client,
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getCategorys != null) {
          this.tableData = data.data.getCategorys.items;
          this.page = data.data.getCategorys.currentPage;
          this.total = data.data.getCategorys.totalItems;
        }
      },
    },
  },
  filters: {
    format(value: string): string {
      if (!value) return '';
      const d = new Date(value);
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    },
  },
  watch: {
    $route(to, from) {
      const self = this as CategoryDetailsTable;

      self.refetchData();
    },
    categoryTag(newClient, oldClient) {
      const self = this as CategoryDetailsTable;

      self.resetData();
    },
  },
})
export default class CategoryDetailsTable extends Vue {
  page: number = 1;
  total: number = 1;
  perPage: number = 10;
  tableData: any[] = [];
  skipQuery: boolean = true;
  cdn: string | undefined = cmsLink;
  pageSizes = [2, 10, 20, 50, 100, 200];
  draggingRow: any = null;
  draggingRowIndex: any = null;
  categoryTag: string = 'POPULAR_CATEGORY';
  categoryTags: any[] = [
    {
      type: 'POPULAR_CATEGORY',
      label: 'Beliebte Kategorien',
      icon: 'fire',
    },
    {
      type: 'OTHER_CATEGORY',
      label: 'Andere Kategorien',
      icon: 'boot',
    },
    {
      type: 'POPULAR_BRAND',
      label: 'Beliebte Marken',
      icon: 'apple-whole',
    },
  ];

  dragstart(payload: any) {
    this.draggingRow = payload.row;
    this.draggingRowIndex = payload.index;
    payload.event.dataTransfer.effectAllowed = 'copy';
  }
  dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = 'copy';
    payload.event.target.closest('tr').classList.add('is-selected');
    payload.event.preventDefault();
  }
  dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    payload.event.preventDefault();
  }
  drop(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    const droppedOnRowIndex = payload.index;

    const successor = payload.index > 0 ? this.tableData[payload.index - 1] : null;
    const self = this.tableData[this.draggingRowIndex];

    console.log(this.draggingRowIndex, droppedOnRowIndex);
    this.tableData = this.arraymove(this.tableData, this.draggingRowIndex, droppedOnRowIndex);

    clients.clientB.mutate({
      mutation: CMS_UPDATE_CATEGORY_RANK,
      variables: {
        updateDealRank: {
          newSuccessorId: successor != null ? successor._id : null,
          newPredecessorId: null,
          updateId: self._id,
          client: self.client,
        },
      },
    });
  }

  arraymove(arr: any[], oldindex: number, newindex: number) {
    if (newindex >= arr.length) {
      let k = newindex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newindex, 0, arr.splice(oldindex, 1)[0]);
    return arr; // for testing
  }

  mounted() {
    this.refetchData();
  }

  resetData() {
    this.page = 1;
    this.refetchData();
  }

  onPageChange(page: number) {
    this.page = page;
    this.refetchData();
  }

  changePageSize() {
    this.page = 1;
    this.refetchData();
  }

  refetchData() {
    this.skipQuery = false;
    this.$apollo.queries.getCategories.refetch({
      tag: this.categoryTag,
      offset: this.perPage * (this.page - 1),
      limit: this.perPage,
      orderBy: 'rank',
      sortOrder: -1,
      client: this.$route.params.client,
    });
  }

  clickTable(event: any) {
    if (event._id != null) {
      this.$router.push({
        path: `/cms/categories/${this.$route.params.client}/${event._id}`,
      });
    }
  }

  editHandler(id: string) {
    this.$router.push({
      path: `/cms/categories/${this.$route.params.client}/${id}`,
    });
  }
}
