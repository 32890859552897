
import { Component, Vue } from 'vue-property-decorator';
import { CREATE_SHOP } from '@/graphlql/mutations';
import { GET_PROCESSORS } from '@/graphlql/queries';

import StoreView from '@/components/stores/StoreView.vue';

@Component({
  props: ['value'],
  components: {
    StoreView,
  },
  apollo: {
    processors: {
      query: GET_PROCESSORS,
    },
  },
})
export default class StoreEdit extends Vue {
  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;
  value: any;
  output = 'Noch nicht angelegt:';

  exitClick() {
    this.$router.push({
      path: `/stores`,
    });
  }

  updatePath(path: any, darkmode: boolean) {
    darkmode ? (this.value.logoDarkmode = path) : (this.value.logo = path);
  }

  mounted() {
    if (this.value.config == null) {
      this.value.config = {};
    }
  }
}
