
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { CMS_GET_SIBLING_RELEASES } from '@/graphlql/queries';
import { cmsLink } from '@/main';

@Component({
  components: {},
  props: {
    dealType: {
      type: String,
    },
  },
  apollo: {
    getSiblingReleases: {
      query: CMS_GET_SIBLING_RELEASES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables: {
        clientB: '',
        clientA: '',
        offset: '',
        limit: '',
        orderBy: 'createdAt',
        sortOrder: -1,
        dealType: '',
        onlyClientA: false,
      },
      skip() {
        return this.skipQuery;
      },
      result(data) {
        if (data != null && data.data != null && data.data.getSiblingReleases != null) {
          this.tableData = data.data.getSiblingReleases.items;
          this.page = data.data.getSiblingReleases.currentPage;
          this.total = data.data.getSiblingReleases.totalItems;
        }
      },
    },
  },
  watch: {
    $route(to, from) {

      const self = this as ReleasesTable;

      self.clientA = self.$route.params.clientA;
      self.clientB = self.$route.params.clientB;
      self.refetchData();
    },
    dealClient(newClient, oldClient) {
      const self = this as ReleasesTable;
      self.resetData();
    },
  },
  filters: {
    format: function (value: string) {
      if (!value) return '';
      const d = new Date(value);
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    },
  },
})
export default class ReleasesTable extends Vue {
  page: number = 1;
  total: number = 1;
  perPage: number = 10;
  tableData: any[] = [];
  skipQuery: boolean = true;
  cdn: string | undefined = cmsLink;
  pageSizes = [2, 10, 20, 50, 100, 200];
  clientA: string = '';
  clientB: string = '';
  dealClient: string = 'both';
  dealClients: any[] = [
    {
      type: 'app',
      label: 'App',
      icon: 'mobile-screen',
    },
    {
      type: 'web',
      label: 'Web',
      icon: 'display',
    },
    {
      type: 'both',
      label: 'Alle',
      icon: 'laptop-mobile',
    },
  ];

  mounted() {
    this.clientA = this.$route.params.clientA;
    this.clientB = this.$route.params.clientB;

    this.refetchData();
  }


  onPageChange(page: number) {
    this.page = page;
    this.refetchData();
  }

  resetData() {
    this.page = 1;
    this.refetchData();
  }

  refetchData() {
    const clientA = this.dealClient == 'web' ? this.clientB : this.clientA;
    const clientB = this.dealClient == 'web' ? this.clientA : this.clientB;

    this.skipQuery = false;
    this.$apollo.queries.getSiblingReleases.refetch({
      clientB: clientB,
      clientA: clientA,
      offset: this.perPage * (this.page - 1),
      limit: this.perPage,
      orderBy: 'releaseDate',
      sortOrder: -1,
      dealType: this.$props.dealType,
      onlyClientA: this.dealClient != 'both',
    });
  }

  clickTable(event: any) {
    if (event._id != null) {
      this.$router.push({
        path: `/cms/releases/${this.$route.params.clientA}/${this.$route.params.clientB}/${event._id}`,
      });
    }
  }

  editHandler(id: string) {
    this.$router.push({
      path: `/cms/releases/${this.$route.params.clientA}/${this.$route.params.clientB}/${id}`,
    });
  }

  deleteHandler(url: string) {
    console.log('delete ', url);
  }
}
