
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import PushDetailsTable from '@/components/cms/PushDetailsTable.vue';

@Component({
  components: {
    PushDetailsTable,
    BaseView,
  },
})
export default class PushDetailsView extends Vue {
  createHandler() {
    this.$router.push({ path: `/cms/pushdetails/${this.$route.params.client}/create` });
  }
}
