
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';

import CKEditor from 'ckeditor4-vue';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
    ckeditor: CKEditor.component,
  },
})


export default class RichText extends BaseCmsComponentClass {
  editorConfig = {
    allowedContent: true,
    disableNativeSpellChecker : false,
    extraPlugins: 'justify',
    toolbarGroups: [
      { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
      { name: 'paragraph', groups: [ 'list', 'align', 'indent', 'bidi', 'blocks', 'paragraph' ] },
      { name: 'links', groups: [ 'links' ] },
      { name: 'insert', groups: [ 'insert' ] },
      '/',
      { name: 'styles', groups: [ 'styles' ] },
      { name: 'tools', groups: [ 'tools' ] },
      '/',
      { name: 'colors', groups: [ 'colors' ] },
      { name: 'others', groups: [ 'others' ] },
      { name: 'about', groups: [ 'about' ] },
      { name: 'forms', groups: [ 'forms' ] }
    ],
    removeButtons: 'JustifyBlock,Save,NewPage,Preview,Print,Templates,Form,Checkbox,Radio,TextField,Textarea,Button,Select,ImageButton,HiddenField,Superscript,Subscript,Scayt,SelectAll,Find,Replace,CopyFormatting,BidiLtr,BidiRtl,Language,Blockquote,CreateDiv,Image,Flash,Smiley,PageBreak,Iframe,Font,FontSize,TextColor,BGColor,ShowBlocks,About',
  };
}
