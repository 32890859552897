
import { Component, Vue } from 'vue-property-decorator';
import { GET_CLIENTS, GET_PROCESSORS } from '@/graphlql/queries';
import { TEST_DOWNLOAD } from '@/graphlql/mutations';
import RichText from '../../components/common/RichText.vue';
import UploadImage from '@/components/common/UploadImage.vue';

@Component({
  props: ['value'],
  components: {
    UploadImage,
    RichText,
  },
  apollo: {
    processors: {
      query: GET_PROCESSORS,
    },
    clients: {
      query: GET_CLIENTS,
    },
  },
})
export default class StoreView extends Vue {
  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;
  value: any;
  visibleNearbyDays: boolean = true;
  selectableNearbyDays: boolean = true;
  activeTab: number = 0;
  showConfiguration: boolean = true;
  month: any = new Date(this.dateValue).toLocaleString('default', { month: 'long' });
  months: any[] = [
    { name: 'Januar', value: 0 },
    { name: 'Februar', value: 1 },
    { name: 'März', value: 2 },
    { name: 'April', value: 3 },
    { name: 'Mai', value: 4 },
    { name: 'Juni', value: 5 },
    { name: 'Juli', value: 6 },
    { name: 'August', value: 7 },
    { name: 'September', value: 8 },
    { name: 'Oktober', value: 9 },
    { name: 'November', value: 10 },
    { name: 'Dezember', value: 11 },
  ];
  year: any = this.dateValue.getFullYear();
  years: any[] = [
    { name: new Date().getFullYear(), value: new Date().getFullYear() },
    { name: new Date().getFullYear() + 1, value: new Date().getFullYear() + 1 },
    { name: new Date().getFullYear() + 2, value: new Date().getFullYear() + 2 },
  ];
  get dateValue() {
    if (this.value.voucherDateLimit != null) return new Date(Date.parse(this.value.voucherDateLimit));
    return new Date();
  }
  set dateValue(value: Date) {
    value.setHours(value.getHours() + 23);
    value.setTime(value.getTime() + 60000 * 59);
    this.value.voucherDateLimit = value;
    this.month = new Date(this.dateValue).toLocaleString('default', { month: 'long' });
    this.year = this.dateValue.getFullYear();
  }

  mounted() {
    if (this.value.config == null) {
      this.value.config = {};
    }
  }

  updatePath(path: any, darkmode: boolean) {
    console.log(darkmode);
    darkmode ? (this.value.logoDarkmode = path) : (this.value.logo = path);
  }
  selectMonth(option: any) {
    if (option) {
      this.dateValue.setMonth(option.value);
    }
    this.value.voucherDateLimit = this.dateValue.toString();
  }
  selectYear(option: any) {
    if (option) {
      this.dateValue.setFullYear(option.value);
    }
    this.value.voucherDateLimit = this.dateValue.toString();
  }

  testDownload() {
    // Call to the graphql mutation
    this.loading = true;
    this.$apollo
      .mutate({
        mutation: TEST_DOWNLOAD,
        // Parameters
        variables: {
          testDownloadForUrlProcessUrl: this.value.config.processUrl,
        },
      })
      .then((data) => {
        this.loading = false;
        this.result = true;
        if (
          data != null &&
          data.data != null &&
          data.data.testDownloadForUrl != null &&
          data.data.testDownloadForUrl.success
        ) {
          this.error = false;
          this.success = true;
        } else {
          this.error = true;
          this.success = false;
        }

        setTimeout(() => this.clearStates(), 10000);
      })
      .catch((error) => {
        this.loading = false;
        this.result = true;
        this.error = true;
        this.success = false;
        setTimeout(() => this.clearStates(), 10000);
      });
  }

  clearStates() {
    this.loading = false;
    this.result = false;
    this.error = false;
    this.success = false;
  }
}
