
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';

@Component({
    props: {
        settings: {
            type: Object,
        },
        content: {
            type: Object,
        },
    },
    components: {
        BaseCmsComponent,
    },
})
export default class VoucherField extends BaseCmsComponentClass {
   
}
