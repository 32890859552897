export default function getConfig(name: string, key: string = 'default'): any {
  const client = process.env.VUE_APP_CLIENT;

  const configs = require.context('@/config');
  const keys = configs.keys();

  if (keys.indexOf('./' + client + '/' + name) >= 0) {
    const value = configs('./' + client + '/' + name);
    return value[key] ?? value;
  }

  if (keys.indexOf('./' + name) >= 0) {
    const value = configs('./' + name);
    return value[key] ?? value;
  }

  return {};
}
