
import { Component } from 'vue-property-decorator';
import BrandEdit from '@/components/brands/BrandEdit.vue';
import { GET_PRODUCT_BRANDS_PAGINATION } from '@/graphlql/queries';
import TableBase from '../common/TableBase.vue';

@Component({
  components: {
    BrandEdit
  },
  apollo: {
    brands: {
      query: GET_PRODUCT_BRANDS_PAGINATION,
      manual: true,
      variables: {
        brandsPaginationOffset: 0,
        brandsPaginationLimit: 10
      },
      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            this.loading = data.loading;
            const tableData: any = [];
            for (const brand of data.data.brandsPagination.brands) {
              tableData.push(brand);
            }

            this.tableData = tableData;
            this.total = data.data.brandsPagination.count;
          }
        }
      }
    }
  }
})
export default class BrandsTable extends TableBase {
  columns = [
    {
      field: 'id',
      label: 'ID',
      width: '80',
      numeric: true
    },
    {
      field: 'name',
      label: 'Name'
    }
  ];

  loadAsyncData() {
    this.$apollo.queries.brands.refetch({
      brandsPaginationOffset: this.perPage * (this.page - 1),
      brandsPaginationLimit: this.perPage
    });
  }

  keys = ['page'];

  onPageChange(page: number) {
    super.onPageChange(page);
  }

  popstateEventAction() {
    super.popstateEventAction();
    this.loadAsyncData();
  }

  mounted() {
    super.mounted();
    this.loadAsyncData();
  }
}
