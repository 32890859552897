<template>
  <div class="emoji-select">
    <b-field label="Emoji">
      <div class="is-flex is-flex-wrap-wrap is-align-items-center flex-gap-2">
        <button
          class="emoji-button is-size-4 py-0 px-2"
          @click="copyEmoji(emoji)"
          v-for="(emoji, index) in emojis"
          :key="index"
        >
          {{ emoji }}
        </button>
      </div>
    </b-field>
  </div>
</template>

<script lang="js">
export default {
  name: 'EmojiSelect',
  data() {
    return {
      emojis: [
        '‼️', '✅', '💥', '🔥', '👀', '💳',
        '🚨', '💯', '🧨', '⏰', '😱', '🤑',
        '🥵']
    }
  },
  methods: {
    copyEmoji(emoji) {
      const el = document.createElement('textarea');
      el.value = emoji;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      el.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(el.value);
      document.body.removeChild(el);
    }
  }
};
</script>
<style>
.flex-gap-2 {
  gap: 0.5rem;
}
.emoji-button {
  border: 1px solid hsl(0, 0%, 86%);
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;
}
.emoji-button:active {
  box-shadow: 0 0 1px 0px #999;
}
</style>
