
import { EventBus } from '@/components/common/event/eventbus';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
  computed: {
    hasVersionsSlot() {
      return !!this.$slots.versions;
    },
  },
})
export default class BaseView extends Vue {
  path: any[] = [];
  colorFromScriptSetup = 'green';

  messageTitle: string = '';
  messageActive: boolean = false;
  messageBody: string = '';

  messageTimeout: any = null;
  duration: number = 5000;

  showMessage(message: { title: string; body: string }) {
    if (message != null && message.title != null && message.body != null) {
      this.messageActive = true;
      this.messageBody = message.body;
      this.messageTitle = message.title;

      if (this.messageTimeout != null) {
        window.clearTimeout(this.messageTimeout);
      }

      this.messageTimeout = window.setTimeout(() => {
        this.messageActive = false;
        this.messageTimeout = null;
      }, 3000);
    }
  }

  mounted() {
    EventBus.$on('show-message', this.showMessage);

    window.scrollTo(0, 0);
    this.$store.dispatch('navigationModule/fetchNavigation');
    this.$store.dispatch('navigationModule/setNavigationIndexes');

    const navigationPath = this.$store.getters['navigationModule/getNavigationElements'];

    for (const parent in navigationPath) {
      let found = false;
      for (const c in navigationPath[parent].children) {
        const child = navigationPath[parent].children[c];

        if (child.path == this.$route.path) {
          this.path.push(navigationPath[parent]);
          this.path.push(child);
          found = true;
        }
        if (found) {
          break;
        }
      }

      if (found) {
        break;
      }
    }
  }
}
