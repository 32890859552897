
import { Component, Vue } from 'vue-property-decorator';
import { cdnLink } from '@/main';
import {
  GET_BRANDMODELS,
  GET_BRANDS,
  GET_CLIENTS,
  GET_PRODUCTS_BY_ARTICLEID,
  GET_SHOPS,
  LIST_PRODUCTS,
} from '@/graphlql/queries';
import {
  ACTIVATE_PRODUCTS,
  ACTIVATE_PRODUCTS_BY_ARTICLE_ID,
  CREATE_BRANDMODEL,
  DEACTIVATE_PRODUCTS,
  DEACTIVATE_PRODUCTS_BY_ARTICLE_ID,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_BASE_DATA,
} from '@/graphlql/mutations';
import TableBase from '../common/TableBase.vue';
import { EventBus } from '../common/event/eventbus';
import * as helper from '@/helper/data-helper';

@Component({
  apollo: {
    brands: {
      query: GET_BRANDS,
      manual: true,

      result(data: any) {
        if (data.data != null) {
          let brands: any = [];
          if (!data.loading) {
            this.loading = data.loading;
            brands = data.data.brands;
          }
          this.brands = brands;
        }
      },
    },
    brandmodels: {
      query: GET_BRANDMODELS,
      manual: true,
      result(data: any) {
        if (data.data != null) {
          this.brandmodels = {};
          for (const entry of data.data.getBrandModels) {
            if (this.brandmodels[entry.brand.id] == undefined) {
              this.brandmodels[entry.brand.id] = [];
            }
            this.brandmodels[entry.brand.id] = this.brandmodels[entry.brand.id].concat(entry.model);
          }
        }
      },
    },
    otherClientProducts: {
      manual: true,
      fetchPolicy: 'no-cache',
      query: GET_PRODUCTS_BY_ARTICLEID,
      variables() {
        return {
          articleIds: [],
          client: 2,
        };
      },
      result(data: any) {
        if (data.data != null) {
          if (data.data.productsByArticleId != null) {
            for (const entry of data.data.productsByArticleId) {
              if (entry.baseScore == null) {
                entry.baseScore = 0;
              }
              Vue.set(this.otherClientProductsObject, entry.articleId, entry);
            }
          }
        }
      },
    },
    clients: {
      manual: true,
      fetchPolicy: 'no-cache',
      query: GET_CLIENTS,
      result(data: any) {
        if (data.data != null) {
          for (const client of data.data.clients) {
            this.clients = this.clients.concat(client);
          }
          this.clients = this.clients.concat({ name: 'Bei allen vorhanden', id: 0 });
        }
      },
    },
    shops: {
      query: GET_SHOPS,
      result(data: any) {
        if (data.data != null) {
          for (const shop of data.data.shops) {
            this.shopsMap[shop.id] = shop;
          }
          this.$forceUpdate();
        }
      },
    },

    products: {
      query: LIST_PRODUCTS,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          productsOrderBy: 'createdAt_DESC',
          productsActive: true,
          productsClient: 1,
          productsOffset: 0,
          productsLimit: 10,
          productsQuery: null,
          productsOnlyWithImages: false,
          productsOnlyWithAvailabilities: true,
          productsShop: -1,
        };
      },
      manual: true,
      result(data: any) {
        if (data.data != null) {
          const tableData: any = [];
          const articleIds = [];
          for (const product of data.data.products.products) {
            if (product.baseScore == null) {
              product.baseScore = 0;
            }
            tableData.push(product);
            articleIds.push(product.articleId);
          }

          this.tableData = tableData;
          this.total = data.data.products.count;
          this.loading = false;
          this.checkedRows = [];

          this.$apollo.queries.otherClientProducts.refetch({
            articleIds: articleIds,
            client: this.selectedClient == 1 ? 2 : 1,
          });
        }
      },
    },
  },
})
export default class ProductsTable extends TableBase {
  typesData = [];
  pageSizes = [10, 20, 50, 100, 200];
  query: string | null = null;
  shopsMap = {};
  shopsMapPS = {};
  shopsMapSNKR = {};
  checkedRows: any[] = [];
  selectedClient: number = 1;
  selectedBrand: number = -1;
  selectedShop: number = -1;
  cdn: string | undefined = cdnLink;
  onlyActive: boolean | null = false;
  onlyWithAvailabilities: boolean | null = true;
  onlyWithImages: boolean | null = true;
  clients = [];
  brands = [];
  otherClientProductsObject: any = {};
  loading = true;
  brandmodels: { [id: number]: string[] } = {};
  changedProducts = [];

  saveSuccess: boolean = false;
  saveError: boolean = false;
  saveResult: boolean = false;
  saveLoading: boolean = false;

  saveAllSuccess: boolean = false;
  saveAllError: boolean = false;
  saveAllResult: boolean = false;
  saveAllLoading: boolean = false;

  loadAsyncData() {
    this.loading = true;
    this.$apollo.queries.products.refetch({
      productsClient: this.selectedClient,
      productsBrand: this.selectedBrand,
      productsOffset: this.perPage * (this.page - 1),
      productsLimit: this.perPage,
      productsQuery: this.query,
      productsActive: this.onlyActive,
      productsOnlyWithImages: this.onlyWithImages,
      productsOnlyWithAvailabilities: this.onlyWithAvailabilities,
      productsShop: this.selectedShop,
    });
  }

  saveModel(model: string, brandId: number) {
    this.$apollo
      .mutate({
        // Query
        mutation: CREATE_BRANDMODEL,
        // Parameters
        variables: {
          input: {
            model: model,
            brandId: brandId,
          },
        },
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateProduct(x: any) {
    this.loading = true;
    for (const entry of this.tableData) {
      if (x === entry.id) {
        this.$apollo
          .mutate({
            // Query
            mutation: UPDATE_PRODUCT,
            // Parameters
            variables: {
              //see comment for cleantypedata method. updatedAt doesnt get send since it gets recalculated in the backend
              updateProductInput: helper.cleanTypeData(entry, ['__typename', 'updatedAt']),
            },
          })
          .then((data) => {
            console.log(data);
            this.$apollo
              .mutate({
                // Query
                mutation: UPDATE_PRODUCT_BASE_DATA,
                // Parameters
                variables: {
                  //see comment for cleantypedata method. updatedAt doesnt get send since it gets recalculated in the backend
                  updateProductBaseDataInput: helper.cleanTypeData(entry, ['__typename', 'updatedAt']),
                },
              })
              .then((data) => {
                this.loadAsyncData();
                console.log(data);
                this.loading = false;
              })
              .catch((error) => {
                console.log(error);
                this.loading = false;
              });
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    }
  }

  changeClient() {
    this.selectedShop = -1;
    this.refetchProduct();
  }

  updateDisplayModel(model: string, id: number) {
    for (const entry of this.tableData) {
      if (entry.id == id) {
        entry.displayModel = model;
        entry.title = entry.brand.name + ' ' + model;
      }
    }
  }

  updateProductOtherClient(id: string) {
    if (!this.otherClientProductsObject) {
      return;
    }
    this.loading = true;
    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_PRODUCT,
        // Parameters
        variables: {
          //see comment for cleantypedata method. updatedAt doesnt get send since it gets recalculated in the backend
          updateProductInput: helper.cleanTypeData(this.otherClientProductsObject[id], ['__typename', 'updatedAt']),
        },
      })
      .then((data) => {
        console.log(data);
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });
  }

  updateTableData(x: any) {
    for (const row of this.tableData) {
      if (row.id === x.id) {
        row.model = x.model;
      }
    }
    this.changedProducts = this.changedProducts.concat(x.id);
  }

  refetchProduct() {
    this.page = 1;
    this.loadAsyncData();
    this.putState();
  }

  //asc might be true for ascending or descending, the name might be wrong, either way it works.
  colorSort(a: { color: [{ name: string }] }, b: { color: [{ name: string }] }, asc: boolean) {
    if (asc) return a.color[0].name.toUpperCase() > b.color[0].name.toUpperCase();
    return a.color[0].name.toUpperCase() < b.color[0].name.toUpperCase();
  }

  categorySort(a: { category: [{ name: string }] }, b: { category: [{ name: string }] }, asc: string) {
    if (asc) {
      return a.category[0].name.toUpperCase() > b.category[0].name.toUpperCase();
    }
    return a.category[0].name.toUpperCase() < b.category[0].name.toUpperCase();
  }

  brandSort(a: { brand: { name: string } }, b: { brand: { name: string } }, asc: boolean) {
    if (asc) return a.brand.name.toUpperCase() > b.brand.name.toUpperCase();
    return a.brand.name.toUpperCase() < b.brand.name.toUpperCase();
  }

  activateProduct() {
    this.saveLoading = true;
    const productIds: number[] = [];

    for (const product of this.checkedRows) {
      if (product != null) {
        productIds.push(product.id);
      }
    }
    this.$apollo
      .mutate({
        // Query
        mutation: ACTIVATE_PRODUCTS,
        // Parameters
        variables: {
          activateProductsInput: productIds,
        },
      })
      .then((data) => {
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = false;
        this.saveSuccess = true;
        //reload data
        this.loadAsyncData();
        setTimeout(() => this.resetSaveButton(), 1000);
      })
      .catch((error) => {
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = true;
        this.saveSuccess = false;
        this.loading = false;
        setTimeout(() => this.resetSaveButton(), 1000);
      });
  }

  activateProductAllClient() {
    this.saveAllLoading = true;
    const productIds: number[] = [];

    for (const product of this.checkedRows) {
      if (product != null) {
        productIds.push(product.articleId);
      }
    }
    this.$apollo
      .mutate({
        // Query
        mutation: ACTIVATE_PRODUCTS_BY_ARTICLE_ID,
        // Parameters
        variables: {
          activateProductsInput: productIds,
        },
      })
      .then((data) => {
        this.saveAllLoading = false;
        this.saveAllResult = true;
        this.saveAllError = false;
        this.saveAllSuccess = true;
        //reload data
        this.loadAsyncData();
        setTimeout(() => this.resetSaveAllButton(), 1000);
      })
      .catch((error) => {
        this.saveAllLoading = false;
        this.saveAllResult = true;
        this.saveAllError = true;
        this.saveAllSuccess = false;
        this.loading = false;
        setTimeout(() => this.resetSaveAllButton(), 1000);
      });
  }

  deactivateProduct() {
    this.saveLoading = true;
    const productIds: number[] = [];

    for (const product of this.checkedRows) {
      if (product != null) {
        productIds.push(product.id);
      }
    }
    this.$apollo
      .mutate({
        // Query
        mutation: DEACTIVATE_PRODUCTS,
        // Parameters
        variables: {
          activateProductsInput: productIds,
        },
      })
      .then((data) => {
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = false;
        this.saveSuccess = true;
        //reload data
        this.loadAsyncData();
        setTimeout(() => this.resetSaveButton(), 1000);
      })
      .catch((error) => {
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = true;
        this.saveSuccess = false;
        this.loading = false;
        setTimeout(() => this.resetSaveButton(), 1000);
      });
  }

  deactivateProductAllClient() {
    this.saveAllLoading = true;
    const productIds: number[] = [];

    for (const product of this.checkedRows) {
      if (product != null) {
        productIds.push(product.articleId);
      }
    }
    this.$apollo
      .mutate({
        // Query
        mutation: DEACTIVATE_PRODUCTS_BY_ARTICLE_ID,
        // Parameters
        variables: {
          activateProductsInput: productIds,
        },
      })
      .then((data) => {
        this.saveAllLoading = false;
        this.saveAllResult = true;
        this.saveAllError = false;
        this.saveAllSuccess = true;
        //reload data
        this.loadAsyncData();
        setTimeout(() => this.resetSaveAllButton(), 1000);
      })
      .catch((error) => {
        this.saveAllLoading = false;
        this.saveAllResult = true;
        this.saveAllError = true;
        this.saveAllSuccess = false;
        this.loading = false;
        setTimeout(() => this.resetSaveAllButton(), 1000);
      });
  }

  resetSaveButton() {
    this.saveSuccess = false;
    this.saveError = false;
    this.saveResult = false;
    this.saveLoading = false;
  }

  resetSaveAllButton() {
    this.saveAllSuccess = false;
    this.saveAllError = false;
    this.saveAllResult = false;
    this.saveAllLoading = false;
  }

  set selectActive(value: string) {
    if (value === 'Aktiv') this.onlyActive = true;
    else if (value === 'Inaktiv') this.onlyActive = false;
    else this.onlyActive = null;
    this.loadAsyncData();
  }

  get selectActive() {
    if (this.onlyActive == true) return 'Aktiv';
    else if (this.onlyActive == false) return 'Inaktiv';
    else return 'Beides';
  }

  perPageDefault: number = 10;

  keys = ['page', 'query', 'client', 'perPage'];

  onPageChange(page: number) {
    super.onPageChange(page);
  }

  popstateEventAction() {
    super.popstateEventAction();
    this.loadAsyncData();
  }

  /***
   * this is the event handler
   */
  saveMarked() {
    this.activateProduct();
  }
  /***
   * this is the event handler
   */
  saveMarkedAllClient() {
    this.activateProductAllClient();
  }

  saveMarkedDeactivate() {
    this.deactivateProduct();
  }
  /***
   * this is the event handler
   */
  saveMarkedDeactivateAllClient() {
    this.deactivateProductAllClient();
  }

  mounted() {
    super.mounted();
    this.resetSaveButton();
    this.loadAsyncData();

    EventBus.$on('save-marked-products', this.saveMarked);
    EventBus.$on('save-marked-products-all', this.saveMarkedAllClient);
    EventBus.$on('save-marked-products-deactivate', this.saveMarkedDeactivate);
    EventBus.$on('save-marked-products-deactivate-all', this.saveMarkedDeactivateAllClient);
  }
}
