
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import clients from '../../graphClient';
import { CMS_DELETE_DEAL, CMS_MODIFY_DOUBLE_DEAL, UPDATE_USER_USAGE, CREATE_USER_USAGE } from '@/graphlql/mutations';
import DealDetail from '@/components/cms/DealDetail.vue';
import CMSView from './CMSView.vue';
import { CMS_GET_DEAL, CMS_GET_TEMPLATES, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import { DragInterface } from '@/interfaces/cms/drag.interface';
import * as helper from '@/helper/data-helper';
import { previewLink, previewLinkPS, previewLinkSNKR } from '@/main';
import router from '@/router';
import { EventBus } from '@/components/common/event/eventbus';
import OpenAiModal from '@/components/openai/OpenAiModal.vue';
import VersionsTable from '@/components/versions/VersionsTable.vue';

@Component({
  components: {
    DealDetail,
    BaseView,
    CMSView,
    VersionsTable,
  },
  apollo: {
    getTemplates: {
      query: CMS_GET_TEMPLATES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        const clientA = this.$route.params.clientA;
        const client = clientA.substring(0, clientA.indexOf('-'));
        return {
          client: client,
          type: 'deal',
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getTemplates != null) {
          this.templates = data.data.getTemplates;
        }
      },
    },
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.dealId,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.dealId;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.dealId !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.dealId !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.dealId !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.currentUsage.dealId === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
    getDeal: {
      query: CMS_GET_DEAL,
      client: 'clientB',
      variables: {
        getDealId: '0',
      },
      skip() {
        return this.skipQueryDeal;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getDeal != null) {
          if (data.data.getDeal.client != null) {
            this.syncDeals = false;
            if (data.data.getDeal.client.indexOf('app') > -1) {
              this.dealApp = this.fixDates(data.data.getDeal);
              this.dealApp.loaded = true;
              helper.applyImageIds(this.dealApp);
              this.cmsClient = 'app';
              this.$store.dispatch('cmsItemsModule/setItems', this.dealApp.content.dynamicContent);
              this.$store.dispatch('cmsItemsModule/setClient', data.data.getDeal.client);

              if (this.dealApp.sibling != null && this.dealApp.sibling._id != null && !this.dealWeb.loaded) {
                this.dealClient = 'both';
                this.$apollo.queries.getDeal.refetch({
                  getDealId: this.dealApp.sibling._id,
                });
              } else if (!this.dealWeb.loaded) {
                this.dealClient = 'app';
              }
            } else {
              this.dealWeb = this.fixDates(data.data.getDeal);
              this.dealWeb.loaded = true;

              helper.applyImageIds(this.dealWeb);
              if (this.dealWeb.sibling == null) {
                this.dealClient = 'web';
                this.cmsClient = 'web';
                this.$store.dispatch('cmsItemsModule/setItems', this.dealWeb.content.dynamicContent);
                this.$store.dispatch('cmsItemsModule/setClient', data.data.getDeal.client);
              } else if (this.dealWeb.sibling != null && this.dealWeb.sibling._id != null && !this.dealApp.loaded) {
                this.dealClient = 'both';
                this.$apollo.queries.getDeal.refetch({
                  getDealId: this.dealWeb.sibling._id,
                });
              }
            }
          }

          this.loaded = true;
          this.changed = false;
          this.siteTitle = this.dealLabel + ' (' + data.data.getDeal.headline + ') speichern';
          //   this.setItems(this.page.content.dynamicContent);
        } else {
          this.$store.dispatch('cmsItemsModule/setItems', []);
          this.$store.dispatch('cmsItemsModule/setClient', '');
        }
      },
      error() {
        this.$store.dispatch('cmsItemsModule/setItems', []);
        this.$store.dispatch('cmsItemsModule/setClient', '');
      },
    },
  },
  watch: {
    dealClient(newClient, oldClient) {
      const self = this as DealView;

      if (newClient == 'both' && oldClient != 'both') {
        if (helper.isEmpty(self.dealWeb)) {
          self.syncDealData(self.dealApp);
        }
      }

      if (newClient != 'both') {
        self.cmsClient = newClient;
      }
    },
    dealApp: {
      handler(newDeal, oldDeal) {
        const self = this as DealView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
        if (self.syncDeals) {
          self.syncDealData(newDeal);
        }
      },
      deep: true,
    },

    dealWeb: {
      handler() {
        const self = this as DealView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }

        self.lastChange = new Date();
      },
      deep: true,
    },
    cmsClient(newClient, oldClient) {
      if (newClient != null && newClient != oldClient) {
        const self = this as DealView;

        if (newClient.indexOf('app') > -1) {
          this.$store.dispatch('cmsItemsModule/setItems', self.dealApp.content.dynamicContent);
          this.$store.dispatch('cmsItemsModule/setClient', self.dealApp.client);
        } else if (newClient.indexOf('web') > -1) {
          this.$store.dispatch('cmsItemsModule/setClient', self.dealWeb.client);
          this.$store.dispatch('cmsItemsModule/setItems', self.dealWeb.content.dynamicContent);
        }
      }
    },
    $route(to, from) {
      const self = this as DealView;
      self.dealType = this.$route.params.dealType;
      self.refetch();
    },
  },

  beforeRouteLeave(to, from, next) {
    const self = this as DealView;
    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class DealView extends Vue {
  siteTitle: string = '';
  dealType: string = 'single';
  dealLabel: string = 'Deal';
  lastChange = new Date();
  lastUpdateChange: any = null;

  previewUrl: string | undefined = previewLink;
  previewUrlPS: string | undefined = previewLinkPS;
  previewUrlSNKR: string | undefined = previewLinkSNKR;

  openAIEnabled: boolean = process.env.VUE_APP_OPENAI_ENABLED == 'true';

  changed: boolean = false;
  loaded: boolean = false;
  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'deal',
    lastTimeEdited: new Date(),
  };
  skipQueryDeal: boolean = true;
  templates: any[] = [];
  dealClients: any[] = [
    {
      type: 'app',
      label: 'App',
      icon: 'mobile-screen',
    },
    {
      type: 'web',
      label: 'Web',
      icon: 'display',
    },
    {
      type: 'both',
      label: 'Beide',
      icon: 'laptop-mobile',
    },
  ];

  cmsVersion: any[] = [
    {
      type: 'app',
      label: 'App',
      icon: 'mobile-screen',
    },
    {
      type: 'web',
      label: 'Web',
      icon: 'display',
    },
  ];

  // dealfam only uses app so the starting dealclient is "app"
  dealClient: string = this.$route.params.clientA.includes('dealfam') ? 'app' : 'both';
  cmsClient: string = 'app';
  syncDeals: boolean = false;
  templateTabIndex: number = 0;

  appValid = false;
  webValid = false;
  isUpdating = false;

  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';
  dealApp: any = {
    images: [],
    headline: '',
    subline: '',
    path: '',
    bullet1Key: '',
    bullet1Val: '',
    bullet2Val: '',
    bullet3Key: '',
    bullet3Val: '',
    bullet4Key: '',
    bullet4Val: '',
    buttonLabel: 'ZUM SHOP',
    price: null,
    priceText: {},
    strikePrice: '',
    startAt: null,
    endsAt: null,
    validTill: null,
    voucherValidUntil: null,
    isActive: false,
    tags: [],
    content: {
      dynamicContent: [],
    },
    links: [],
  };
  dealWeb: any = {
    images: [],
    headline: '',
    subline: '',
    path: '',
    bullet1Key: '',
    bullet1Val: '',
    bullet2Val: '',
    bullet3Key: '',
    bullet3Val: '',
    bullet4Key: '',
    bullet4Val: '',
    buttonLabel: 'ZUM SHOP',
    price: null,
    priceText: {},
    strikePrice: '',
    startAt: null,
    endsAt: null,
    validTill: null,
    voucherValidUntil: null,
    isActive: false,
    tags: [],
    content: {
      dynamicContent: [],
    },
    links: [],
  };

  syncDealData(newDeal: any) {
    console.log('syncDealData: NewDeal', newDeal);
    EventBus.$emit('price-template-changed');
    for (const key in newDeal) {
      if (key != 'images' && key != 'voucherFile') {
        if (Array.isArray(newDeal[key])) {
          if (key == 'links') {
            // Special Logic: Tamo doesn't want to sync the url inside the link component.
            // So when using drag&drop in the link component, the url won't be dragged
            this.dealWeb[key] = helper.cleanTypeArray(newDeal[key], ['url'], this.dealWeb[key], ['url']);
          } else {
            this.dealWeb[key] = helper.cleanTypeArray(newDeal[key], []);
          }
        } else {
          if (key != '_id' && key != 'client') {
            if (key == 'content') {
              //do not sync
              //  self.dealWeb[key] = helper.cleanTypeData(newDeal[key], []);
            } else {
              this.dealWeb[key] = newDeal[key];
            }
          }
        }
      }
    }
  }

  openGeneratorModal() {
    this.$buefy.modal.open({
      parent: this,
      component: OpenAiModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        title: this.dealApp?.headline != null ? this.dealApp?.headline : this.dealWeb?.headline,
      },
    });
  }

  fixDates(deal: any) {
    if (deal['startAt'] != null) {
      deal['startAt'] = new Date(deal['startAt']);
    }

    if (deal['endsAt'] != null) {
      deal['endsAt'] = new Date(deal['endsAt']);
    }

    if (deal['validTill'] != null) {
      deal['validTill'] = new Date(deal['validTill']);
    }
    if (deal['voucherValidUntil'] != null) {
      deal['voucherValidUntil'] = new Date(deal['voucherValidUntil']);
    }

    return deal;
  }

  refetch() {
    if (this.$route.params.dealId == 'create') {
      this.siteTitle = 'Deal erstellen';
      this.syncDeals = true;
      this.$store.dispatch('cmsItemsModule/setItems', this.dealApp.content.dynamicContent);
      this.$store.dispatch('cmsItemsModule/setClient', this.$route.params.clientA);
    } else if (this.$route.params.dealId != null) {
      this.skipQueryDeal = false;
      this.$apollo.queries.getDeal.refetch({
        getDealId: this.$route.params.dealId,
      });
    }
  }

  openPreview() {
    const client = this.$route.params.clientA;

    if (client.indexOf('ps') > -1) {
      window.open(this.previewUrlPS + 'preview', '_blank');
    } else if (client.indexOf('snkr') > -1) {
      window.open(this.previewUrlSNKR + 'preview', '_blank');
    } else {
      window.open(this.previewUrl + 'preview', '_blank');
    }
  }

  mounted() {
    this.dealType = this.$route.params.dealType;
    this.refetch();

    window.addEventListener('message', this.updatePreviewData, false);

    EventBus.$on('save-deals-Web', this.webIsValid);
    EventBus.$on('save-deals-App', this.appIsValid);
  }

  unmounted() {
    window.removeEventListener('message', this.updatePreviewData);

    EventBus.$off('save-deals-Web', this.webIsValid);
    EventBus.$off('save-deals-App', this.appIsValid);
  }

  beforeDestroy() {
    EventBus.$off('save-deals-Web', this.webIsValid);
    EventBus.$off('save-deals-App', this.appIsValid);
  }

  updatePreviewData(event: any) {
    if (event.data != null && event.data.message == 'startsync') {
      this.lastUpdateChange = new Date();
      event.source.postMessage(
        { message: 'sentdata', getDealByPath: this.getPreviewDealData(this.dealWeb) },
        event.origin
      );
    }

    if (event.data != null && event.data.message == 'syncdata') {
      if (this.lastUpdateChange == null || this.lastChange.getTime() > this.lastUpdateChange.getTime()) {
        this.lastUpdateChange = new Date();
        event.source.postMessage(
          { message: 'sentdata', getDealByPath: this.getPreviewDealData(this.dealWeb) },
          event.origin
        );
      }
    }
  }
  async userLeave() {
    this.currentUsage.inUse = false;

    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  async deleteDeal() {
    const content = prompt('Bitte "löschen" zum bestätigen eingeben');
    if (content === 'löschen') {
      if (this.dealApp?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_DEAL,
          variables: {
            deleteDealId: this.dealApp._id,
          },
        });
      }

      if (this.dealWeb?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_DEAL,
          variables: {
            deleteDealId: this.dealWeb._id,
          },
        });
      }
      // Surpress unsaved change prompt, a bit hacky but works
      this.changed = false;
      router.push({ name: 'DealsView' });
    }
  }

  async saveDealHandler(whatsnew: boolean) {
    if (this.isUpdating) {
      this.$buefy.notification.open({
        message: `Bereits im Speichervorgang`,
        duration: 5000,
        type: 'is-warning ',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });

      setTimeout(() => {
        this.isUpdating = false;
      }, 10000);

      return;
    }

    this.isUpdating = true;

    const dealApp = this.getDealData(this.dealApp);

    if (dealApp.client == null || dealApp.client.length == 0) {
      dealApp.client = this.$route.params.clientA;
    }

    const dealWeb = this.getDealData(this.dealWeb);
    if (dealWeb.client == null || dealWeb.client.length == 0) {
      dealWeb.client = this.$route.params.clientB;
    }

    clients.clientB
      .mutate({
        mutation: CMS_MODIFY_DOUBLE_DEAL,
        variables: {
          deals: {
            dealApp:
              this.dealClient == 'both' || this.dealClient == 'app'
                ? helper.cleanTypeData(dealApp, ['__typename', 'updatedAt', 'createdAt', 'sibling'])
                : null,
            dealWeb:
              this.dealClient == 'both' || this.dealClient == 'web'
                ? helper.cleanTypeData(dealWeb, ['__typename', 'updatedAt', 'createdAt', 'sibling'])
                : null,
          },
          updateWhatsnewDate: whatsnew ? true : false,
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.modifyDoubleDeal) {
          if (this.$route.params.dealId == 'create') {
            this.$router.push({
              path: `/cms/deals/${this.$route.params.dealType}/${this.$route.params.clientA}/${
                this.$route.params.clientB
              }/${
                data.data.modifyDoubleDeal.dealApp
                  ? data.data.modifyDoubleDeal.dealApp._id
                  : data.data.modifyDoubleDeal.dealWeb._id
              }`,
            });
          }

          if (data.data.modifyDoubleDeal.dealApp) {
            this.dealApp = this.fixDates(data.data.modifyDoubleDeal.dealApp);
            this.dealApp.loaded = true;
            helper.applyImageIds(this.dealApp);

            if (!data.data.modifyDoubleDeal.dealWeb || this.cmsClient != 'web') {
              this.$store.dispatch('cmsItemsModule/setItems', this.dealApp.content.dynamicContent);
              this.$store.dispatch('cmsItemsModule/setClient', this.dealApp.client);
            }
          }

          if (data.data.modifyDoubleDeal.dealWeb) {
            this.dealWeb = this.fixDates(data.data.modifyDoubleDeal.dealWeb);

            this.dealWeb.loaded = true;
            helper.applyImageIds(this.dealWeb);

            if (!data.data.modifyDoubleDeal.dealApp || this.cmsClient == 'web') {
              this.$store.dispatch('cmsItemsModule/setItems', this.dealWeb.content.dynamicContent);
              this.$store.dispatch('cmsItemsModule/setClient', this.dealWeb.client);
            }
          }

          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }

        this.isUpdating = false;
        this.changed = false;
      })
      .catch((error) => {
        this.isUpdating = false;
        this.$buefy.notification.open({
          message: `Fehler beim Anlegen`,
          duration: 5000,
          type: 'is-danger',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      });
  }

  validateAndSave(whatsnew: boolean) {
    if (
      !(
        (this.dealClient == 'app' && this.dealApp.links.length > 0) ||
        (this.dealClient == 'web' && this.dealWeb.links.length > 0) ||
        (this.dealClient == 'both' && this.dealApp.links.length > 0 && this.dealWeb.links.length > 0)
      )
    ) {
      this.$buefy.notification.open({
        message: `Jeweils ein Link benötigt`,
        duration: 5000,
        type: 'is-danger',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });
      return;
    }

    let linksEmpty = false;
    for (const link of this.dealApp.links) {
      if (linksEmpty || this.dealClient == 'web') {
        break;
      }
      linksEmpty = link['url'] == '';
    }
    for (const link of this.dealWeb.links) {
      if (linksEmpty || this.dealClient == 'app') {
        break;
      }
      linksEmpty = link['url'] == '';
    }

    if (linksEmpty) {
      this.$buefy.notification.open({
        message: `Links dürfen nicht leer sein`,
        duration: 5000,
        type: 'is-danger',
        pauseOnHover: true,
        position: 'is-bottom-right',
      });
      return;
    }
    if (whatsnew) EventBus.$emit('validate-and-save-deals', 'whatsnew');
    else EventBus.$emit('validate-and-save-deals');
  }

  allValid(whatsnew: boolean) {
    if (this.webValid && this.appValid) {
      this.webValid = false;
      this.appValid = false;
      this.saveDealHandler(whatsnew);
    } else if (this.webValid && this.dealClient == 'web') {
      this.webValid = false;
      this.appValid = false;
      this.saveDealHandler(whatsnew);
    } else if (this.appValid && this.dealClient == 'app') {
      this.webValid = false;
      this.appValid = false;
      this.saveDealHandler(whatsnew);
    }
  }

  appIsValid(whatsnew: boolean) {
    this.appValid = true;
    this.allValid(whatsnew);
  }

  webIsValid(whatsnew: boolean) {
    this.webValid = true;
    this.allValid(whatsnew);
  }

  getDealData(dealData: any) {
    const deal = JSON.parse(
      JSON.stringify(helper.cleanTypeData(dealData, ['__typename', 'updatedAt', 'createdAt', 'images']))
    );

    delete deal['loaded'];

    deal['images'] = [];
    deal['price'] = parseFloat(deal['price']);
    deal['strikePrice'] = parseFloat(deal['strikePrice']);
    deal.dealType = this.dealType;

    if (dealData['images'] != null && dealData['images'].length > 0) {
      for (const imageObj of dealData.images) {
        deal.images.push(imageObj._id);
      }
    }

    if (deal['links'] != null && deal['links'].length > 0) {
      for (const linkObj of deal.links) {
        delete linkObj['id'];
      }
    }

    if (dealData['startAt']) {
      deal['startAt'] = dealData['startAt'].toISOString();
    }

    if (dealData['endsAt']) {
      deal['endsAt'] = dealData['endsAt'].toISOString();
    }

    if (dealData['validTill']) {
      deal['validTill'] = dealData['validTill'].toISOString();
    }
    if (dealData['voucherValidUntil']) {
      deal['voucherValidUntil'] = dealData['voucherValidUntil'].toISOString();
    }

    if (deal['path']) {
      const lastChar = deal['path'].charAt(deal['path'].length - 1);
      if (lastChar !== '/') {
        deal['path'] = deal['path'] + '/';
        dealData['path'] = dealData['path'] + '/';
      }
    }

    return deal;
  }

  getPreviewDealData(dealData: any) {
    const deal = JSON.parse(
      JSON.stringify(helper.cleanTypeData(dealData, ['__typename', 'updatedAt', 'createdAt', 'images']))
    );

    delete deal['loaded'];

    deal['images'] = [];
    deal['price'] = parseFloat(deal['price']);
    deal['strikePrice'] = parseFloat(deal['strikePrice']);
    deal.dealType = this.dealType;

    if (dealData['images'] != null && dealData['images'].length > 0) {
      for (const imageObj of dealData.images) {
        deal.images.push(imageObj);
      }
    }

    if (deal['links'] != null && deal['links'].length > 0) {
      for (const linkObj of deal.links) {
        delete linkObj['id'];
      }
    }

    if (dealData['startAt']) {
      deal['startAt'] = dealData['startAt'].toISOString();
    }

    if (dealData['endsAt']) {
      deal['endsAt'] = dealData['endsAt'].toISOString();
    }

    if (dealData['validTill']) {
      deal['validTill'] = dealData['validTill'].toISOString();
    }

    if (dealData['voucherValidUntil']) {
      deal['voucherValidUntil'] = dealData['voucherValidUntil'].toISOString();
    }

    if (deal['path']) {
      const lastChar = deal['path'].charAt(deal['path'].length - 1);
      if (lastChar !== '/') {
        deal['path'] = deal['path'] + '/';
        dealData['path'] = dealData['path'] + '/';
      }
    }

    return deal;
  }

  addTemplateHandler(template: any, type: string) {
    switch (type) {
      case 'replace':
        if (this.cmsClient == 'app') {
          this.dealApp.content = JSON.parse(JSON.stringify(template.content));
        } else if (this.cmsClient == 'web') {
          this.dealWeb.content = JSON.parse(JSON.stringify(template.content));
        }
        break;
      case 'add':
        if (this.cmsClient == 'app') {
          let id = this.getNextId(this.dealApp.content.dynamicContent);
          for (const item of template.content.dynamicContent) {
            const copy = JSON.parse(JSON.stringify(item));
            copy.id = ++id;
            this.dealApp.content.dynamicContent.push(copy);
          }
        } else if (this.cmsClient == 'web') {
          let id = this.getNextId(this.dealWeb.content.dynamicContent);
          for (const item of template.content.dynamicContent) {
            const copy = JSON.parse(JSON.stringify(item));
            copy.id = ++id;
            this.dealWeb.content.dynamicContent.push(copy);
          }
        }
        break;
    }

    if (this.cmsClient == 'app') {
      this.$store.dispatch('cmsItemsModule/setItems', this.dealApp.content.dynamicContent);
      this.templateTabIndex = 0;
    } else if (this.cmsClient == 'web') {
      this.$store.dispatch('cmsItemsModule/setItems', this.dealWeb.content.dynamicContent);
      this.templateTabIndex = 0;
    }
  }

  getNextId(items: DragInterface[]) {
    let max = this.resolveMaxId(items);
    return ++max;
  }

  resolveMaxId(items: DragInterface[]) {
    if (items == null || items.length == 0) {
      return 0;
    }

    let max = 0;

    for (const element of items) {
      if (element.id > max) {
        max = element.id;
      }

      if (element.items != null && element.items.length > 0) {
        const temp = this.resolveMaxId(element.items);
        if (temp > max) {
          max = temp;
        }
      }
    }

    return max;
  }
}
