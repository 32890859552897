
import Vue from 'vue';
import Component from 'vue-class-component';

//import { GET_BRANDMODELS, GET_BRANDS } from '@/graphlql/queries';
import { GET_BRANDMODELS, GET_BRANDS } from '@/graphlql/queries';
import { UPDATE_BRANDMODEL, CREATE_BRANDMODEL, DELETE_BRANDMODEL } from '@/graphlql/mutations';
import TableBase from '@/components/common/TableBase.vue';
import BaseView from '../common/BaseView.vue';

@Component({
  components: { BaseView },
  apollo: {
    brandmodels: {
      query: GET_BRANDMODELS,
      manual: true,

      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            this.loading = data.loading;
            const tableData: any = [];
            this.tableData = data.data.getBrandModels;
            for (const brandmodel of data.data.getBrandModels) {
              const simpleBrandModel = {
                id: brandmodel.id,
                model: brandmodel.model,
                brand: brandmodel.brand,
              };

              tableData.push(simpleBrandModel);
            }
            this.tableData = tableData;
          }
        }
      },
    },

    brands: {
      query: GET_BRANDS,
      manual: true,

      result(data: any) {
        if (data.data != null) {
          let brands: any = [];
          if (!data.loading) {
            this.loading = data.loading;
            brands = data.data.brands;
          }
          this.brands = brands;
        }
      },
    },
  },
})
export default class BrandModelsView extends TableBase {
  saveSuccess: boolean = false;
  saveError: boolean = false;
  saveResult: boolean = false;
  saveLoading: boolean = false;

  brands: any[] = [];
  //chosenbrand = '-1';
  currentBrandModels: any[] = [];
  deleted: number[] = [];
  currentbrand = { name: 'invalid', id: -1 };

  async loadAsyncData() {
    await this.$apollo.queries.brandmodels.refetch();
    await this.$apollo.queries.brands.refetch();
  }

  mounted() {
    super.mounted();
    this.loadAsyncData();
  }

  tenNewBrandModel() {
    for (let i = 0; i < 10; i++) {
      this.newBrandModel();
    }
  }

  newBrandModel() {
    if (this.currentbrand.id != -1) {
      const x = { id: -1, model: '', brand: this.getBrandById(this.currentbrand.id) };
      this.tableData = this.tableData.concat(x);
      this.currentBrandModels = this.currentBrandModels.concat(x);
      this.perPage = this.perPage + 1;
    }
  }

  resetSaveButton() {
    this.saveSuccess = false;
    this.saveError = false;
    this.saveResult = false;
    this.saveLoading = false;
  }

  async refresh() {
    await this.loadAsyncData();
    const newBrandModels = [];
    for (const brandModel of this.tableData) {
      if (brandModel.brand.id === this.currentbrand.id) {
        newBrandModels.push(brandModel);
      }
    }
    this.currentBrandModels = newBrandModels;
    this.perPage = this.currentBrandModels.length;
  }

  async save() {
    this.saveLoading = true;
    for (const brandmodel of this.tableData) {
      if (brandmodel.id != -1 && brandmodel.model != '') {
        await this.$apollo
          .mutate({
            // Query
            mutation: UPDATE_BRANDMODEL,
            // Parameters
            variables: {
              input: {
                id: brandmodel.id,
                model: brandmodel.model,
                brandId: brandmodel.brand.id,
              },
            },
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (brandmodel.id === -1 && brandmodel.model != '') {
        await this.$apollo
          .mutate({
            // Query
            mutation: CREATE_BRANDMODEL,
            // Parameters
            variables: {
              input: {
                model: brandmodel.model,
                brandId: brandmodel.brand.id,
              },
            },
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    for (const id of this.deleted) {
      await this.$apollo
        .mutate({
          // Query
          mutation: DELETE_BRANDMODEL,
          // Parameters
          variables: {
            id: id,
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    await this.refresh();
    this.saveLoading = false;
    this.saveResult = true;
    this.saveError = false;
    this.saveSuccess = true;
    setTimeout(() => this.resetSaveButton(), 1000);

    /*const newBrands = [];
      for (const brandModel of this.tableData) {
        if (brandModel.brand.id === value.id) {
          newBrands.push(brandModel);
        }
      }
      this.currentBrandModels = newBrands;*/

    // if brandmodel.id === -1
    // mutate createbrandmodel
    // else
    // mutate updatebrandmodel
    // for each brandmodelid in deletedbrandmodels
    // mutate delete bandmodelid
  }

  set chosenbrand(value: any) {
    this.refresh();
    this.currentbrand = this.getBrandById(value.id);
  }

  get chosenbrand() {
    return this.currentbrand;
  }

  deleteBrandModel(brandModelId: number) {
    if (brandModelId != -1) {
      this.deleted = this.deleted.concat(brandModelId);
    }
    const newBrandModels: any[] = [];
    const newcurrentBrandModels: any[] = [];
    for (const brandModel of this.tableData) {
      if (brandModel.id != brandModelId) {
        newBrandModels.push(brandModel);
      }
    }
    for (const brandModel of this.currentBrandModels) {
      if (brandModel.id != brandModelId) {
        newcurrentBrandModels.push(brandModel);
      }
    }
    this.tableData = newBrandModels;
    this.currentBrandModels = newcurrentBrandModels;
  }

  getBrandNameById(id: number) {
    for (const brand of this.brands) {
      if (brand.id === id) {
        return brand.name;
      }
    }
    return '';
  }

  getBrandById(id: number) {
    for (const brand of this.brands) {
      //console.log(brand.id, id);
      if (brand.id === id) {
        return brand;
      }
    }
    return undefined;
  }
}
