
import { Component, Vue, Watch } from 'vue-property-decorator';
import ColorEdit from '@/components/colors/ColorEdit.vue';
import { GET_PRODUCT_COLORS } from '@/graphlql/queries';
import TableBase from '../common/TableBase.vue';

@Component({
  components: {
    ColorEdit,
  },
  apollo: {
    colors: {
      query: GET_PRODUCT_COLORS,
      manual: true,
      variables: {
        colorsPaginationOffset: 0,
        colorsPaginationLimit: 10,
      },
      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            this.loading = data.loading;
            const tableData: any = [];
            for (const color of data.data.colorsPagination.colors) {
              tableData.push(color);
            }

            this.tableData = tableData;
            this.total = data.data.colorsPagination.count;
          }
        }
      },
    },
  },
})
export default class ColorsTable extends TableBase {
  columns = [
    {
      field: 'id',
      label: 'ID',
      width: '80',
      numeric: true,
    },
    {
      field: 'name',
      label: 'Name',
    },
  ];
  selected: any = null;


  loadAsyncData() {
    this.$apollo.queries.colors.refetch({
      colorsPaginationOffset: this.perPage * (this.page - 1),
      colorsPaginationLimit: this.perPage,
    });
  }

  keys = [
    "page"
  ]

  reloadColors(){
    this.mounted();
  }

  onPageChange(page: number) {
    super.onPageChange(page);
  }

  popstateEventAction() {
    super.popstateEventAction();
    this.loadAsyncData();
  }

  mounted() {
    super.mounted();
    this.loadAsyncData();
  }
}
