
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import TemplatesTable from '@/components/cms/TemplatesTable.vue';
import { CMS_GET_TEMPLATES } from '@/graphlql/queries';
import { cmsLink } from '@/main';
import clients from '@/graphClient';
import { CMS_CREATE_TEMPLATE, CMS_DELETE_TEMPLATE, CMS_UPDATE_TEMPLATE } from '@/graphlql/mutations';
import * as helper from '@/helper/data-helper';

@Component({
  components: {
    TemplatesTable,
    BaseView,
  },
  apollo: {
    getTemplates: {
      query: CMS_GET_TEMPLATES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          client: this.$route.params.client,
          type: this.$route.params.type,
        };
      },
      result(data) {
        if (data != null && data.data != null && data.data.getTemplates != null) {
          this.tableData = data.data.getTemplates;
        }
      },
    },
  },
})
export default class StringTemplatesView extends Vue {
  tableData: any[] = [];

  saveTemplate(template: any) {
    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_TEMPLATE,
        variables: {
          template: helper.cleanTypeData(template, ['__typename', 'updatedAt', 'createdAt']),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.updateTemplate != null && data.data.updateTemplate._id) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  createTemplateHandler() {
    clients.clientB
      .mutate({
        mutation: CMS_CREATE_TEMPLATE,
        variables: {
          template: {
            client: this.$route.params.client,
            content: { sublineTemplate: '', detailTemplate: '', sublineAppTemplate: '', detailAppTemplate: '' },
            title: '',
            type: this.$route.params.type,
          },
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.createTemplate != null && data.data.createTemplate._id) {
          this.$buefy.notification.open({
            message: `Erfolgreich angelegt`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }

        this.$apollo.queries.getTemplates.refetch();
      });
  }

  clickTable(event: any) {
    if (event._id != null) {
      this.$router.push({
        path: `/cms/templates/${this.$route.params.client}/${event._id}`,
      });
    }
  }

  deleteHandler(id: string) {
    clients.clientB
      .mutate({
        mutation: CMS_DELETE_TEMPLATE,
        variables: {
          deleteTemplateId: id,
        },
      })
      .then((data) => {
        console.log(data.data);
        if (data != null && data.data != null && data.data.deleteTemplate != null && data.data.deleteTemplate) {
          this.$buefy.notification.open({
            message: `Erfolgreich gelöscht`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }

        this.$apollo.queries.getTemplates.refetch();
      });
  }
}
