
import { Component, Vue } from 'vue-property-decorator';
import { UPDATE_PRODUCT_BRANDS } from '@/graphlql/mutations';

import UploadImage from '@/components/common/UploadImage.vue';

@Component({
  props: ['value'],
  components: {
    UploadImage,
  },
})
export default class BrandEdit extends Vue {
  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;
  value: any;

  updatePath(path: any) {
    this.value.logo = path;
  }

  save() {
    // Call to the graphql mutation
    this.loading = true;
    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_PRODUCT_BRANDS,
        // Parameters
        variables: {
          updateBrandInput: {
            id: this.value.id,
            name: this.value.name,
            description: this.value.description,
            logo: this.value.logo,
            popularity: this.value.popularity
          },
        },
      })
      .then((data) => {
        this.loading = false;
        this.result = true;
        this.error = false;
        this.success = true;

        setTimeout(() => this.clearStates(), 1000);
      })
      .catch((error) => {
        this.loading = false;
        this.result = true;
        this.error = true;
        this.success = false;
        console.error(error);
        setTimeout(() => this.clearStates(), 1000);
      });
  }
  clearStates() {
    this.loading = false;
    this.result = false;
    this.error = false;
    this.success = false;
  }
}
