export default {
  items: [
    {
      type: 'Columns',
      children: [
        {
          type: 'Column',
          content: { class: 'half' },
          children: [
            { type: 'Headline', content: 'Links' },
            {
              type: 'Columns',
              children: [
                {
                  type: 'LinkDashboardCard',
                  content: {
                    link: '/cms/deals/single/brow-app/brow-web',
                    contextColor: '#2893c9',
                    icon: 'badge-percent',
                    heading: 'Deals',
                    tag: 'Browgames',
                  },
                },
                {
                  type: 'LinkDashboardCard',
                  content: {
                    link: '/cms/sites/brow-web',
                    contextColor: '#2893c9',
                    icon: 'sitemap',
                    heading: 'Sites',
                    tag: 'Browgames',
                  },
                },
                {
                  type: 'LinkDashboardCard',
                  content: {
                    link: '/cms/pushnotifications/brow',
                    contextColor: '#2893c9',
                    icon: 'bell',
                    heading: 'Notifications',
                    tag: 'Browgames',
                  },
                },
                {
                  type: 'LinkDashboardCard',
                  content: {
                    link: '/cms/pushdetails/brow',
                    contextColor: '#2893c9',
                    icon: 'bell-exclamation',
                    heading: 'Details',
                    tag: 'Browgames',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    { type: 'Headline', content: 'Suchmaschine' },
    {
      type: 'Component',
      component: 'dashboard/components/SearchEngineStatistics.vue',
      content: {},
    },
    { type: 'Headline', content: 'System' },
    {
      type: 'Component',
      component: 'dashboard/components/SystemStatistics.vue',
      content: {},
    },
  ],
};
