
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NavigationNodeInterface } from '@/interfaces/navigation/navigation.interface';
import MenuClickCreatedMixin from '@/events/navigation/NavigationEventBus';
import { mixins } from 'vue-class-component';

@Component({
  name: 'navigation-node',
  watch: {
    $route(to, from) {
      const self = this as NavigationNode;
      if (self.menuIsOpen) {
        self.closeOpenedDropdowns();
      }
    },
  },
})
export default class NavigationNode extends mixins<Vue>(MenuClickCreatedMixin) {
  public menuIsOpen = false;
  public childrenCounter = 0;
  public styleObject = {};
  public result: boolean = false;

  @Prop({ required: true })
  public nodeData!: NavigationNodeInterface;

  @Prop()
  public nodeParentColor!: string;

  @Prop({ required: false })
  private parentDropDownIsOpen!: boolean;

  @Prop({ required: false })
  private isHeader!: boolean;

  @Prop({ required: false })
  private customClass!: string;

  /*
    checks if "parentDropDownIsOpen" changed. if changed, then set styleObject.height on 0px
  */
  @Watch('parentDropDownIsOpen')
  function() {
    this.styleObject = { height: '0px' };
  }

  /*
    checks if "parentDropDownIsOpen" changed. if changed, then set styleObject.height on 0px
  */
  @Watch('menuIsOpen')
  watchMenuIsOpen() {
    this.closeOpenedDropdowns();
  }

  setActiveNode(nodeName: string) {
    this.$store.dispatch('navigationModule/setActiveNode', nodeName);
  }

  getAssetUrl(assetUrl: string) {
    return require('@/assets/' + assetUrl);
  }

  get hasChildren() {
    return !!this.nodeData.children && this.nodeData.children.length > 0 && !this.isHeader;
  }

  get isRootElement() {
    return this.nodeData.parent === '0' || this.isHeader;
  }

  get isActiveNode() {
    const nodeName = this.$store.getters['navigationModule/getActiveNode']
    if(this.nodeData.name === nodeName)
    return true;
    return false;
  }

  get dropDownIsOpen(): boolean {
    let openPathString: any;
    let openPath: any[];
    if (localStorage.getItem('openPath') === null) {
      this.result = false;
      return false;
    } else {
      openPathString = localStorage.getItem('openPath');
      openPath = openPathString?.split(',');
      openPath.forEach((openPathElement) => {
        if (this.$store.getters['navigationModule/getNavigationPathOpen'].includes(openPathElement)) return true;
      });
      this.result = openPath.includes(this.nodeData.name);
      return this.result;
    }
  }

  closeLevel2() {
    this.$store.dispatch('navigationModule/setNavigationLevel2', null);
  }

  updateOpenLevel2() {
    this.$store.dispatch('navigationModule/setNavigationLevel2', this.nodeData);
  }

  findNodeInNavigationIndex(name: string, type: string) {
    /*
      checks navigationShort if there is an object containing parent/name equal to String name; returns it
     */
    if (type === 'name') {
      return this.$store.getters['navigationModule/getNavigationIndexed'].filter(function (
        obj: NavigationNodeInterface
      ) {
        return obj.name === name;
      });
    } else {
      return this.$store.getters['navigationModule/getNavigationIndexed'].filter(function (
        obj: NavigationNodeInterface
      ) {
        return obj.parent === name;
      });
    }
  }

  mousePositionChanged() {
    /*
      starts to update the "openedDropdowns" when the menu is "open".
    * */
    if (this.menuIsOpen) this.updateOpenedDropdowns();
  }
  openOnReload() {
    /*
     *    sets the openedDropdowns Array
     *    openedDropdowns: the current clicked "sub/menu" with the parent-element (...)
     * */
    let openPathString: any;
    let openPath: any[];

    if (localStorage.getItem('openPath') === null) {
      openPath = [];
    } else {
      openPathString = localStorage.getItem('openPath');
      openPath = openPathString?.split(',');
    }

    /*
     *      if the component is clicked, then the toggle will be set true (via setToggle());
     *      if toggle is true the currentName wont be pushed into the activeCollapse Array;
     *      result: component wont be collapsed
     *
     *      childrencounter counts the amount of childrens of the current object.
     *      styleObject.height will set with the childrenCounter * 44 as String + "px"
     *      used for the css transition
     *
     * */

    if (openPath.indexOf(this.nodeData.name) > -1) {
      this.childrenCounter = this.findNodeInNavigationIndex(this.nodeData.name, 'parent').length;
      this.childrenCounter *= 44;
      this.styleObject = { height: this.childrenCounter + 'px' };
    } else {
      this.styleObject = { height: '0px' };
      openPath = openPath.filter((str) => str !== this.nodeData.name);
    }
  }

  closeOpenedDropdowns(): any {
    localStorage.setItem('openPath', [].toString());
    this.$store.dispatch('navigationModule/setNavigationPathOpen', []);
  }

  updateOpenedDropdowns(): any {
    /*
     *    sets the openedDropdowns Array
     *    openedDropdowns: the current clicked "sub/menu" with the parent-element (...)
     * */
    let openPathString: any;
    let openPath: any[];
    if (localStorage.getItem('openPath') === null) {
      openPath = [];
    } else if (this.menuIsOpen) {
      //open only dropdown if the menu is open
      openPath = [];
    } else {
      //load current open elements
      openPathString = localStorage.getItem('openPath');
      openPath = openPathString?.split(',');
    }

    /*
     *      if the component is clicked, then the toggle will be set true (via setToggle());
     *      if toggle is true the currentName wont be pushed into the activeCollapse Array;
     *      result: component wont be collapsed
     *
     *      childrencounter counts the amount of childrens of the current object.
     *      styleObject.height will set with the childrenCounter * 44 as String + "px"
     *      used for the css transition
     *
     * */

    if (!this.dropDownIsOpen && this.nodeData.children != undefined) {
      openPath.push(this.nodeData.name);
      this.childrenCounter = this.findNodeInNavigationIndex(this.nodeData.name, 'parent').length;
      this.childrenCounter *= 44;
      this.styleObject = { height: this.childrenCounter + 'px' };
    } else {
      this.styleObject = { height: '0px' };
      openPath = openPath.filter((str) => str !== this.nodeData.name);
    }

    this.$store.dispatch('navigationModule/setNavigationLevel2', null);

    /*
     *    checks if the currentParent is not equal to '0'. If its equal, the current Node
     *    is already the Root-Tree - else the while will start and iterate through
     *    every parent-element
     *
     *    the current node (name) and the following parent-names will be dispatched into a global stored Array
     * */

    let traversedNode = {
      name: this.nodeData.name,
      parent: this.nodeData.parent,
    };

    while (traversedNode.parent !== '0') {
      traversedNode = this.findNodeInNavigationIndex(traversedNode.parent, 'name')[0];
      openPath.push(traversedNode.name);
    }

    localStorage.setItem('openPath', openPath.toString());
    this.$store.dispatch('navigationModule/setNavigationPathOpen', openPath);
  }

  /*
  Created-Hook for Vue-Component. Will set the childrenCounter to zero and sets the styleObject.height to zero
  both will used to change the transition on submenus
  just with a giving height css can use transitions at all
   */
  created() {
    this.childrenCounter = 0;
    this.styleObject = { height: this.childrenCounter + 'px' };
    if (localStorage.getItem('openPath') === null) localStorage.setItem('openPath', '');
  }
}
