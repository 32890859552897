
import { Component, Vue } from 'vue-property-decorator';
import { cdnLink } from '@/main';
import {
  GET_BRANDS,
  GET_CLIENTS,
  GET_SHOPS,
  LIST_PRODUCTS,
  GET_INTERACTIONS,
  SEARCH_PRODUCTS,
} from '@/graphlql/queries';
import {
  ACTIVATE_PRODUCTS,
  ACTIVATE_PRODUCTS_BY_ARTICLE_ID,
  UPDATE_INDEX,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_BASE_DATA,
} from '@/graphlql/mutations';
import TableBase from '../common/TableBase.vue';
import * as helper from '@/helper/data-helper';

@Component({
  apollo: {
    brands: {
      query: GET_BRANDS,
      manual: true,

      result(data: any) {
        if (data.data != null) {
          let brands: any = [];
          if (!data.loading) {
            this.loading = data.loading;
            brands = data.data.brands;
          }
          this.brands = brands;
        }
      },
    },

    clients: {
      manual: true,
      fetchPolicy: 'no-cache',
      query: GET_CLIENTS,
      result(data: any) {
        if (data.data != null) {
          for (const client of data.data.clients) {
            this.clients = this.clients.concat(client);
          }
          this.clients = this.clients.concat({ name: 'Bei allen vorhanden', id: 0 });
        }
      },
    },
    interactions: {
      manual: true,
      fetchPolicy: 'no-cache',
      query: GET_INTERACTIONS,
      variables() {
        return {
          ids: [],
          date: Date.now(),
        };
      },
      result(data: any) {
        if (data.data != null) {
          this.combinedScores = {};
          for (const combinedScoreEntry of data.data.getInteraction) {
            this.combinedScores[combinedScoreEntry.id] = combinedScoreEntry.interactionScore;
            for (const tableEntry of this.tableData) {
              if (tableEntry.id == combinedScoreEntry.id) {
                this.interactionScore[combinedScoreEntry.id] =
                  this.combinedScores[combinedScoreEntry.id] - tableEntry.baseScore;
              }
            }
          }
        }

        this.$forceUpdate();
      },
    },

    shops: {
      query: GET_SHOPS,
      result(data: any) {
        if (data.data != null) {
          for (const shop of data.data.shops) {
            this.shopsMap[shop.id] = shop;
          }
        }
      },
    },
    elasticproducts: {
      query: SEARCH_PRODUCTS,
      fetchPolicy: 'no-cache',
      manual: true,
      variables: {
        searchProductsOrder: 'pop',
        searchProductClient: 1,
        seachProductsSize: 100,
        searchProductsPage: 0,
        searchProductsQuery: null,
        searchProductsArgs: [],
      },
      result(data: any) {
        if (data.data != null) {
          this.elasticproducts = data.data.searchProducts.hits[0];
          this.print = JSON.stringify(data.data.searchProducts.pagination);
          this.productIds = [];
          const tableData: any = [];
          for (const product of data.data.searchProducts.hits) {
            if (product.baseScore == null) {
              product.baseScore = 0;
            }
            tableData.push(product);
            this.productIds = this.productIds.concat(product.id);
          }

          this.$apollo.queries.interactions.refetch({ ids: this.productIds, date: new Date().getTime() });

          this.tableData = tableData;
          this.total = data.data.searchProducts.pagination.total;

          this.loading = false;
          this.checkedRows = [];
        }
      },
    },
  },
})
export default class ScoreTable extends TableBase {
  constructor() {
    super();
    this.perPage = 10;
  }
  elasticproducts = ''; //Products are loaded from elastic since we need the calculated scores. They are saved in this variable
  combinedScores: Record<string, number> = {}; // Interactionscore of the loaded products has to be loaded separatly .
  interactionScore: any = {};
  productIds = []; // Ids of the currently displayed products

  pageSizes = [10, 20, 50, 100, 200];
  query: string | null = null;
  shopsMap = {};
  checkedRows: any[] = [];
  selectedClient: number = 1;
  selectedBrand: number = -1;
  cdn: string | undefined = cdnLink;
  clients: any[] = [];
  brands = [];
  loading = true;
  changedProducts: number[] = [];
  print = '';
  tableDataKey = 0;

  saveSuccess: boolean = false;
  saveError: boolean = false;
  saveResult: boolean = false;
  saveLoading: boolean = false;

  draggingRow: any = null;

  getClientClass(clientId: number) {
    if (clientId != null && this.clients != null && this.clients.length > 0) {
      for (const c of this.clients) {
        if (c.id == clientId) {
          return c.name.toLowerCase();
        }
      }
    }
    return '';
  }
  loadAsyncData() {
    this.loading = true;

    if (this.selectedBrand == -1) {
      this.$apollo.queries.elasticproducts.refetch({
        searchProductsOrder: 'pop',
        searchProductClient: this.selectedClient,
        searchProductsPage: this.page - 1,
        searchProductsSize: this.perPage,
        searchProductsQuery: this.query,
        searchProductsArgs: [],
      });
    } else {
      this.$apollo.queries.elasticproducts.refetch({
        searchProductsOrder: 'pop',
        searchProductClient: this.selectedClient,
        searchProductsPage: this.page - 1,
        searchProductsSize: this.perPage,
        searchProductsQuery: this.query,
        searchProductsArgs: [
          {
            key: 'brand.name.keyword',
            value: [this.selectedBrand],
          },
        ],
      });
    }
  }

  get combinedScore() {
    return this.tableData.map((entry) => {
      return { ...entry, combinedScore: this.combinedScores[entry.id] };
    });
  }

  async updateProduct(x: any, refetch: boolean) {
    for (const entry of this.tableData) {
      if (x === entry.id) {
        await this.$apollo
          .mutate({
            // Query
            mutation: UPDATE_PRODUCT,
            // Parameters
            variables: {
              //see comment for cleantypedata method. updatedAt doesnt get send since it gets recalculated in the backend
              updateProductInput: helper.cleanTypeData(entry, ['__typename', 'updatedAt']),
            },
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
        await this.$apollo.mutate({
          // Query
          mutation: UPDATE_PRODUCT_BASE_DATA,
          // Parameters
          variables: {
            //see comment for cleantypedata method. updatedAt doesnt get send since it gets recalculated in the backend
            updateProductBaseDataInput: helper.cleanTypeData(entry, ['__typename', 'updatedAt']),
          },
        });
      }
    }
    if (refetch) {
      await this.refetchProduct(false);
    }
  }

  updateTableData(x: any) {
    for (const entry of this.tableData) {
      if (entry.id === x.id) {
        entry.baseScore = x.baseScore;
      }
    }
    if (!(this.checkedRows.indexOf(x.id) > -1)) {
      this.checkedRows = this.checkedRows.concat(x.id);
    }
  }
  refetchProduct(resetPage: boolean = true) {
    if (resetPage) this.page = 1;
    this.loadAsyncData();
    this.putState();
  }

  //asc might be true for ascending or descending, the name might be wrong, either way it works.

  brandSort(a: { brand: { name: string } }, b: { brand: { name: string } }, asc: boolean) {
    if (asc) return a.brand.name.toUpperCase() > b.brand.name.toUpperCase();
    return a.brand.name.toUpperCase() < b.brand.name.toUpperCase();
  }

  async saveAllProducts() {
    this.saveLoading = true;
    const productIds: number[] = [];

    for (const product of this.checkedRows) {
      if (product != null) {
        productIds.push(product);
      }
    }
    for (const id of productIds) {
      await this.updateProduct(id, false);
    }
    this.saveLoading = false;
    this.saveResult = true;
    this.saveError = false;
    this.saveSuccess = true;
    //reload data
    this.loadAsyncData();

    setTimeout(() => this.resetSaveButton(), 1000);
  }

  resetSaveButton() {
    this.saveSuccess = false;
    this.saveError = false;
    this.saveResult = false;
    this.saveLoading = false;
  }

  perPageDefault: number = 100;

  keys = ['page', 'query', 'client', 'perPage', 'page'];

  onPageChange(page: number) {
    super.onPageChange(page);
  }

  popstateEventAction() {
    super.popstateEventAction();
    this.loadAsyncData();
  }

  mounted() {
    super.mounted();
    this.resetSaveButton();
    this.loadAsyncData();
  }

  dragstart(payload: any) {
    this.draggingRow = payload;
    payload.event.dataTransfer.effectAllowed = 'move';
  }
  dragover(payload: any) {
    payload.event.dataTransfer.dropEffect = 'move';
    payload.event.target.closest('tr').classList.add('is-selected');
    payload.event.preventDefault();
  }
  dragleave(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    payload.event.preventDefault();
  }
  drop(payload: any) {
    payload.event.target.closest('tr').classList.remove('is-selected');
    for (const entry of this.tableData) {
      if (entry.id === this.draggingRow.row.id) {
        if (this.draggingRow.index > payload.index) {
          entry.baseScore =
            payload.row.baseScore +
            this.interactionScore[payload.row.id] -
            this.interactionScore[this.draggingRow.row.id] +
            1;
        } else {
          entry.baseScore =
            payload.row.baseScore +
            this.interactionScore[payload.row.id] -
            this.interactionScore[this.draggingRow.row.id] -
            1;
        }
        const updatedCombinedScores = { ...this.combinedScores };
        Vue.set(
          updatedCombinedScores,
          this.draggingRow.row.id,
          entry.baseScore + this.interactionScore[this.draggingRow.row.id]
        );
        this.combinedScores = updatedCombinedScores;
        if (!(this.checkedRows.indexOf(this.draggingRow.row.id) > -1)) {
          this.checkedRows = this.checkedRows.concat(this.draggingRow.row.id);
        }
      }
    }
  }
  get computedCheckedRows() {
    return this.checkedRows;
  }

  set computedCheckedRows(value) {
    // If one row was unchecked
    if (this.checkedRows.length > value.length) {
      let toSplice = undefined;
      for (const row of this.checkedRows) {
        if (value.indexOf(row) == -1) {
          toSplice = this.checkedRows.indexOf(row);
        }
      }
      if (toSplice != undefined) {
        this.checkedRows.splice(toSplice);
      }
    } else {
      // If a box was checked, add it if it isnt in already
      if (!(this.checkedRows.indexOf(value[value.length - 1].id) > -1)) {
        this.checkedRows = this.checkedRows.concat(value[value.length - 1].id);
      }
    }
  }
}
