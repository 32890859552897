//supported Languages needs a new enum
export enum Locales {
  EN = 'en',
  DE = 'de'
}

//selection-example
export const LOCALES = [
  { value: Locales.EN, caption: 'English' },
  { value: Locales.DE, caption: 'Deutsch' }
];
