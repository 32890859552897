
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
  },
})
export default class ReleaseListSlider extends BaseCmsComponentClass {
  getConfigurationText() {
    let text = '';

    text += this.getContentString(this.content.tags, 'Tags');
    text += this.getContentString(this.content.skip, 'Starte mit Position');
    text += this.getContentString(this.content.take, 'Anzahl');

    return text.substring(0, text.length - 2);
  }

  getContentString(object: any, text: string) {
    if (object != null && object != '') {
      return '<strong>' + text + '</strong>: ' + object + ', ';
    }

    return '';
  }
}
