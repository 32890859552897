import { Vue, Component } from 'vue-property-decorator';

@Component({
  filters: {
    toCents: (value: number) => {
      return value * 100.0;
    },
    toLocale: (value: number, locale?: string) => {
      locale = locale || 'de-DE';
      return parseFloat(value.toFixed(2)).toLocaleString(locale);
    },
    currency: (value: number, locale?: string, currency?: string) => {
      locale = locale || 'de-DE';
      currency = currency || 'EUR';
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2
      });
      value = value / 100.0;
      return formatter.format(parseFloat(value.toFixed(2)));
    }
  }
})
export default class IntlMixin extends Vue {}
