
import Vue from 'vue';
import Component from 'vue-class-component';
import StoreEdit from '@/components/stores/StoreEdit.vue';
import StoreCreate from '@/components/stores/StoreCreate.vue';
import BaseView from '../common/BaseView.vue';
import { GET_SHOP, IS_MONOTOTE_DISABLED } from '@/graphlql/queries';
import VersionsTable from '@/components/versions/VersionsTable.vue';
import {
  DEACTIVATE_SHOP,
  UPDATE_SHOP,
  PROCESS_SHOP,
  TOGGLE_MONOTOTE,
  RESET_SHOP_IMAGEORDER,
  CREATE_SHOP,
} from '@/graphlql/mutations';
import StoreStatistics from '@/components/stores/StoreStatistics.vue';

@Component({
  components: { StoreEdit, StoreCreate, BaseView, VersionsTable, StoreStatistics },

  apollo: {
    isMonototeDisabled: {
      query: IS_MONOTOTE_DISABLED,
    },
    getShop: {
      query: GET_SHOP,
      skip() {
        return this.skip;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          getShopId: this.$route.params.id,
        };
      },
      result(data: any) {
        console.log(data.data);
        if (data.data != null && data.data.getShop != null) {
          if (data.data.getShop != null) {
            this.loaded = true;
            this.storeLabel = data.data.getShop.title;
            this.siteTitle = 'Store: ' + this.storeLabel;
            this.store = data.data.getShop;
          }
        }
      },
    },
  },
})
export default class StoreView extends Vue {
  storeLabel: string | null = null;
  siteTitle: string | null = null;
  skip: boolean = true;

  store: any | null = null;

  selected: any | null = null;
  createNewObject: any | null = null;

  createdStore(createdObject: any) {
    console.log(createdObject);
  }

  validateAndSave() {
    if (this.$route.params.id == 'create') {
      this.saveCreate();
    } else {
      this.saveUpdate();
    }
  }

  refetch() {
    this.skip = false;

    if (this.$route.params.id == 'create') {
      this.siteTitle = 'Store erstellen';
      this.store = {
        config: {
          processor: '',
          updateInterval: 900,
          processUrl: '',
          lastImportDate: null,
        },
        active: false,
        isReseller: false,
        description: '',
        logo: '',
        logoDarkmode: '',
        title: '',
      };
    } else if (this.$route.params.id != null) {
      this.$apollo.queries.getShop.refetch({
        getShopId: parseInt(this.$route.params.id, 10),
      });
    }
  }

  mounted() {
    this.refetch();
  }

  deactivateShop() {
    this.$apollo
      .mutate({
        mutation: DEACTIVATE_SHOP,
        // Parameters
        variables: {
          shopId: this.store.id,
        },
      })
      .then((data) => {
        this.$buefy.notification.open({
          message: `Erfolgreich deaktiviert`,
          duration: 5000,
          type: 'is-success',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      })
      .catch((error) => {
        this.$buefy.notification.open({
          message: `Fehler beim Abschicken`,
          duration: 5000,
          type: 'is-danger',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      });
  }

  saveCreate() {
    // Call to the graphql mutation
    this.$apollo
      .mutate({
        // Query
        mutation: CREATE_SHOP,
        // Parameters
        variables: {
          createShopInput: {
            config: {
              processor: this.store.config.processor,
              updateInterval: this.store.config.updateInterval,
              processUrl: this.store.config.processUrl,
              popularity: this.store.config.popularity,
              imageOrder: this.store.config.imageOrder,
            },
            client: this.store.client,
            active: this.store.active,
            description: this.store.description,
            logo: this.store.logo,
            logoDarkmode: this.store.logoDarkmode,
            title: this.store.title,
            secondaryTitle: this.store.secondaryTitle,
            voucher: this.store.voucher,
            voucherTitle: this.store.voucherTitle,
            voucherDescription: this.store.voucherDescription,
            ApplyToSale: this.store.ApplyToSale,
            voucherDateLimit: this.store.voucherDateLimit,
            voucherStartDate: this.store.voucherStartDate,
            tags: this.store.tags,
            isReseller: this.store.isReseller,
          },
        },
      })
      .then((data) => {
        if (data?.data?.createShop?.id) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
          this.$router.push({
            path: `/stores/${data?.data?.createShop?.id}`,
          });
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Abschicken`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      })
      .catch((error) => {
        this.$buefy.notification.open({
          message: `Fehler beim Abschicken`,
          duration: 5000,
          type: 'is-danger',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      });
  }

  saveUpdate() {
    // Call to the graphql mutation
    let voucherValue = 0;
    if (!isNaN(this.store.voucherValue)) {
      voucherValue = +this.store.voucherValue;
    }

    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_SHOP,
        // Parameters
        variables: {
          updateShopInput: {
            config: {
              processor: this.store.config.processor,
              updateInterval: this.store.config.updateInterval,
              processUrl: this.store.config.processUrl,
              popularity: this.store.config.popularity,
              imageOrder: this.store.config.imageOrder,
            },
            client: this.store.client,
            active: this.store.active,
            description: this.store.description,
            logo: this.store.logo,
            logoDarkmode: this.store.logoDarkmode,
            title: this.store.title,
            secondaryTitle: this.store.secondaryTitle,
            voucher: this.store.voucher,
            voucherTitle: this.store.voucherTitle,
            voucherValue: voucherValue,
            voucherDescription: this.store.voucherDescription,
            voucherApplyToSale: this.store.voucherApplyToSale,
            voucherApplyToNoSale: this.store.voucherApplyToNoSale,
            voucherDateLimit: this.store.voucherDateLimit,
            tags: this.store.tags,
            isReseller: this.store.isReseller,
          },
          updateShopId: this.store.id,
        },
      })
      .then((data) => {
        this.$buefy.notification.open({
          message: `Erfolgreich gespeichert`,
          duration: 5000,
          type: 'is-success',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      })
      .catch((error) => {
        this.$buefy.notification.open({
          message: `Fehler beim Abschicken`,
          duration: 5000,
          type: 'is-danger',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      });
  }

  startImport() {
    this.$apollo
      .mutate({
        mutation: PROCESS_SHOP,
        // Parameters
        variables: {
          processShopShopId: parseInt(this.store.id, 10),
          processShopPath: this.store.config.processUrl,
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.processShop != null && data.data.processShop.success) {
          this.$buefy.notification.open({
            message: `Erfolgreich abgeschickt`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Abschicken`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      })
      .catch((error) => {
        this.$buefy.notification.open({
          message: `Fehler beim Abschicken`,
          duration: 5000,
          type: 'is-danger',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      });
  }

  toggleMonotote() {
    this.$apollo
      .mutate({
        mutation: TOGGLE_MONOTOTE,
        // Parameters
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.toggleMonotote != null) {
          this.$buefy.notification.open({
            message: `Erfolgreich abgeschickt`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Abschicken`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      })
      .catch((error) => {
        this.$buefy.notification.open({
          message: `Fehler beim Abschicken`,
          duration: 5000,
          type: 'is-danger',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      });

    this.$apollo.queries.isMonototeDisabled.refetch();
  }

  resetImageOrder() {
    if (this.store && this.store.id) {
      this.$apollo
        .mutate({
          // Query
          mutation: RESET_SHOP_IMAGEORDER,
          // Parameters
          variables: {
            id: this.store.id,
          },
        })
        .then((data) => {
          if (
            data != null &&
            data.data != null &&
            data.data.resetShopImageOrder != null &&
            data.data.resetShopImageOrder
          ) {
            this.$buefy.notification.open({
              message: `Erfolgreich abgeschickt`,
              duration: 5000,
              type: 'is-success',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          } else {
            this.$buefy.notification.open({
              message: `Fehler beim Abschicken`,
              duration: 5000,
              type: 'is-danger',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          }
        })
        .catch((error) => {
          this.$buefy.notification.open({
            message: `Fehler beim Abschicken`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        });
    }
  }

  isNikeStore() {
    return this.store.title == 'PS_NIKE' || this.store.title == 'SNKR_NIKE';
  }
}
