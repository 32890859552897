
import { Component, Vue } from 'vue-property-decorator';
import { UPDATE_SEO } from '@/graphlql/mutations';

import SeoDetailView from '@/components/seo/SeoDetailView.vue';
import * as helper from '@/helper/data-helper';

@Component({
  props: ['value'],
  components: {
    SeoDetailView,
  },
})
export default class SeoEdit extends Vue {
  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;

  value: any;

  mounted() {
    if (this.value.context == null) {
      this.value.context = [];
    }
  }

  save() {
    // Call to the graphql mutation
    this.loading = true;
    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_SEO,
        // Parameters
        variables: {
          updateSeoInput: helper.cleanTypeData(this.value, ['clientName']),
        },
      })
      .then((data) => {
        this.loading = false;
        this.result = true;
        this.error = false;
        this.success = true;

        setTimeout(() => this.clearStates(), 1000);
      })
      .catch((error) => {
        this.loading = false;
        this.result = true;
        this.error = true;
        this.success = false;
        console.error(error);
        setTimeout(() => this.clearStates(), 1000);
      });
  }

  clearStates() {
    this.loading = false;
    this.result = false;
    this.error = false;
    this.success = false;
  }
}
