
import Vue from 'vue';
import Component from 'vue-class-component';
import { FIND_ALL_ALIASES, GET_ALIASES_PAGINATION } from '@/graphlql/queries';
import { UPDATE_ALIAS, UPDATE_ALIASES } from '@/graphlql/mutations';
import TableBase from '@/components/common/TableBase.vue';
import AliasesEdit from '@/components/aliases/AliasesEdit.vue';
import AliasesCreate from '@/components/aliases/AliasesCreate.vue';
import BaseView from '../common/BaseView.vue';

/*@Component({
  apollo: {
    findAllAliases: {
      query: FIND_ALL_ALIASES
    }
  }
})

async data
*/

@Component({
  components: {
    AliasesEdit,
    AliasesCreate,
    BaseView,
  },
  apollo: {
    aliases: {
      query: GET_ALIASES_PAGINATION,
      manual: true,
      variables: {
        aliasesPaginationPage: 0,
        aliasesPaginationSize: 2,
      },
      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            this.loading = data.loading;
            console.log(data.data.aliasesPagination.aliases);
            const tableData: any = [];
            for (const alias of data.data.aliasesPagination.aliases) {
              tableData.push(alias);
              if (alias.isActive) this.checkedRows.push(alias);
            }

            this.tableData = tableData;
            this.total = data.data.aliasesPagination.count;
          }
        }
      },
    },
  },
})
export default class AliasesView extends TableBase {
  saveSuccess: boolean = false;
  saveError: boolean = false;
  saveResult: boolean = false;
  saveLoading: boolean = false;

  checkedRows: any = [];
  finalData = [];

  perPage = 5;
  createNewObject: any = null;

  loadAsyncData() {
    this.$apollo.queries.aliases.refetch({
      aliasesPaginationPage: this.page - 1,
      aliasesPaginationSize: this.perPage,
    });
  }
  keys = ['page'];

  onPageChange(page: number) {
    super.onPageChange(page);
  }

  closecreatedAlias() {
    this.loadAsyncData();
    this.createNewObject = null;
  }

  createdAlias(data: any) {
    this.loadAsyncData();
    this.createNewObject = null;
    if (this.selected == null) {
      this.selected = data.createSearchAlias;
    }
  }

  popstateEventAction() {
    super.popstateEventAction();
    this.loadAsyncData();
  }
  mounted() {
    super.mounted();
    this.loadAsyncData();
  }

  saveActive() {
    this.saveLoading = true;
    const updateAliases: any = [];

    for (const alias of this.tableData) {
      const mappingData: any = { id: alias.id, origin: alias.origin, alias: alias.alias };
      let active = false;
      for (const activeAlias of this.checkedRows) {
        if (activeAlias != null && activeAlias.id == alias.id) {
          active = true;
          break;
        }
      }
      mappingData['isActive'] = active;
      updateAliases.push(mappingData);
    }
    console.log(updateAliases);
    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_ALIASES,
        // Parameters
        variables: {
          input: updateAliases,
        },
      })
      .then((data) => {
        console.log(data);

        //setTimeout(() => this.clearStates(), 1000);
      })
      .catch((error) => {
        console.log(error);
      });

    this.finalData = updateAliases;
    this.saveLoading = false;
  }

  createNew() {
    this.createNewObject = {
      origin: '',
      alias: '',
    };

    this.selected = null;
  }
}
