import { render, staticRenderFns } from "./OpenAiModal.vue?vue&type=template&id=2ef99565"
import script from "./OpenAiModal.vue?vue&type=script&lang=ts"
export * from "./OpenAiModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports