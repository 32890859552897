
import Vue from 'vue';
import Component from 'vue-class-component';
import ScoreTable from '@/components/score/ScoreTable.vue';
import BaseView from '../common/BaseView.vue';
import { UPDATE_INDEX } from '@/graphlql/mutations';

@Component({ components: { ScoreTable, BaseView } })
export default class ProductsView extends Vue {
  initSearchIndex() {
    this.$apollo
      .mutate({
        mutation: UPDATE_INDEX,
      })
      .then((data) => {
        if (data.data != null) {
          if (data.data.updateIndex == true) {
            this.$buefy.notification.open({
              message: `Such-index wird neu aufgebaut.`,
              duration: 5000,
              type: 'is-success',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          } else {
            this.$buefy.notification.open({
              message: `Such-index wird bereits neu gebaut.`,
              duration: 5000,
              type: 'is-info',
              pauseOnHover: true,
              position: 'is-bottom-right',
            });
          }
        }
      })
      .catch((error) => {
        this.$buefy.notification.open({
          message: `Es ist ein Fehler beim Aufruf passiert!`,
          duration: 5000,
          type: 'is-danger',
          pauseOnHover: true,
          position: 'is-bottom-right',
        });
      });
  }
}
