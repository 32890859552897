export default {
  '1': {
    id: 1,
    name: 'suchmaschine',
    parent: '0',
    path: '',
    pathNamedParams: [],
    pathQueryParams: [],
    icon: 'magnifying-glass',
    order: 1,
    visible: true,
    permission: 'SEARCH_ENGINE_CMS',
    active: true,
    position: 'sidebar',
    activeNode: '',
    contextColor: '#edc226',
    children: [
      {
        id: 11,
        name: 'productsearch',
        parent: '1',
        path: '/productsearch',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'magnifying-glass',
        order: 1,
        visible: true,
        active: true,
        position: 'sidebar',
        activeNode: '',
        contextColor: '#edc226',
      },
      {
        id: 12,
        name: 'products',
        parent: '1',
        path: '/products',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'gamepad',
        order: 2,
        visible: true,
        active: true,
        position: 'sidebar',
        activeNode: '',
        contextColor: '#edc226',
      },
      {
        id: 13,
        name: 'editing',
        parent: '1',
        path: '',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'pen-to-square',
        order: 3,
        visible: true,
        active: true,
        position: 'sidebar',
        activeNode: '',
        contextColor: '#edc226',
        children: [
          {
            id: 131,
            name: 'colors',
            parent: '13',
            path: '/colors',
            pathNamedParams: [],
            pathQueryParams: [],
            icon: 'palette',
            order: 1,
            visible: true,
            active: true,
            position: 'sidebar',
            activeNode: '',
            contextColor: '#edc226',
          },
          {
            id: 132,
            name: 'brands',
            parent: '13',
            path: '/brands',
            pathNamedParams: [],
            pathQueryParams: [],
            icon: 'copyright',
            order: 2,
            visible: true,
            active: true,
            position: 'sidebar',
            activeNode: '',
            contextColor: '#edc226',
          },
          {
            id: 137,
            name: 'scores',
            parent: '13',
            path: '/scores',
            pathNamedParams: [],
            pathQueryParams: [],
            icon: 'fire',
            order: 6,
            visible: true,
            active: true,
            position: 'sidebar',
            activeNode: '',
            contextColor: '#edc226',
          },
        ],
      },
      {
        id: 15,
        name: 'search-admin',
        parent: '1',
        path: '',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'screwdriver-wrench',
        order: 1,
        visible: true,
        active: true,
        position: 'sidebar',
        activeNode: '',
        contextColor: '#edc226',
        children: [
          {
            id: 151,
            name: 'clients',
            parent: '15',
            path: '/clients',
            pathNamedParams: [],
            pathQueryParams: [],
            icon: 'globe-europe',
            order: 1,
            visible: true,
            active: true,
            position: 'sidebar',
            activeNode: '',
            contextColor: '#edc226',
          },
          {
            id: 152,
            name: 'mapping',
            parent: '15',
            path: '/mapping',
            pathNamedParams: [],
            pathQueryParams: [],
            icon: 'map-signs',
            order: 2,
            visible: true,
            active: true,
            position: 'sidebar',
            activeNode: '',
            contextColor: '#edc226',
          },
          {
            id: 153,
            name: 'stores',
            parent: '15',
            path: '/stores',
            pathNamedParams: [],
            pathQueryParams: [],
            icon: 'store',
            order: 3,
            visible: true,
            active: true,
            position: 'sidebar',
            activeNode: '',
            contextColor: '#edc226',
          },
          {
            id: 154,
            name: 'reporting',
            parent: '15',
            path: '/reporting',
            pathNamedParams: [],
            pathQueryParams: [],
            icon: 'align-left',
            order: 4,
            visible: true,
            active: true,
            position: 'sidebar',
            activeNode: '',
            contextColor: '#edc226',
          },
          {
            id: 155,
            name: 'aliases',
            parent: '15',
            path: '/aliases',
            pathNamedParams: [],
            pathQueryParams: [],
            icon: 'expand-alt',
            order: 5,
            visible: true,
            active: true,
            position: 'sidebar',
            activeNode: '',
            contextColor: '#edc226',
          },
        ],
      },
    ],
  },
  '2': {
    id: 2,
    name: 'browgames',
    parent: '0',
    path: '',
    pathNamedParams: [],
    pathQueryParams: [],
    icon: 'crown',
    order: 1,
    activeNode: '',
    contextColor: '#26a6ed',
    visible: true,
    active: true,
    position: 'sidebar',
    children: [
      {
        id: 2111,
        name: 'deals',
        parent: '211',
        path: '/cms/deals/single/brow-app/brow-web',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'badge-percent',
        order: 1,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#0C8900',
        position: 'sidebar',
      },
      {
        id: 2113,
        name: 'sites',
        parent: '211',
        path: '/cms/sites/brow-web',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'sitemap',
        order: 2,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
      {
        id: 2114,
        name: 'sites-app',
        parent: '211',
        path: '/cms/sites/brow-app',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'mobile-screen',
        order: 3,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },

      {
        id: 2117,
        name: 'pushnotifications',
        parent: '211',
        path: '/cms/pushnotifications/brow',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'bell',
        order: 7,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },

      {
        id: 2118,
        name: 'pushdetail',
        parent: '211',
        path: '/cms/pushdetails/brow',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'bell-exclamation',
        order: 8,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
      {
        id: 2119,
        name: 'stories',
        parent: '211',
        path: '/cms/stories/brow',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'circle',
        order: 9,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
      {
        id: 21110,
        name: 'blog',
        parent: '211',
        path: '/cms/blogs/brow',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'blog',
        order: 10,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
      {
        id: 21112,
        name: 'instafeeds',
        parent: '211',
        path: '/cms/instafeeds/brow',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'fa-brands fa-instagram',
        order: 11,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
      {
        id: 21111,
        name: 'templates',
        parent: '211',
        path: '/cms/templates/brow',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'comment-dollar',
        order: 14,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
      {
        id: 21114,
        name: 'texttemplates',
        parent: '211',
        path: '/cms/stringtemplates/brow-text/deal',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'input-text',
        order: 16,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
      {
        id: 2115,
        name: 'categories',
        parent: '211',
        path: '/cms/categories/brow',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'sitemap',
        order: 17,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
      {
        id: 2116,
        name: 'vouchers',
        parent: '211',
        path: '/cms/vouchers/brow',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'percent',
        order: 17,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
      {
        id: 21117,
        name: 'tags',
        parent: '211',
        path: '/cms/tags/brow',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'tag',
        order: 17,
        visible: true,
        active: true,
        activeNode: '',
        contextColor: '#FFFFFF',
        position: 'sidebar',
      },
    ],
  },
  '6': {
    id: 6,
    name: 'admin',
    parent: '0',
    path: '',
    pathNamedParams: [],
    pathQueryParams: [],
    icon: 'dragon',
    order: 12,
    visible: true,
    active: true,
    position: 'sidebar',
    activeNode: '',
    contextColor: '#26ed76',
    children: [
      {
        id: 41,
        name: 'usermanagement',
        parent: '4',
        path: '/usermanagement',
        pathNamedParams: [],
        pathQueryParams: [],
        icon: 'users',
        order: 4,
        visible: true,
        active: true,
        position: 'sidebar',
        activeNode: '',
        contextColor: '#26ed76',
      },
    ],
  },
};
