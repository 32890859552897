
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

@Component({
  mixins: [reactiveProp],
  props: ['options'],
  extends: Bar,
})
export default class BarChart extends Mixins(reactiveProp, Bar) {
  @Prop() options!: any;
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  }
}
