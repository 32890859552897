import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import store from './store';
import router from './router';
import { ApolloLink, HttpLink } from 'apollo-boost';

const errorHandler = onError(({ networkError, graphQLErrors }) => {
  const errors = graphQLErrors as any;

  let isUnauthorized = false;

  for (const error of errors) {
    if (error != null && error.extensions != null && error.extensions.exception != null) {
      if (error.extensions.exception.status == 403 || error.extensions.exception.status == 401) {
        isUnauthorized = true;
      }
    }
    if (error != null && error.extensions != null && error.extensions.response != null) {
      if (error.extensions.response.statusCode == 403 || error.extensions.response.statusCode == 401) {
        isUnauthorized = true;
      }
    }
  }

  if (isUnauthorized) {
    localStorage.setItem('loggedIn', 'false');
    store.dispatch('authModule/checkAuth');
    router.push({ name: 'Login' });
  }
});

// HTTP connection to the API
const httpLinkA = createUploadLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_APOLLO_CLIENT_A || 'http://gateway.localhost/graphql',
  credentials: 'include',

  headers: {
    'access-token': sessionStorage.getItem('accessToken') || (store.state as any)['authModule'].accessToken,
    'refresh-token': sessionStorage.getItem('refreshToken') || (store.state as any)['authModule'].refreshToken,
  },
});

// HTTP connection to the API
const httpLinkB = createUploadLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_APOLLO_CLIENT_B || 'http://gateway.localhost/graphql', // if b is not defined
  credentials: 'include',
  headers: {
    'access-token': sessionStorage.getItem('accessToken') || (store.state as any)['authModule'].accessToken,
    'refresh-token': sessionStorage.getItem('refreshToken') || (store.state as any)['authModule'].refreshToken,
  },
});

// Cache implementation
const cache = new InMemoryCache();
cache.reset();

// link to pass current language on every query
const middleware = new ApolloLink((operation, forward) => {
  const headers = {
    'access-token': sessionStorage.getItem('accessToken') || (store.state as any)['authModule'].accessToken,
    'refresh-token': sessionStorage.getItem('refreshToken') || (store.state as any)['authModule'].refreshToken,
  };

  // add the authorization to the headers
  operation.setContext({
    headers,
  });

  return forward(operation);
});

const clientA = new ApolloClient({
  link: ApolloLink.from([middleware, errorHandler.concat(httpLinkA as any)]),
  cache,
});

const clientB = new ApolloClient({
  link: ApolloLink.from([middleware, errorHandler.concat(httpLinkB as any)]),
  cache,
});

const clients = {
  clientA,
  clientB,
};

export default clients;
