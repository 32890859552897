
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';
import UploadImage from '../UploadImage.vue';
import ReferenceInput from '../ReferenceInput.vue';
import { cmsLink } from '@/main';
import DealPrice from '../DealPrice.vue';
@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
    UploadImage,
    ReferenceInput,
    DealPrice,
  },
})
export default class SmallCard extends BaseCmsComponentClass {
  cdn: string | undefined = cmsLink;

  referenceChanged(reference: string) {
    this.content.reference = reference;
  }

  pathChanged(path: string) {
    console.log('pathChanged', path, this.content.path);
    this.content.path = path;
  }

  referenceTypeChanged(referenceType: string) {
    this.content.referenceType = referenceType;
  }
}
