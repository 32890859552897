
import Vue from 'vue';
import Component from 'vue-class-component';
import { GET_CLIENTS, SEARCH_INFORMATION, SEARCH_PRODUCTS } from '@/graphlql/queries';
import ProductCard from '@/components/product/ProductCard.vue';
import BaseView from '../common/BaseView.vue';

interface BaseFilter {
  field: string;
  key: string;
  values: string[] | null;
  selectedValue: string | null;
}

@Component({
  components: {
    ProductCard,
    BaseView,
  },
  watch: {
    $route(to, from) {
      const self = this as ProductSearchView;
      self.searchQuery = to.query.query as string;
      self.changeClient();
    },
  },
  apollo: {
    searchProducts: {
      query: SEARCH_PRODUCTS,
      manual: true,
      variables: {
        searchProductsPage: 0,
        searchProductsSize: 21,
        searchProductsQuery: '',
        searchProductsArgs: [],
        searchProductClient: null,
        searchProductsOrder: 'pop',
      },
      result(data) {
        if (data != null && data.loading == false) {
          if (data.data.searchProducts != null && data.data.searchProducts.pagination != null) {
            this.total = data.data.searchProducts.pagination.total;
            this.page = data.data.searchProducts.pagination.currentPage + 1;
            this.searchProducts = data.data.searchProducts;
          }
        }
      },
    },
    searchInformation: {
      query: SEARCH_INFORMATION,
      variables: {
        searchInformationQuery: '',
      },
    },
    clients: {
      query: GET_CLIENTS,
    },
  },
})
export default class ProductSearchView extends Vue {
  searchProducts: any = {};
  searchInformation: any = {};
  searchFilter: BaseFilter[] = [];
  searchQuery: string | null = '';
  page = 1;
  perPage = 21;
  total = 0;
  choosenClient = 1;

  async resetFilter() {
    this.searchQuery = '';
    for (const filter of this.searchFilter) {
      filter.selectedValue = null;
    }

    this.page = 1;
    await this.$apollo.queries.searchProducts.refetch({
      searchProductsPage: 0,
      searchProductsSize: this.perPage,
      searchProductsQuery: '',
      searchProductsArgs: [],
      searchProductClient: this.choosenClient,
    });
  }

  async changePage(currentPage: number) {
    await this.filterProducts(currentPage);
  }
  async filterQuery() {
    await this.filterProducts();
  }

  async changeClient() {
    await this.initFilter();
    await this.filterProducts();
  }

  async filterProducts(currentPageOrValue: any = null) {
    this.page = currentPageOrValue == null || typeof currentPageOrValue == 'string' ? 1 : currentPageOrValue;

    const args: { key: string; value: string }[] = [];

    for (const filter of this.searchFilter) {
      if (filter.selectedValue) {
        args.push({ key: filter.field, value: filter.selectedValue });
      }
    }

    await this.$apollo.queries.searchProducts.refetch({
      searchProductsPage: this.page - 1,
      searchProductsSize: this.perPage,
      searchProductsQuery: this.searchQuery,
      searchProductsArgs: args,
      searchProductClient: this.choosenClient,
    });
  }

  async initFilter() {
    this.searchFilter = [];
    await this.$apollo.queries.searchInformation.refetch({
      searchQuery: '',
      searchInformationArgs: [],
      searchInformationClient: this.choosenClient,
    });

    for (const filter of this.searchInformation.filter) {
      this.searchFilter.push({
        field: filter.field,
        key: filter.key,
        values: filter.values,
        selectedValue: null,
      });
    }
  }

  async mounted() {
    this.searchQuery = this.$route.query.query as string;
    await this.initFilter();
    await this.filterProducts();
  }
}
