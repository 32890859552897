
import Vue from 'vue';
import Component from 'vue-class-component';
import CMSView from '@/views/cms/CMSView.vue';
import UploadImage from '@/components/cms/UploadImage.vue';
import CustomTagInput from '../tags/CustomTagInput.vue';

@Component({
  components: { CMSView, UploadImage, CustomTagInput },
  props: {
    url: Object,
    page: Object,
    context: String,
  },
})
export default class CMSMeta extends Vue {
  activeTab: number = 0;
  page: any;
  url: any;
  context: any;

  getData() {
    return (this.$refs['cmsView'] as any).getItems;
  }
}
