
import { Component, Vue } from 'vue-property-decorator';
import UploadImage from '@/components/common/UploadImage.vue';
import { cdnLink } from '@/main';

@Component({
  props: ['value'],
  components: { UploadImage },
})
export default class ClientAdvertsView extends Vue {
  value: any;

  cdn: string | undefined = cdnLink;
  
  deleteItem(index: number) {
    this.value.splice(index, 1);
  }

  addItem() {
    this.value.push({ name: '', image: '', link: '' });
  }

  updatePath(index: number, image: any) {
    if (this.value != null && this.value[index]) {
      this.value[index].image = image;
    }
  }
}
