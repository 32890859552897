import gql from 'graphql-tag';

export const SEARCH_PRODUCTS = gql`
  query SearchProducts(
    $searchProductsQuery: String
    $searchProductsSize: Int
    $searchProductsPage: Int
    $searchProductsArgs: [SearchArgsInputDto!]
    $searchProductClient: Float
    $searchProductsOrder: String
  ) {
    searchProducts(
      query: $searchProductsQuery
      size: $searchProductsSize
      page: $searchProductsPage
      args: $searchProductsArgs
      client: $searchProductClient
      order: $searchProductsOrder
    ) {
      hits {
        active
        brand {
          description
          logo
          name
          popularity
          id
        }
        images {
          origin
          order
          link
          alt
          name
          id
        }
        color {
          id
          name
          color
          hex
          image
        }
        category {
          id
          name
          alt
          image
        }
        title
        model
        displayModel
        articleId
        articleNo
        client
        createdAt
        discount
        gender
        lastUpdateDate
        material
        minPrice
        name
        updatedAt
        description
        uvp
        baseScore
        id
        availabilities {
          sizesUk
          sizesUs
          id
          shop
          directLink
          shippingCosts
          discount
          price
          stock
          sizesEu
          isResellerShop
        }
      }
      query
      args {
        key
        value
      }
      pagination {
        currentPage
        pageCount
        pageSize
        total
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query Product($productArticleId: String!, $productClient: Float) {
    product(articleId: $productArticleId, client: $productClient) {
      id
      client
      active
      brand {
        description
        logo
        name
        popularity
        id
      }
      color {
        image
        hex
        color
        name
        id
      }
      category {
        image
        alt
        name
        id
      }
      name
      title
      model
      displayModel
      gender
      material
      articleId
      articleNo
      description
      images {
        origin
        order
        link
        alt
        name
        id
      }
      uvp
      minPrice
      discount
      createdAt
      updatedAt
      baseScore
      lastUpdateDate
      disabled
      availabilities {
        sizesUk
        sizesUs
        sizesEu
        stock
        price
        discount
        shippingCosts
        directLink
        shop
        id
        isResellerShop
      }
    }
  }
`;

export const GET_MAPPING_TYPES = gql`
  query GetMappingTypes {
    mappingTypes {
      name
      id
    }
  }
`;

export const GET_MAPPINGS = gql`
  query GetMappings(
    $mappingsType: Float!
    $mappingsLimit: Int
    $mappingsOffset: Int
    $mappingsShopId: Float
    $mappingOrder: String
    $onlyActive: Boolean
  ) {
    mappings(
      type: $mappingsType
      shopId: $mappingsShopId
      limit: $mappingsLimit
      offset: $mappingsOffset
      order: $mappingOrder
      onlyActive: $onlyActive
    ) {
      count
      mappings {
        type {
          name
        }
        key
        value
        id
        active
      }
    }
  }
`;

export const GET_SHOPS = gql`
  query GetShops {
    shops {
      title
      secondaryTitle
      id
      logo
      logoDarkmode
      description
      active
      createdAt
      updatedAt
      client
      voucher
      voucherTitle
      voucherDescription
      voucherApplyToSale
      tags
      voucherDateLimit
      partnerShop
      isReseller
      config {
        id
        processUrl
        updateInterval
        lastImportDate
        processor
        imageOrder
        popularity
      }
    }
  }
`;

export const GET_SHOPS_PAGINATION = gql`
  query ShopsPagination($shopsPaginationLimit: Int, $shopsPaginationOffset: Int) {
    shopsPagination(limit: $shopsPaginationLimit, offset: $shopsPaginationOffset) {
      shops {
        active
        client
        config {
          processor
          updateInterval
          lastImportDate
          processUrl
          id
          imageOrder
          popularity
        }
        isReseller
        voucher
        voucherTitle
        voucherDescription
        voucherApplyToSale
        voucherApplyToNoSale
        voucherDateLimit
        voucherValue
        tags
        updatedAt
        createdAt
        description
        logo
        logoDarkmode
        title
        secondaryTitle
        id
      }
      count
    }
  }
`;

export const GET_SHOP = gql`
  query GetShop($getShopId: Float!) {
    getShop(id: $getShopId) {
      id
      title
      secondaryTitle
      logo
      logoDarkmode
      description
      active
      client
      createdAt
      updatedAt
      voucher
      voucherTitle
      voucherDescription
      voucherApplyToSale
      voucherApplyToNoSale
      voucherDateLimit
      isReseller
      tags
      config {
        processor
        updateInterval
        lastImportDate
        processUrl
        id
        imageOrder
        popularity
      }
    }
  }
`;

export const GET_PRODUCT_COLORS = gql`
  query ColorsPagination($colorsPaginationLimit: Int, $colorsPaginationOffset: Int) {
    colorsPagination(limit: $colorsPaginationLimit, offset: $colorsPaginationOffset) {
      colors {
        image
        hex
        color
        name
        id
      }
      count
    }
  }
`;

export const GET_PRODUCT_COLORS_FULL = gql`
  query Colors {
    colors {
      id
      name
      color
      hex
      image
    }
  }
`;

export const GET_PRODUCT_BRANDS_PAGINATION = gql`
  query BrandsPagination($brandsPaginationOffset: Int, $brandsPaginationLimit: Int) {
    brandsPagination(offset: $brandsPaginationOffset, limit: $brandsPaginationLimit) {
      count
      brands {
        description
        logo
        name
        id
        popularity
      }
    }
  }
`;

export const GET_PRODUCT_BRANDS = gql`
  query Brands {
    brands {
      description
      logo
      name
      id
      popularity
    }
  }
`;

export const GET_PRODUCT_CATEGORIES = gql`
  query Categories {
    categories {
      image
      alt
      name
      id
    }
  }
`;

export const GET_PROCESSORS = gql`
  query Processors {
    processors
  }
`;

export const GET_MAPPING_INFORMATION = gql`
  query GetMappingInformation($getMappingInformationShopId: Float!) {
    getMappingInformation(shopId: $getMappingInformationShopId) {
      active
      type {
        name
        id
      }
      total
    }
  }
`;

export const SEARCH_INFORMATION = gql`
  query SearchInformation($searchInformationQuery: String, $searchInformationClient: Float) {
    searchInformation(query: $searchInformationQuery, client: $searchInformationClient) {
      filter {
        key
        field
        values
      }
      query
      minPrice
      maxPrice
    }
  }
`;

export const CMS_SEARCH = gql`
  query SearchCMS($query: String, $type: String, $type2: String, $client: String, $onlyActive: Boolean, $limit: Int) {
    searchCMS(query: $query, type: $type, type2: $type2, client: $client, onlyActive: $onlyActive, limit: $limit) {
      items {
        id
        previewImage {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        title
        isActive
        content
        client
        type
        type2
        createdAt
        updatedAt
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const GET_BRANDMODELS = gql`
  query GetBrandModels {
    getBrandModels {
      id
      model
      brand {
        id
        name
      }
    }
  }
`;

export const GET_FILTERBUBBLES = gql`
  query Filterbubbles {
    filterbubbles {
      id
      content
      clientId
    }
  }
`;

export const GET_ALL_COLORS = gql`
  query GetAllColors {
    getAllColors {
      id
      name
    }
  }
`;

export const GET_CLIENTS = gql`
  query Clients {
    clients {
      updatedAt
      createdAt
      active
      adverts {
        image
        name
        link
        id
      }
      referrer {
        name
        id
        referrer
      }
      images {
        link
        image
        name
        id
      }
      name
      id
    }
  }
`;
export const GET_SEO_PAGINATION = gql`
  query SeoPagination($seoPaginationLimit: Int, $seoPaginationOffset: Int) {
    seoPagination(limit: $seoPaginationLimit, offset: $seoPaginationOffset) {
      count
      entries {
        text
        context {
          value
          key
        }
        path
        id
        client
      }
    }
  }
`;

export const LIST_PRODUCTS = gql`
  query Products(
    $productsOrderBy: String
    $productsActive: Boolean
    $productsClient: Float
    $productsOffset: Int
    $productsLimit: Int
    $productsQuery: String
    $productsOnlyWithImages: Boolean
    $productsOnlyWithAvailabilities: Boolean
    $productsBrand: Float
    $productsShop: Float
  ) {
    products(
      orderBy: $productsOrderBy
      active: $productsActive
      client: $productsClient
      offset: $productsOffset
      limit: $productsLimit
      query: $productsQuery
      onlyWithImages: $productsOnlyWithImages
      onlyWithAvailabilities: $productsOnlyWithAvailabilities
      brand: $productsBrand
      shop: $productsShop
    ) {
      count
      products {
        id
        client
        active
        brand {
          name
          id
          description
          logo
          popularity
        }
        color {
          image
          hex
          color
          name
          id
        }
        category {
          image
          alt
          name
          id
        }
        name
        title
        model
        displayModel
        gender
        material
        articleId
        articleNo
        baseScore
        disabled
        description
        images {
          origin
          order
          link
          alt
          name
          id
        }
        uvp
        minPrice
        discount
        createdAt
        updatedAt
        lastUpdateDate
        availabilities {
          sizesUk
          sizesUs
          sizesEu
          stock
          price
          discount
          directLink
          shippingCosts
          id
          isResellerShop
          shop
        }
      }
    }
  }
`;

export const GET_PRODUCT_STATISTICS = gql`
  query Statistics($statisticsStartDate: DateTime!, $statisticsProduct: Float!) {
    statistics(startDate: $statisticsStartDate, product: $statisticsProduct) {
      product
      clicks
      views
      wishlist
      fakes
      date
    }
  }
`;

export const GET_TOP_PRODUCTS = gql`
  query TopProducts($topProductsOrder: Float!, $topProductsCount: Float!, $topProductsStartDate: DateTime!) {
    topProducts(order: $topProductsOrder, count: $topProductsCount, startDate: $topProductsStartDate) {
      product
      views
      clicks
      wishlist
      fakes
      date
    }
  }
`;

export const IS_MONOTOTE_DISABLED = gql`
  query IsMonototeDisabled {
    isMonototeDisabled
  }
`;

export const GET_PRODUCTS = gql`
  query ProductsById($productsByIdIds: [Float!]!) {
    productsById(ids: $productsByIdIds) {
      id
      client
      active
      brand {
        description
        logo
        name
        id
      }
      availabilities {
        sizesUk
        sizesUs
        id
        shop
        directLink
        shippingCosts
        discount
        price
        stock
        isResellerShop
        sizesEu
      }
      releaseDate
      lastUpdateDate
      updatedAt
      createdAt
      discount
      uvp
      minPrice
      images {
        origin
        order
        link
        alt
        name
        id
      }
      color {
        id
        name
        color
        hex
        image
      }
      category {
        id
        name
        alt
        image
      }
      name
      title
      model
      displayModel
      gender
      material
      articleId
      articleNo
      description
    }
  }
`;

export const GET_PRODUCTS_BY_ARTICLEID = gql`
  query ProductsByArticleId($articleIds: [String!]!, $client: Float!) {
    productsByArticleId(ids: $articleIds, client: $client) {
      id
      client
      active
      brand {
        description
        logo
        name
        id
        popularity
      }
      availabilities {
        sizesUk
        sizesUs
        id
        shop
        directLink
        shippingCosts
        isResellerShop
        discount
        price
        stock
        sizesEu
      }
      releaseDate
      lastUpdateDate
      updatedAt
      createdAt
      discount
      uvp
      minPrice
      images {
        origin
        order
        link
        alt
        name
        id
      }
      color {
        id
        name
        color
        hex
        image
      }
      category {
        id
        name
        alt
        image
      }
      name
      title
      model
      displayModel
      gender
      material
      articleId
      articleNo
      description
      baseScore
    }
  }
`;

export const GET_REPORTS = gql`
  query Reports {
    reports {
      shopName
      shopId
      createdProducts
      fileChange
      importProductCount
      importDate
      updatedProducts
    }
  }
`;

export const REMOVE_REPORTS = gql`
  query DeleteMany($deleteManyIds: [Int!]!) {
    deleteMany(ids: $deleteManyIds) {
      shopName
      shopId
      importProductCount
      importDate
      updatedProducts
      createdProducts
      fileChange
    }
  }
`;

export const GET_SIZES = gql`
  query GetShoeSizes($getShoeSizesKey: String!) {
    getShoeSizes(key: $getShoeSizesKey) {
      key
      women {
        eu
        uk
        us
      }
      children {
        eu
        uk
        us
      }
      men {
        eu
        uk
        us
      }
    }
  }
`;

export const GET_BRANDS = gql`
  query Brands {
    brands {
      name
      id
    }
  }
`;
export const FIND_ALL_ALIASES = gql`
  query FindAllAliases {
    findAllAliases {
      id
      origin
      alias
    }
  }
`;

export const GET_INTERACTIONS = gql`
  query GetInteraction($ids: [Float!]!, $date: DateTime!) {
    getInteraction(ids: $ids, date: $date) {
      id
      interactionScore
    }
  }
`;

export const GET_ALIASES_PAGINATION = gql`
  query AliasesPagination($aliasesPaginationPage: Int, $aliasesPaginationSize: Int) {
    aliasesPagination(page: $aliasesPaginationPage, size: $aliasesPaginationSize) {
      count
      aliases {
        id
        origin
        alias
        isActive
      }
    }
  }
`;

export const GET_PROCESS_STATISTICS = gql`
  query GetProcessStatistics {
    getProcessStatistics {
      report
      uptime
    }
  }
`;

export const GET_VIEW_STATISTICS = gql`
  query GetViewStatistics($client: Float!, $startDate: DateTime!, $endDate: DateTime!) {
    getViewStatistics(client: $client, startDate: $startDate, endDate: $endDate) {
      client
      views
      clicks
      date
    }
  }
`;

export const GET_IMPORT_STATISTICS = gql`
  query GetImportStatistics {
    getImportStatistics {
      total
      totalImported
    }
  }
`;

export const BACKEND_USER_IS_LOGGED_IN = gql`
  query BackendUserIsLoggedIn {
    backendUserIsLoggedIn
  }
`;

export const BACKEND_USER_GET_CURRENT = gql`
  query BackendUserGetCurrentUser {
    backendUserGetCurrentUser {
      lastname
      permissions
      avatar
      username
      email
      createdAt
      clientPermissions
      firstname
      id
    }
  }
`;

/**
 * TODO: Media is missing. Media has to be added to the CMS Queries
 */

export const CMS_GET_PAGES = gql`
  query GetPages {
    getPages {
      _id
      content
      title
      description
      client
      createdAt
      updatedAt
    }
  }
`;

export const CMS_GET_PAGE = gql`
  query GetPage($id: String!) {
    getPage(_id: $id) {
      _id
      content
      client
      createdAt
      updatedAt
      title
      pageTitle
      description
      img {
        updatedAt
        createdAt
        mimeType
        filename
        altName
        altText
        _id
      }
    }
  }
`;

export const BACKEND_USER_GET_ALL = gql`
  query GetBackendUsers($offset: Int, $limit: Int) {
    getBackendUsers(offset: $offset, limit: $limit) {
      count
      backendUsers {
        id
        username
        email
        avatar
        createdAt
        updatedAt
        clientPermissions
        permissions
        firstname
        lastname
      }
    }
  }
`;

export const GET_INCOMING = gql`
  query IncomingPagination($offset: Int, $limit: Int) {
    incomingPagination(offset: $offset, limit: $limit) {
      count
      incoming {
        createdAt
        data
        target
        id
      }
    }
  }
`;

export const CMS_GET_DEALS = gql`
  query GetDeals($offset: Int, $limit: Int, $orderBy: String, $sortOrder: Float, $headline: String, $client: String!) {
    getDeals(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      sortOrder: $sortOrder
      headline: $headline
      client: $client
    ) {
      items {
        _id
        content
        client
        createdAt
        updatedAt
        headline
        price
        reducedPrice
        isActive
        metaTitle
        metaDescription
        endsAt
        tags
        images {
          _id
          filename
          mimeType
          createdAt
          updatedAt
        }
        links {
          type
          url
          label
          target
        }
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const CMS_GET_SIBLING_RELEASES = gql`
  query GetSiblingReleases(
    $clientB: String!
    $clientA: String!
    $onlyClientA: Boolean
    $orderBy: String
    $sortOrder: Float
    $limit: Int
    $offset: Int
  ) {
    getSiblingReleases(
      clientB: $clientB
      clientA: $clientA
      onlyClientA: $onlyClientA
      orderBy: $orderBy
      sortOrder: $sortOrder
      limit: $limit
      offset: $offset
    ) {
      totalItems
      totalPages
      currentPage
      items {
        _id
        content
        client
        createdAt
        updatedAt
        images {
          _id
          filename
          originalName
          altName
          altText
          createdAt
          updatedAt
          mimeType
        }
        headline
        price
        reducedPrice
        releaseDate
        hasReleaseDate
        isActive
        tags
        metaTitle
        metaDescription
        sibling {
          _id
          client
        }
      }
    }
  }
`;

export const CMS_GET_SIBLING_DEALS = gql`
  query GetSiblingDeals(
    $clientB: String!
    $clientA: String!
    $offset: Int
    $limit: Int
    $orderBy: String
    $dealType: String
    $sortOrder: Float
    $onlyClientA: Boolean
    $headline: String
    $isActive: Boolean
  ) {
    getSiblingDeals(
      clientB: $clientB
      clientA: $clientA
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      dealType: $dealType
      sortOrder: $sortOrder
      onlyClientA: $onlyClientA
      headline: $headline
      isActive: $isActive
    ) {
      totalItems
      totalPages
      currentPage
      items {
        _id
        client
        headline
        images {
          _id
          mimeType
          filename
          originalName
          altName
          altText
        }
        isActive
        isScheduled
        rank
        updatedAt
        sibling {
          _id
          client
          headline
          rank
          dealType
        }
        price
        reducedPrice
        metaTitle
        metaDescription
        dealType
        links {
          type
          url
          label
          target
        }
      }
    }
  }
`;
export const USER_USAGE_EXISTING_OR_IN_USE = gql`
  query Get_USER_USAGE($targetId: String!) {
    existingOrInUsage(targetId: $targetId) {
      existing
      inUse
      user
    }
  }
`;

export const CMS_GET_TEMPLATES = gql`
  query GetTemplates($client: String!, $type: String) {
    getTemplates(client: $client, type: $type) {
      _id
      title
      createdAt
      updatedAt
      content
      client
      type
    }
  }
`;

export const CMS_GET_TEMPLATE = gql`
  query GetTemplate($id: String!) {
    getTemplate(_id: $id) {
      _id
      title
      createdAt
      updatedAt
      content
      client
      type
    }
  }
`;

export const CMS_GET_DEAL = gql`
  query GetDeal($getDealId: String!) {
    getDeal(id: $getDealId) {
      _id
      content
      client
      createdAt
      updatedAt
      headline
      subline
      path
      price
      reducedPrice
      priceText
      strikePrice
      isActive
      bullet1Key
      bullet1Val
      bullet2Key
      bullet2Val
      bullet3Key
      bullet3Val
      bullet4Key
      bullet4Val
      startAt
      endsAt
      validTill
      voucherValidUntil
      exclusive
      voucher
      voucherFile
      voucherText
      tags
      metaTitle
      metaDescription
      sibling {
        _id
      }
      images {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        createdAt
        updatedAt
      }
      buttonLabel
      links {
        type
        url
        label
        target
      }
    }
  }
`;

export const CMS_GET_RELEASES = gql`
  query getReleases(
    $offset: Int
    $limit: Int
    $orderBy: String
    $sortOrder: Float
    $client: String
    $headline: String
    $hasReleaseDate: Boolean
  ) {
    getReleases(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      sortOrder: $sortOrder
      client: $client
      headline: $headline
      hasReleaseDate: $hasReleaseDate
    ) {
      items {
        _id
        content
        client
        createdAt
        updatedAt
        images {
          _id
          filename
          originalName
          altName
          altText
          createdAt
          updatedAt
          mimeType
        }
        headline
        price
        reducedPrice
        releaseDate
        hasReleaseDate
        isActive
        metaTitle
        metaDescription
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const CMS_GET_RELEASE = gql`
  query GetRelease($getReleaseByIdId: String!) {
    getReleaseById(id: $getReleaseByIdId) {
      _id
      content
      path
      client
      createdAt
      updatedAt
      price
      reducedPrice
      releaseDate
      hasReleaseDate
      isActive
      articleId
      bullet1Key
      bullet1Val
      bullet2Key
      bullet2Val
      bullet3Key
      bullet3Val
      bullet4Key
      bullet4Val
      tags
      sibling {
        _id
      }
      shops {
        _id
        type
        url
        releaseDate
        price
        shop {
          _id
          previewImage {
            _id
            filename
            originalName
            mimeType
            altName
            altText
            createdAt
            updatedAt
          }
          headline
          tags
        }
      }
      raffles {
        _id
        type
        url
        releaseDate
        validUntil
        price
        shop {
          _id
          previewImage {
            _id
            filename
            originalName
            mimeType
            altName
            altText
            createdAt
            updatedAt
          }
          headline
          tags
        }
      }
      images {
        _id
        filename
        altName
        altText
        mimeType
        createdAt
        updatedAt
      }
      headline
      metaTitle
      metaDescription
    }
  }
`;

export const CMS_GET_URL_TREE = gql`
  query GetUrlTree($client: String) {
    getUrlTree(client: $client) {
      root
      client
      _id
    }
  }
`;

export const CMS_GET_URL_NODE = gql`
  query GetNode($getNodeId: String!) {
    getNode(id: $getNodeId) {
      _id
      path
      previewPath
      isActive
      title
      target {
        _id
      }
      tags
      redirect
    }
  }
`;

export const CMS_GET_PUSHDETAILS = gql`
  query GetPushDetails(
    $orderBy: String
    $sortOrder: Float
    $limit: Int
    $offset: Int
    $client: String
    $headline: String
  ) {
    getPushDetails(
      orderBy: $orderBy
      sortOrder: $sortOrder
      limit: $limit
      offset: $offset
      client: $client
      headline: $headline
    ) {
      items {
        tags
        displayDate
        subline
        images {
          updatedAt
          createdAt
          altName
          altText
          mimeType
          originalName
          filename
          _id
        }
        links {
          type
          url
          label
          target
        }
        headline
        updatedAt
        createdAt
        client
        content
        _id
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const CMS_GET_PUSHDETAIL = gql`
  query GetPushDetail($getPushDetailId: String!) {
    getPushDetail(id: $getPushDetailId) {
      _id
      tags
      displayDate
      subline
      images {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        createdAt
        updatedAt
      }
      links {
        type
        url
        label
        target
      }
      content
      client
      createdAt
      headline
      updatedAt
    }
  }
`;

export const CMS_GET_PUSHNOTIFICATIONS = gql`
  query GetPushNotifications($client: String, $limit: Int, $offset: Int, $sortOrder: Float, $orderBy: String) {
    getPushNotifications(client: $client, limit: $limit, offset: $offset, sortOrder: $sortOrder, orderBy: $orderBy) {
      items {
        _id
        content
        client
        createdAt
        updatedAt
        title
        body
        previewImage {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        topic
        reference
        referenceType
        shippingDate
        firebaseSentDate
        firebaseResponse
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const GET_ALL_NOT_RUNNING_IMPORT_ALERTS = gql`
  query getAllNotRunningImportAlerts {
    getAllNotRunningImportAlerts {
      importDate
      shopId
      id
      shopName
      importDate
      importedProducts
      importedImages
      createdProducts
      updatedProducts
      totalProducts
      shopName
      isGzip
      isZip
      uncompressedFileLength
      createdMappings
      createdRelations
      foundAvailabilities
      foundExisting
      missingBrand
      missingColor
      missingColor
      missingIds
      lastSuccessfullImport
      missingStock
      resolvedIds
      importStatus
      importResult
    }
  }
`;

export const GET_ALL_SUCCESSFUL_IMPORT_ALERTS = gql`
  query getAllSuccessfulImportAlerts {
    getAllSuccesfulImportAlerts {
      importDate
      shopId
      id
      shopName
      importDate
      importedProducts
      importedImages
      createdProducts
      updatedProducts
      totalProducts
      shopName
      isGzip
      isZip
      uncompressedFileLength
      createdMappings
      createdRelations
      lastSuccessfullImport
      foundAvailabilities
      foundExisting
      missingBrand
      missingColor
      missingIds
      missingStock
      resolvedIds
      importStatus
      importResult
    }
  }
`;

export const GET_ALL_IMPORT_ALERTS = gql`
  query getAllImportAlerts {
    getAllImportAlerts {
      importDate
      shopId
      id
      shopName
      importDate
      importedProducts
      importedImages
      createdProducts
      updatedProducts
      totalProducts
      shopName
      isGzip
      isZip
      uncompressedFileLength
      createdMappings
      createdRelations
      lastSuccessfullImport
      foundAvailabilities
      foundExisting
      missingBrand
      missingColor
      missingColor
      missingIds
      missingStock
      resolvedIds
      importStatus
      importResult
    }
  }
`;

export const CMS_GET_PUSHNOTIFICATION = gql`
  query GetPushNotificationById($getPushNotificationByIdId: String!) {
    getPushNotificationById(id: $getPushNotificationByIdId) {
      _id
      body
      title
      subtitle
      client
      createdAt
      updatedAt
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        createdAt
        updatedAt
      }
      topic
      reference
      referenceType
      shippingDate
      isActive
      firebaseSentDate
      firebaseResponse
    }
  }
`;

export const CMS_GET_BLOGS = gql`
  query GetBlogs($client: String, $offset: Int, $limit: Int, $sortOrder: Float, $orderBy: String) {
    getBlogs(client: $client, offset: $offset, limit: $limit, sortOrder: $sortOrder, orderBy: $orderBy) {
      items {
        _id
        content
        client
        createdAt
        updatedAt
        isActive
        title
        rank
        previewImage {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        tags
        metaTitle
        metaDescription
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const CMS_GET_BLOG = gql`
  query GetBlogById($getBlogByIdId: String!) {
    getBlogById(id: $getBlogByIdId) {
      _id
      content
      path
      client
      createdAt
      updatedAt
      isActive
      title
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        createdAt
        updatedAt
      }
      tags
      metaTitle
      metaDescription
    }
  }
`;

export const CMS_GET_STORIES = gql`
  query GetStories(
    $client: String
    $offset: Int
    $limit: Int
    $sortOrder: Float
    $orderBy: String
    $isActive: Boolean
  ) {
    getStories(
      client: $client
      offset: $offset
      limit: $limit
      sortOrder: $sortOrder
      orderBy: $orderBy
      isActive: $isActive
    ) {
      items {
        _id
        content
        client
        createdAt
        updatedAt
        isActive
        startDate
        endDate
        previewImage {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        tags
        reference
        referenceType
        headline
        subline
        priceText
        price
        reducedPrice
        strikePrice
        voucher
        buttonLabel
        buttonType
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const CMS_GET_STORY = gql`
  query GetStoryById($getStoryByIdId: String!) {
    getStoryById(id: $getStoryByIdId) {
      _id
      content
      client
      createdAt
      updatedAt
      startDate
      endDate
      isActive
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        createdAt
        updatedAt
      }
      tags
      reference
      referenceType
      headline
      subline
      priceText
      price
      reducedPrice
      strikePrice
      voucher
      buttonLabel
      buttonType
    }
  }
`;

export const GET_STORY_RATING_COUNT = gql`
  query GetStoryRatingCount($getStoryRatingCountId: String!) {
    getStoryRatingCount(id: $getStoryRatingCountId)
  }
`;

export const CMS_GET_IN_APP_FLYERS = gql`
  query GetInAppFlyers(
    $offset: Int
    $limit: Int
    $sortOrder: Float
    $orderBy: String
    $isActive: Boolean
    $client: String
  ) {
    getInAppFlyers(
      offset: $offset
      limit: $limit
      sortOrder: $sortOrder
      orderBy: $orderBy
      isActive: $isActive
      client: $client
    ) {
      currentPage
      items {
        _id
        content
        client
        createdAt
        updatedAt
        isActive
        headline
        image {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          ignoreIndex
          createdAt
          updatedAt
        }
        dealHeadline
        subline
        priceText
        price
        reducedPrice
        strikePrice
        voucher
        voucherFile
        voucherText
        bullet1Key
        bullet1Val
        bullet2Key
        bullet2Val
        bullet3Key
        bullet3Val
        bullet4Key
        bullet4Val
        reference
        referenceType
        buttonLabel
        startDate
        endDate
      }
      totalItems
      totalPages
    }
  }
`;

export const CMS_GET_IN_APP_FLYER = gql`
  query GetInAppFlyerById($getInAppFlyerByIdId: String!) {
    getInAppFlyerById(id: $getInAppFlyerByIdId) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      headline
      image {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        ignoreIndex
        createdAt
        updatedAt
      }
      dealHeadline
      subline
      priceText
      price
      reducedPrice
      strikePrice
      voucher
      voucherFile
      voucherText
      bullet1Key
      bullet1Val
      bullet2Key
      bullet2Val
      bullet3Key
      bullet3Val
      bullet4Key
      bullet4Val
      reference
      referenceType
      buttonLabel
      startDate
      endDate
    }
  }
`;

export const CMS_GET_CURRENT_FLYER = gql`
  query GetCurrentInAppFlyer($client: String) {
    getCurrentInAppFlyer(client: $client) {
      _id
      content
      client
      createdAt
      updatedAt
      isActive
      headline
      image {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        ignoreIndex
        createdAt
        updatedAt
      }
      dealHeadline
      subline
      priceText
      price
      reducedPrice
      strikePrice
      voucher
      voucherFile
      voucherText
      bullet1Key
      bullet1Val
      bullet2Key
      bullet2Val
      bullet3Key
      bullet3Val
      bullet4Key
      bullet4Val
      reference
      referenceType
      buttonLabel
      startDate
      endDate
    }
  }
`;

export const CMS_GET_RESELL_SHOPS = gql`
  query GetResellShops(
    $client: String
    $orderBy: String
    $sortOrder: Float
    $limit: Int
    $offset: Int
    $isActive: Boolean
  ) {
    getResellShops(
      client: $client
      orderBy: $orderBy
      sortOrder: $sortOrder
      limit: $limit
      offset: $offset
      isActive: $isActive
    ) {
      items {
        tags
        headline
        previewImage {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        previewImageDark {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        isActive
        updatedAt
        createdAt
        client
        _id
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const CMS_GET_RESELL_SHOP = gql`
  query GetResellShopById($getResellShopByIdId: String!) {
    getResellShopById(id: $getResellShopByIdId) {
      tags
      headline
      previewImage {
        altName
        altText
        mimeType
        originalName
        filename
        _id
      }
      previewImageDark {
        altName
        altText
        mimeType
        originalName
        filename
        _id
      }
      isActive
      updatedAt
      createdAt
      client
      _id
    }
  }
`;

export const CMS_GET_INSTAFEEDS = gql`
  query GetInstafeeds($offset: Int, $limit: Int, $sortOrder: Float, $orderBy: String, $client: String) {
    getInstafeeds(offset: $offset, limit: $limit, sortOrder: $sortOrder, orderBy: $orderBy, client: $client) {
      currentPage
      totalPages
      totalItems
      items {
        referenceType
        reference
        subline
        tags
        headline
        previewImage {
          createdAt
          updatedAt
          altName
          altText
          mimeType
          originalName
          filename
          _id
        }
        isActive
        updatedAt
        createdAt
        client
        _id
      }
    }
  }
`;
export const CMS_GET_INSTAFEED = gql`
  query GetInstafeedById($getInstafeedByIdId: String!) {
    getInstafeedById(id: $getInstafeedByIdId) {
      _id
      client
      createdAt
      updatedAt
      isActive
      previewImage {
        _id
        filename
        originalName
        mimeType
        altName
        altText
        createdAt
        updatedAt
      }
      headline
      subline
      tags
      reference
      referenceType
    }
  }
`;

export const CMS_GET_CATEGORY = gql`
  query GetCategoryById($getCategoryByIdId: String!) {
    getCategoryById(id: $getCategoryByIdId) {
      _id
      isActive
      title
      client
      query
      previewImage {
        _id
        filename
        originalName
        altName
        mimeType
        altText
        createdAt
        updatedAt
      }
      rank
      colorCode
      tag
    }
  }
`;

export const CMS_GET_CATEGORIES = gql`
  query GetCategorys(
    $client: String!
    $offset: Int
    $limit: Int
    $sortOrder: Float
    $orderBy: String
    $tag: CategoryTag
    $isActive: Boolean
  ) {
    getCategorys(
      client: $client
      offset: $offset
      limit: $limit
      sortOrder: $sortOrder
      orderBy: $orderBy
      tag: $tag
      isActive: $isActive
    ) {
      items {
        _id
        isActive
        title
        client
        query
        previewImage {
          _id
          filename
          originalName
          mimeType
          altName
          altText
          createdAt
          updatedAt
        }
        rank
        colorCode
        tag
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const CMS_GET_VOUCHER_LIST = gql`
  query GetVoucherList($getVoucherListId: String!) {
    getVoucherList(id: $getVoucherListId) {
      id
      name
      expirationDate
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const CMS_GET_VOUCHER_FROM_LIST = gql`
  query GetVoucherFromList($getVoucherFromListId: String!) {
    getVoucherFromList(id: $getVoucherFromListId) {
      code
    }
  }
`;

export const CMS_GET_VOUCHER_LISTS = gql`
  query GetVoucherLists($offset: Int, $limit: Int, $isActive: Boolean) {
    getVoucherLists(offset: $offset, limit: $limit, isActive: $isActive) {
      items {
        id
        name
        expirationDate
        isActive
        createdAt
        updatedAt
        voucherCount
        voucherUsed
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const CMS_GET_TAGS_BY_CLIENT_AND_TYPE = gql`
  query GetTagsByClientAndType($client: String!, $type: String!) {
    getTagsByClientAndType(client: $client, type: $type) {
      tags
    }
  }
`;

export const CMS_GET_TAGS = gql`
  query GetTags($client: String!, $category: String) {
    getTags(client: $client, category: $category) {
      _id
      client
      name
      categoryId {
        _id
        client
        name
      }
    }
  }
`;

export const CMS_GET_TAG_CATEGORIES = gql`
  query GetTagCategories($client: String!) {
    getTagCategories(client: $client) {
      _id
      client
      name
      tags {
        _id
        client
        name
      }
    }
  }
`;

export const GET_RANDOM_WALLPAPER_IMAGE = gql`
  query GetRandomWallpaperImage {
    getRandomWallpaperImage {
      id
      width
      height
      url
      photographer
      photographer_url
      photographer_id
      avg_color
      src {
        original
        large2x
        large
        medium
        small
        portrait
        landscape
        tiny
      }
      liked
      alt
    }
  }
`;
export const CMS_GENERATE_TEXT = gql`
  query GenerateText($prompt: String!) {
    generateText(prompt: $prompt)
  }
`;

export const CMS_GENERATE_LONG_TEXT = gql`
  query GenerateLongText($prompt: String!, $maxToken: Float!) {
    generateLongText(prompt: $prompt, max_token: $maxToken)
  }
`;

export const CMS_SEARCH_IMAGES = gql`
  query SearchImage($offset: Int, $limit: Int, $sortOrder: Float, $orderBy: String, $query: String) {
    searchImage(offset: $offset, limit: $limit, sortOrder: $sortOrder, orderBy: $orderBy, query: $query) {
      items {
        id
        path
        preview
        filename
        keywords
        createdAt
        updatedAt
      }
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const SEARCH_EVENT_REPORTS = gql`
  query SearchEventReports($query: String, $args: [ReportArgsInputDto!], $size: Int, $page: Int) {
    searchEventReports(query: $query, args: $args, size: $size, page: $page) {
      query
      pagination {
        total
        pageSize
        pageCount
        currentPage
      }
      hits
    }
  }
`;

export const SEARCH_REPORTS = gql`
  query SearchReports($dateStart: DateTime, $state: String, $shopId: Float, $dateEnd: DateTime) {
    searchReports(dateStart: $dateStart, state: $state, shopId: $shopId, dateEnd: $dateEnd) {
      shopName
      shopId
      importProductCount
      importStartDate
      importDate
      updatedProducts
      createdProducts
      fileChange
      processor
      client
      state
      message
    }
  }
`;
