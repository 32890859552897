import { render, staticRenderFns } from "./DoubleButton.vue?vue&type=template&id=1412e5b9&scoped=true"
import script from "./DoubleButton.vue?vue&type=script&lang=ts"
export * from "./DoubleButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1412e5b9",
  null
  
)

export default component.exports