
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';

import CKEditor from 'ckeditor4-vue';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
    ckeditor: CKEditor.component,
  },
})
export default class Ticker extends BaseCmsComponentClass {
  editorConfig = {
    allowedContent: true,
    disableNativeSpellChecker: false,
    extraPlugins: 'justify',
    toolbarGroups: [
      { name: 'links', groups: ['links'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    ],
    removeButtons:
      'JustifyBlock,Save,NewPage,Preview,Print,Templates,Form,Checkbox,Radio,TextField,Textarea,Button,Select,ImageButton,HiddenField,Superscript,Subscript,Scayt,SelectAll,Find,Replace,CopyFormatting,BidiLtr,BidiRtl,Language,Blockquote,CreateDiv,Image,Flash,Smiley,PageBreak,Iframe,Font,FontSize,TextColor,BGColor,ShowBlocks,About',
  };
  posts: { post: string; isHighlighted: boolean }[] = [];
  wantToAddNew = false;
  currentEditablePost = -1;
  postToEdit = '';

  newPost() {
    this.wantToAddNew = true;
  }
  addPost() {
    this.posts.unshift({ post: this.postToEdit, isHighlighted: false });
    this.wantToAddNew = false;
    this.$props.content.posts = this.posts;
    this.postToEdit = '';
  }
  startEditingPost(post: string, index: number) {
    this.postToEdit = post;
    this.currentEditablePost = index;
  }
  updatePost(index: number) {
    this.posts[index].post = this.postToEdit;
    this.currentEditablePost = -1;
    this.$props.content.posts = this.posts;
    this.postToEdit = '';
  }
  updatePostHighlighted(index: number) {
    console.log(this.posts[index].isHighlighted);
    this.$props.content.posts = this.posts;
  }
  mounted() {
    this.posts = this.$props.content.posts || [];
  }
}
