
import { Component } from 'vue-property-decorator';
import {
  GET_CLIENTS,
  GET_PRODUCT,
  GET_PRODUCT_BRANDS,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_COLORS_FULL,
  GET_SHOPS,
  GET_SIZES,
  GET_INTERACTIONS,
} from '@/graphlql/queries';
import { cdnLink } from '@/main';
import { mixins } from 'vue-class-component';
import IntlMixin from '@/mixins/intl';
import RichText from '../../components/common/RichText.vue';
import ProductStatistics from '../../components/product/ProductStatistics.vue';
import {
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_BASE_DATA,
  ACTIVATE_PRODUCTS_BY_ARTICLE_ID,
  DEACTIVATE_PRODUCTS_BY_ARTICLE_ID,
  RESET_PRODUCT_IMAGEORDER,
} from '@/graphlql/mutations';
import BaseView from '../common/BaseView.vue';
import * as helper from '@/helper/data-helper';
import VersionsTable from '@/components/versions/VersionsTable.vue';

@Component({
  components: { RichText, ProductStatistics, BaseView, VersionsTable },
  apollo: {
    getShoeSizes: {
      query: GET_SIZES,
      variables: {
        getShoeSizesKey: '',
      },
      result(data: any) {
        this.sizes = data.data.getShoeSizes;
      },
      manual: true,
    },
    shops: {
      query: GET_SHOPS,
    },
    categories: {
      query: GET_PRODUCT_CATEGORIES,
    },

    colors: {
      query: GET_PRODUCT_COLORS_FULL,
    },
    interaction: {
      query: GET_INTERACTIONS,
      variables() {
        return {
          ids: [0],
          date: new Date().getTime(),
        };
      },
      result(data: any) {
        if (data.data != null && data.data.getInteraction.length != 0) {
          this.interactionScore = data.data.getInteraction[0].interactionScore;
        }
      },
      manual: true,
    },
    brands: {
      query: GET_PRODUCT_BRANDS,
    },
    clients: {
      query: GET_CLIENTS,
    },
    product: {
      query: GET_PRODUCT,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          productArticleId: this.$route.params.articleId,
          productClient: 1,
        };
      },
      result(data: any) {
        if (data != null && data.data != null && data.data.product != null) {
          const product = data.data.product;
          this.articleId = product.articleId;
          this.id = product.id;
          if (this.currentClient != data.data.product.client) {
            this.currentClient = data.data.product.client;
          }
          if (product.brand != null) {
            if (this.brands != null && this.brands.length > 0) {
              for (const brand of this.brands) {
                if (brand.id == product.brand.id) {
                  this.selectedBrand = brand;
                  break;
                }
              }
            } else {
              this.selectedBrand = product.brand;
            }

            this.$apollo.queries.interaction.refetch({ ids: [product.id], date: new Date().getTime() });
            this.$apollo.queries.getShoeSizes.refetch({ getShoeSizesKey: product.brand.name });
          }
          if (product.color != null && product.color.length > 0) {
            this.selectedColor = product.color[0];
          }

          if (product.category != null) {
            this.selectedCategories = [];
            for (const cat of product.category) {
              this.selectedCategories.push(cat.id);
            }
          }
          this.product = product;
          this.$forceUpdate();
        }
      },
    },
  },
})
export default class ProductDetailView extends mixins(IntlMixin) {
  id = -1;
  interactionScore = 0;
  product!: any;
  articleId!: any;
  shops: any[] = [];
  clients: any[] = [];
  shopMap: any = {};
  currentClient: number = 1;
  categories: any[] = [];
  gallery = false;
  selectedBrand: any = null;
  selectedColor: any = null;
  selectedCategories: any[] = [];
  path: any[] = [];

  cdn: string | undefined = cdnLink;

  sizes: any = {};

  saveSuccess: boolean = false;
  saveError: boolean = false;
  saveResult: boolean = false;
  saveLoading: boolean = false;
  showEmptyColumns: boolean = false;

  saveAllSuccess: boolean = false;
  saveAllError: boolean = false;
  saveAllResult: boolean = false;
  saveAllLoading: boolean = false;

  changeClient() {
    this.$apollo.queries.product.refetch({
      productArticleId: this.articleId,
      productClient: this.currentClient,
    });
  }
  getDate(time: string): string {
    const d = new Date(parseInt(time));
    return d.toLocaleDateString();
  }

  getCurrentShop(shopId: number): any {
    if (this.shopMap[shopId] != null) return this.shopMap[shopId];

    const shop = this.shops.find((shop) => shop.id === shopId);
    this.shopMap[shop.id] = shop;
    return shop ?? {};
  }

  getCategory(catId: number): any {
    return this.categories.find((cat) => cat.id === catId) ?? {};
  }

  getSizes(catId: number, index: number): any[] {
    const cat = this.getCategory(catId);
    let temp = [];

    if (cat != null) {
      if (cat.name == 'Herren') {
        temp = this.sizes.men;
      }

      if (cat.name == 'Damen') {
        temp = this.sizes.women;
      }

      if (cat.name == 'Kinder') {
        temp = this.sizes.children;
      }
    }

    if (!this.showEmptyColumns) {
      const result: any[] = [];
      for (const value of temp) {
        if (
          this.isSizeActive(index, value.eu, 'eu') ||
          this.isSizeActive(index, value.us, 'us') ||
          this.isSizeActive(index, value.uk, 'uk')
        ) {
          result.push(value);
        }
      }
      return result;
    } else {
      return temp;
    }
  }

  getClient(clientId: number): any {
    return this.clients.find((client) => client.id === clientId) ?? {};
  }

  isSizeActive(index: number, size: string, type: string): boolean {
    if (this.product != null && this.product.availabilities != null && this.product.availabilities[index] != null) {
      const availability = this.product.availabilities[index];

      let sizes = [];
      switch (type) {
        case 'eu':
          sizes = availability.sizesEu;
          break;
        case 'uk':
          sizes = availability.sizesUk;
          break;
        case 'us':
          sizes = availability.sizesUs;
          break;
      }
      return sizes.indexOf(size) > -1;
    }

    return false;
  }

  toggleSize(index: number, size: string, type: string) {
    if (this.product != null && this.product.availabilities != null && this.product.availabilities[index] != null) {
      let sizeIndex = -1;
      switch (type) {
        case 'eu':
          sizeIndex = this.product.availabilities[index].sizesEu.indexOf(size);

          if (sizeIndex > -1) {
            this.product.availabilities[index].sizesEu.splice(sizeIndex, 1);
          } else {
            this.product.availabilities[index].sizesEu.push(size);
          }

          break;
        case 'uk':
          sizeIndex = this.product.availabilities[index].sizesUk.indexOf(size);

          if (sizeIndex > -1) {
            this.product.availabilities[index].sizesUk.splice(sizeIndex, 1);
          } else {
            this.product.availabilities[index].sizesUk.push(size);
          }
          break;
        case 'us':
          sizeIndex = this.product.availabilities[index].sizesUs.indexOf(size);

          if (sizeIndex > -1) {
            this.product.availabilities[index].sizesUs.splice(sizeIndex, 1);
          } else {
            this.product.availabilities[index].sizesUs.push(size);
          }
          break;
      }
    }
  }

  resetImageOrder() {
    if (this.product && this.product.id) {
      this.$apollo.mutate({
        // Query
        mutation: RESET_PRODUCT_IMAGEORDER,
        // Parameters
        variables: {
          id: this.product.id,
        },
      });
    }
  }

  async saveProduct() {
    // new articleId / styleId was set, check if it already exists
    if (this.product.articleId !== this.articleId) {
      const exists = await this.$apollo
        .query({
          query: GET_PRODUCT,
          variables: {
            productArticleId: this.product.articleId,
            productClient: this.product.clientId,
          },
        })
        .then((data) => {
          if (data.data.product) {
            throw new Error(`Die ArtikelId ${this.product.articleId} existiert bereits! Bitte wähle eine andere ID.`);
          }
          return false;
        })
        .catch((e) => {
          console.log('Error', e);
          alert(e);
          return true;
        });
      if (exists) {
        return false;
      }
    }

    this.saveLoading = true;

    const categories: any[] = [];

    for (const cat of this.selectedCategories) {
      categories.push(helper.cleanTypeData(this.getCategory(cat), []));
    }
    const updateProduct = helper.cleanTypeData(this.product, ['updatedAt']);

    updateProduct.brand = helper.cleanTypeData(this.selectedBrand, []);
    updateProduct.color = [helper.cleanTypeData(this.selectedColor, [])];
    updateProduct.category = categories;
    updateProduct.touched = true;
    updateProduct.uvp = parseInt(updateProduct.uvp, 10);

    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_PRODUCT_BASE_DATA,
        // Parameters
        variables: {
          updateProductBaseDataInput: updateProduct,
        },
      })
      .then((data) => {
        this.changeClient();
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = false;
        this.saveSuccess = true;
        setTimeout(() => this.resetSaveButton(), 1000);
      })
      .catch((error) => {
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = true;
        this.saveSuccess = false;
        setTimeout(() => this.resetSaveButton(), 1000);
      });

    // Only updated for the exact client, updatebasedata updates for all clients.
    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_PRODUCT,
        // Parameters
        variables: {
          updateProductInput: updateProduct,
        },
      })
      .then((data) => {
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = false;
        this.saveSuccess = true;
        setTimeout(() => this.resetSaveButton(), 1000);
      })
      .catch((error) => {
        this.saveLoading = false;
        this.saveResult = true;
        this.saveError = true;
        this.saveSuccess = false;
        setTimeout(() => this.resetSaveButton(), 1000);
      });
    const id = this.product.articleId;
    const oldArticleId = this.articleId;

    setTimeout(() => {
      window.location.href = window.location.href.replace(encodeURIComponent(oldArticleId), id);
    }, 100);
  }

  set DarstellungsModell(model: string) {
    this.product.displayModel = model;
    this.product.title = this.product.brand.name + ' ' + this.product.displayModel;
  }

  get DarstellungsModell() {
    return this.product.displayModel;
  }

  activateAllClients() {
    //this.saveProduct();
    this.saveAllLoading = true;
    const productIds: number[] = [this.articleId];
    this.$apollo
      .mutate({
        // Query
        mutation: ACTIVATE_PRODUCTS_BY_ARTICLE_ID,
        // Parameters
        variables: {
          activateProductsInput: productIds,
        },
      })
      .then((data) => {
        this.saveAllLoading = false;
        this.saveAllResult = true;
        this.saveAllError = false;
        this.saveAllSuccess = true;
        setTimeout(() => this.resetSaveAllButton(), 1000);
      })
      .catch((error) => {
        this.saveAllLoading = false;
        this.saveAllResult = true;
        this.saveAllError = true;
        this.saveAllSuccess = false;
        setTimeout(() => this.resetSaveAllButton(), 1000);
      });
    this.changeClient();
  }

  deactivateAllClients() {
    this.saveAllLoading = true;
    const productIds: number[] = [this.articleId];
    this.$apollo
      .mutate({
        // Query
        mutation: DEACTIVATE_PRODUCTS_BY_ARTICLE_ID,
        // Parameters
        variables: {
          activateProductsInput: productIds,
        },
      })
      .then((data) => {
        this.saveAllLoading = false;
        this.saveAllResult = true;
        this.saveAllError = false;
        this.saveAllSuccess = true;
        setTimeout(() => this.resetSaveAllButton(), 1000);
      })
      .catch((error) => {
        this.saveAllLoading = false;
        this.saveAllResult = true;
        this.saveAllError = true;
        this.saveAllSuccess = false;
        setTimeout(() => this.resetSaveAllButton(), 1000);
      });
    this.changeClient();
  }

  resetSaveAllButton() {
    this.saveAllSuccess = false;
    this.saveAllError = false;
    this.saveAllResult = false;
    this.saveAllLoading = false;
  }

  mounted() {
    this.articleId = this.$route.params.articleId;

    this.$store.dispatch('navigationModule/fetchNavigation');
    this.$store.dispatch('navigationModule/setNavigationIndexes');
    const navigationPath = this.$store.getters['navigationModule/getNavigationElements'];

    for (const parent in navigationPath) {
      for (const c in navigationPath[parent].children) {
        const child = navigationPath[parent].children[c];
        if (child.path == '/products') {
          this.path.push(navigationPath[parent]);
          this.path.push(child);
        }
      }
    }
  }

  resetSaveButton() {
    this.saveSuccess = false;
    this.saveError = false;
    this.saveResult = false;
    this.saveLoading = false;
  }
}
