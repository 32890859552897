
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import TemplatesTable from '@/components/cms/TemplatesTable.vue';

@Component({
  components: {
    TemplatesTable,
    BaseView,
  },
})
export default class TemplatesView extends Vue {
  createTemplateHandler() {
    this.$router.push({ path: `/cms/templates/${this.$route.params.client}/create` });
  }
}
