
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import EmojiSelect from '@/components/cms/EmojiSelect.vue';
import clients from '../../graphClient';
import {
  CMS_CREATE_PUSHNOTIFICATION,
  CMS_DELETE_PUSHNOTIFICATION,
  CMS_UPDATE_PUSHNOTIFICATION,
  UPDATE_USER_USAGE,
  CREATE_USER_USAGE,
} from '@/graphlql/mutations';
import CMSView from './CMSView.vue';
import { CMS_GET_PUSHDETAIL, CMS_GET_PUSHNOTIFICATION, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import * as helper from '@/helper/data-helper';
import UploadImage from '@/components/cms/UploadImage.vue';
import ReferenceInput from '@/components/cms/ReferenceInput.vue';

@Component({
  components: {
    BaseView,
    CMSView,
    UploadImage,
    ReferenceInput,
    EmojiSelect,
  },
  apollo: {
    getPushNotificationById: {
      query: CMS_GET_PUSHNOTIFICATION,
      client: 'clientB',
      variables: {
        getPushNotificationByIdId: '0',
      },
      skip() {
        return this.skipQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getPushNotificationById != null) {
          this.pushnotification = data.data.getPushNotificationById;
          if (this.pushnotification['shippingDate'] != null) {
            this.pushnotification['shippingDate'] = new Date(this.pushnotification['shippingDate']);
          }

          if (this.pushnotification['isActive'] == null) {
            this.pushnotification['isActive'] = false;
          }

          this.siteTitle = 'Pushnotificataion (' + this.pushnotification.title + ') speichern';

          this.loaded = true;
          this.changed = false;

          //   this.setItems(this.page.content.dynamicContent);
        }
      },
    },
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.id,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.id;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.dealId !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.id !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.id !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.id === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
    getPushDetail: {
      query: CMS_GET_PUSHDETAIL,
      client: 'clientB',
      variables: {
        getPushDetailId: '0',
      },
      skip() {
        return this.skipPushQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getPushDetail != null) {
          const detail = data.data.getPushDetail;

          this.pushnotification.reference = detail._id;
          if (detail.images != null && detail.images.length > 0) {
            this.pushnotification.previewImage = detail.images[0];
          }

          if (detail.headline != null && detail.headline.length > 0) {
            this.pushnotification.title = detail.headline;
          }

          if (detail.subline != null && detail.subline.length > 0) {
            this.pushnotification.body = detail.subline;
          }

          if (detail.displayDate != null && detail.displayDate.length > 0) {
            this.pushnotification.shippingDate = new Date(detail.displayDate);
          }
        }
      },
    },
  },
  computed: {
    getClient() {
      const self = this as PushNotificationView;
      if (self.pushnotification.referenceType === 'pushdetail') {
        return self.$route.params.client;
      }
      return self.$route.params.client + '-app';
    },
  },
  watch: {
    $route(to, from) {
      const self = this as PushNotificationView;
      self.refetch();
    },
    pushnotification: {
      handler() {
        const self = this as PushNotificationView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    const self = this as PushNotificationView;
    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class PushNotificationView extends Vue {
  siteTitle: string = '';
  skipQuery: boolean = true;
  skipPushQuery: boolean = true;

  changed: boolean = false;
  loaded: boolean = false;
  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';
  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'pushnotification',
    lastTimeEdited: new Date(),
  };

  pushnotification: any = {
    previewImage: {},
    title: '',
    subtitle: '',
    body: '',
    shippingDate: new Date(),
    topic: 'ALL',
    reference: '',
    referenceType: 'link',
    isActive: true,
  };

  getEmpty() {
    return {
      previewImage: {},
      title: '',
      body: '',
      shippingDate: new Date(),
      topic: 'ALL',
      reference: '',
      referenceType: 'link',
      isActive: true,
    };
  }

  refetch() {
    if (this.$route.params.id == 'create') {
      this.siteTitle = 'Push Notification erstellen';
      this.pushnotification = this.getEmpty();

      if (this.$route.query.reference != null && this.$route.query.reference != '') {
        this.skipPushQuery = false;
        this.pushnotification.referenceType = this.$route.query.referenceType;
        this.$apollo.queries.getPushDetail.refetch({
          getPushDetailId: this.$route.query.reference,
        });
      }
    } else if (this.$route.params.id != null) {
      this.skipQuery = false;
      this.$apollo.queries.getPushNotificationById.refetch({
        getPushNotificationByIdId: this.$route.params.id,
      });
    }
  }

  mounted() {
    this.refetch();
  }

  referenceChanged(reference: string) {
    this.pushnotification.reference = reference;
  }

  referenceTypeChanged(referenceType: string) {
    this.pushnotification.referenceType = referenceType;
  }

  async saveNew() {
    this.pushnotification.client = this.$route.params.client;
    clients.clientB
      .mutate({
        mutation: CMS_CREATE_PUSHNOTIFICATION,
        variables: {
          pushNotification: this.getData(),
        },
      })
      .then((data) => {
        if (
          data != null &&
          data.data != null &&
          data.data.createPushNotification != null &&
          data.data.createPushNotification._id
        ) {
          this.$router.push({
            path: `/cms/pushnotifications/${this.$route.params.client}/${data.data.createPushNotification._id}`,
          });
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async saveExisting() {
    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_PUSHNOTIFICATION,
        variables: {
          pushNotification: this.getData(),
        },
      })
      .then((data) => {
        if (
          data != null &&
          data.data != null &&
          data.data.updatePushNotification != null &&
          data.data.updatePushNotification._id
        ) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
          this.siteTitle = 'Pushnotificataion (' + this.pushnotification.title + ') speichern';
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async deleteHandler() {
    const content = prompt('Bitte "löschen" zum bestätigen eingeben');
    if (content === 'löschen') {
      if (this.pushnotification?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_PUSHNOTIFICATION,
          variables: {
            deletePushNotificationId: this.pushnotification._id,
          },
        });
      }

      // Surpress unsaved change prompt, a bit hacky but works
      this.changed = false;
      this.$router.push({ name: 'PushNotificationsView' });
    }
  }

  async userLeave() {
    this.currentUsage.inUse = false;

    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  getData() {
    this.pushnotification.client = this.$route.params.client;

    const returnData = JSON.parse(
      JSON.stringify(helper.cleanTypeData(this.pushnotification, ['__typename', 'updatedAt', 'createdAt']))
    );

    if (returnData['previewImage'] && returnData['previewImage']['_id']) {
      returnData['previewImage'] = returnData['previewImage']['_id'];
    } else {
      delete returnData['previewImage'];
    }

    delete returnData['firebaseSentDate'];
    delete returnData['firebaseResponse'];

    if (this.pushnotification['shippingDate']) {
      returnData['shippingDate'] = this.pushnotification['shippingDate'].toISOString();
    }

    return returnData;
  }

  async saveHandler() {
    if (this.$route.params.id == 'create') {
      this.saveNew();
    } else if (this.$route.params.id != null) {
      this.saveExisting();
    }
  }
}
