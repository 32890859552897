import { Module } from 'vuex';
import { DragInterface } from '../../../interfaces/cms/drag.interface';
import { RootStateInterface } from '../../../interfaces/rootState.interface';

const cmsItemsModule: Module<{ items: DragInterface[]; client: string }, RootStateInterface> = {
  namespaced: true,
  state: {
    items: [],
    client: '',
  },
  getters: {
    getItems(state: { items: DragInterface[] }) {
      return state.items;
    },
    getClient(state: { items: DragInterface[]; client: string }) {
      return state.client;
    },
  },
  mutations: {
    SET_ITEMS(state: { items: DragInterface[]; client: string }, items: DragInterface[], client?: string) {
      state.items = items;
      if (client != null) {
        state.client = client;
      }
    },
    SET_CLIENT(state: { items: DragInterface[]; client: string }, client?: string) {
      if (client != null) {
        state.client = client;
      }
    },
    ADD_ITEM(state: { items: DragInterface[]; client: string }, item: DragInterface) {
      state.items.push(item);
    },
    UPDATE_ITEM(state: { items: DragInterface[]; client: string }, item: DragInterface) {
      state.items.push(item);
    },
    REMOVE_ITEM(state: { items: DragInterface[]; client: string }, item: DragInterface) {
      const removeItemIndex = (currentItems: DragInterface[]): boolean => {
        for (const [index, currentItem] of currentItems.entries()) {
          if (currentItem.id === item.id) {
            currentItems.splice(index, 1);
            return true;
          }
        }

        for (const [index, currentItem] of currentItems.entries()) {
          if (currentItem.items && currentItem.items.length > 0) {
            removeItemIndex(currentItem.items);
          }
        }
        return false;
      };
      const itemIndex = removeItemIndex(state.items);
    },
  },
  actions: {
    setItems({ commit }, items: DragInterface[]) {
      commit('SET_ITEMS', items);
    },
    setClient({ commit }, client?: string) {
      commit('SET_CLIENT', client);
    },
    updateItem({ commit }, item: DragInterface) {
      commit('UPDATE_ITEM', item);
    },
    addItem({ commit }, item: DragInterface) {
      commit('ADD_ITEM', item);
    },
    removeItem({ commit }, item: DragInterface) {
      commit('REMOVE_ITEM', item);
    },
  },
};

export default cmsItemsModule;
