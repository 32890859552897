<template>
  <div class="panel-block is-flex-grow-1 is-justify-content-space-between">
    <b-field grouped :message="errorMessageTag(tag._id)" :type="errorMessageType(tag._id)">
      <p class="px-3 py-2 is-flex-grow-1" v-if="tagToUpdate !== tag._id">{{ tag.name }}</p>
      <b-input expanded v-if="tagToUpdate === tag._id" v-model="newTagName" @keyup.native.enter="updateTag(tag)" />
      <b-select
        placeholder="Kategorie"
        v-model="newTagCategory"
        v-if="tagToUpdate === tag._id && tagCategories.length > 0"
      >
        <option v-for="category in tagCategories" :value="category._id" :key="category._id">
          {{ category.name }}
        </option>
      </b-select>
      <div class="buttons is-flex-wrap-nowrap">
        <b-button
          size="is-small"
          type="is-dark"
          icon-right="close-thick"
          @click="close"
          v-if="tagToUpdate !== tag._id && closable"
        />
        <b-button
          size="is-small"
          type="is-info"
          icon-right="pen"
          @click="onUpdateTag(tag)"
          v-if="tagToUpdate !== tag._id"
        />
        <b-button
          size="is-small"
          type="is-success"
          icon-right="floppy"
          v-if="tagToUpdate === tag._id"
          @click="updateTag(tag)"
        />
        <b-button
          size="is-small"
          type="is-info"
          icon-right="cancel"
          v-if="tagToUpdate === tag._id"
          @click="cancelUpdateTag"
        />
        <b-button size="is-small" type="is-danger" icon-right="delete" @click="deleteTag(tag)" />
      </div>
    </b-field>
  </div>
</template>

<script>
import Vue from 'vue';
import { CMS_UPDATE_TAG, CMS_DELETE_TAG } from '@/graphlql/mutations';
import { EventBus } from '../../components/common/event/eventbus';

export default {
  name: 'TagEditor',
  props: {
    tag: {
      type: Object,
      required: true,
    },
    tagCategories: {
      type: Array,
      required: true,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newTagCategory: '',
      tagToUpdate: '',
      newTagName: '',
      errorTag: false,
      errorMsgTag: '',
      errorMsgId: '',
      loading: false,
      success: false,
    };
  },
  methods: {
    close() {
      this.$emit('closeTagEditor');
    },
    onUpdateTag(tag) {
      this.tagToUpdate = tag._id;
      this.newTagName = tag.name;
    },
    updateTag(tag) {
      this.errorMsgId = tag._id;
      if (!this.newTagName) {
        this.errorTag = true;
        this.errorMsgTag = 'Kann nicht gespeichert werden! Kategorie ist leer!';
        return;
      }
      this.$apollo
        .mutate({
          mutation: CMS_UPDATE_TAG,
          variables: {
            id: this.tagToUpdate,
            tagInput: {
              client: this.$route.params.client,
              name: this.newTagName,
              categoryId: this.newTagCategory === '' ? null : this.newTagCategory,
            },
          },
        })
        .then((data) => this.onTagFullfilled(data))
        .catch((error) => this.onTagError(error));
    },
    cancelUpdateTag() {
      this.tagToUpdate = '';
      this.newTagName = '';
      this.newTagCategory = null;
    },
    deleteTag(tag) {
      this.errorMsgId = tag._id;
      this.$apollo
        .mutate({
          mutation: CMS_DELETE_TAG,
          variables: {
            id: tag._id,
          },
        })
        .then((data) => this.onTagFullfilled(data))
        .catch((error) => this.onTagError(error));
    },
    onTagFullfilled(data) {
      // EVENT TO TAG_CAT
      EventBus.$emit('updateTagPanel');
      EventBus.$emit('updateCategoryPanel');
      this.errorMsgId = '';
      this.tagToUpdate = '';
      this.newTagName = '';
      this.loading = false;
      this.errorTag = false;
      this.success = true;
      this.newTag = '';
      this.errorMsgTag = '';
      this.newTagCategory = '';
    },
    onTagError(error) {
      this.loading = false;
      this.result = true;
      this.errorTag = true;
      this.errorMsgTag = error.message;
      this.success = false;
    },
    errorMessageTag(id) {
      if (this.errorMsgId === id && this.errorTag && this.errorMsgTag !== '') {
        return this.errorMsgTag;
      }
      return '';
    },
    errorMessageType(id) {
      if (this.errorMsgId === id && this.errorTag && this.errorMsgTag !== '') {
        return 'is-danger';
      }
      return '';
    },
  },
};
</script>
