
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import InstaFeedTable from '@/components/cms/InstaFeedTable.vue';

@Component({
  components: {
    InstaFeedTable,
    BaseView,
  },
})
export default class InstaFeedsView extends Vue {
  createHandler() {
    this.$router.push({ path: `/cms/instafeeds/${this.$route.params.client}/create` });
  }
}
