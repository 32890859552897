
import { Component, Vue } from 'vue-property-decorator';
import { UPDATE_PRODUCT_COLORS } from '@/graphlql/mutations';

import UploadImage from '@/components/common/UploadImage.vue';
import EditReplaceSingleColor from '@/components/common/EditReplaceSingleColor.vue';

@Component({
  props: ['value'],
  components: {
    UploadImage,
    EditReplaceSingleColor,
  },
})
export default class ColorEdit extends Vue {
  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;
  value: any;

  updateHex(hex: string) {
    this.value.hex = hex;
  }

  updatePath(path: any) {
    this.value.image = path;
  }

  reloadColors() {
    this.$emit('reload-color-table', { message: 'reloading colors' });
  }
  save() {
    // Call to the graphql mutation
    this.loading = true;
    this.$apollo
      .mutate({
        // Query
        mutation: UPDATE_PRODUCT_COLORS,
        // Parameters
        variables: {
          updateColorInput: {
            id: this.value.id,
            name: this.value.name,
            color: this.value.color,
            image: this.value.image,
            hex: this.value.hex,
          },
        },
      })
      .then((data) => {
        this.loading = false;
        this.result = true;
        this.error = false;
        this.success = true;

        setTimeout(() => this.clearStates(), 1000);
      })
      .catch((error) => {
        this.loading = false;
        this.result = true;
        this.error = true;
        this.success = false;
        console.error(error);
        setTimeout(() => this.clearStates(), 1000);
      });
  }
  clearStates() {
    this.loading = false;
    this.result = false;
    this.error = false;
    this.success = false;
  }
}
