
import { layer } from '@fortawesome/fontawesome-svg-core';
import { Vue } from 'vue-property-decorator';

export default abstract class TableBase extends Vue {
  columns: any[] = [];

  tableData: any[] = [];

  keys: any[] = [];

  page: number = 1;
  perPage: number = 10;
  total: number = 0;
  loading: boolean = true;
  selected: any = null;

  onPageChange(page: number) {
    this.page = page;
    this.loadAsyncData();
    this.putState();
  }

  putState() {
    const url = new URL(window.location.toString());

    const self = this as any;
    for (const key of this.keys) {
      if (self[key] != null) {
        url.searchParams.set(key, self[key].toString());
      }
    }

    window.history.pushState({}, '', url.toString());
  }

  popstateEventAction() {
    this.init();
  }

  init() {
    if (this.$route != null && this.$route.query != null) {
      const query = this.$route.query;
      const self = this as any;
      for (const key of this.keys) {
        if (query[key] != null) {
          if (typeof self[key] == 'number') {
            self[key] = parseInt(query[key].toString(), 10);
          } else {
            self[key] = query[key].toString();
          }
        } else {
          if (typeof self[key] == 'number') {
            self[key] = self[key + 'Default'] != null ? self[key + 'Default'] : 1;
          }
        }
      }
    }

    this.loadAsyncData();
  }

  abstract loadAsyncData(): void;

  mounted() {
    this.init();
    window.addEventListener('popstate', this.popstateEventAction);
    this.loadAsyncData();
  }
}
