
import clients from '@/graphClient';
import { CMS_UPLOAD_FILE } from '@/graphlql/mutations';
import cacheImage from '@/helper/image-caching';
import { cmsLink } from '@/main';
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';
import { $axios } from '@/store/axios-instance';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
  },
  watch: {
    file(newFile: any, oldFile: any) {
      // Call to the graphql mutation
      const self = this as Banner;
      console.log("Banner CMS_UPLOAD_FILE DONE");

      const formData = new FormData();
      formData.append('file', newFile);

      $axios.post(process.env.VUE_APP_CMS_URL + "media/upload",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((data: any) => {
        if (data?.data) {
          self.content.filename = data.data.filename;
          self.content.originalName = data.data.originalName;
          self.content._id = data.data._id;
          self.content.mimeType = data.data.mimeType;
          self.content.altName = data.data.altName;
          self.content.altText = data.data.altText;

          cacheImage(self.content._id, self.content.originalName);
        }
      })
        .catch((error) => {
          console.error(error);
        });
    },
  },
})
export default class Banner extends BaseCmsComponentClass {
  file: any = null;
  target: string[] = ['self', 'blank'];
  cdn: string | undefined = cmsLink;
}
