
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import PushNotificationsTable from '@/components/cms/PushNotificationsTable.vue';

@Component({
  components: {
    PushNotificationsTable,
    BaseView,
  },
})
export default class PushNotificationsView extends Vue {
  createHandler() {
    this.$router.push({ path: `/cms/pushnotifications/${this.$route.params.client}/create` });
  }
}
