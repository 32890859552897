import { Locales } from '@/i18n/locales';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { messages } from '@/i18n/translations/index.ts';

export const defaultLocale = Locales.DE;
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: defaultLocale,
  messages
});

export default i18n;
