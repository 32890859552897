
import Component from 'vue-class-component';
import ShopList from '../ShopList.vue';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';
import { cmsLink } from '@/main';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
    ShopList,
  },
})
export default class ReleaseShops extends BaseCmsComponentClass {
  cdn: string | undefined = cmsLink;
}
