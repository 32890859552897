//This Method gets used to remove certain keys from an object. this is nessesary because graphql adds a "__typename" property to everything you receive from the database.
//Once you made changes to the object graphql wont allow you to resubmit it because it has a property thats not supposed to be there. thus you filter it first.
export function cleanTypeData(
  context: any,
  omitKeys: any[],
  originalData: any | undefined = null,
  keepKeys: any[] | undefined = []
): any {
  let result: any = {};
  if (Array.isArray(context) || typeof context === 'object') {
    for (const key in context) {
      if (keepKeys != null && keepKeys.indexOf(key) >= 0) {
        result[key] = originalData != null ? originalData[key] : null;
      } else if (omitKeys == null || omitKeys.indexOf(key) == -1) {
        if (key.indexOf('__') !== 0) {
          if (Array.isArray(context[key])) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            result[key] = cleanTypeArray(
              context[key],
              omitKeys,
              originalData != null ? originalData[key] : null,
              keepKeys
            );
          } else if (context[key] == null) {
            result[key] = null;
          } else if (context[key] instanceof Date) {
            result[key] = new Date(context[key]);
          } else if (typeof context[key] === 'object') {
            result[key] = cleanTypeData(
              context[key],
              omitKeys,
              originalData != null ? originalData[key] : null,
              keepKeys
            );
          } else {
            result[key] = context[key];
          }
        }
      }
    }
  } else {
    result = context;
  }

  return result;
}

export function cleanTypeArray(
  data: any[],
  omitKeys: any[],
  originalData: any[] | undefined = [],
  keepKeys: any[] | undefined = []
): any[] {
  const result: any[] = [];

  for (const key in data) {
    if (omitKeys == null || omitKeys.indexOf(key) == -1) {
      result.push(cleanTypeData(data[key], omitKeys, originalData != null ? originalData[key] : null, keepKeys));
    }
  }
  return result;
}

export function applyImageIds(obj: any) {
  if (obj['images'] != null) {
    let i = 1;
    for (const item of obj.images) {
      item.itemId = i++;
    }
  }
}

export function getNextId(items: any[]) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  let max = resolveMaxId(items);
  return ++max;
}

export function isEmpty(obj: any) {
  let notEmpty = true;

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      notEmpty = obj[key].length > 0;
    } else {
      notEmpty = obj[key] != null && obj[key] != '';
    }
  }

  return !notEmpty;
}

export function resolveMaxId(items: any[]) {
  if (items == null || items.length == 0) {
    return 0;
  }

  let max = 0;

  for (const element of items) {
    if (element.id > max) {
      max = element.id;
    }

    if (element.items != null && element.items.length > 0) {
      const temp = resolveMaxId(element.items);
      if (temp > max) {
        max = temp;
      }
    }
  }

  return max;
}
