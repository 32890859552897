
import { cmsLink } from '@/main';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
  props: {
    items: {
      type: Array,
    },
    label: {
      type: String,
    },
    reference: {
      type: String,
    },
  },
  watch: {
    query(newValue, old) {
      this.$emit('query-changed', newValue);
    },
  },
})
export default class BaseChooser extends Vue {
  reference: any;
  query: string = '';
  items: any;
  label: any;

  cdn: string | undefined = cmsLink;

  itemSelected(item: any) {
    this.$emit('item-selected', item);
  }
}
