
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import DealsTable from '@/components/cms/DealsTable.vue';

@Component({
  components: {
    DealsTable,
    BaseView,
  },
})
export default class DealsView extends Vue {
  dealType: string = 'single';
  dealLabel: string = 'Deal';

  mounted() {
    if (this.$route.params.dealType == 'double') {
      this.dealType = this.$route.params.dealType;
      this.dealLabel = 'DoubleDeal';
    }
  }

  createDealHandler() {
    this.$router.push({
      path: `/cms/deals/${this.dealType}/${this.$route.params.clientA}/${this.$route.params.clientB}/create`,
    });

    //this.$router.push({ name: 'CreateDeal' });
  }
}
