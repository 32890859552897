
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
})
export default class BaseCmsComponent extends Vue {
  open: boolean = false;
  content: any;
  copy: any;
  settings: any;
  linkModal: boolean = true;

  openHandler() {
    this.open = !this.open;
  }

  closeHandler() {
    console.log('closeHandler');
  }

  abortHandler() {
    //close handler
    this.openHandler();
  }
  clickedContent(event: any) {
    //console.log(event);
    if (event) {
      //event.preventDefault();
      event.stopPropagation();

      return false;
    }
  }

  mounted() {
    if (this.content != null) {
      this.copy = JSON.parse(JSON.stringify(this.content));
    }

    const sidebar = document.getElementsByClassName('sidebar-content');

    if (sidebar.length > 0) {
      Array.from(sidebar).forEach((sb) => {
        sb.addEventListener('click', this.clickedContent);
      });
    }

    if(this.settings.component === 'RichText' || this.settings.component === 'Ticker') {
      this.linkModal = false;
    }
  }

  beforeDestroy() {
    const sidebar = document.getElementsByClassName('sidebar-content');

    if (sidebar.length > 0) {
      Array.from(sidebar).forEach((sb) => {
        sb.removeEventListener('click', this.clickedContent);
      });
    }
  }
}
