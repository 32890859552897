export const common = {
  from: 'from',
  shops: 'Shops',
  discountCode: 'discount code',
  instead: 'instead',
  plus: 'plus',
  shipping: 'shipping',
  update1: 'Update',
  update2: 'ago',
  minutes: 'minutes',
  hours: 'hours',
  days: 'days'
};
