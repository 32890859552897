<template>
  <draggable
    tag="div"
    class="tree-section is-relative"
    group="foo"
    :id="`node-${parentId}`"
    :list="list"
    ghostClass="ghost"
    dragClass="drag"
    chosenClass="chosen"
    :move="moveHandler"
    @change="changeHandler"
  >
    <div
      class="tree-content is-relative flex align-items-center tree-level-1"
      v-for="(element, index) in list"
      :key="index"
      :ref="'comp' + element._id"
    >
      <div class="tree-section level-1">
        <div class="tree-content is-align-items-center">
          <!--
                    <div class="tree-box is-flex is-align-items-center is-justify-content-center"></div>
          -->
          <p class="m-0 ml-2 tree-grab" v-on:click.stop.prevent="treeClicked(element)" :title="element.path">
            <font-awesome-icon :icon="['fal', 'file']" />
            {{ element.title != null && element.title.length > 0 ? element.title : element.path }}
          </p>
        </div>
      </div>
      <div class="cccc tree-section tree-level-1" v-if="element.children.length > 0">
        <div class="tree-line is-small"></div>
      </div>
      <nested-tree
        :parentId="element._id"
        :list="element.children"
        class="has-flex-gap pt-2 tree-level-1"
        v-on:tree:clicked="treeClicked"
        v-on:tree:updated="treeUpdated"
      />
    </div>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable';

import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  name: 'NestedTree',
  components: {
    draggable,
  },
  props: {
    parentId: {
      type: String,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
  },
})
export default class NestedTree extends Vue {
  treeClicked(element) {
    this.$emit('tree:clicked', element);
  }

  treeUpdated(element) {
    this.$emit('tree:updated', element);
  }

  moveHandler(event) {
    if (event.from !== event.to) {
      return false;
    }
  }

  changeHandler(event) {
    this.$emit('tree:updated', { _id: this.parentId, children: this.list });
  }
}
</script>
<style lang="scss">
.drag-list-item {
  flex: 1 1 0;
}

.ghost {
  margin: 0;
}

.border-x {
  border: 1px dashed $grey-dark;
}

.tree-grab {
  user-select: none;
  cursor: grab;
}

.icon {
  pointer-events: none;
}

.tree-level-1 {
  margin-left: 0.5rem;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
