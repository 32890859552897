
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SmallDashboardCard extends Vue {
  @Prop({ required: false })
  link?: string;

  @Prop({ required: false })
  contextColor?: string;

  routeToLink() {
    if (this.link != null) {
      this.$router.push({ path: this.link });
    }
  }

  hasContent() {
    return !!this.$slots.content;
  }
}
