import { render, staticRenderFns } from "./ReleaseShops.vue?vue&type=template&id=df7b0cac&scoped=true"
import script from "./ReleaseShops.vue?vue&type=script&lang=ts"
export * from "./ReleaseShops.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df7b0cac",
  null
  
)

export default component.exports