
import { Component, Vue } from 'vue-property-decorator';
import StoreEdit from '@/components/stores/StoreEdit.vue';
import StoreCreate from '@/components/stores/StoreCreate.vue';
import { GET_SHOPS_PAGINATION } from '@/graphlql/queries';
import TableBase from '../common/TableBase.vue';
import { cdnLink, cmsLink } from '@/main';

@Component({
  components: {
    StoreEdit,
    StoreCreate,
  },
  apollo: {
    shops: {
      query: GET_SHOPS_PAGINATION,
      manual: true,
      variables: {
        shopsPaginationOffset: 0,
        shopsPaginationLimit: 10,
      },

      fetchPolicy: 'no-cache',
      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            this.loading = data.loading;
            const tableData: any = [];
            for (const shop of data.data.shopsPagination.shops) {
              tableData.push(shop);
            }

            this.tableData = tableData;
            this.total = data.data.shopsPagination.count;
          }
        }
      },
    },
  },
  filters: {
    format: function (value: string) {
      if (!value) return '';
      const d = new Date(value);
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    },
  },
})
export default class StoresTable extends TableBase {
  cdn: string | undefined = cdnLink;

  loadAsyncData() {
    this.$apollo.queries.shops.refetch({
      shopsPaginationOffset: this.perPage * (this.page - 1),
      shopsPaginationLimit: this.perPage,
    });
  }

  createNew() {
    this.$router.push({ path: `/stores/create` });
  }

  keys = ['page'];

  onPageChange(page: number) {
    super.onPageChange(page);
  }

  popstateEventAction() {
    super.popstateEventAction();
    this.loadAsyncData();
  }

  mounted() {
    super.mounted();
    this.loadAsyncData();
  }
}
