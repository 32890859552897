
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import clients from '../../graphClient';
import CustomTagInput from '@/components/tags/CustomTagInput.vue';
import {
  CMS_CREATE_INSTAFEED,
  CMS_UPDATE_INSTAFEED,
  CMS_DELETE_INSTAFEED,
  UPDATE_USER_USAGE,
  CREATE_USER_USAGE,
} from '@/graphlql/mutations';
import CMSView from './CMSView.vue';
import { CMS_GET_INSTAFEED, USER_USAGE_EXISTING_OR_IN_USE } from '@/graphlql/queries';
import * as helper from '@/helper/data-helper';
import ReferenceInput from '@/components/cms/ReferenceInput.vue';
import UploadImage from '@/components/cms/UploadImage.vue';
import VersionsTable from '@/components/versions/VersionsTable.vue';

@Component({
  components: {
    BaseView,
    CMSView,
    UploadImage,
    ReferenceInput,
    CustomTagInput,
    VersionsTable,
  },
  apollo: {
    getInstafeedById: {
      query: CMS_GET_INSTAFEED,
      client: 'clientB',
      variables: {
        getInstafeedByIdId: '0',
      },
      skip() {
        return this.skipQuery;
      },
      fetchPolicy: 'no-cache',
      manual: true,
      result(data: any) {
        if (data.data != null && data.data.getInstafeedById != null) {
          if (data.data.getInstafeedById.previewImage == null) {
            data.data.getInstafeedById.previewImage = {};
          }
          this.instafeed = data.data.getInstafeedById;
          this.siteTitle = 'Feed (' + this.instafeed.headline + ') speichern';

          this.loaded = true;
          this.changed = false;
        }
      },
    },
    checkExistingOrInUse: {
      query: USER_USAGE_EXISTING_OR_IN_USE,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,
      variables() {
        return {
          targetId: this.$route.params.id,
        };
      },
      async result(data) {
        if (data != null && data.data != null && data.data.existingOrInUsage != null) {
          this.existing = data.data.existingOrInUsage.existing;
          this.currentUsage.inUse = data.data.existingOrInUsage.inUse;
          this.usingUser = data.data.existingOrInUsage.user;
          const currentUser = this.$store.getters['authModule/getCurrentUser'];
          this.currentUsage.userId = currentUser.id;
          this.currentUsage.username = currentUser.username;
          this.currentUsage.targetId = this.$route.params.id;
          if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser !== currentUser.username &&
            this.$route.params.dealId !== 'create' &&
            process.env.USER_USAGE_CHECK_ENABLED == 'true'
          ) {
            const answer = window.confirm(
              `${this.usingUser} bearbeitet die Seite gerade. Wollen Sie die Seite dennoch bearbeiten?`
            );
            if (answer) {
              this.inUsage = true;
              this.acceptWarning = false;
              this.currentUsage.inUse = true;
              await clients.clientB.mutate({
                mutation: UPDATE_USER_USAGE,
                variables: {
                  targetId: this.currentUsage.targetId,
                  userUsageDto: {
                    ...this.currentUsage,
                  },
                },
              });
              return;
            } else {
              this.acceptWarning = true;
              this.$router.replace('/');
            }
          } else if (this.currentUsage.inUse === false && this.existing && this.$route.params.id !== 'create') {
            this.currentUsage.inUse = true;
            this.inUsage = true;
            await clients.clientB.mutate({
              mutation: UPDATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          } else if (
            this.currentUsage.inUse &&
            this.existing &&
            this.usingUser === currentUser.username &&
            this.$route.params.id !== 'create'
          ) {
            this.inUsage = true;
            this.currentUsage.inUse = true;
          } else if (this.$route.params.id === 'create') {
            this.inUsage = false;
            this.currentUsage.inUse = false;
          } else {
            this.inUsage = true;
            this.currentUsage.inUse = true;
            await clients.clientB.mutate({
              mutation: CREATE_USER_USAGE,
              variables: {
                targetId: this.currentUsage.targetId,
                userUsageDto: {
                  ...this.currentUsage,
                },
              },
            });
          }
        }
      },
    },
  },
  watch: {
    $route(to, from) {
      const self = this as InstaFeedView;
      self.refetch();
    },
    instafeed: {
      handler() {
        const self = this as InstaFeedView;
        if (self.loaded) {
          self.loaded = false;
        } else {
          self.changed = true;
        }
      },
      deep: true,
    },
  },
  beforeRouteLeave(to, from, next) {
    const self = this as InstaFeedView;
    if (self.changed) {
      const answer = window.confirm('Möchtest Du Seite wirklich verlassen. Es gibt ungespeicherte Änderungen!');
      if (answer) {
        self.userLeave();
        next();
      } else {
        next(false);
      }
    } else {
      if (self.acceptWarning) {
        next();
      } else if (!self.acceptWarning && self.inUsage) {
        self.userLeave();
        next();
      }
      next();
    }
  },
})
export default class InstaFeedView extends Vue {
  siteTitle: string = '';
  skipQuery: boolean = true;

  templateTabIndex: number = 0;
  templates: any[] = [];

  changed: boolean = false;
  loaded: boolean = false;

  inUsage: boolean = false;
  currentUsage: any = {
    inUse: true,
    userId: '',
    username: '',
    targetId: '',
    type: 'instaFeeds',
    lastTimeEdited: new Date(),
  };
  existing: boolean = false;
  acceptWarning: boolean = false;
  usingUser: string = '';

  instafeed: any = {
    headline: '',
    subline: '',
    isActive: false,
    previewImage: {},
    reference: '',
    referenceType: '',
  };

  getEmpty() {
    return {
      headline: '',
      subline: '',
      isActive: false,
      previewImage: {},
      reference: '',
      referenceType: 'link',
    };
  }

  refetch() {
    if (this.$route.params.id == 'create') {
      this.siteTitle = 'Feedeintrag erstellen';
      this.instafeed = this.getEmpty();
    } else if (this.$route.params.id != null) {
      this.skipQuery = false;
      this.$apollo.queries.getInstafeedById.refetch({
        getInstafeedByIdId: this.$route.params.id,
      });
    }
  }

  mounted() {
    this.refetch();
  }

  referenceChanged(reference: string) {
    this.instafeed.reference = reference;
  }

  referenceTypeChanged(referenceType: string) {
    this.instafeed.referenceType = referenceType;
  }

  async saveNew() {
    clients.clientB
      .mutate({
        mutation: CMS_CREATE_INSTAFEED,
        variables: {
          instafeed: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.createInstafeed != null && data.data.createInstafeed._id) {
          this.$router.push({
            path: `/cms/instafeeds/${this.$route.params.client}/${data.data.createInstafeed._id}`,
          });
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  async saveExisting() {
    clients.clientB
      .mutate({
        mutation: CMS_UPDATE_INSTAFEED,
        variables: {
          instafeed: this.getData(),
        },
      })
      .then((data) => {
        if (data != null && data.data != null && data.data.updateInstafeed != null && data.data.updateInstafeed._id) {
          this.$buefy.notification.open({
            message: `Erfolgreich gespeichert`,
            duration: 5000,
            type: 'is-success',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });

          this.changed = false;
        } else {
          this.$buefy.notification.open({
            message: `Fehler beim Anlegen`,
            duration: 5000,
            type: 'is-danger',
            pauseOnHover: true,
            position: 'is-bottom-right',
          });
        }
      });
  }

  getData() {
    this.instafeed.client = this.$route.params.client;

    const returnData = JSON.parse(
      JSON.stringify(helper.cleanTypeData(this.instafeed, ['__typename', 'updatedAt', 'createdAt']))
    );

    if (returnData['previewImage'] && returnData['previewImage']['_id']) {
      returnData['previewImage'] = returnData['previewImage']['_id'];
    } else {
      delete returnData['previewImage'];
    }

    return returnData;
  }

  async saveHandler() {
    if (this.$route.params.id == 'create') {
      this.saveNew();
    } else if (this.$route.params.id != null) {
      this.saveExisting();
    }
  }

  async userLeave() {
    this.currentUsage.inUse = false;

    if (this.currentUsage.targetId !== 'create')
      await clients.clientB.mutate({
        mutation: UPDATE_USER_USAGE,
        variables: {
          targetId: this.currentUsage.targetId,
          userUsageDto: {
            ...this.currentUsage,
          },
        },
      });
  }

  async deleteHandler() {
    const content = prompt('Bitte "löschen" zum bestätigen eingeben');
    if (content === 'löschen') {
      if (this.instafeed?._id) {
        await clients.clientB.mutate({
          mutation: CMS_DELETE_INSTAFEED,
          variables: {
            deleteInstafeedId: this.instafeed._id,
          },
        });
      }

      // Surpress unsaved change prompt, a bit hacky but works
      this.changed = false;
      this.$router.push({ name: 'InstaFeedsView' });
    }
  }
}
