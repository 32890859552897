
import { CMS_SEARCH_IMAGES } from '@/graphlql/queries';
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
@Component({
  components: { BaseView },
  apollo: {
    searchImage: {
      query: CMS_SEARCH_IMAGES,
      client: 'clientB',
      fetchPolicy: 'no-cache',
      manual: true,

      variables: {
        query: null,
        offset: 0,
        limit: 20,
      },
      result(data) {
        if (data != null && data.data != null && data.data.searchImage != null) {
          this.container = [];
          this.addToContainer(data.data.searchImage.items);
        }
      },
    },
  },
  watch: {
    query(oldValue, newValue) {
      const self = this as ImagesView;
      if (self.timerRef) {
        clearTimeout(self.timerRef);
        self.timerRef = null;
      }

      self.timerRef = window.setTimeout(() => {
        self.updateQuery();
      }, 500);
    },
  },
})
export default class ImagesView extends Vue {
  query: string = '';
  timerRef: any = null;
  pageSize: number = 20;
  page: number = 0;

  container: any[] = [];
  containerCount: number = 4;

  addToContainer(items: any[]) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const containerIndex = i % this.containerCount;

      if (this.container[containerIndex] == undefined) this.container[containerIndex] = [];

      this.container[containerIndex].push(item);
    }

    console.log(this.container);
  }

  updateQuery() {
    this.page = 0;
    this.$apollo.queries.searchImage.refetch({ query: this.query, offset: 0, limit: 20 });
  }
}
