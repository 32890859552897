
import { Component, Vue } from 'vue-property-decorator';
import { GET_REPORTS, GET_SHOPS, GET_ALL_NOT_RUNNING_IMPORT_ALERTS, GET_ALL_SUCCESSFUL_IMPORT_ALERTS, GET_ALL_IMPORT_ALERTS } from '@/graphlql/queries';
import LineChart from '../../components/common/LineChart.vue';
import TableBase from '../common/TableBase.vue';

@Component({
  components: { LineChart },
  apollo: {
    getAllNotRunningImports: {
      query: GET_ALL_NOT_RUNNING_IMPORT_ALERTS,
      manual: true,
      result(data) {
        if(data.data != null) {
          this.notRunningImportAlerts = data.data.getAllNotRunningImportAlerts;
        }
      }
    },
    getAllSuccessfulImports: {
      query: GET_ALL_SUCCESSFUL_IMPORT_ALERTS,
      manual: true,
      result(data) {
        if(data.data != null) {
          this.tableData = data.data.getAllSuccesfulImportAlerts;
          this.runningImportAlerts = data.data.getAllSuccesfulImportAlerts;
        }
      }
    },
    getAllImports: {
      query: GET_ALL_IMPORT_ALERTS,
      manual: true,
      result(data) {
        if(data.data != null) {
          this.importAlerts = data.data.getAllImportAlerts
        }
      }
    },
    getShops: {
      query: GET_SHOPS,
      variables: {
        shops: [],
      },
      manual: true,
      result(data) {
        if (data.data != null && data.data.shops != null) {
          for (const shop of data.data.shops) {
            this.shops.push(shop);
          }
        }
      },
    },
  },
  watch: {
    async selectedShop() {
      const self = this as ReportingTable;
      await self.getData();
      self.updateLabels();
    },
    async selectedImportOption() {
      const self = this as ReportingTable;
      await self.getData();
      self.updateImportData();
    },
    async selectedStartDate() {
      const self = this as ReportingTable;
      await self.getData();
      self.updateLabels();
    },
  },
})
export default class ReportingTable extends TableBase {
  loading: any = false;
  tableData: any[] = [];
  notRunningImportAlerts: any[] = [];
  runningImportAlerts: any[] = [];
  importAlerts: any[] = [];
  perPage = 200

  options: any = [
    {
      id: "1",
      field: "Erfolgreiche Imports"
    },
    {
      id: "2",
      field: "Fehlerhafte Imports"
    },
    {
      id: "3",
      field: "Alle Imports"
    },
  ];
  selectedImportOption: any = this.options[0].id;
  columns = [
    {
      field: 'shopName',
      label: 'Shop Name',
      width: '30%',
    },
    {
      field: 'shopId',
      label: 'Shop Id',
      numeric: true,
      width: '20%',
    },
    {
      field: 'importDate',
      label: 'Import Date',
      width: '30%',
    },
    {
      field: 'updatedProducts',
      label: 'Updated Products',
      width: '20%',
    },
    {
      field: 'createdProducts',
      label: 'Created Products',
      width: '20%',
    },
    {
      field: 'importProductCount',
      label: 'Import Data',
      width: '20%',
    },
    {
      field: 'fileChange',
      label: 'File Changed',
      width: '20%',
    },
  ];
  weekdays: any[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dateMap: any = {
    day: 1,
    week: 7,
    month: 31,
    all: 100000,
  };
  startDates: any[] = [
    { value: this.dateMap['day'], label: 'day' },
    { value: this.dateMap['week'], label: 'week' },
    { value: this.dateMap['month'], label: 'month' },
    { value: this.dateMap['all'], label: 'all' },
  ];
  reportData: {
    shopName: string;
    shopId: number;
    importProductCount: number;
    updatedProducts: number;
    createdProducts: number;
    fileChange: boolean;
    importDate: string;
  }[] = [];
  shops: any = [];
  updatedProducts: any = [];
  createdProducts: any = [];
  importedProducts: any = [];
  fileChange: any = [];
  shopName = [];
  shopId = [];
  importDate = [];
  labels: any[] = [];

  datacollection: any = {};
  selectedStartDate: any = this.startDates[3].value;
  selectedShop = this.shops[0] ? undefined : 'SNKR_Tint';
  async getData() {
    const result = await this.$apollo.query({ query: GET_REPORTS });
    if (result != null) {
      this.reportData = result.data.reports;
    }
    return result.data;
  }

  onPageChange(page: number) {
    super.onPageChange(page);
  }

  /*
  loadAsyncData() {
    this.loading = true;
    this.$apollo.queries.products.refetch({
      productsClient: this.selectedClient,
      productsBrand: this.selectedBrand,
      productsOffset: this.perPage * (this.page - 1),
      productsLimit: this.perPage,
      productsQuery: this.query,
      productsActive: this.onlyActive,
      productsOnlyWithImages: this.onlyWithImages,
      productsOnlyWithAvailabilities: this.onlyWithAvailabilities,
      productsShop: this.selectedShop,
    });
  }
  */
  loadAsyncData() {
    this.loading = true;
    this.$apollo.queries.products.refetch({
      productsShop: this.selectedShop,
    });
    this.$apollo.queries.getAllNotRunningImports.refetch({
    });
  }

  updateImportData() {
    switch(this.selectedImportOption) {
      case "1":
        this.tableData = this.runningImportAlerts;
        break;
      case "2":
        this.tableData = this.notRunningImportAlerts;
        break;
      case "3":
        this.tableData = this.importAlerts;
        break;
      default:
        this.tableData = this.runningImportAlerts;

    }
  }
  updateLabels() {
    //Remove old data
    this.updatedProducts = [];
    this.createdProducts = [];
    this.importedProducts = [];
    this.labels = [];
    this.tableData = [];

    //fetch new data for the selected shop and time
    for (const report of this.reportData) {
      const date = new Date(report.importDate);
      const diffTime = Math.round((new Date().getTime() - date.getTime()) / (1000 * 3600 * 24));

      if (this.selectedStartDate >= diffTime && this.selectedShop == report.shopName) {
        this.updatedProducts.push(report.updatedProducts);
        this.createdProducts.push(report.createdProducts);
        this.importedProducts.push(report.importProductCount);
        this.fileChange.push(report.fileChange);
        if (this.selectedStartDate == 7) this.labels.push(this.weekdays[date.getDay()]);

        if (this.selectedStartDate == 1) this.labels.push(date.toLocaleTimeString('de-DE'));

        if (this.selectedStartDate > 30) this.labels.push(date.toLocaleDateString('de-DE'));
      }
    }

    //update data in chart
    this.datacollection = {
      labels: this.labels,
      datasets: [
        {
          label: 'Updated Products',
          data: this.updatedProducts,
          borderColor: '#48c774',
        },
        {
          label: 'Created Products',
          data: this.createdProducts,
          borderColor: '#ffdd57',
        },
        {
          label: 'Imported Products',
          data: this.importedProducts,
          borderColor: '#3298dc',
        },
        {
          label: 'File Changed',
          data: this.fileChange,
          borderColor: '#000000',
        },
      ],
    };
  }
}
