
import { Component, Vue } from 'vue-property-decorator';
import { CREATE_ALIAS } from '@/graphlql/mutations';

@Component({})
export default class AliasesCreate extends Vue {
  loading: boolean = false;
  result: boolean = false;
  success: boolean = false;
  error: boolean = false;
  origin = '';
  alias = '';
  output = 'Noch nicht angelegt:';

  save() {
    if (this.origin === '' || this.alias === '') {
      this.output = 'Bitte geben sie gültige Werte ein';

      this.$emit('alias:notcreated');

      setTimeout(() => this.clearStates(), 1000);
      setTimeout(() => this.clearStates(), 1000);
    } else
      this.$apollo
        .mutate({
          // Query
          mutation: CREATE_ALIAS,
          // Parameters
          variables: {
            input: {
              origin: this.origin,
              alias: this.alias,
              isActive: true,
              // Id is markes as optional in services but for some reason still required here. The Id passed here will not be used, so just
              // pass whatever. Services will discard it and generate the actual id.
              id: 18
            }
          }
        })
        .then(data => {
          this.loading = false;
          this.result = true;
          this.error = false;
          this.success = true;

          this.$emit('alias:created', data.data);

          setTimeout(() => this.clearStates(), 1000);
        })
        .catch(error => {
          this.loading = false;
          this.result = true;
          this.error = true;
          this.success = false;
          setTimeout(() => this.clearStates(), 1000);
        });
  }
  clearStates() {
    this.loading = false;
    this.result = false;
    this.error = false;
    this.success = false;
  }
}
