
import Vue from 'vue';
import Component from 'vue-class-component';

import { GET_CLIENTS, GET_FILTERBUBBLES } from '@/graphlql/queries';
import { UPDATE_FILTERBUBBLE, CREATE_FILTERBUBBLE, DELETE_FILTERBUBBLE } from '@/graphlql/mutations';
import TableBase from '@/components/common/TableBase.vue';
import BaseView from '../common/BaseView.vue';

@Component({
  components: { BaseView },
  apollo: {
    filterbubbles: {
      query: GET_FILTERBUBBLES,
      manual: true,

      result(data: any) {
        if (data.data != null) {
          if (!data.loading) {
            this.loading = data.loading;
            const tableData: any = [];
            for (const filterbubble of data.data.filterbubbles) {
              const simpleFiterbubble = {
                id: filterbubble.id,
                content: filterbubble.content,
                clientId: filterbubble.clientId,
              };

              tableData.push(simpleFiterbubble);
            }
            this.tableData = tableData;
          }
        }
      },
    },

    clients: {
      query: GET_CLIENTS,
      manual: true,

      result(data: any) {
        if (data.data != null) {
          let clients: any = [];
          if (!data.loading) {
            this.loading = data.loading;
            clients = data.data.clients;
          }
          this.clients = clients;
        }
      },
    },
  },
})
export default class FilterBubblesView extends TableBase {
  saveSuccess: boolean = false;
  saveError: boolean = false;
  saveResult: boolean = false;
  saveLoading: boolean = false;
  isPaginated: boolean = true;
  perPage: number = 15;
  isPaginationRounded: boolean = true;
  clients: any[] = [];
  currentFilterBubbles: any[] = [];
  deleted: number[] = [];
  currentClient = { name: 'invalid', id: -1 };

  async loadAsyncData() {
    await this.$apollo.queries.filterbubbles.refetch();
    await this.$apollo.queries.clients.refetch();
  }

  mounted() {
    super.mounted();
    this.loadAsyncData();
  }

  tenNewFilterbubbles() {
    for (let i = 0; i < 10; i++) {
      this.newFilterbubble();
    }
  }

  newFilterbubble() {
    if (this.currentClient.id != -1) {
      const x = { id: -1, content: '', clientId: this.currentClient };
      this.tableData = this.tableData.concat(x);
      this.currentFilterBubbles = this.currentFilterBubbles.concat(x);
    }
  }

  resetSaveButton() {
    this.saveSuccess = false;
    this.saveError = false;
    this.saveResult = false;
    this.saveLoading = false;
  }

  async refresh() {
    await this.loadAsyncData();
    const newFilterbubbles = [];
    for (const filterbubble of this.tableData) {
      if (filterbubble.clientId === this.currentClient) {
        newFilterbubbles.push(filterbubble);
      }
    }
    this.currentFilterBubbles = newFilterbubbles;
  }

  async save() {
    this.saveLoading = true;
    for (const filterBubble of this.tableData) {
      if (filterBubble.id != -1 && filterBubble.content != '') {
        await this.$apollo
          .mutate({
            // Query
            mutation: UPDATE_FILTERBUBBLE,
            // Parameters
            variables: {
              filterbubbleId: filterBubble.id,
              content: filterBubble.content,
              clientId: filterBubble.clientId,
            },
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (filterBubble.id === -1 && filterBubble.content != '') {
        await this.$apollo
          .mutate({
            // Query
            mutation: CREATE_FILTERBUBBLE,
            // Parameters
            variables: {
              content: filterBubble.content,
              clientId: filterBubble.clientId,
            },
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    for (const id of this.deleted) {
      await this.$apollo
        .mutate({
          // Query
          mutation: DELETE_FILTERBUBBLE,
          // Parameters
          variables: {
            deleteFilterbubbleId: id,
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    await this.refresh();
    this.saveLoading = false;
    this.saveResult = true;
    this.saveError = false;
    this.saveSuccess = true;
    setTimeout(() => this.resetSaveButton(), 700);
  }

  set chosenClient(value: any) {
    this.currentClient = this.getClientById(value);
    this.refresh();
  }

  get chosenClient() {
    return this.currentClient;
  }

  deleteFilterbubble(filterbubbleId: number) {
    if (filterbubbleId != -1) {
      this.deleted = this.deleted.concat(filterbubbleId);
    }
    const newFilterbubbles: any[] = [];
    const newcurrentFilterBubbles: any[] = [];
    for (const filterBubble of this.tableData) {
      if (filterBubble.id != filterbubbleId) {
        newFilterbubbles.push(filterBubble);
      }
    }
    for (const filterBubble of this.currentFilterBubbles) {
      if (filterBubble.id != filterbubbleId) {
        newcurrentFilterBubbles.push(filterBubble);
      }
    }
    this.tableData = newFilterbubbles;
    this.currentFilterBubbles = newcurrentFilterBubbles;
  }

  getClientNameById(id: number) {
    for (const client of this.clients) {
      if (client.id === id) {
        return client.name;
      }
    }
    return '';
  }

  getClientById(id: number) {
    for (const client of this.clients) {
      if (client.id === id) {
        return client.id;
      }
    }
    return undefined;
  }
}
