
import EditSingleColor from '@/components/common/EditSingleColor.vue';
import Component from 'vue-class-component';
import BaseCmsComponent from './base/BaseCmsComponent.vue';
import BaseCmsComponentClass from './base/BaseCmsComponentClass.vue';

@Component({
  props: {
    settings: {
      type: Object,
    },
    content: {
      type: Object,
    },
  },
  components: {
    BaseCmsComponent,
    EditSingleColor
  },
  computed: {
    rawHtml() {
      const self = this as Headline;
      return `<${self.content.type} style="color: ${self.content.color}">${self.content.text}</${self.content.type}>`;
    },
  },
})
export default class Headline extends BaseCmsComponentClass {
  sizes: string[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  alignment: string[] = ['left', 'center', 'right'];
  colorPickerHandler(hex: string) {
    this.content.color = hex;
  }
}
