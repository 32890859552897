
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import BaseView from '../common/BaseView.vue';
import StoriesTable from '@/components/cms/StoriesTable.vue';

@Component({
  components: {
    StoriesTable,
    BaseView,
  },
})
export default class StoriesView extends Vue {
  createHandler() {
    this.$router.push({ path: `/cms/stories/${this.$route.params.client}/create` });
  }
}
